import React, 
{useRef, 
useState, 
useCallback, 
useEffect } from "react";
import {
  Form,
  Button,
  ButtonGroup,
  Card,
  Alert,
  Tab,
  Tabs,
  Carousel,
  Image,
  Container,
  Row,
  Col,
  ListGroup,
  Navbar,
  Modal,
  Dropdown,
  DropdownButton,
  FloatingLabel,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate, useParams, Route } from "react-router-dom";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { fires, auth } from "../firebase";
import { sendEventEmail, sendEventTicketEMTPaymentEmail } from "../contexts/API";
import firebase from "firebase/app";
import "firebase/firestore";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { validate } from "email-validator";



export default function EventTestRegistration() {
  
    const { currentUser, logout, signup, login } = useAuth();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showCompleted, setShowCompleted] = useState(false)
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "40%";

  const { t, i18n } = useTranslation();

  const lngs = [
    { code: "en", native: "English" },
    { code: "zh", native: "简体中文" },
  ];

  let { id, lang } = useParams();
  var currentUID = ""

  const privacyLink = "https://www.leyuanreview.com/privacy"

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [registerType, setRegisterType] = useState(0)
  const [eventDetails, setEventDetails] = useState({
    title: '',
    description: '',
    descriptions: {
        'en': [],
        'zh': []
    },
    photoURL: '',
    id: '',
    signup: false,
    ticketStatus: false,
  });
  const [eventOptions, setEventOptions] = useState({
    'allow-multiple': false,
    'comment':false,
    'draw':false,
    'picture':true,
  })
  const [eventPriceOptions, setEventPriceOptions] = useState(
      {
      priceType: 'FREE', 
      price: 0, 
      priceOriginal: 0, 
      quan: 1,
      priceId: '', 
      productId: '', 
      priceCurrency: 'CAD', 
      priceEMTEmail: ''
    })

  const [orgDetails, setOrgDetails] = useState({});
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });
  const [eventAdditionalQuestions, setEventAdditionalQuestions] = useState([
    {
      title: '',
      placeholder: '',
      id: '',
      order: -1,
      optionType: 'text',
      valueType: 'text',
      required: false,
      options: [],
      optionMore: false,
      value: '',
    }
  ])
  const [eventMemberDetails, setEventMemberDetails] = useState({})

  const eventRegisterEmailRef = useRef()
  const eventRegisterFirstNameRef = useRef()
  const eventRegisterLastNameRef = useRef()
  

  const eventLoginEmailRef = useRef()
  const eventLoginPasswordRef = useRef()

  const eventPurchaseFirstNameRef = useRef()
  const eventPurchaseLastNameRef = useRef()
  


  const routeRedirect404 = () =>{ 
    let path = '/404' ; 
    window.location.href = path
  }

  const routeBackEvents = (eId) => {
    let path = '/'+eId ; 
    window.location.href = path
  }

  const [coverImg,setCoverImg] =
    useState("https://images.unsplash.com/photo-1439792675105-701e6a4ab6f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1746&q=80");
  const [profileImg,setProfileImg] =
    useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png");

    function createTicketId() {
      const fNumber = Math.floor(1000 + Math.random() * 9000);
      const sNumber = Math.floor(1000 + Math.random() * 9000);
      const tNumber = Math.floor(1000 + Math.random() * 9000);
      const idString =
        fNumber.toString() + "-" + sNumber.toString() + "-" + tNumber.toString();
      return idString;
    }
  
    function registerTicket(ticket, eTitle, eId, eOrgName, userName) {
      if (ticket != "") {
        const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
        var ticketInfo = {
          validated: true,
          eventTitle: eTitle,
          eventId: eId,
          userName: userName,
          fromUID: '',
          eventOrgName: eOrgName,
          timestamp: timestampNow,
        };
        fires.collection("event_tickets").doc(ticket).set(ticketInfo);
      }
    }

    function handleEventEmtPayment(userEmail,userName,firstName,lastName){
      var formAnswers = []
      eventAdditionalQuestions.forEach((eventQItm) => {
        formAnswers.push(
          {
          title: eventQItm.title, 
          value: eventQItm.value,
          id: eventQItm.id,
        })
      })

      const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
      const ua = window.navigator.userAgent.toLowerCase();
      fires
                .collection("events")
                .doc(id)
                .collection("members")
                .doc(userEmail)
                .set({
                  name: userName,
                  checkinStatus: false,
                  payment: false,
                  firstName: firstName,
                  lastName: lastName,
                  quantity: eventPriceOptions.quan,
                  work: '',
                  wechat: '',
                  fromUA: ua,
                  timestamp: timestampNow,
                  email: userEmail,
                  paymentType: 'EMT',
                  lang: i18n.language,
                  forms: formAnswers,
                })
                .then(() => {
                  
                  sendEventTicketEMTPaymentEmail(
                    userEmail,
                    eventDetails.title,
                    id,
                    eventPriceOptions.priceEMTEmail,
                    eventPriceOptions.price,
                    '',
                    '',
                    userName,
                    eventDetails.user.userOrg,
                    i18n.language,
                    eventPriceOptions.quan)

                    setShowCompleted(true)
                });
    }

    function handleEventFreeRegistration(userEmail,userName,firstName,lastName){
      var formAnswers = []
      eventAdditionalQuestions.forEach((eventQItm) => {
        formAnswers.push(
          {
          title: eventQItm.title, 
          value: eventQItm.value,
          id: eventQItm.id,
        })
      })
      const ticketId = createTicketId();
      const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
      const ua = window.navigator.userAgent.toLowerCase();
      fires
                .collection("events")
                .doc(id)
                .collection("members")
                .doc(userEmail)
                .set({
                  name: userName,
                  checkinStatus: false,
                  payment: false,
                  firstName: firstName,
                  lastName: lastName,
                  quantity: eventPriceOptions.quan,
                  work: '',
                  wechat: '',
                  fromUA: ua,
                  ticketId: ticketId,
                  timestamp: timestampNow,
                  email: userEmail,
                  paymentType: 'FREE',
                  lang: i18n.language,
                  forms: formAnswers,
                })
                .then(() => {
                  
                  const dateString =
                      eventDetails.startDate.toDate().toLocaleDateString("zh") +
                      " " +
                      eventDetails.startDate.toDate().toLocaleTimeString("zh");
                    var orgName = "";
                    if (eventDetails.user.userOrg != null) {
                      orgName = eventDetails.user.userOrg;
                    }
                    registerTicket(ticketId, eventDetails.title, id, orgName,userName);
                    sendEventEmail(
                      userEmail,
                      eventDetails.title,
                      i18n.language,
                      dateString,
                      eventDetails.address.fullAddress == ""
                        ? "线上活动"
                        : eventDetails.address.fullAddress,
                      id,
                      userName,
                      ticketId,
                      orgDetails.name
                    );

                    setShowCompleted(true)
                });
    }


    async function handleUserRegister(e){
        e.preventDefault()
        const uFirstName = eventRegisterFirstNameRef.current.value;
        const uLastName = eventRegisterLastNameRef.current.value;
        const uEmail = eventRegisterEmailRef.current.value.toLowerCase();
        setError("");
        setLoading(true);
        if (!eventDetails.ticketStatus) {
          //ticket not available
          window.alert("Ticket is not available to purchase")
        }
        else{
          if (eventPriceOptions.priceType == "EMT" && eventPriceOptions.price > 0){
            handleEventEmtPayment(uEmail,uFirstName + " " + uLastName,uFirstName,uLastName)
          }
          else if (eventPriceOptions.priceType == "FREE") {
            //direct register ticket
            handleEventFreeRegistration(uEmail,uFirstName + " " + uLastName,uFirstName,uLastName)
          }
          else if (eventPriceOptions.priceId != "" && eventPriceOptions.price > 0 && eventPriceOptions.priceType == "CREDIT"){
            
          }
          else{
            window.alert("Price is not correct")
          }
        }
        
        
      }


      function ValidateEmail(mail) 
  {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return (true)
  }
    
    return (false)
}





      


    
    useEffect(() => {
        fires.collection('events').doc(id).get().then((snapshot) => {
            if (snapshot.exists) {
                const postData = snapshot.data()

                document.title = postData.title;
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", postData.description);

              var ua = window.navigator.userAgent.toLowerCase();

          const timestampNow = firebase.firestore.Timestamp.fromDate(
            new Date()
          );
          const platformName = "web";
          let deviceName = "";
          if (isAndroid) {
            deviceName = "android";
          } else if (isIOS) {
            deviceName = "ios";
          } else {
            deviceName = "web";
          }
          
          var viewLang = i18n.language
          const viewData = {
            fromUA: ua,
            fromUID: '',
            platform: platformName,
            device: deviceName,
            lang: viewLang,
            timestamp: timestampNow,
          };

          fires.collection("events").doc(id).collection("views").add(viewData);

            if (postData.photoURL !== "") {
              let link = document.querySelector("link[rel~='icon']");
              if (!link) {
                link = document.createElement("link");
                link.rel = "icon";
                document.getElementsByTagName("head")[0].appendChild(link);
              }
              link.href = postData.photoURL;
            }
            
                if (lang != null){
                  i18n.changeLanguage(lang)
                }
                if (postData.status != null) {
                  if (postData.status == 'live' || postData.status == 'private' || postData.status == 'paid-event'){
                    
                    if (postData.priceType != null){
                        if (postData.priceType == "CREDIT" || postData.priceType == "EMT" || postData.priceType == "FREE"){

                          var ticketS = false
                          if (postData.ticketStatus != null){
                            ticketS = postData.ticketStatus
                          }
                          else{
                            ticketS = true
                          }
                          var descriptionsAsset = {en: [], zh: []}
                          if (postData.descriptions != null){
                              if (postData.descriptions.en != null && postData.descriptions.zh != null) {
                                  descriptionsAsset = postData.descriptions
                              }
                          }

                          setEventDetails({...postData, ticketStatus: ticketS, descriptions: descriptionsAsset})
                          
                            setEventPriceOptions({
                                quan: 1,
                                price: postData.price, 
                                priceCurrency: postData.priceCurrency, 
                                priceEMTEmail: postData.priceEMTEmail, 
                                priceId: postData.priceId, 
                                priceOriginal: postData.priceOriginal, 
                                priceType: postData.priceType})
                            
                        }
                        else{
                            routeBackEvents(id)
                        }
                    }
                    else{
                        routeBackEvents(id)
                    }

                    var fuid = ''
                    var femail = ''
                    const orgId = postData.orgId

                    //options
                    if (postData.options != null){
                      setEventOptions(postData.options)
                    }

                    fires.collection('events').doc(id).collection('forms').get().then((snapshotVal)=>{
                      var formDataSet = []
                      snapshotVal.docs.forEach((itm) => {
                        const formId = itm.id
                        const formData = itm.data()
                        formDataSet.push({...formData, id: formId, value: ''})
                      })

                      formDataSet.sort((a,b) => {
                        return a.order - b.order
                      })

                      setEventAdditionalQuestions(formDataSet)
                    })

                    if (auth.currentUser != null) {
                        fuid = auth.currentUser.uid
                        currentUID = fuid
                        femail = auth.currentUser.email
                        if (femail != ''){
                            fires.collection('events').doc(id).collection('members').doc(femail).get().then((snapshotA)=>{
                                if (snapshotA.exists){
                                    const memberDetails = snapshotA.data()
                                    setEventMemberDetails({...memberDetails})
                                    setRegisterType(3)
                                    if (postData.priceType == "EMT"){
                                      const paymentConfirm = window.confirm(i18n.language == 'en' ? 'Have you paid ticket price using EMT?' : '您已经用电子邮箱转账了吗？')
                                      if (paymentConfirm){
                                        window.location.href = "/"+id
                                      }
                                    }
                                    

                                    if (fuid != ''){
                                        fires.collection('users').doc(fuid).get().then((snapshotA)=>{
                                            const snapData = snapshotA.data()
                                            setUserDetails({...snapData, id: fuid})
                                        })
                                        if (orgId != ""){
                                            fires.collection('groups').doc(orgId).get().then((snapshotA)=>{
                                                const snapData = snapshotA.data()
                                                setOrgDetails({...snapData})
                                                if (snapData.profileURL != ""){
                                                    setProfileImg(snapData.profileURL)
                                                }
                                                if (snapData.coverURL != ""){
                                                    setCoverImg(snapData.coverURL)
                                                }
                                                
                                            })
                                        }
                                    }
                                    else{
                                        
                                    }

                                }
                                else{
                                    
                                }
                            })
                        }
                        else{
                            routeRedirect404()
                        }
                    }
                    else{
                        //no auth
                        if (orgId != ""){
                          fires.collection('groups').doc(orgId).get().then((snapshotA)=>{
                              const snapData = snapshotA.data()
                              setOrgDetails({...snapData})
                              if (snapData.profileURL != ""){
                                  setProfileImg(snapData.profileURL)
                              }
                              if (snapData.coverURL != ""){
                                  setCoverImg(snapData.coverURL)
                              }
                          })
                      }
                    }
                  }
                  else{
                    //not live, or ended
                    routeRedirect404()
                  }
                }
                else{
                  //not found
                  //deleted
                  routeRedirect404()
                // posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
                // setPosts(posts)
                }

            }
            else{
                //deleted
                routeRedirect404()
                // posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
                // setPosts(posts)
                
            }
        });
      }, []);

  return (
    <>

<Helmet>
                <title>{eventDetails.title}</title>
                <meta
                  name="description"
                  content={eventDetails.description}
                />
                <meta name="twitter:card" content={eventDetails.photoURL} />
                <meta name="twitter:site" content="@user" />
                <meta name="twitter:creator" content="@user" />
                <meta name="twitter:title" content={eventDetails.title} />
                <meta
                  name="twitter:description"
                  content={eventDetails.description}
                />
                <meta name="twitter:image" content={eventDetails.photoURL} />
                <meta property="og:title" content={eventDetails.title} />
                <meta
                  property="og:description"
                  content={eventDetails.description}
                />
                <meta property="og:image" content={eventDetails.photoURL} />
                <meta
                  property="og:url"
                  content={"https://rileyevents.com/" + id}
                />
                <meta
                  property="og:site_name"
                  content={eventDetails.title}
                />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
              </Helmet>

      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh",minWidth: '100vw',backgroundImage: 'url(' + coverImg + ')' }}
      >
        <div className="w-100" style={{ maxWidth: maxW }}>
          <Card
            className="border-0 align-items-center justify-content-center" 
            style={{
                alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor:"white",
            marginTop: '25px',
            marginBottom: '25px'}}
          >
            <Card.Img
              variant="top"
              src={eventDetails.photoURL}
              style={{ maxHeight: "600px" }}
            />
              <Card.Body className = "align-items-center justify-content-center">
                
              <Card.Title className="text-center"
               style={{ fontSize: "30px",
                fontWeight:'bold', 
                color: "#000000"}}>{eventDetails.title}</Card.Title>
                {
                    eventDetails.descriptions.zh.map((descItm) => {
                        return <Card.Text className="text-center"
               style={{ fontSize: "15px",
                fontWeight:'normal', 
                marginTop: '12px',
                color: "#000000"}}>{descItm}</Card.Text>
                    })
                }
            {/* <Card.Text className="text-center"
               style={{ fontSize: "18px",
                fontWeight:'normal', 
                marginTop: '20px',
                color: "#000000"}}>{eventDetails.description}</Card.Text> */}
             
             {error && <Alert variant="danger">{error}</Alert>}
             {success && <Alert variant="success">{success}</Alert>}
                  
             <Modal
                  show={showCompleted}
                  onHide={() => {
                    setShowCompleted(false);
                    window.location.href = "/" + id
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {t("event_details_success_header")}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div
                      className="justify-content-center align-items-center"
                      style={{
                        marginTop: "15px",
                        marginBottom: "15px",
                      }}
                    >
                      <Card.Title className="text-center">
                        {'已完成活动报名'}
                      </Card.Title>
                      <Card.Text
                        className="text-center"
                        style={{ marginTop: "15px" }}
                      >
                        {eventPriceOptions.priceType == "EMT" ? '您已经完成活动报名，我们将会发送邮件告知您转账信息，请您及时查收并转账! ' : '您已经完成活动报名'}
                      </Card.Text>
                    </div>
                    <div className="align-items-start" controlId="price-item" style={{marginTop: '25px'}}>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="fw-normal">{t('event_details_registration_header_payment_type')}</div>
                            <div className="fw-bold">{eventPriceOptions.priceType == 'EMT' ? 'Email Transfer' : 'Credit Card'}</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="fw-normal">{t('event_details_registration_header_original_price')}</div>
                            <div className="fw-bold">${Math.round(eventPriceOptions.priceOriginal * 100) / 100}{eventPriceOptions.priceCurrency}</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="fw-normal">{t('event_details_registration_header_service_fee')}</div>
                            <div className="fw-bold">${Math.round((eventPriceOptions.price - eventPriceOptions.priceOriginal) * 100) / 100}{eventPriceOptions.priceCurrency}</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="fw-bold">{t('event_details_registration_header_total_price')}</div>
                            <div className="fw-bold">${eventPriceOptions.price * eventPriceOptions.quan}{eventPriceOptions.priceCurrency}</div>
                        </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setShowCompleted(false);
                        eventRegisterEmailRef.current.value = ""
                        eventRegisterFirstNameRef.current.value = ""
                        eventRegisterLastNameRef.current.value = ""
                        window.location.href = "/" + id
                      }}
                    >
                      {t("event_details_action_ok")}
                    </Button>
                  </Modal.Footer>
                </Modal>

                    <Form>
                    <Form.Group className="mb-3" controlId="register.lastn">
                    <FloatingLabel
                    label={t("event_details_registration_header_signup_firstname") + " *"}>
                  <Form.Control
                          type="text"
                          placeholder=""
                          defaultValue=""
                          ref={eventRegisterFirstNameRef}
                          autoFocus
                          required
                        />
                  </FloatingLabel>
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="register.firstn">
                      <FloatingLabel
                    label={t("event_details_registration_header_signup_lastname") + " *"}>
                  <Form.Control
                          type="text"
                          placeholder=""
                          defaultValue=""
                          ref={eventRegisterLastNameRef}
                          required
                        />
                  </FloatingLabel>
                        
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="register.email">
                        <FloatingLabel
                    label={t("event_details_registration_header_signup_email") + " *"}>
                        <Form.Control
                          type="email"
                          placeholder=""
                          ref={eventRegisterEmailRef}
                          defaultValue=""
                          onChange={(e)=>{
                            const emailEntered = e.target.value
                            if (validate(emailEntered)){
                              console.log('valid email: '+emailEntered)
                            }
                            else{
                              console.log('not valid email: '+emailEntered)
                            }
                          }}
                          required
                        />
                    </FloatingLabel>

                      </Form.Group>
                      
                      <Form.Group className="mb-3" controlId="register.quan" hidden={(!eventOptions["allow-multiple"] && eventOptions["allow-multiple"] != null)}>
                      <FloatingLabel
                    label={t("event_details_registration_header_quantity") + " *"}>
                      <Form.Control
                          type="number"
                          placeholder=""
                          defaultValue={eventPriceOptions.quan}
                          min={1}
                          onChange={(e)=>{
                            const quan = parseInt(e.target.value)
                            if (quan >= 1){
                              var priceOpt = eventPriceOptions
                              priceOpt.quan = quan
                              setEventPriceOptions(priceOpt)
                              setSuccess('更新至 '+quan + '人')
                            }
                          }}
                          required
                        />
                      </FloatingLabel>
                        
                      </Form.Group>

                      </Form>

                      <Form>
                      {
                        eventAdditionalQuestions.map((formItm) => {
                          const orderI = formItm.order

                          if (formItm.optionType == 'options'){
                            const moreOptons = formItm.optionMore
                            
                            return <Form.Group className="mb-3" controlId="register.email">
                                      <Form.Label>{formItm.title} {formItm.required ? '*' : ''}</Form.Label>
                                      <Form.Label><b>{formItm.value == '' ? '' : ' - ' + formItm.value}</b></Form.Label>
                                      
                                <DropdownButton
                              title={formItm.placeholder}
                              className="mb-3"
                              variant="outline-dark"
                              style={{ marginTop: "5px", fontWeight: "bold", minWidth: '100%' }}
                              onSelect={(e) => {
                                if (e == 'more-option'){
                                  const openDialogString = window.prompt(formItm.placeholder)
                                  if (openDialogString != "" && openDialogString != null){
                                    var eventQues = eventAdditionalQuestions
                                    eventQues[orderI].value = openDialogString
                                    setEventAdditionalQuestions(eventQues)
                                    setSuccess(formItm.title + ' - ' + openDialogString)
                                  }
                                }
                                else{
                                  var eventQues = eventAdditionalQuestions
                                  eventQues[orderI].value = e
                                  setEventAdditionalQuestions(eventQues)
                                  setSuccess(formItm.title + ' - ' + e)
                                }
                              }}
                            >
                              {
                                formItm.options.map((formOption) => {
                                  return <Dropdown.Item eventKey={formOption}>{formOption}</Dropdown.Item>
                                })
                              }
                              <Dropdown.Item hidden={!moreOptons} eventKey="more-option">更多...</Dropdown.Item>
                            </DropdownButton>

                              
                            
                            
                            
                          </Form.Group>
                          }
                          else if (formItm.optionType == 'text'){
                            return <Form.Group className="mb-3" controlId="register.email">
                            <FloatingLabel
                                label={formItm.title + (formItm.required ? ' *' : ' ')}>
                              <Form.Control
                              type={formItm.valueType}
                              placeholder={formItm.placeholder}
                              defaultValue=""
                              required={formItm.required}
                              onChange={(e)=>{
                                const enteredValue = e.target.value
                                var eventQues = eventAdditionalQuestions
                                eventQues[orderI].value = enteredValue
                                setEventAdditionalQuestions(eventQues)
                              }}
                            />
                            </FloatingLabel>
                          </Form.Group>
                          }
                          else if (formItm.optionType == 'textarea'){
                            return <Form.Group className="mb-3" controlId="register.email">
                              <FloatingLabel
                                label={formItm.title + (formItm.required ? ' *' : ' ')}>
                              <Form.Control
                              as="textarea"
                              rows={5}
                              placeholder={formItm.placeholder}
                              defaultValue=""
                              required={formItm.required}
                              onChange={(e)=>{
                                const enteredValue = e.target.value
                                var eventQues = eventAdditionalQuestions
                                eventQues[orderI].value = enteredValue
                                setEventAdditionalQuestions(eventQues)
                              }}
                            />
                                </FloatingLabel>
                          </Form.Group>
                          }
                          else{

                          }
                        })
                      }
                      </Form>

                      <Form style={{marginTop: '25px'}}>
                      <Form.Group className="align-items-start" controlId="price-item" style={{marginTop: '15px'}}>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="fw-normal">{t('event_details_registration_header_payment_type')}</div>
                            <div className="fw-bold">{eventPriceOptions.priceType == 'EMT' ? 'Email Transfer' : eventPriceOptions.priceType == 'CREDIT' ? 'Credit Card' : '免费FREE'}</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="fw-normal">{t('event_details_registration_header_original_price')}</div>
                            <div className="fw-bold">${Math.round(eventPriceOptions.priceOriginal * 100) / 100}{eventPriceOptions.priceCurrency}</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="fw-normal">{t('event_details_registration_header_service_fee')}</div>
                            <div className="fw-bold">${Math.round((eventPriceOptions.price - eventPriceOptions.priceOriginal) * 100) / 100}{eventPriceOptions.priceCurrency}</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="fw-bold">{t('event_details_registration_header_total_price')}</div>
                            <div className="fw-bold">${eventPriceOptions.price * eventPriceOptions.quan}{eventPriceOptions.priceCurrency}</div>
                        </div>
                    </Form.Group>
                    <Form.Group className="align-items-start" controlId="price-item" style={{marginTop: '15px'}}>
                        <div className="fw-bold">{t('event_details_heading_refund_info')}</div>
                        <div className="fw-normal">{eventPriceOptions.priceType == 'CREDIT' ? t('event_details_support_refund_stripe') : t('event_details_support_refund_possible')}</div><Card.Link href="https://stripe.com/en-ca/pricing" hidden={eventPriceOptions.priceType != 'CREDIT'}>Stripe</Card.Link>
                    </Form.Group>
                      <div 
                  className="d-flex align-items-center justify-content-center" 
                  id="form-register" style={{marginTop: '40px'}}>
                    <Button variant="dark" style={{width: '100%' ,height: '40px', fontWeight: 'bold'}} 
                    disabled={!eventDetails.ticketStatus}
                    onClick={(e)=>{
                      //actions register
                      //handleUserRegister(e)
                      }}>
                      {t('event_details_registration_header_register_action')}(${eventPriceOptions.price * eventPriceOptions.quan}{eventPriceOptions.priceCurrency})
                      </Button>
                    </div>

                    <Card.Text className="text-center">{t('event_details_registration_agreement')}</Card.Text>
                    <Form.Group 
                    className="d-flex align-items-center justify-content-center" 
                    style={{marginTop: '15px'}} 
                    controlId="register.instruction">
                        <Card.Link 
                        className="text-center" 
                        style={{color: 'black'}}
                        href={privacyLink}>{t('event_details_registration_header_signup_action_privacy')}</Card.Link>
                      </Form.Group>

                    </Form>
             
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}

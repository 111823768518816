import React, 
{useRef, 
useState, 
useCallback, 
useEffect } from "react";
import {
  Form,
  Button,
  ButtonGroup,
  Card,
  Alert,
  Tab,
  Tabs,
  Carousel,
  Image,
  Container,
  Row,
  Col,
  ListGroup,
  Badge,
  Navbar,
  FloatingLabel,
  Modal,
  Nav,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate, useParams, Route } from "react-router-dom";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { fires, auth } from "../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import uuid from "react-uuid";
import {
  FaClock,
  FaLandmark,
  FaGlassCheers,
  FaGlobeAmericas,
} from "react-icons/fa";
import { Helmet } from "react-helmet";
import { sendEmailTo } from "../contexts/API";

export default function EventOrganizer() {
  
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "70%";

  let { id } = useParams();
  var currentUA = ""

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showMemberInfo, setShowMemberInfo] = useState(false)

  const [orgEvents, setOrgEvents] = useState([]);
  const [orgPastEvents, setOrgPastEvents] = useState([]);
  
  const [orgDetails, setOrgDetails] = useState({
    name: '',
    profileURL: '',
    bannerURL: '',
    policy: ['-'],
    picURLs: [''],
    description: '',
    descriptions: [],
    contacts: [],
  });
  const [userDetails, setUserDetails] = useState({
  });
  const [orgInfo, setOrgInfo] = useState({
    email: '',
    firstName: '',
    lastName: '',
  })
  const [orgMemberInfo, setOrgMemberInfo] = useState({
    email: '',
    firstName: '',
    lastName: '',
  })
  const [isMember, setIsMember] = useState(false);
  const [isFollower, setIsFollower] = useState(false);
  const [orgPosts, setOrgPosts] = useState([])
  const [orgArticles, setOrgArticles] = useState([]);
  const [memberCount, setMemberCount] = useState(0)
  

  const contactEmailRef = useRef()
  const contactNameRef = useRef()
  const contactContentRef = useRef()

  const memberRequestEmailRef = useRef()
  const memberRequestFNRef = useRef()
  const memberRequestLNRef = useRef()
  const memberRequestCommentRef = useRef()

  const routeRedirect404 = () =>{ 
    let path = '/' ; 
    window.location.href = path
  }

  

  function loadLinkedPosts(){
    fires.collection('posts').where('orgId','==',id).limit(5).get().then((snapshot) => {
      var linkedPosts = []
      snapshot.docs.forEach((doc) => {
        const docData = doc.data()
        const docId = doc.id
        linkedPosts.push({...docData, id: docId})
      })
      
      setOrgPosts(linkedPosts)
    })
  }

  function loadLinkedArticles(){
    fires.collection('exps').where('orgId','==',id).limit(10).get().then((snapshot) => {
      var linkedArticle = []
      snapshot.docs.forEach((doc) => {
        const docData = doc.data()
        const docId = doc.id
        linkedArticle.push({...docData, id: docId})
      })
      setOrgArticles(linkedArticle)
    })
  }

  function routeToMemberCenter(){
    window.location.href = "/member-center/"+orgDetails.id
  }

    function routeToRequestJoin() {
      if (auth.currentUser != null){
        const uid = auth.currentUser.uid
        const uemail = auth.currentUser.email
        if (uid != "" && uemail != ""){
            //request directly
            let promptT = window.prompt("Let us know why you want to join?")
            if (promptT != ""){
              fires.collection('groups').doc(orgDetails.id).collection('requests').add({
                email: uemail,
                firstName: "",
                lastName: "",
                comment: promptT,
                uid: uid,
                status: '',
                timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
              })

            }
        }
        else{
          setShowMemberInfo(true)
        }
      }
      else{
        setShowMemberInfo(true)
      }
  }

  function requestToJoinMembership(){
    window.location.href = "/membership-request/"+id
  }

  function joinOrganizerMembership(){
    const memberREmail = memberRequestEmailRef.current.value
    const memberRFirstName = memberRequestFNRef.current.value
    const memberRLastName = memberRequestLNRef.current.value
    const memberRComment = memberRequestCommentRef.current.value

    fires.collection('groups').doc(orgDetails.id).collection('requests').add({
      email: memberREmail,
      firstName: memberRFirstName,
      lastName: memberRLastName,
      comment: memberRComment,
      uid: '',
      status: 'requested',
      timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
    }).then((docRef)=>{
      const refId = docRef.id

      if (auth.currentUser != null){
        const uid = auth.currentUser.uid
        if (uid != "" && orgDetails.id != ""){
          fires
          .collection('users')
          .doc(uid)
          .collection('groups')
          .doc(orgDetails.id).set({
          name: orgDetails.name,
          description: orgDetails.description,
          imageURL: orgDetails.profileURL,
          status: 'requested',
          id: refId,
          timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
        })

        }
        

      }
      setShowMemberInfo(false)
      window.alert("Your request has been recorded \n\n" + refId)
    })

  }

  function routeToUnFollowAccount() {
    if (auth.currentUser != null){
      const uid = auth.currentUser.uid
      const uemail = auth.currentUser.email
      if (uid != "" && uemail != ""){
        fires.collection('groups').doc(id).collection('followers').doc(uid).get().then((snapT)=>{
          if (snapT.exists){
            fires.collection('groups').doc(id).collection('followers').doc(uid).delete().then(()=>{
              fires.collection('users').doc(uid).collection('groups').doc(id).delete()
              window.location.reload()
            })
          }
          else{
            window.location.reload()
          }
        })
      }
      else{
          let path = 'https://letjoy.app/'+id ; 
          window.location.href = path
      }
    }
    else{
      let path = 'https://letjoy.app/'+id ; 
      window.location.href = path
    }
}

  const [coverImg,setCoverImg] =
    useState("https://images.unsplash.com/photo-1439792675105-701e6a4ab6f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1746&q=80");
  const [profileImg,setProfileImg] =
    useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png");

    useEffect(() => {
        fires.collection('groups').doc(id).get().then((snapshot) => {
            if (snapshot.exists) {
                const posts = [];
                const postData = snapshot.data();
                const uid = postData.fromUID
                if (postData.info != null){
                  setOrgInfo(postData.info)
                }

                if (postData.live != null) {
                  if (postData.live){
                    
                    var memCount = 0
                    if (postData.member_count != null){
                      memCount = postData.member_count
                      setMemberCount(memCount)
                    }
                    
                    loadLinkedPosts()
                    loadLinkedArticles()
                    // live user
                    // add views

                    var ua = window.navigator.userAgent.toLowerCase()
                    currentUA = ua
                    var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
                    const platformName = 'web'
                    var deviceName = 'ios'
                    if (isAndroid){
                        deviceName = 'android'
                      }
                      else if (isIOS) {
                        deviceName = 'ios'
                      }
                      else {
                        deviceName = 'web'
                      }
                      var fuid = ''
                      var femail = ''
                if (auth.currentUser != null) {
                  fuid = auth.currentUser.uid
                  if (auth.currentUser.email != null) {
                    femail = auth.currentUser.email
                  }
                  if (fuid != ''){
                    fires.collection('users').doc(fuid).collection('visits').add({orgId: id, fromUA: ua, timestamp: timestampNow})
                    fires.collection('users').doc(fuid).get().then((snapshotA)=>{
                        const snapData = snapshotA.data()
                        setUserDetails({...snapData, id: fuid})
                    })
                    if (femail != ''){
                      fires.collection('groups').doc(id).collection('members').doc(femail).get().then((snapshotA)=>{
                        setIsMember(snapshotA.exists)
                    })
                    }
                  }
                  
                }
                const viewData = {'fromUID':fuid, 'type':'page', 'fromUA':ua, 'platform': platformName, 'device': deviceName, 'timestamp':timestampNow}
                fires.collection('groups').doc(id).collection('views').add(viewData)

                if (postData.length == 0){
                  
                }
                else{
                  var picUrls = []
                  var policy = []
                  var bannerURL = ""
                  var descriptions = []
                  var contacts = []
                  if (postData.picURLs != null){
                    picUrls = postData.picURLs
                  }
                  if (postData.bannerURL != null){
                    bannerURL = postData.bannerURL
                  }
                  if (postData.policy != null){
                    policy = postData.policy
                  }
                  if (postData.descriptions != null){
                    descriptions = postData.descriptions
                  }
                  if (postData.contacts != null){
                    contacts = postData.contacts
                  }
                    setOrgDetails({...postData,
                      id:id,
                      picURLs: picUrls, 
                      bannerURL: bannerURL, 
                      policy: policy, 
                      descriptions: descriptions, 
                      contacts: contacts})

                    if (postData.coverURL != null){
                        setCoverImg(postData.coverURL)
                    }
                    if (postData.profileURL != null){
                        setProfileImg(postData.profileURL)
                    }

                    document.title = postData.name;
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", postData.description);

            if (postData.profileURL !== "") {
              let link = document.querySelector("link[rel~='icon']");
              if (!link) {
                link = document.createElement("link");
                link.rel = "icon";
                document.getElementsByTagName("head")[0].appendChild(link);
              }
              link.href = postData.profileURL;
            }

                    

                    fires
                    .collection('events')
                    .where('orgId','==',id)
                    .where('approved','==',true)
                    .orderBy('startDate','desc')
                    .get().then((snapshotA) => {
                      var orgEvents = [];
                      var orgPastEventss = []
                      snapshotA.docs.forEach((doc) => {
                        const contentId = doc.id;
                        const contentData = doc.data();
                        if (contentData.startDate != null && contentData.endDate != null){
                          const today = new Date()
                          const eStart = contentData.startDate.toDate()
                          const eEnd = contentData.endDate.toDate()
                          if (today < eStart){
                              //not started
                              orgEvents.push({ ...contentData, id: contentId, eventStatus: 'upcoming' })
                          }
                          else if (today >= eStart && today < eEnd){
                              //ongoing
                              orgEvents.push({ ...contentData, id: contentId, eventStatus: 'ongoing' })
                          }
                          else if (today >= eEnd){
                              //end
                              orgPastEventss.push({ ...contentData, id: contentId, eventStatus: 'completed' })
                          }
                          else{
                              //false
                          }
                      }
                      })
                      setOrgEvents(orgEvents)
                      setOrgPastEvents(orgPastEventss)
                  })

                }

                  }
                  else{
                    //not live
                    routeRedirect404()
                  }
                }
                else{
                  //not found
                  //deleted
                  routeRedirect404()
                // posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
                // setPosts(posts)
                }

            }
            else{
                //deleted
                routeRedirect404()
                // posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
                // setPosts(posts)
                
            }
        });
      }, []);

  return (
    <>
         <Helmet>
                <title>{orgDetails.name}</title>
                <meta
                  name="description"
                  content={orgDetails.description}
                />
                <meta name="twitter:card" content={orgDetails.profileURL} />
                <meta name="twitter:site" content="@user" />
                <meta name="twitter:creator" content="@user" />
                <meta name="twitter:title" content={orgDetails.name} />
                <meta
                  name="twitter:description"
                  content={orgDetails.description}
                />
                <meta name="twitter:image" content={orgDetails.profileURL} />
                <meta property="og:title" content={orgDetails.name} />
                <meta
                  property="og:description"
                  content={orgDetails.description}
                />
                <meta property="og:image" content={orgDetails.profileURL} />
                <meta
                  property="og:url"
                  content={"https://rileyevents.com/organizer/" + id}
                />
                <meta
                  property="og:site_name"
                  content={orgDetails.name}
                />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
              </Helmet>
      <Container
        className="d-flex justify-content-center"
        style={{ minHeight: "100vh",minWidth: '100vw', backgroundColor: '#EEE' }}
      >
        <div className="w-100" style={{ maxWidth: maxW }}>

        <Card className="border-0" style={{ marginTop: '25px', marginBottom: '25px', padding: '20px', borderRadius: '15px'}}>

        <Carousel
        hidden={orgDetails.bannerURL == ''}
        >
          <Carousel.Item>
          <Card.Img
          variant="top"
          src={orgDetails.bannerURL}
          hidden={orgDetails.bannerURL == ''}
          style={{
            objectFit: 'cover',
            maxHeight: isMobile ? '600px' : '1200px'
          }}
        />
          </Carousel.Item>
          {
            orgDetails.picURLs.map((urlString) => {
              return <Carousel.Item>
              <Card.Img
              variant="top"
              src={urlString}
              hidden={orgDetails.bannerURL == ''}
              style={{
                maxHeight: isMobile ? '500px' : '1000px', 
              }}
            />
              </Carousel.Item>
            })
          }

        </Carousel>

        

          <div className="d-flex align-items-center justify-content-between" style={{
            marginTop: '25px'
          }}>

          <div
                              className="d-flex align-items-center border-0"
                              style={{
                                alignSelf: "center",
                                display: "flex",
                                width: "100%",
                                marginLeft: '15px',
                                marginRight: '15px',
                                marginBottom: "5px",
                              }}
                              onClick={()=>{
                                
                              }}
                              >
                                <Card.Img
                                  src={profileImg}
                                  style={{height: isMobile ? '50px' : '150px', width: isMobile ? '50px' : '150px'}}
                                />
                                <div style={{ marginLeft: "15px" }}>
                                <Card.Title
          style={{ fontSize: isMobile ? '20px' : "30px", fontWeight: "bold" }}
        >
          {orgDetails.name}
        </Card.Title>
                                <Card.Text
          style={{
            marginTop: "0px",
            fontSize: "17px",
            fontWeight: "bold",
            marginBottom: "0px",
          }}
        >
          {'Public Group'}
        </Card.Text>
        <Card.Text>
          {memberCount} Members
        </Card.Text>

                                </div>
                                </div>

          <div>
          <Button 
              style={{alignItems:'center', 
                        justifyContent:'space-between', 
                        backgroundColor:'black', 
                        borderRadius:'25px', 
                        borderColor:'#ffffff', 
                        borderWidth:'2px', 
                        fontSize: '15px', 
                        fontWeight:'bold', 
                        height: '50px',
                        width:  isMobile ? '100px' : '150px', 
                        marginTop: "15px"}} onClick={()=>{
                            if (isMember){
                                //member center
                                routeToMemberCenter()
                            }
                            else{
                                requestToJoinMembership()
                                //routeToRequestJoin()
                            }
                        }}>{isMember ? 'Member' : 'Join'}</Button>
          </div>

            <Modal show={showMemberInfo}
                  onHide={() => {
                    setShowMemberInfo(false);
                  }}>
            <Modal.Header closeButton>
                <Modal.Title>
                  Request to join <b>{orgDetails.name}</b>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <Form.Group style={{marginTop: '5px'}}>
                  <Form.Label>You will request to join organizer's group with exclusive events and offers, please fill the following information to join <b>{orgDetails.name}</b></Form.Label>
                </Form.Group>

                <Form.Group style={{marginTop: '15px'}}>
                  <FloatingLabel label="Email Address *">
                    <Form.Control type="email" ref={memberRequestEmailRef} placeholder="Email Address *" />
                  </FloatingLabel>
                </Form.Group>

                <Row md={2} style={{marginTop: '15px'}}>
                  <Col>
                  <Form.Group>
                  <FloatingLabel label="First Name *">
                    <Form.Control type="name" ref={memberRequestFNRef} placeholder="First Name *" />
                  </FloatingLabel>
                </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group>
                  <FloatingLabel label="Last Name *">
                    <Form.Control type="name" ref={memberRequestLNRef} placeholder="Last Name" />
                  </FloatingLabel>
                </Form.Group>
                  </Col>
                </Row>

                <Form.Group style={{marginTop: '15px'}}>
                  <FloatingLabel label="Comments">
                    <Form.Control as="textarea" rows={5} ref={memberRequestCommentRef} placeholder="Comments" />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group style={{marginTop: '15px'}}>
                  <Form.Label>If you already requested, please wait for our decision to approve you in this group.</Form.Label>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="success" onClick={()=>{
                joinOrganizerMembership()
              }}>Request to join</Button>
              <Button variant="secondary" onClick={()=>{
                setShowMemberInfo(false)
              }}>Cancel</Button>
            </Modal.Footer>
          </Modal>


          </div>

<Card.Body>

<Tabs
                              defaultActiveKey="overview"
                              id="organizer-tab"
                              className="border-0 mb-3"
                              style={{ }}
                              fill
                            >
                              <Tab
                              eventKey="overview"
                              className="border-0"
                              title="Home">

                <div>
                  <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>Group Details</Card.Text>
                </div>
                <Card.Text style={{fontSize: '17px', color: 'black', marginTop: '15px'}} hidden={orgDetails.descriptions.length != 0}>{orgDetails.description}</Card.Text>
                {
                  orgDetails.descriptions.map((desc)=>{
                    return <Card.Text style={{fontSize: '17px', color: 'black', marginTop: '15px'}}>{desc}</Card.Text>
                  })
                }
                <div>
                  <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>Group Location</Card.Text>
                </div>
                <Card.Text style={{fontSize: '17px', color: 'black', marginTop: '15px'}}>{orgDetails.location}, {orgDetails.country}</Card.Text>
                <div>
                  <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>Group Policy</Card.Text>
                </div>
                <br/>
                {
                  orgDetails.policy.map((policyItm) => {
                    return <Card.Text style={{fontSize: '17px', color: 'black', marginTop: '15px'}}>{policyItm}</Card.Text>
                  })
                }
                <div>
                  <Card.Text 
                  style={{
                    fontSize: '22px', 
                    fontWeight: 'bold', 
                    color: 'black'
                    }}>Group Links</Card.Text>
                </div>
                <br/>
                {
                  orgDetails.contacts.map((contactItm) => {
                    return <Card.Link style={{fontSize: '17px', color: 'black', marginTop: '15px'}} href={contactItm.url}>{contactItm.name}</Card.Link>
                  })
                }
                

                <div className="d-flex align-items-center justify-content-center">
                <Button 
                variant="dark" 
                hidden={true}
                style={{
                  alignItems:'center', 
                  justifyContent:'space-between', 
                  backgroundColor:'black', 
                  borderRadius:'25px', 
                  borderColor:'#ffffff', 
                  borderWidth:'2px', 
                  fontSize: '15px', 
                  fontWeight:'bold', 
                  height: '50px',
                  width: '250px',
                  marginTop: "15px"
                }}>
                  Request to join group
                </Button>
                </div>
                

                              </Tab>
                              
                              <Tab
                              eventKey="upcoming-event"
                              title="Upcoming Events"
                              onSelect={(e)=>{
                                
                              }}>

                  <div>
                  <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>Upcoming Events ({orgEvents.length})</Card.Text>
                </div>
                {
              orgEvents.map(eventItm => {
                return <Card className="border-0" style={{width: '100%', marginTop: '25px'}} onClick={()=> {
                  window.location.href = "/" + eventItm.id
                  }}>
          
          <Card.Body>
                              <div
                                    className="d-flex align-items-center"
                                    style={{
                                      alignSelf: "center",
                                      display: "flex",
                                      width: "100%",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <Card.Img style={{width: '100px', height: '75px', objectFit: 'cover'}} src={eventItm.photoURL} />
                                    <div style={{ marginLeft: '15px' }}>
                                      <Card.Text style={{fontSize: '12px', fontWeight: 'bold', color: '#F57828', margin: 0}}>{eventItm.startDate.toDate().toLocaleDateString('en')}</Card.Text>
                                      <Card.Text style={{fontSize: '17px', fontWeight: 'bold', color: 'black', margin: 0}}>{eventItm.title}</Card.Text>
                                      <Card.Text style={{fontSize: '12px', fontWeight: 'normal', color: 'black', margin: 0}}>{eventItm.address.fullAddress}</Card.Text>
                                      <Card.Text style={{fontSize: '12px', fontWeight: 'normal', color: 'black', margin: 0}}>{eventItm.startDate.toDate().toLocaleString('en')}</Card.Text>
                                    </div>
                                  </div>
            
          </Card.Body>
          </Card>
//                 return <Card className = "border-0 justify-content-center" style={{ margin: "25px", backgroundColor:"#ffffff", borderRadius: "15px"}}>
// <Card.Img variant="top" style={{maxHeight: "450px"}} src={eventItm.photoURL} />
// <Card.Title className="text-center" style={{margin: "15px", fontSize: '20px', fontWeight: 'bold'}}>{eventItm.title}</Card.Title>
// <Card.Text className="text-center" style={{margin: "15px", fontSize: '15px', fontWeight: 'normal', color: 'green'}}>{eventItm.eventStatus == 'ongoing' ? 'ongoing' : eventItm.eventStatus == 'upcoming' ? 'upcoming' : ''}</Card.Text>
// <Card.Text className="text-center" style={{marginLeft: "15px", marginRight: "15px", fontSize: '15px', fontWeight: 'normal'}}>{eventItm.description.substring(0,80)}</Card.Text>
// <Card.Text
//   style={{
//     marginLeft: "15px", 
//     marginRight: "15px", 
//     fontSize: '15px', 
//     fontWeight: 'normal'}}>
//       Event Start: {eventItm.startDate.toDate().toLocaleString("en")}</Card.Text>
// <Card.Text
//   style={{
//     marginLeft: "15px", 
//     marginRight: "15px", 
//     fontSize: '15px', 
//     fontWeight: 'normal'}}>
//       Event End: {eventItm.endDate.toDate().toLocaleString("en")}</Card.Text>
// <Card.Text
//   style={{
//     marginLeft: "15px", 
//     marginRight: "15px", 
//     fontSize: '15px', 
//     fontWeight: 'normal'}}>
//       Address: {eventItm.address.fullAddress}</Card.Text>
// <div className="justify-content-center align-items-center" style={{alignSelf: 'center'}}>
// <Button style={{backgroundColor:'transparent', borderRadius:'25px', borderColor:'#000000', borderWidth:'2px', fontSize: '20px', fontWeight:'normal', height: '50px', marginTop: "25px", marginBottom: "15px", color: "black", width: '200px'}} onClick={()=> {
//   window.location.href = "/"+eventItm.id
// }}>View Event</Button>
// </div>
// </Card>
              })
            }


                              </Tab>
                              <Tab
                              eventKey="past-events"
                              title="Past Events"
                              onSelect={(e)=>{
                                
                              }}>
                                <div>
                  <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>Past Events ({orgPastEvents.length})</Card.Text>
                </div>
                                {
              orgPastEvents.map(eventItm => {
                return <Card className="border-0" style={{width: '100%', marginTop: '25px'}} onClick={()=> {
                  window.location.href = "/" + eventItm.id
                  }}>
          
          <Card.Body>
                              <div
                                    className="d-flex align-items-center"
                                    style={{
                                      alignSelf: "center",
                                      display: "flex",
                                      width: "100%",
                                      marginBottom: "5px",
                                    }}
                                  >
                                    <Card.Img style={{width: '100px', height: '75px', objectFit: 'cover'}} src={eventItm.photoURL} />
                                    <div style={{ marginLeft: '15px' }}>
                                      <Card.Text style={{fontSize: '12px', fontWeight: 'bold', color: '#F57828', margin: 0}}>{eventItm.startDate.toDate().toLocaleDateString('en')}</Card.Text>
                                      <Card.Text style={{fontSize: '17px', fontWeight: 'bold', color: 'black', margin: 0}}>{eventItm.title}</Card.Text>
                                      <Card.Text style={{fontSize: '12px', fontWeight: 'normal', color: 'black', margin: 0}}>{eventItm.address.fullAddress}</Card.Text>
                                      <Card.Text style={{fontSize: '12px', fontWeight: 'normal', color: 'black', margin: 0}}>{eventItm.startDate.toDate().toLocaleString('en')}</Card.Text>
                                    </div>
                                  </div>
            
          </Card.Body>
          </Card>
//                 return <Card className = "border-0 justify-content-center" style={{padding: '15px', margin: "25px", backgroundColor:"#ffffff", borderRadius: "15px",}}>
//                   <Row md={2} hidden={isMobile}>
//                     <Col>
//                       <Card.Img variant="top" style={{maxHeight: "500px"}} src={eventItm.photoURL} />
//                     </Col>
//                     <Col>
//                     <Card.Title className="text-center" style={{margin: "15px", fontSize: '20px', fontWeight: 'bold'}}>{eventItm.title} <Badge bg="danger">Ended</Badge></Card.Title>
//                     <Card.Text
//   style={{
//     marginLeft: "15px", 
//     marginRight: "15px", 
//     fontSize: '15px', 
//     fontWeight: 'normal'}}>
//       Event Start: {eventItm.startDate.toDate().toLocaleString("en")}</Card.Text>
// <Card.Text
//   style={{
//     marginLeft: "15px", 
//     marginRight: "15px", 
//     fontSize: '15px', 
//     fontWeight: 'normal'}}>
//       Event End: {eventItm.endDate.toDate().toLocaleString("en")}</Card.Text>
// <Card.Text
//   style={{
//     marginLeft: "15px", 
//     marginRight: "15px", 
//     fontSize: '15px', 
//     fontWeight: 'normal'}}>
//       Address: {eventItm.address.fullAddress}</Card.Text>
//                     </Col>
                    
//                   </Row>

//                   <Card.Img variant="top" hidden={!isMobile} style={{maxHeight: "500px"}} src={eventItm.photoURL} />
//                   <Card.Title className="text-center" hidden={!isMobile} style={{margin: "15px", fontSize: '20px', fontWeight: 'bold'}}>{eventItm.title} <Badge bg="danger">Ended</Badge></Card.Title>
                  



// <div className="justify-content-center align-items-center" style={{alignSelf: 'center'}}>
// <Button style={{backgroundColor:'transparent', borderRadius:'25px', borderColor:'#000000', borderWidth:'2px', fontSize: '20px', fontWeight:'normal', height: '50px', marginTop: "25px", marginBottom: "15px", color: "black", width: '200px'}} onClick={()=> {
//   window.location.href = "/"+eventItm.id
// }}>View Event</Button>
// </div>
// </Card>
              })
            }
                              </Tab>
                              <Tab
                              eventKey="posts"
                              title="Posts"
                              onSelect={(e)=>{
                                
                              }}>
                                <div className="d-flex align-items-center justify-content-between">
                                  <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>Posts</Card.Text>
                                  <Button 
                                  variant='dark' 
                                  style={{
                                    borderColor: "#000000", 
                                    height: '45px',
                                    fontSize: '15px',
                                    fontWeight: 'bold',
                                    borderRadius: '22px'
                                  }} onClick={()=>{
                                    window.location.href = "/create-post/"+orgDetails.id
                                  }}>Create Post</Button>
                                </div>
                                <Row md={5} style={{marginTop: '25px'}} hidden={isMobile}>
                                  {
                                    orgPosts.map((postItm) => {
                                      return <Col style={{marginTop: '15px'}}>
                                      <Card className="border-0" onClick={()=>{
                                        window.location.href = "/post/"+postItm.id
                                      }}>
                                        <Card.Img variant="top" style={{maxHeight: '300px', borderRadius: '25px'}} src={postItm.imageURL}/>
                                        <Card.Title style={{marginTop: '15px', fontSize: '15px', fontWeight: 'bold'}}>{postItm.postContent.substring(0,50)}</Card.Title>

                                        <ListGroup.Item
                                  className="d-flex align-items-center"
                                  style={{
                                    alignSelf: "center",
                                    display: "flex",
                                    width: "100%",
                                    marginBottom: "5px",
                                  }}
                                  onClick={() => {
                                    
                                  }}
                                >
                                  <Image
                                    roundedCircle
                                    src={
                                      postItm.user.profileURL
                                    }
                                    width="25px"
                                    height="25px"
                                  />
                                  <div style={{ marginLeft: "5px" }}>
                                    <Card.Text
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "normal",
                                        marginBottom: "0px",
                                        color: "black",
                                      }}
                                    >
                                      {postItm.user.userName}
                                    </Card.Text>
                                    
                                  </div>
                                </ListGroup.Item>
                                      </Card>
                                      </Col>
                                    })
                                  }
                                </Row>

                                  {
                                    orgPosts.map((postItm) => {
                                      return <Card className="border-0" hidden={!isMobile} style={{marginTop: '15px'}} onClick={()=>{
                                        window.location.href = "/post/"+postItm.id
                                      }}>
                                        <Card.Img variant="top" style={{maxHeight: '300px', borderRadius: '25px'}} src={postItm.imageURL}/>
                                        <Card.Title style={{marginTop: '15px', fontSize: '15px', fontWeight: 'bold'}}>{postItm.postContent.substring(0,50)}</Card.Title>

                                        <ListGroup.Item
                                  className="d-flex align-items-center"
                                  style={{
                                    alignSelf: "center",
                                    display: "flex",
                                    width: "100%",
                                    marginBottom: "5px",
                                  }}
                                  onClick={() => {
                                    
                                  }}
                                >
                                  <Image
                                    roundedCircle
                                    src={
                                      postItm.user.profileURL
                                    }
                                    width="25px"
                                    height="25px"
                                  />
                                  <div style={{ marginLeft: "5px" }}>
                                    <Card.Text
                                      style={{
                                        fontSize: "12px",
                                        fontWeight: "normal",
                                        marginBottom: "0px",
                                        color: "black",
                                      }}
                                    >
                                      {postItm.user.userName}
                                    </Card.Text>
                                    
                                  </div>
                                </ListGroup.Item>
                                      </Card>
                                      
                                    })
                                  }
                                <Card.Text style={{fontSize: '17px', color: 'black', marginTop: '15px'}}>Join {orgDetails.name}'s community to view more posts.</Card.Text>
                              </Tab>
                              <Tab
                              eventKey="articles"
                              title={"Articles"}>
                                <div className="d-flex align-items-center justify-content-between">
                                  <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>Articles</Card.Text>
                                  <Button variant='dark' style={{
                                    borderColor: "#000000", 
                                    height: '45px',
                                    fontSize: '15px',
                                    fontWeight: 'bold',
                                    borderRadius: '22px'
                                  }}>View more</Button>
                                </div>

                                <ListGroup style={{marginTop: '25px'}}>
                                  {
                                    orgArticles.map((articleItm) => {
                                      return <ListGroup.Item className="border-0" onClick={()=>{
                                        window.location.href="/article/"+articleItm.id
                                      }}>
                                        <div 
                                        className="d-flex align-items-center justify-content-between" 
                                        style={{
                                          marginTop: '25px'
                                        }}>
                              <div
                              className="d-flex align-items-center border-0"
                              style={{
                                alignSelf: "center",
                                display: "flex",
                                width: "100%",
                                marginLeft: '15px',
                                marginRight: '15px',
                                marginBottom: "5px",
                              }}
                              onClick={()=>{
                                
                              }}
                              >
                                <Card.Img
                                  src={articleItm.imageURL}
                                  style={{height: isMobile ? '50px' : '100px', width: isMobile ? '50px' : '100px'}}
                                />
                                <div 
                                style=
                                {{ 
                                  marginLeft: "15px" 
                                  }}>
                                <Card.Link
                                  href={"/article/"+articleItm.id}
                                  style={{ fontSize: isMobile ? '15px' : "22px", fontWeight: "bold", color: 'black' }}
                                  >
                                {articleItm.title}
                                </Card.Link>
                                <Card.Text>
                                  {articleItm.viewCount} Read
                                </Card.Text>

                                </div>
                                </div>
                                </div>
                                        
                                      </ListGroup.Item>
                                    })
                                  }
                                </ListGroup>
                                
                                <Card.Text style={{fontSize: '17px', color: 'black', marginTop: '15px'}}>Join {orgDetails.name}'s community to view more articles</Card.Text>
                              </Tab>
                              <Tab
                              eventKey="communities"
                              title={"Community"}>
                                <div className="d-flex align-items-center justify-content-between">
                                  <Card.Text style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>Community</Card.Text>
                                  <Button variant='dark' style={{
                                    borderColor: "#000000", 
                                    height: '45px',
                                    fontSize: '15px',
                                    fontWeight: 'bold',
                                    borderRadius: '22px'
                                  }}>Join now</Button>
                                </div>
                                
                                <Card.Text style={{fontSize: '17px', color: 'black', marginTop: '15px'}}>Join {orgDetails.name}'s community to find other members</Card.Text>
                              </Tab>
                              <Tab
                              eventKey="messages"
                              title={"Contact us"}>

          <Form style={{marginTop: "50px", padding: '15px', borderRadius:'25px', borderColor: 'white', borderWidth: '3px', padding: '10px'}}>
            <h3 className="text-center mb-4" style={{color: 'black', fontWeight: 'bold'}}>Contact us</h3>
            {error && <Alert variant="danger">{error}</Alert>}
            <Form.Group id="email" style={{marginTop: '15px'}}>
              <Form.Label style={{color: 'black', fontSize: '17px', fontWeight: 'bold'}}>Email Address</Form.Label>
              <Form.Control style={{backgroundColor: 'transparent', borderWidth: '3px', color: 'black', height: '50px', borderRadius: '25px'}} type="email" ref={contactEmailRef} placeholder="Please enter your email" required  />
            </Form.Group>
            <Form.Group id="name" style={{marginTop: '15px'}}>
              <Form.Label style={{color: 'black', fontSize: '17px', fontWeight: 'bold'}}>Name</Form.Label>
              <Form.Control style={{backgroundColor: 'transparent', borderWidth: '3px', color: 'black', height: '50px', borderRadius: '25px'}} type="text" ref={contactNameRef} placeholder="Please enter your name" required  />
            </Form.Group>
            <Form.Group id="content" style={{marginTop: '15px'}}>
              <Form.Label style={{color: 'black', fontSize: '17px', fontWeight: 'bold'}}>Content</Form.Label>
              <Form.Control style={{backgroundColor: 'transparent', borderWidth: '3px', color: 'black', height: '120px', borderRadius: '25px'}} as="textarea" ref={contactContentRef} rows={10} required  />
            </Form.Group>
            <Button className="w-100 p3" 
                style={{alignItems:'center', 
                        justifyContent:'space-between', 
                        backgroundColor:'#111', 
                        borderRadius:'25px', 
                        borderColor:'#ffffff', 
                        borderWidth:'3px', 
                        fontSize: '15px', 
                        fontWeight:'bold', 
                        height: '50px',
                        marginTop: "15px"}}
                  onClick={()=>{
              var emailVal = contactEmailRef.current.value
              var nameVal = contactNameRef.current.value
              var contentVal = contactContentRef.current.value

              var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());

              if (emailVal != "" && nameVal != "" && contentVal != ""){
                fires
                .collection('groups')
                .doc(id)
                .collection('feedbacks')
                .add({
                  name: nameVal, 
                  email: emailVal, 
                  content: contentVal, 
                  timestamp: timestampNow}).then(()=>{
                    contactEmailRef.current.value = ""
                    contactNameRef.current.value = ""
                    contactContentRef.current.value = ""
                    if (orgInfo.email != ""){
                      var emailContent = "Name: "+ nameVal + "\n\nEmail Address: " + emailVal + "\n\nContent: \n" + contentVal
                      sendEmailTo(orgInfo.email,"[EVENTGO] You have a new message",emailContent)
                      sendEmailTo('admin@letjoy.app',"[EVENTGO] You have a new message",emailContent)
                    }
                    else{
                      
                    }

                    window.alert('We have received your request')
                  })
              }
              else{
                setError("Failed to leave message")
              }
                  }}>Submit</Button>
          </Form>

                              </Tab>
                              </Tabs>
</Card.Body>
</Card>



        </div>
      </Container>
    </>
  );
}

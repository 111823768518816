import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert, Tab, Tabs, Carousel, Image, Container, Row, Col, Navbar, Badge, ListGroup } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { auth, fires} from "../../firebase"
import { Link,  Route, useParams, Navigate } from "react-router-dom"
import {isMobile, isAndroid, isIOS} from 'react-device-detect';
import uuid from 'react-uuid';
import { Helmet } from "react-helmet"
import Footer from "./Footer"


export default function Pricing() {
    const [error, setError] = useState("")
    const [index, setIndex] = useState(0);
    const { currentUser, logout } = useAuth()
    
  
    const [posts, setPosts] = useState([]);
    const [groups, setGroups] = useState([]);
    const [events, setEvents] = useState([]);
  
    const maxW = isMobile ? "100%" : "90%"

    return (
        <>
        <Helmet>
            <title>EventGo pricing</title>     
            <meta property="og:title" content="EventGo pricing" />        
            <meta property="og:description" content="EventGo - Pioneering the Future of Events" />        
          <meta property="og:url" content={"https://evtgo.com/"} />
          <meta property="og:site_name" content="EventGo - Pioneering the Future of Events" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
            </Helmet>
            <Container 
            className="d-flex justify-content-center"
              style={{minHeight: '100vh', minWidth: '100vw',backgroundColor: 'white'}}>
            </Container>
            <br/><br/><br/>
            <Footer/>
        </>
    )
}
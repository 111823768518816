import React, { useRef, useState, useCallback, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  Container,
  DropdownButton,
  Dropdown,
  Modal,
  Tabs,
  Tab,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { fires, auth } from "../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import uuid from "react-uuid";

export default function EventSignup() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const passwordConfirmRef = useRef();
  const nameRef = useRef();
  const workRef = useRef();

  const orgIdRef = useRef();
  const orgNameRef = useRef();
  const orgDescriptionRef = useRef();
  const orgTypeCustomRef = useRef();
  const { currentUser, logout, signup, login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "50%";

  let { id } = useParams();

  const [value, setValue] = useState("选择下列类型");

  const [show, setShow] = useState(false);

  const [userInfo, setUserInfo] = useState({});
  const [eventInfo, setEventInfo] = useState({});
  const [posts, setPosts] = useState([]);
  const [matchID, setMatchID] = useState("");

  //0 is signup, 1 is checkin, 2 is finished, 3 is not found
  const [checkinStatus, setCheckinStatus] = useState(0);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const coverImg =
    "url(https://images.unsplash.com/photo-1610730297006-ed0e847c34f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)";
  const profileImg =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png";

  const [currentEmail, setCurrentEmail] = useState("");

  useEffect(() => {
    return fires
      .collection("events")
      .doc(id)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {
          const eInfo = snapshot.data();
          setEventInfo({ ...eInfo });
          if (currentUser !== null) {
            setCurrentEmail(currentUser.email);
          }
          if (currentEmail !== "") {
            fires
              .collection("events")
              .doc(id)
              .collection("members")
              .doc(currentEmail)
              .get()
              .then((snapshotA) => {
                const uid = auth.currentUser.uid;
                if (snapshotA.exists) {
                  navigate(-1);
                } else {
                  //user email not found
                  fires
                    .collection("users")
                    .doc(uid)
                    .get()
                    .then((snapshotB) => {
                      if (snapshotB.exists) {
                        const userData = snapshotB.data();
                        setUserInfo({ ...userData });
                      }
                    });
                }
              });
          } else {
            //user not login
          }
        } else {
          //event not found
        }
      });
  }, []);

  async function handleSignupSubmit(e) {
    e.preventDefault();
    const emailA = e.target[0].value;
    const nameU = e.target[1].value;
    const workU = e.target[2].value;
    const passwordA = e.target[3].value;
    const passwordB = e.target[4].value;

    if (passwordA !== passwordB) {
      return setError("密码输入不一致");
    }
    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    setError("");
    setLoading(true);
    try {
      await signup(emailA, passwordA);
      const uid = auth.currentUser.uid;
      const email = auth.currentUser.email;
      fires
        .collection("users")
        .doc(uid)
        .set({
          userName: nameU,
          name: nameU,
          work: workU,
          email: emailA,
          currentEvent: id,
          timestamp: timestampNow,
        })
        .then(() => {
          fires
            .collection("events")
            .doc(id)
            .collection("members")
            .doc(emailA)
            .set({
              name: nameU,
              checkinStatus: false,
              work: workU,
              timestamp: timestampNow,
            })
            .then(() => {
              const dateString = eventInfo.startDate.toDate().toLocaleDateString("zh") + ' ' + eventInfo.startDate.toDate().toLocaleTimeString("zh")
              navigate(-1);
            });
        });
      setLoading(false);
    } catch {
      setError("无法创建账户");
    }
  }

  async function handleLoginSubmit(e) {
    e.preventDefault();
    const emailA = e.target[0].value;
    const pass = e.target[1].value;

    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    setError("");
    setLoading(true);

    try {
      await login(emailA, pass);
      const uid = auth.currentUser.uid;
      fires
        .collection("users")
        .doc(uid)
        .get()
        .then((snapshotA) => {
          if (snapshotA.exists) {
            const usersData = snapshotA.data();
            const usersName = usersData.name;
            const usersWork = usersData.work;
            const usersWechat = usersData.wechat;

            fires
              .collection("users")
              .doc(uid)
              .update({ currentEvent: id, timestamp: timestampNow })
              .then(() => {
                setCurrentEmail(auth.currentUser.email);
                fires
                  .collection("events")
                  .doc(id)
                  .collection("members")
                  .doc(emailA)
                  .set({
                    name: usersName,
                    checkinStatus: false,
                    work: usersWork,
                    wechat: usersWechat,
                    timestamp: timestampNow,
                  })
                  .then(() => {
                    const dateString = eventInfo.startDate.toDate().toLocaleDateString("zh") + ' ' + eventInfo.startDate.toDate().toLocaleTimeString("zh")
                    navigate(-1);
                  });
              });
          } else {
            setError("无法找到用户");
          }
        });

      setLoading(false);
    } catch {
      setError("无法创建账户");
    }
  }

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          backgroundImage: coverImg,
        }}
      >
        <div className="w-100" style={{ maxWidth: maxW }}>
          <Card
            className="border-0"
            style={{ marginTop: "35px", marginBottom: "35px" }}
          >
            <Card.Body>
              <h2 className="text-center mb-4">{eventInfo.title}</h2>
              {error && <Alert variant="danger">{error}</Alert>}

              <Tabs
                defaultActiveKey={"login-checkin"}
                id="signup-tab"
                className="mb-3"
                style={{ marginTop: "15px" }}
              >
                <Tab eventKey="signup-checkin" title="注册">
                  <Form
                    onSubmit={handleSignupSubmit}
                    hidden={currentUser !== null}
                  >
                    <Form.Group id="email" style={{ marginTop: "15px" }}>
                      <Form.Label>电子邮箱 *</Form.Label>
                      <Form.Control type="email" required />
                    </Form.Group>
                    <Form.Group id="name" style={{ marginTop: "15px" }}>
                      <Form.Label>您的姓名 *</Form.Label>
                      <Form.Control type="text" required />
                    </Form.Group>
                    <Form.Group id="work" style={{ marginTop: "15px" }}>
                      <Form.Label>您的工作领域</Form.Label>
                      <Form.Control type="text" />
                    </Form.Group>
                    <Form.Group id="password" style={{ marginTop: "15px" }}>
                      <Form.Label>输入密码 *</Form.Label>
                      <Form.Control type="password" required />
                    </Form.Group>
                    <Form.Group
                      id="password-repeat"
                      style={{ marginTop: "15px" }}
                    >
                      <Form.Label>再次输入密码 *</Form.Label>
                      <Form.Control type="password" required />
                    </Form.Group>
                    <Button
                      variant="dark"
                      disabled={loading}
                      className="w-100"
                      type="submit"
                      style={{ marginTop: "25px", height: "40px" }}
                    >
                      注册账户并参与活动
                    </Button>
                  </Form>
                </Tab>
                <Tab eventKey="login-checkin" title="登录">
                  <Form
                    onSubmit={handleLoginSubmit}
                    hidden={currentUser !== null}
                  >
                    <Form.Group id="email" style={{ marginTop: "15px" }}>
                      <Form.Label>电子邮箱 *</Form.Label>
                      <Form.Control type="email" required />
                    </Form.Group>
                    <Form.Group id="password" style={{ marginTop: "15px" }}>
                      <Form.Label>密码 *</Form.Label>
                      <Form.Control type="password" required />
                    </Form.Group>
                    <Button
                      variant="dark"
                      disabled={loading}
                      className="w-100"
                      type="submit"
                      style={{ marginTop: "25px", height: "40px" }}
                    >
                      登录账户并参与活动
                    </Button>
                  </Form>
                </Tab>
              </Tabs>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}

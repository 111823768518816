import React, 
{useRef, 
useState, 
useCallback, 
useEffect } from "react";
import {
  Form,
  Button,
  ButtonGroup,
  Card,
  Alert,
  Tab,
  Tabs,
  Image,
  Carousel,
  Container,
  Row,
  Col,
  ListGroup,
  Navbar,
  Modal,
} from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { 
    Link, 
    useNavigate, 
    useParams, 
    Route
 } from "react-router-dom";
import { 
    isMobile, 
    isAndroid, 
    isIOS
 } from "react-device-detect";
import { fires, auth } from "../../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import Gallery from "./Gallery"
import "./style.css"

import { FullScreen, useFullScreenHandle } from "react-full-screen";

export default function EventWall() {
  let { id } = useParams();
  const handle = useFullScreenHandle();

  
  return (
    <>
    <FullScreen handle={handle}>
    <Container
     className="d-flex align-items-center justify-content-center"
        style={{width: '100vw', height: '100vh', backgroundColor: 'white' }}
      >
          <div 
          className="d-flex align-items-center justify-content-center"
          style={{height: '100vh', width: '100vw'}} onClick={()=>{
              if (handle.active) {
                  handle.exit()
              }
              else{
                handle.enter()
              }
            
          }}>
          <Gallery id = {id}/>
          </div>

     </Container>
    </FullScreen>
    </>
  );
}

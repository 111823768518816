import React, 
{useRef, 
useState, 
useCallback, 
useEffect } from "react";
import {
  Form,
  Button,
  ButtonGroup,
  Card,
  Alert,
  Tab,
  Tabs,
  Carousel,
  Image,
  Container,
  Row,
  Col,
  ListGroup,
  DropdownButton,
  Dropdown,
  Navbar,
  Modal,
} from "react-bootstrap";
import { 
    useAuth
 } from "../contexts/AuthContext";
import { 
    Link, 
    useNavigate, 
    useParams, 
    Route
 } from "react-router-dom";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { fires, auth } from "../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import { Helmet } from "react-helmet";
import { sendEventLuckyDrawSubmissionEmail } from "../contexts/API";

import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

export default function EventLuckyDrawForm() {
  
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "40%";

  let { id,drawId,langCode } = useParams();
  var currentUA = ""

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [eventDetails, setEventDetails] = useState([]);
  const [eventDrawItem, setEventDrawItem] = useState({name: '', imageURL: '', description: '', url: '', endline: ''})
  const [orgDetails, setOrgDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [eventMemberDetails, setEventMemberDetails] = useState({})

  const [genderSelected, setGenderSelected] = useState("NA")
  const [lang, setLang] = useState('en')
  const [phoneNum, setPhoneNum] = useState('')


  const routeRedirect404 = () =>{ 
    let path = '/404' ; 
    window.location.href = path
  }

  function createRandomId(){
    const fNumber = Math.floor(10000 + Math.random() * 90000)
    const sNumber = Math.floor(10000 + Math.random() * 90000)
    const idString = fNumber.toString() + '-' + sNumber.toString()
    return idString
  }

  const [coverImg,setCoverImg] =
    useState("https://images.unsplash.com/photo-1439792675105-701e6a4ab6f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1746&q=80");
  const [profileImg,setProfileImg] =
    useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png");

    function submitLuckyDrawForm(e){
        e.preventDefault()
        const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
        const uFirstName = e.target[0].value;
        const uLastName = e.target[1].value;
        const uEmail = e.target[2].value;
        const uPhone = phoneNum;
        if (uEmail != ""){
            const ticket = createRandomId()
            fires
            .collection('events')
            .doc(id)
            .collection('draw-members')
            .doc(uEmail)
            .set(
                {
                    'firstName': uFirstName, 
                    'lastName':uLastName, 
                    'email': uEmail, 
                    'gender': genderSelected,
                    'ticket': ticket,
                    'drawId': drawId,
                    'phone': uPhone,
                    'name': uFirstName + ' ' + uLastName,
                    'lang': 'en',
                    'timestamp': timestampNow
                }).then(()=>{
                    sendEventLuckyDrawSubmissionEmail(
                        uEmail,
                        eventDetails.title,
                        id,
                        uFirstName + " " + uLastName,
                        eventDrawItem.name,
                        ticket,
                        eventDrawItem.name,
                        lang)
                    window.alert('Submission successful!')
                    window.location.href = "/"+eventDetails.id+'/en'
                })

        }
        
    
    }
    
    useEffect(() => {
        fires.collection('events').doc(id).get().then((snapshot) => {
            if (snapshot.exists) {
                const postData = snapshot.data()
                if (postData.status != null) {
                  if (postData.status == 'live' || postData.status == 'private' || postData.status == 'ended'){
                    setEventDetails(postData)

                    if (langCode != null){
                      if (langCode != ""){
                        if (langCode == 'zh'){
                          setLang(langCode)
                        }
                        else if (langCode == 'en'){
                          setLang(langCode)
                        }
                      }
                    }
                    else{

                    }

                    if (postData.photoURL !== "") {
                        let link = document.querySelector("link[rel~='icon']");
                        if (!link) {
                          link = document.createElement("link");
                          link.rel = "icon";
                          document.getElementsByTagName("head")[0].appendChild(link);
                        }
                        link.href = postData.photoURL;
                      }

                    //setup lucky draw
                    if (postData.drawItems != null){
                        var luckyDraws = {}
                        luckyDraws = postData.drawItems
                        if (Object.keys(luckyDraws).includes(drawId)){
                            const drawItem = luckyDraws[drawId]
                            setEventDrawItem({...drawItem})
                        }
                        else{
                            routeRedirect404()
                        }
                    }
                    else{
                        routeRedirect404()
                    }

                    var fuid = ''
                    var femail = ''
                    const orgId = postData.orgId
                    if (auth.currentUser != null) {
                        fuid = auth.currentUser.uid
                        femail = auth.currentUser.email
                        if (femail != ''){
                            fires.collection('events').doc(id).collection('members').doc(femail).get().then((snapshotA)=>{
                                if (snapshotA.exists){
                                    const memberDetails = snapshotA.data()
                                    setEventMemberDetails({...memberDetails})
                                    if (fuid != ''){
                                        fires.collection('users').doc(fuid).get().then((snapshotA)=>{
                                            const snapData = snapshotA.data()
                                            setUserDetails({...snapData, id: fuid})
                                        })
                                        if (orgId != ""){
                                            fires.collection('groups').doc(orgId).get().then((snapshotA)=>{
                                                const snapData = snapshotA.data()
                                                setOrgDetails({...snapData})
                                                if (snapData.profileURL != ""){
                                                    setProfileImg(snapData.profileURL)
                                                }
                                                if (snapData.coverURL != ""){
                                                    setCoverImg(snapData.coverURL)
                                                }
                                            })

                                            
                                        }
                                    }
                                    else{
                                        routeRedirect404()
                                    }

                                }
                                else{
                                    routeRedirect404()
                                }
                            })
                        }
                        else{
                            routeRedirect404()
                        }
                    }
                    else{
                        //no auth
                        if (orgId != ""){
                          fires.collection('groups').doc(orgId).get().then((snapshotA)=>{
                              const snapData = snapshotA.data()
                              setOrgDetails({...snapData})
                              if (snapData.profileURL != ""){
                                  setProfileImg(snapData.profileURL)
                              }
                              if (snapData.coverURL != ""){
                                  setCoverImg(snapData.coverURL)
                              }
                          })
                      }
                    }
                  }
                  else{
                    //not live, or ended
                    routeRedirect404()
                  }
                }
                else{
                  //not found
                  //deleted
                  routeRedirect404()
                // posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
                // setPosts(posts)
                }

            }
            else{
                //deleted
                routeRedirect404()
                // posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
                // setPosts(posts)
                
            }
        });
      }, []);

  return (
    <>
        <Helmet>
                <title>{eventDrawItem.name+" lucky draw event"}</title>
                <meta name="description" content={eventDetails.description} />
                <meta name="twitter:card" content={eventDetails.photoURL} />
                <meta name="twitter:site" content="@user" />
                <meta name="twitter:creator" content="@user" />
                <meta name="twitter:title" content={eventDrawItem.name+" lucky draw event"} />
                <meta name="twitter:description" content={eventDetails.description} />
                <meta name="twitter:image" content={eventDetails.photoURL} />
                <meta property="og:title" content={eventDrawItem.name+" lucky draw event"} />
                <meta property="og:description" content={eventDetails.description} />
                <meta property="og:image" content={eventDetails.photoURL} />
                <meta
                  property="og:url"
                  content={"https://events.letjoy.app/" + id+ "/en"}
                />
                <meta property="og:site_name" content={eventDrawItem.name+" lucky draw event"} />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
              </Helmet>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh",minWidth: '100vw',backgroundImage: 'url(' + coverImg + ')' }}
      >
        <div className="w-100" style={{ maxWidth: maxW }}>
          <Card
            className="border-0 align-items-center justify-content-center" 
            style={{
                alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor:"white",
            marginTop: '25px',
            marginBottom: '25px'}}
          >
            
            <Card.Img
              variant="top"
              src={eventDrawItem.imageURL}
              style={{ maxHeight: "250px" }}
            />

              <Card.Body className = "align-items-center justify-content-center">
              <Card.Title
              className="text-center"
               style={{ fontSize: "30px",
                fontWeight:'bold', 
                color: "#000000"}}>{eventDrawItem.name}</Card.Title>
            
                <Form style={{marginTop: '25px'}} onSubmit={submitLuckyDrawForm}>
                <Form.Group className="mb-3" controlId="register.lastn">
                    <Form.Label style={{fontSize: '15px', fontWeight: 'bold'}}>{'First Name *'}</Form.Label>
                    <Form.Control
                          type="text"
                          placeholder={'Please let us know your first name'}
                          defaultValue={eventMemberDetails.firstName}
                          autoFocus
                          required
                    />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="register.firstn">
                        <Form.Label style={{fontSize: '15px', fontWeight: 'bold'}}>{'Last Name *'}</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder={'Please let us know your last name'}
                          defaultValue={eventMemberDetails.lastName}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="register.email">
                        <Form.Label style={{fontSize: '15px', fontWeight: 'bold'}}>{'Email Address *'}</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder={'Please let us know your email address'}
                          defaultValue={eventMemberDetails.email}
                          required
                        />
                        </Form.Group>
                      <Form.Group className="mb-3" controlId="register.phone">
                        <Form.Label style={{fontSize: '15px', fontWeight: 'bold'}}>{'Phone Number *'}</Form.Label>
                        <PhoneInput
                          placeholder="Please let us know your phone number"
                          value={phoneNum}
                          onChange={setPhoneNum}/>
                        </Form.Group>
                    <Form.Group className="mb-3" controlId="register.email">
                    <Form.Label style={{fontSize: '15px', fontWeight: 'bold'}}>{'Your Gender *'}</Form.Label><br/>
                    <div className="d-flex align-items-center justify-content-center">

                    <ButtonGroup 
                  className="align-items-center justify-content-center">
              <Button variant={genderSelected == "M" ? "dark" : "outline-dark"} 
                  onClick={()=>{
                    setGenderSelected("M")
                  }}>{'Male'}</Button>
              <Button variant={genderSelected == "F" ? "dark" : "outline-dark"} 
                  onClick={()=>{
                    setGenderSelected("F")
                  }} >{'Female'}</Button>
                <Button variant={genderSelected == "NA" ? "dark" : "outline-dark"} 
                  onClick={()=>{
                    setGenderSelected("NA")
                  }} >{'NA'}</Button>
              </ButtonGroup>

                    </div>
                    
                    </Form.Group>
                    <div
                    style={{marginTop: '45px'}}
                    className="d-flex align-items-center justify-content-center"
                    >
                    <Card.Text className="text-center">{eventDrawItem.endline != "" ? "* " + eventDrawItem.endline : "* This event is sponsored by "+eventDrawItem.name+", hosted by " + orgDetails.name + ". Click 'Submit Lucky Draws', you will receive product update from this sponsor in future."}</Card.Text>
                    </div>
                    <div 
                  className="d-flex align-items-center justify-content-center"
                  style={{marginTop: '15px'}}
                  id="form-login">
                    <Button 
                    style={{
                        marginLeft: '0px', 
                        marginRight: '0px', 
                        height: '40px', 
                        width: '200px',
                        borderRadius: '20px', fontSize: '15px', fontWeight: 'bold'}} variant="dark" type="submit">
                      {'Submit Lucky Draws'}
                      </Button>
                    </div>


                </Form>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}

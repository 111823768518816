import React, 
{useRef, 
useState, 
useCallback, 
useEffect } from "react";
import {
  Form,
  Button,
  ButtonGroup,
  Card,
  Alert,
  Tab,
  Tabs,
  Carousel,
  Image,
  Container,
  Row,
  Col,
  ListGroup,
  Navbar,
  Modal,
  FloatingLabel,
} from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { 
    Link, 
    useNavigate, 
    useParams, 
    Route
 } from "react-router-dom";
import { 
    isMobile, 
    isAndroid, 
    isIOS
 } from "react-device-detect";
import { fires, auth } from "../../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import StarRatings from 'react-star-ratings';
import { QRCodeCanvas } from "qrcode.react";
import Gallery from "react-photo-gallery";
import { Helmet } from "react-helmet";
import { FileUploader } from "react-drag-drop-files";

export default function GuestPass() {
  
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "50%";

  const fileTypes = ["JPG", "PNG", "GIF","HEIC","SVG","MOV","MP4"];


  let { id, } = useParams();
  var currentUA = ""
  

  const [showUpload, setShowUpload] = useState(false);
  const handleUploadClose = () => setShowUpload(false);
  const handleUploadShow = () => setShowUpload(true);

  const [showText, setShowText] = useState(false);
  const handleTextClose = () => setShowText(false);
  const handleTextShow = () => setShowText(true);

  const [showCheckin, setShowCheckin] = useState(false);
  const handleCheckinOpen = () => setShowCheckin(true);
  const handleCheckinClose = () => setShowCheckin(false);

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageURL, setSelectedImageURL] = useState("");
  const [uploadS, setUploadS] = useState("请点击上传图片开始上传")
  const [uploadedURL, setUploadedURL] = useState('')
  const textRef = useRef();
  
  const [eventPhotos, setEventPhotos] = useState([])
  const [eventMessages, setEventMessages] = useState([])
  const [jorItems, setJorItems] = useState([])
  const [userItem, setUserItems] = useState({
      email: '',
      firstName: '',
      lastName: '',
      name: '',
  })

  const [eventDetails, setEventDetails] = useState({
    status: "NA",
    title: "",
    description: "",
    announcement: "",
    announcements: [],
    titles: { en: "", zh: "" },
    descriptions: { en: [], zh: [] },
    drawInstruction: "",
    startDate: firebase.firestore.Timestamp.fromDate(new Date()),
    endDate: firebase.firestore.Timestamp.fromDate(new Date()),
    address: {"name": "","fullAddress": ""},
    priceType: "FREE",
    agenda: [],
    sponsor: [],
    drawItems: {},
    wifiLink: "",
  });
  const [orgDetails, setOrgDetails] = useState({});
  
  const [eventOptions, setEventOptions] = useState({
    poll:false,
    draw:false,
    namecard:false,
    review:false,
    comment:true,
    picture:true,
    socialmedia:true,
    hidehost:false,
    'register-feedback':false,
    'accept-registration':true,
    max:500})

  const routeRedirect404 = () =>{ 
    let path = '/404' ; 
    window.location.href = path
  }

  const [coverImg,setCoverImg] =
    useState("https://images.unsplash.com/photo-1561489396-888724a1543d?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  const [profileImg,setProfileImg] =
    useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png");

    function uploadImage(file) {
        fetch('https://r2-worker.sam-12e.workers.dev/', {
          method: 'POST',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': file.type
          },
          body: file
        })
          .then((response) => response.text())
          .then((data) => {
            const downloadURL = "https://pub-e888c0363b7c412caf77f22ba84fcb86.r2.dev/" + data
            fires.collection('events').doc(id).collection('pictures').add(
                {
                    'name': userItem.name,
                    'email': userItem.email,
                    'status': 'pending',
                    'imageURL': downloadURL,
                    'timestamp': firebase.firestore.Timestamp.fromDate(new Date())
                }
            ).then((addedRef) => {
                setSelectedImageURL(downloadURL)
                setSelectedImage(null)
                setUploadedURL(downloadURL)
                
            })
            
          }) // 
          .catch((error) => console.error(error));
      }
    
      const handleImageChange = (e) => {
        e.preventDefault()
        const file = e.target.files[0];
        setSelectedImage(file)
      }

      const handleImageFileUpload = (file) => {
        setSelectedImage(file)
      }
    
      const handleImageSubmit = (e) => {
        e.preventDefault()
        const file = selectedImage;
        if (!file) return;
    
        uploadImage(file)
      }

      function userBecomeMember(email,fn,ln,uid, orgId){
          if (orgId != ""){

            fires.collection('groups').doc(orgId).collection('requests').add({
                email: email,
                firstName: fn,
                lastName: ln,
                comment: "event from " + eventDetails.title,
                uid: uid,
                status: 'requested',
                timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
              })

          }
        
      }

      function userDataSignup(email,fn,ln,uid){
        fires.collection('users').doc(uid).set({
            email: email,
            firstName: fn,
            lastName: ln,
            currentEvent: id,
            uid: uid,
            name: fn + " " + ln,
            userName: fn + " " + ln,
            lang: 'zh',
            isAnonymous: true,
            timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
          }).then((dat) => {
              handleCheckinClose()
              window.location.reload()
          })
      }

      const userEventSignup = (email, fn, ln) => {

        if (auth.currentUser != null){
          const uid = auth.currentUser.uid
          if (orgDetails.id != null && orgDetails.id != ""){
              userBecomeMember(email,fn,ln,uid,orgDetails.id)
          }
          handleCheckinClose()
          window.location.reload()
      }
      else{
          auth.signInAnonymously().then((userDe) => {
              const uid = userDe.user.uid
              if (orgDetails.id != null && orgDetails.id != ""){
                  userBecomeMember(email,fn,ln,uid,orgDetails.id)
              }
              userDataSignup(email,fn,ln,uid)
          })
      }
      }

      const checkinPrompt = () => {
          return <Modal show={showCheckin} onHide={handleCheckinClose}>
              <Modal.Header closeButton><Card.Title style={{fontWeight: 'bold'}}>Guest Pass 签到簿</Card.Title></Modal.Header>
              <Modal.Body>
                  <Form onSubmit={(e) => {
                      e.preventDefault();
                      const fn = e.target[0].value
                      const ln = e.target[1].value
                      const email = e.target[2].value.toLowerCase()
                      if (fn != "" && ln != "" && email != "" && email.includes("@")){
                          //validation
                          userEventSignup(email,fn,ln)
                      }
                      else{
                          alert("Failed to signup")
                      }
                  }}>
                      <Row md={2} style={{marginTop: '15px'}}>
                          <Col>
                            <Form.Group>
                                <FloatingLabel label="您的名字 First Name *">
                                    <Form.Control type="name" required/>
                                </FloatingLabel>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                                <FloatingLabel label="您的姓 Last Name *">
                                    <Form.Control type="name" required/>
                                </FloatingLabel>
                            </Form.Group>
                          </Col>
                      </Row>

                      <Form.Group style={{marginTop: '15px'}}>
                                <FloatingLabel label="您的电子邮件 Email Address *">
                                    <Form.Control type="email" required/>
                                </FloatingLabel>
                            </Form.Group>

                            <Form.Group style={{marginTop: '15px'}}>
                                <Button variant="dark" className="w-100 fw-bold" type="submit">提交 Submit</Button>
                                <Card.Text style={{marginTop: '15px', fontSize: 12}}>在提交后您会参与本次活动并收到来自活动举办方的其他活动的邮件通知</Card.Text>
                                <Card.Text style={{marginTop: '0px', fontSize: 12}}>After submission, you will participate in the event and receive email notification from this organizer in the future</Card.Text>
                            </Form.Group>

                  </Form>
              </Modal.Body>
          </Modal>
      }

    useEffect(() => {
        fires.collection('events').doc(id).get().then((snapshotA) => {
            if (snapshotA.exists){
                const eventData = snapshotA.data()
                setEventDetails({...eventData})
                if (eventData.user.imageURL !== null && eventData.user.imageURL !== "") {
                    setProfileImg(eventData.user.imageURL)
                    //setCoverImg(eventData.photoURL)
                }

                if (auth.currentUser != null){
                    fires.collection('users').doc(auth.currentUser.uid).get().then((snapshotUser) => {
                        if (snapshotUser.exists){
                            const userData = snapshotUser.data()
                            setUserItems(userData)
                        }
                    })
                }
                else{
                    handleCheckinOpen()
                }

                if (eventData.orgId != null){
                    if (eventData.orgId != ""){
                        fires.collection("groups").doc(eventData.orgId).get().then((snapshotOrg) => {
                            if (snapshotOrg.exists){
                                const orgData = snapshotOrg.data()
                                setOrgDetails({...orgData})
                            }
                        })
                    }
                }
                

                fires.collection('events').doc(id).collection('comments').orderBy('timestamp', 'desc').get().then((snapshotB) => {
                    var messageSD = []
                    var journey = jorItems
                    snapshotB.docs.forEach(doc => {
                        const messageId = doc.id
                        var messageData = doc.data()
                        messageData.id = messageId
                        messageData.type = "message"
                        messageData.typeName = "文字"
                        messageSD.push(messageData)
                    })
                    
                    setEventMessages(messageSD)
                    
                    messageSD.forEach((itemSD) => {
                        let matched = jorItems.filter((itemA) => {
                            let itemId = itemA.id;
                            return (itemId == itemSD.id)
                        })
                        if (matched.length == 0){
                            journey.push(itemSD);
                        }
                    })

                    journey.sort((a,b) => {
                        return b.timestamp.toDate() - a.timestamp.toDate()
                    })

                    setJorItems(journey)
                    
                    
                    
                })

                fires.collection('events').doc(id).collection('pictures').get().then((snapshotB) => {
                    var photoSD = []
                    var journey = jorItems
                    snapshotB.docs.forEach(doc => {
                        const photoId = doc.id
                        var photoData = doc.data()
                        photoData.id = photoId
                        photoData.content = photoData.imageURL
                        photoData.type = "photo"
                        photoData.typeName = "照片"
                        photoSD.push(photoData)
                        
                    })
                    
                    setEventPhotos(photoSD);

                    photoSD.forEach((itemSD) => {
                        let matched = jorItems.filter((itemA) => {
                            let itemId = itemA.id;
                            return (itemId == itemSD.id)
                        })
                        if (matched.length == 0){
                            journey.push(itemSD);
                        }
                    })

                    journey.sort((a,b) => {
                        return b.timestamp.toDate() - a.timestamp.toDate()
                    })

                    setJorItems(journey)
                    
                    
                })

            }
            else{
                //event not found
                routeRedirect404()
            }
        })
      }, []);

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{
            minHeight: "100vh",
            minWidth: '100vw',
            backgroundImage: 'url(' + coverImg + ')',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'  }}
      >
          <Helmet>
                <title>{eventDetails.title}</title>
                <meta
                  name="description"
                  content={eventDetails.description}
                />
                <meta name="twitter:card" content={eventDetails.photoURL} />
                <meta name="twitter:site" content="@user" />
                <meta name="twitter:creator" content="@user" />
                <meta name="twitter:title" content={eventDetails.title} />
                <meta
                  name="twitter:description"
                  content={eventDetails.description}
                />
                <meta name="twitter:image" content={eventDetails.photoURL} />
                <meta property="og:title" content={eventDetails.title} />
                <meta
                  property="og:description"
                  content={eventDetails.description}
                />
                <meta property="og:image" content={eventDetails.photoURL} />
                <meta
                  property="og:url"
                  content={"https://rileyevents.com/event-info/" + id}
                />
                <meta
                  property="og:site_name"
                  content={eventDetails.title}
                />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
              </Helmet>

        <div className="w-100" style={{ maxWidth: maxW }}>
            {
                checkinPrompt()
            }
          <Card
            className="border-0 align-items-center justify-content-center" 
            style={{
                alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor:"white",
            marginTop: '15px',
            marginBottom: '15px'}}
          >
              <Card.Body className = "align-items-center justify-content-center">
              <Card.Title className="text-center"
               style={{ fontSize: "30px",
                fontWeight:'bold', 
                color: "#000000"}}>
                    {eventDetails.title}
            </Card.Title>
            {
                eventDetails.descriptions.zh.map((desc) => {
                    return <Card.Text
                    style={{ fontSize: "15px",
                     fontWeight:'normal', 
                     marginTop: '25px',
                     color: "#000000"}}>
                         {desc}
                 </Card.Text>
                })
            }
            </Card.Body>
          </Card>
          

          <Card
            className="border-0" 
            style={{
            backgroundColor:"white",
            marginTop: '15px',
            marginBottom: '15px'}}
          >
              <Card.Body className = "align-items-start">
              <Card.Title
               style={{ 
                   fontSize: "25px",
                fontWeight:'bold', 
                color: "#000000",
                marginTop: '5px',
                }}>
                    活动进程
            </Card.Title>
            <Card.Text
               style={{ fontSize: "15px",
                fontWeight:'bold', 
                marginTop: '15px',
                color: "#000000"}}>
                    {eventDetails.agenda.length == 0 ? '目前还没有活动进程' : ''}
            </Card.Text>
            <ListGroup>
            {
                eventDetails.agenda.map((agend) => {
                    return <ListGroup.Item className="border-0">
                        <div>{agend.date.toDate().toLocaleTimeString('zh')} <b>{agend.title}</b></div>
                    </ListGroup.Item>
                })
            }
            </ListGroup>
            </Card.Body>
          </Card>

          <Card
            className="border-0" 
            style={{
            backgroundColor:"white",
            marginTop: '15px',
            marginBottom: '15px'}}
          >
              <Card.Body className = "align-items-start">
              <Card.Title
               style={{ 
                   fontSize: "25px",
                fontWeight:'bold', 
                color: "#000000",
                marginTop: '5px',
                }}>
                    开始你的分享
            </Card.Title>
            <Card.Link
               style={{ fontSize: "17px",
                fontWeight:'bold', 
                marginTop: '15px',}}
                onClick={(e)=>{
                    handleCheckinOpen()
                }}
                hidden={userItem.email != ''}>
                    签到分享
            </Card.Link>
            <br/>
            
            <Button style={{marginTop: '25px', backgroundColor: "#000000", borderWidth: 0}} onClick={()=>{
                handleTextShow()
            }}>
                发文字
            </Button>
            <Button style={{marginTop: '25px', backgroundColor: "#000000", borderWidth: 0, marginLeft: '5px'}} onClick={()=>{
                handleUploadShow()
            }}>
                发照片
            </Button>
            </Card.Body>
          </Card>
          {
                      jorItems.map((journeyItem) => {
                          var ftype = ""
                          var ftypeName = ""
                          if (journeyItem.type != null){
                              ftype = journeyItem.type
                          }
                          if (journeyItem.typeName != null){
                            ftypeName = journeyItem.typeName
                          }
                          return <Card
                          className="border-0" 
                          style={{
                          backgroundColor:"white",
                          marginTop: '15px',
                          marginBottom: '15px'}}
                        >
                            <Card.Body className = "align-items-start">
                            <Card.Title
                             style={{ 
                                 fontSize: "20px",
                              fontWeight:'normal', 
                              color: "#000000",
                              marginTop: '5px',
                              }}>
                                  {journeyItem.name == "" ? '匿名用户' : journeyItem.name}发布了{ftypeName}
                          </Card.Title>
                          <Card.Text
                             style={{ 
                                 fontSize: "15px",
                              fontWeight:'normal', 
                              color: "#000000",
                              marginTop: '0px',
                              }}>
                                  {journeyItem.timestamp.toDate().toLocaleString('en')}
                          </Card.Text>
                          <Card.Text
                             style={{ 
                                 fontSize: "20px",
                              fontWeight:'bold', 
                              color: "#000000",
                              marginTop: '5px',
                              }}
                              hidden={ftype == 'photo'}>
                                  {journeyItem.content}
                          </Card.Text>
                          <Card.Img 
                          src={journeyItem.content} 
                          style={{maxWidth: '100%'}} hidden={ftype != 'photo'}/>
                          </Card.Body>
                        </Card>
                      })
                  }


          <Modal
                  show={showText}
                  onHide={() => {
                    handleTextClose()
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      添加文字
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                      <Form>
                          <Form.Group>
                              <Form.Label>请输入一段文字</Form.Label>
                              <Form.Control as='textarea' rows={10} ref={textRef} />
                          </Form.Group>
                      </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="dark" onClick={()=>{
                        var textR = textRef.current.value
                        if (textR != null && textR != ""){
                            const newText = {
                                'name': userItem.name,
                                'email': userItem.email,
                                'status': 'pending',
                                'comment': textR,
                                'content': textR,
                                'timestamp': firebase.firestore.Timestamp.fromDate(new Date())
                            }
                            fires.collection('events').doc(id).collection('comments').add(newText).then((itmRef) => {
                                const commentId = itmRef.id
                                window.location.reload();
                            });
                            handleTextClose();
                            
                        }
                    }}>添加</Button>
                      <Button variant="secondary" onClick={()=>{
                          handleTextClose()
                      }}>关闭</Button>
                  </Modal.Footer>
                  </Modal>

          <Modal
                  show={showUpload}
                  onHide={() => {
                    handleUploadClose()
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      上传照片
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  <FileUploader
                  handleChange={handleImageFileUpload} 
                  name="传照片"
                  label="可以上传和投送照片到这里"
                  maxSize={200}
                  types={fileTypes} />
                  <br/>
                  {selectedImage && (
                              <div>
                              <img 
                              alt="not found" 
                              width={"250px"} 
                              src={URL.createObjectURL(selectedImage)} 
                              />
                              <br />
                              <Alert 
                              variant="primary"
                              >{uploadS}
                              </Alert>
                              <div>
                                <button 
                                onClick={()=>setSelectedImage(null)}
                                >移除</button>
                                <button 
                                onClick={handleImageSubmit}
                                >上传</button>
                              </div>
                              </div>
      )}
            <input
        type="file"
        name="coverImage"
        title="选择照片"
        onChange={handleImageChange}
      />
      <br /><br />

                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                  </Modal>

                  

         

          
        </div>
      </Container>
    </>
  );
}

import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert, Container, Row} from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link } from "react-router-dom"
import {isMobile} from 'react-device-detect';
import { fires } from "../firebase";
import firebase from "firebase/app";
import "firebase/firestore";

export default function EventTicketValidation() {
  const emailRef = useRef()
  const { resetPassword } = useAuth()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const maxW = isMobile ? "100%" : "40%"

  const ticketARef = useRef()
  const ticketBRef = useRef()
  const ticketCRef = useRef()


  return (
    <>
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh", minWidth: "100vw", backgroundImage: "url(https://images.unsplash.com/photo-1489549132488-d00b7eee80f1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)" }}
    >
      <div className="w-100" style={{ maxWidth: maxW }}>

      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Validate your ticket</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          {message && <Alert variant="success">{message}</Alert>}
          <Form>
          <Form.Label>Ticket Id</Form.Label>
            <Row md={3}>
            <Form.Group id="number" style={{marginTop: '15px'}}>
              <Form.Control type="number" placeholder="XXXX" maxLength={4} ref={ticketARef} required />
            </Form.Group>
            <Form.Group id="number" style={{marginTop: '15px'}}>
              <Form.Control type="number" placeholder="XXXX" maxLength={4} ref={ticketBRef} required />
            </Form.Group>
            <Form.Group id="number" style={{marginTop: '15px'}}>
              <Form.Control type="number" placeholder="XXXX" maxLength={4} ref={ticketCRef} required />
            </Form.Group>
            </Row>
            <Button 
            disabled={loading} 
            className="w-100"
            style={{
              marginTop: '15px', 
              backgroundColor: 'black'
              }}
            onClick={()=>{
                const ticketANum = ticketARef.current.value
                const ticketBNum = ticketBRef.current.value
                const ticketCNum = ticketCRef.current.value

                if (ticketANum != "" && ticketBNum != "" && ticketCNum != ""){
                  const ticket_full = ticketANum + '-' + ticketBNum + '-' + ticketCNum
                    fires.collection('event_tickets').doc(ticket_full).get().then((docSnap) => {
                        if (docSnap.exists){
                            const ticketData = docSnap.data()
                            const eveTitle = ticketData.eventTitle
                            const eveValid = ticketData.validated
                            if (eveValid != null){
                                if (eveValid){
                                    window.location.href = "/ticket-id/" + ticket_full
                                }
                                else{
                                    window.alert('Your ticket is invalid')
                                }
                            }
                            else{
                                window.alert('Your ticket is invalid')
                            }
                            
                        }
                        else{
                            window.alert('Your ticket is invalid')
                        }
                        
                    })
                }
            }}>
              Validate your ticket
            </Button>
          </Form>
        </Card.Body>
      </Card>
        
        </div>
        </Container>
      
    </>
  )
}

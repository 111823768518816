import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import Divider from '@material-ui/core/Divider';
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import {
  createSystemLoginNotification,
} from "../contexts/API";
import { auth,fires } from "../firebase";
import firebase from "firebase/app";
import "firebase/firestore";

export default function Newsletter() {
  const emailRef = useRef();
  const passRef = useRef();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login } = useAuth();
  const maxW = isMobile ? "100%" : "40%";

  const [isSubscribe, setIsSubscribe] = useState(false)

  let { emailId } = useParams();
  

  const coverImgURL =
    "url(https://images.unsplash.com/photo-1489549132488-d00b7eee80f1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)";

  useEffect(()=>{
    const subs = window.location.href.includes('/subscribe') || window.location.href.includes('newsletter')
    setIsSubscribe(subs)
  },[])

  async function handleNewsletterSubscribe() {
    var currentEmail = emailRef.current.value
    var currentPassword = passRef.current.value
    setError("");
    setLoading(true);
    if (isSubscribe){
        fires
        .collection('email_list')
        .doc(currentEmail)
        .set({
            "email": currentEmail, 
            "timestamp": firebase.firestore.Timestamp.fromDate(new Date())})
        .then(() => {
            setSuccess("Newsletter subscribe successfully!")
            navigate('/')
        })
      setLoading(false);
    }
    else{
        try {
            await login(currentEmail, currentPassword);
            if (auth.currentUser.email == currentEmail){
                fires
                    .collection('email_list')
                    .doc(currentEmail).delete().then(()=>{
                        setSuccess('You have been unsubscribe from our email list')
                        navigate('/')
                    })
            }
            else{
                setError("unsubscribe failed, please try again");
            }
          } catch {
            setError("login failed, please try again");
          }
    }
    
  }

  

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          backgroundImage: coverImgURL,
        }}
      >
        <div className="w-100" style={{ maxWidth: maxW }}>
          <Card>
            <Card.Body>
              <h3 className="text-center mb-4">{isSubscribe ? 'Newsletter Subscription' : 'Unsubscribe'}</h3>
              <p className="text-center mb-4">{isSubscribe ? 'Subscribe to our weekly event newsletter' : 'Unsubscribe from our email list'}</p>
              {success && <Alert variant="success">{success}</Alert>}
              {error && <Alert variant="danger">{error}</Alert>}
              <Form style={{}}>
                <Form.Group id="email" style={{ marginTop: "25px" }}>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control type="email" ref={emailRef} disabled={emailId != ""} defaultValue={emailId}  required />
                </Form.Group>
                <Form.Group id="password" style={{ marginTop: "15px" }} hidden={window.location.href.includes('/subscribe') || window.location.href.includes('/newsletter')}>
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" ref={passRef} required />
                </Form.Group>
                <Button
                  disabled={loading}
                  className="w-100"
                  onClick={()=>{
                      handleNewsletterSubscribe()
                  }}
                  style={{ 
                    marginTop: "15px", 
                    borderColor: 'transparent',
                    backgroundColor: 'black' }}
                >
                  {isSubscribe ? 'Subscribe' : 'Unsubscribe'}
                </Button>
                <Form.Group style={{marginTop: '15px'}}>
                    <Form.Label>{isSubscribe ? 'Subscribe to our emaillist, you will receive the weekly event recommendation emails' : 'Unsubscribe to our emaillist you will no longer to receive such emails'}</Form.Label>
                </Form.Group>
              </Form>
              <div className="w-100 text-center mt-3" hidden={isSubscribe}>
                <Link to="/forgot-password" style={{color: 'black'}}>Forget password?</Link>
              </div>
              <div className="w-100 text-center mt-3" hidden={isSubscribe}>
                <Link to={'/signup'} style={{color: 'black'}}>Not a member? Signup</Link>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}

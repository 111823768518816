import React, 
{useRef, 
useState, 
useCallback, 
useEffect } from "react";
import {
  Form,
  Button,
  ButtonGroup,
  Card,
  Alert,
  Tab,
  Tabs,
  Carousel,
  Image,
  Container,
  Badge,
  Row,
  Col,
  ListGroup,
  Navbar,
  Modal,
} from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { 
    Link, 
    useNavigate, 
    useParams, 
    Route
 } from "react-router-dom";
import { 
    isMobile, 
    isAndroid, 
    isIOS
 } from "react-device-detect";
import { fires, auth } from "../../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import StarRatings from 'react-star-ratings';
import { QRCodeCanvas } from "qrcode.react";

export default function EventService() {
  
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "60%";


  let { serviceId } = useParams();
  var currentUA = ""
  

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [serviceDetails, setServiceDetails] = useState({
      id: '',
      photoURL:'',
      bannerURL:'',
      service: '',
      serviceType: '',
      name: '',
      description: '',
      descriptions: [],
      orgId: '',
      contact: {
          email: '',
          phone: '',
          url: ''
      },
      timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
  })

  const [eventDetails, setEventDetails] = useState({});
  const [orgDetails, setOrgDetails] = useState({});
  

  const submitEmailRef = useRef()
  const detailCommentRef = useRef()

  const routeRedirect404 = () =>{ 
    let path = '/404' ; 
    window.location.href = path
  }

  const [coverImg,setCoverImg] =
    useState("https://images.pexels.com/photos/255379/pexels-photo-255379.jpeg?cs=srgb&dl=pexels-miguel-%C3%A1-padri%C3%B1%C3%A1n-255379.jpg&fm=jpg");
  const [profileImg,setProfileImg] =
    useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png");


    useEffect(() => {
        fires.collection('services').doc(serviceId).get().then((snapshot) => {
            if (snapshot.exists) {
                const serviceData = snapshot.data()
                setServiceDetails({...serviceData})
                createLinkViewLog(window.location.origin,'service item')
                if (serviceData.bannerURL != null){
                    if (serviceData.bannerURL != ""){
                        setCoverImg(serviceData.bannerURL)
                    }
                }
            }
            else{
                //deleted
                routeRedirect404()
            }
        });
      }, [serviceId]);



      function createLinkViewLog(linkVisited, linkName) {
        const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
        var ua = window.navigator.userAgent.toLowerCase();
        const platformName = "web";
        let deviceName = "";
        if (isAndroid) {
          deviceName = "android";
        } else if (isIOS) {
          deviceName = "ios";
        } else {
          deviceName = "web";
        }
        let fuid = "";
        if (auth.currentUser !== null) {
            fuid = auth.currentUser.uid;
        }
        var lName = ""
        var linkV = ""
        if (linkName != null){
          lName = linkName
        }
        if (linkVisited != null){
          linkV = linkVisited
        }
        const viewData = {
            fromUA: ua,
            fromUID: fuid,
            platform: platformName,
            device: deviceName,
            linkName: lName,
            linkURL: linkV,
            timestamp: timestampNow,
          };
        fires.collection("services").doc(serviceId).collection("views").add(viewData);
      }

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh",minWidth: '100vw', backgroundImage: 'url(' + coverImg + ')', objectFit: 'cover' }}
      >
        <div className="w-100" style={{ maxWidth: maxW }}>
          <Card
            className="border-0 align-items-center justify-content-center" 
            style={{
                alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor:"white",
            marginTop: '25px',
            marginBottom: '25px'}}
          >
            <Card.Img className = "shadow-4-strong border-0" variant="top" src={serviceDetails.photoURL} style={{maxWidth: '200px', marginTop: '25px'}}/>
              <Card.Body className = "align-items-center justify-content-center">
              <Card.Title className="text-center"
               style={{ fontSize: "30px",
                fontWeight:'bold', 
                color: "#000000"}}>
                    {serviceDetails.name}
              </Card.Title>

              <div className="d-flex align-items-center justify-content-center">
                <Badge bg="success" style={{fontSize: '20px', fontWeight: 'bold', marginTop: '25px', marginBottom: '45px'}} pill>{serviceDetails.service}</Badge>
              </div>
              {
                serviceDetails.descriptions.map((desc) => {
                  return <Card.Text
                  style={{ 
                      fontSize: "17px",
                   fontWeight:'normal', 
                   color: "#000000"}}>
                       {desc}
               </Card.Text>
                })
              }

                <div className="d-flex align-items-center justify-content-center">
                    <Button className="w-100 border-0" variant="dark" style={{ fontWeight: 'bold', marginTop: '25px'}} onClick={()=>{
                        if (serviceDetails.contact.email != null){
                            createLinkViewLog(serviceDetails.contact.email,'email')
                            window.location.href = "mailto:" + serviceDetails.contact.email
                        }
                    }}>Email us</Button>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                    <Button className="w-100 border-0" variant="warning" style={{ fontWeight: 'bold', marginTop: '25px'}} onClick={()=>{
                        if (serviceDetails.contact.phone != null){
                            createLinkViewLog(serviceDetails.contact.phone,'phone')
                            window.location.href = "tel://" + serviceDetails.contact.phone
                        }
                    }}>Call us</Button>
                </div>
                <div className="d-flex align-items-center justify-content-center">
                    <Button className="w-100 border-0" variant="info" style={{ fontWeight: 'bold', marginTop: '25px'}} onClick={()=>{
                        if (serviceDetails.contact.url != null){
                            createLinkViewLog(serviceDetails.contact.url,'url')
                            window.location.href = serviceDetails.contact.url
                        }
                    }}>Visit website</Button>
                </div>
            
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}

import React, 
{ useRef, 
  useState, 
  useEffect 
} from "react"

import { 
  Form, 
  Button, 
  Badge, 
  Card, 
  Alert, 
  Container, 
  Spinner, 
  ListGroup, 
  Breadcrumb, 
  Accordion, 
  Dropdown, 
  DropdownButton, 
  Row, 
  Col, 
  Image, 
  Nav, 
  NavDropdown, 
  FloatingLabel,
  Navbar, 
  Modal, 
  Tabs, 
  Tab } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { auth, fires, storageRef, analytics} from "../firebase"
import { Link, useNavigate, useParams } from "react-router-dom"
import {isMobile,isAndroid,isIOS} from 'react-device-detect';
import firebase from "firebase"
import 'firebase/firestore';
import { QRCodeCanvas } from "qrcode.react"
import { FaClock, FaLandmark, FaGlassCheers,FaGlobeAmericas } from "react-icons/fa";
import { useTranslation } from 'react-i18next';

import {
  EmailShareButton,
  EmailIcon,
  FacebookShareButton,
  FacebookIcon,
  LineIcon,
  LineShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  RedditShareButton,
  RedditIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  WeiboShareButton,WeiboIcon
} from "react-share";
import { saveAs } from 'file-saver'

export default function LiveEvent() {

  const { currentUser, logout } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [posts, setPosts] = useState([]);
  const [viewTypes, setViewTypes] = useState([])
  const [views, setViews] = useState([]);
  const [todayViews, setTodayViews] = useState([]); 

  const [sponsors, setSponsors] = useState([]);
  const [agendas, setAgendas] = useState([]);
  const [userData, setUserData] = useState({});
  const [eventData, setEventData] = useState({});
  const [eventComments, setEventComments] = useState([]);
  const [eventLikes, setEventLikes] = useState([]);
  const [eventMembers, setEventMembers] = useState([]);
  const [eventCheckinMembers, setEventCheckinMembers] = useState([]);
  const [eventHandRaiseMembers, setEventHandRaiseMembers] = useState([]);
  const [eventThumbUpMembers, setEventThumbUpMembers] = useState([]);
  const [eventThumbDownMembers, setEventThumbDownMembers] = useState([]);
  const [eventHandsClapMembers,setEventHandsClapMembers] = useState([]);

  const [eventDetailsInfo, setEventDetailsInfo] = useState({
    title: "",
    descriptions: [""],
    orgName: "",
    orgURL: "",
    language: "zh",
  });

  const [userDetailData, setUserDetailData] = useState({
    name: "",
    email: "",
    userName: "",
    firstName: "",
    lastName: ""
  })

  const [eventLikedMembers, setEventLikedMembers] = useState([]);
  const [eventPics, setEventPics] = useState([]);

  const { t, i18n } = useTranslation();

  const profileImg = "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png"
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageURL, setSelectedImageURL] = useState(profileImg);
  const [uploadS, setUploadS] = useState(t('event_details_live_action_picture_waiting_upload'))
  
  const commentAddRef = useRef()

  const personalInfoWork = useRef()
  const personalInfoWechat = useRef()

  

  const [eventStartStatus, setEventStartStatus] = useState('NA')
  const [eventOptions, setEventOptions] = useState({
    "poll":false,
    "draw":false,
    "namecard":false,
    "review":false,
    "comment":true,
    "picture":true,
    "socialmedia":true,
    "register-feedback":false,
    "accept-registration":true,
    "max":500})
  const [drawItems, setDrawItems] = useState({})

  const [defaultDraws, setDefaultDraws] = useState({name: '',description:'', url:'', imageURL: '', order: 0, total: 0, remaining: 0, id: ''})
  const [currentDraws, setCurrentDraws] = useState({name: '',description:'', url:'', imageURL: '', order: 0, total: 0, remaining: 0, id: ''})
  
  const [luckyDrawData, setLuckyDrawData] = useState({
    drawId: '',
    drawWinnerId: '',
    email: '',
    firstName: '',
    lastName: '',
    gender: '',
    name: '',
    ticket: '',
    lang: '',
    timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
  })

  function createEventMemberLog(eType, eTypeDescription, uEmail, uidd) {
    timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    const joinLog = {
      timestamp: timestampNow,
      type: eType,
      typeDescription: eTypeDescription,
      email: uEmail,
      uid: uidd,
    };
    fires.collection("events").doc(id).collection("logs").add(joinLog);

  }

  const handleImageChange = (e) => {
    e.preventDefault()
    const file = e.target.files[0];
    setSelectedImage(file)
  }

  const handleImageSubmit = (e) => {
    e.preventDefault()
    const file = selectedImage;
    if (!file) return;
    var fileNameStr = Math.floor(Math.random() * (1000000 - 1000 + 1) + 1000)

    var uploadTask = storageRef.child('expresources').child(fileNameStr.toString() + "_" + auth.currentUser.uid + '.jpg').put(file);

    uploadTask.on('state_changed', 
      (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            setUploadS("Stop uploading")
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
          setUploadS('Uploaded: ' + progress + '%')
            break;
        }
    }, 
    (error) => {
      setError("Error uploading")
    }, 
    () => {

      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        setSelectedImageURL(downloadURL)
        setSelectedImage(null)
        const ttimestamp = firebase.firestore.Timestamp.fromDate(new Date());
        const nameN = memberMeInfo.name
        const emailN = memberMeInfo.email
        fires
        .collection('events')
        .doc(id)
        .collection('pictures')
        .add({
          'timestamp':ttimestamp,
        'name':nameN,
        'email':emailN,
        'imageURL':downloadURL})
    });

    })

    
  }

  function routeToRoot(){
    window.location.href = "/"
  }

  const handlePersonalInfoModify = (e) => {
    const workInfo = personalInfoWork.current.value
    const wechatInfo = personalInfoWechat.current.value
    if (id != "" && memberMeInfo.email != ""){
      const uid = auth.currentUser.uid
      if (uid != ''){
        fires
        .collection('users')
        .doc(uid)
        .update(
          {
            'work':workInfo,
            'wechat':wechatInfo
          }).then(()=>{
            fires
      .collection('events')
      .doc(id)
      .collection('members')
      .doc(memberMeInfo.email)
      .update({work: workInfo, wechat: wechatInfo}).then(()=>{
        handleCloseUserModal()
      })
          })
      }
      
    }
    
    
    
  }

  const profileString =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";

  const [memberMeInfo, setMemberMeInfo] = useState({
    email: "",
    name: "",
    firstName: "",
    lastName: "",
    work: "",
    wechat: "",
    handsup: false,
    thumbup: false,
    thumbdown: false,
    handsclap: false,
    checkinStatus: true,
    drawOptions: "",
    ticketId: "",
    tickets: [],
    profileURL: profileString,
    invitedMembers: [],
  });
  

  const [links, setLinks] = useState([]);

  const [linkCounts, setLinkCounts] = useState({})

  const [invitedMembers, setInvitedMembers] = useState([])
  

  const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    

    //images
    const [showImageModal, setShowImageModal] = useState(false);
    const handleCloseImageModal = () => setShowImageModal(false);
    const handleShowImageModal = () => setShowImageModal(true);
    const [imageModalUrl, setImageModalUrl] = useState("")

    const [showUserModal, setShowUserModal] = useState(false);
    const handleCloseUserModal = () => setShowUserModal(false);
    const handleShowUserModal = () => setShowUserModal(true);

    const [modalInfo, setModalInfo] = useState({
      title:"",
      description:"",
      options: 0,
      type: "options"
    })

    const [showNameCardModal, setShowNameCardModal] = useState(false);
    const handleCloseNameCardModal = () => setShowNameCardModal(false);
    const handleShowNameCardModal = () => setShowNameCardModal(true);
    const [selectedMember, setSelectedMember] = useState({
      email: "",
    name: "",
    work: "",
    handsup: false,
    thumbup: false,
    thumbdown: false,
    handsclap: false,
    checkinStatus: true,
    })

    //user Model
    const [showAllUserModal, setShowAllUserModal] = useState(false);
    const handleCloseAllUserModal = () => setShowAllUserModal(false);
    const handleShowAllUserModal = () => setShowAllUserModal(true);

    //chat Model and pictures
    const [showAllChatModal, setShowAllChatModal] = useState(false);
    const handleCloseAllChatModal = () => setShowAllChatModal(false);
    const handleShowAllChatModal = () => setShowAllChatModal(true);

    //pictures
    const [showPictureModal, setShowPictureModal] = useState(false);
    const handleClosePictureModal = () => setShowPictureModal(false);
    const handleShowPictureModal = () => setShowPictureModal(true);

    //presenting
    const presentingLinkRef = useRef()

  const maxW = isMobile ? "100%" : "50%"

  var uid = currentUser.uid;
  var cEmail = currentUser.email;
  var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());

  let { id, lang } = useParams();
  

  const routeToLogout= () =>{ 
    logout()
    let path = '/' ; 
    window.location.href = path;
  }

  const isToday = (someDate) => {
    const today = new Date()
    return someDate.getDate() == today.getDate() &&
      someDate.getMonth() == today.getMonth() &&
      someDate.getFullYear() == today.getFullYear()
  }

  function createLinkVisitLog(linkVisited, linkName) {
    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
    var ua = window.navigator.userAgent.toLowerCase();
    const platformName = "web";
    let deviceName = "";
    if (isAndroid) {
      deviceName = "android";
    } else if (isIOS) {
      deviceName = "ios";
    } else {
      deviceName = "web";
    }
    let fuid = "";
    if (auth.currentUser !== null) {
        fuid = auth.currentUser.uid;
    }
    var viewLang = i18n.language
    var lName = ""
    var linkV = ""
    if (linkName != null){
      lName = linkName
    }
    if (linkVisited != null){
      linkV = linkVisited
    }
    const viewData = {
        fromUA: ua,
        fromUID: fuid,
        platform: platformName,
        device: deviceName,
        lang: viewLang,
        linkName: lName,
        linkURL: linkV,
        timestamp: timestampNow,
      };
    fires.collection("events").doc(id).collection("views").add(viewData);
  }

  function handleUserProfileChange(e){
    e.preventDefault()
    const emailAddr = e.target[0].value
    const nameStr = e.target[1].value
    const workStr = e.target[2].value
    const wechatStr = e.target[3].value

    if (nameStr != null  && workStr != null && wechatStr != null && auth.currentUser.email != "" && nameStr != ""){
      fires
      .collection('events')
      .doc(id)
      .collection('members')
      .doc(auth.currentUser.email)
      .update(
        {
          name: nameStr, 
          work: workStr, 
          wechat: wechatStr}).then(()=>{

          })
    }
    else{
      if (nameStr == "" || nameStr == null){
        window.alert(i18n.language == 'en' ? 'Your name could not be empty' : '您的名字不能为空')
      }
    }
  }

  function loadAllOtherUsers(userEmail){
    //getting all member data
    fires
    .collection('events')
    .doc(id)
    .collection('members')
    .orderBy('timestamp','desc')
    .get()
    .then((snapshotA) => {
      var allMembers = [];
      var checkinMembers = [];
      var handsUpMembers = [];
      var thumbUpMembers = [];
      var thumbDownMembers = [];
      var handsClapMembers = [];

      snapshotA.docs.forEach((doc) => {
          const memberId = doc.id;
          const memberData = doc.data();

          var userName = ""
          var userProfileURL = ""
          
          if (memberData.userName != null){
              userName = memberData.userName
          }
          if (memberData.userProfileURL != null){
              userProfileURL = memberData.userProfileURL
          }
          if (memberId == userEmail){
            setMemberMeInfo({...memberData})
          }
          if (memberData.handsup != null){
              if (memberData.handsup){
                  handsUpMembers.push({ ...memberData, id: memberId, email: memberId, userName: userName, userProfileURL: userProfileURL})
              }
          }
          if (memberData.thumbup != null){
              if (memberData.thumbup){
                  thumbUpMembers.push({ ...memberData, id: memberId, email: memberId, userName: userName, userProfileURL: userProfileURL})
              }
          }
          if (memberData.thumbdown != null){
              if (memberData.thumbdown){
                  thumbDownMembers.push({ ...memberData, id: memberId, email: memberId, userName: userName, userProfileURL: userProfileURL})
              }
          }
          if (memberData.handsclap != null){
              if (memberData.handsclap){
                  handsClapMembers.push({ ...memberData, id: memberId, email: memberId, userName: userName, userProfileURL: userProfileURL})
              }
          }
          if (memberData.checkinStatus != null){
              if (memberData.checkinStatus){
                  checkinMembers.push({ ...memberData, id: memberId, email: memberId, userName: userName, userProfileURL: userProfileURL})
              }
          }
          allMembers.push({ ...memberData, id: memberId, email: memberId, userName: userName, userProfileURL: userProfileURL})
      })
      setEventMembers(allMembers)
      setEventCheckinMembers(checkinMembers)
      setEventHandRaiseMembers(handsUpMembers)
      setEventThumbUpMembers(thumbUpMembers)
      setEventThumbDownMembers(thumbDownMembers)
      setEventHandsClapMembers(handsClapMembers)

  })

  }

  function loadLuckyDrawInformation(){
    if (auth.currentUser != null){
      if (auth.currentUser.email != ""){
        fires
        .collection('events')
        .doc(id)
        .collection('draw-members')
        .doc(auth.currentUser.email)
        .get()
        .then((snapshotA)=>{
          if (snapshotA.exists){
            const luckyDrawInfo = snapshotA.data()
            setLuckyDrawData(luckyDrawInfo)
          }
        })
      }
    }
  }


  function loadAllMessages(){
    fires
    .collection('events')
    .doc(id)
    .collection('messages')
    .orderBy('timestamp','desc')
    .get()
    .then((snapshotA) => {
      var allComments = [];
      snapshotA.docs.forEach((doc) => {
          const commentId = doc.id;
          const commentData = doc.data();
          var userName = ""
          var profileURL = ""
          if (commentData.fromName != null){
              userName = commentData.fromName
          }
          if (commentData.profileURL != null){
              profileURL = commentData.profileURL
          }
          if (commentData.approved != null){
            if (commentData.approved){
              allComments.push(
                { ...commentData, 
                  id: commentId, 
                  fromName: userName, 
                  profileURL: profileURL })
            }
          }

          
      })
      setEventComments(allComments)
    })

  }

  function loadAllPictures(){
    //get messages information
    fires
    .collection('events')
    .doc(id)
    .collection('pictures')
    .orderBy('timestamp','desc')
    .get()
    .then((snapshotA) => {
      var allPics = [];
      snapshotA.docs.forEach((doc) => {
        const commentId = doc.id;
        const commentData = doc.data();
        allPics.push({ ...commentData, id: commentId })
      })
      setEventPics(allPics)
    })
  }

  function changeTitleBaseonLanguage(){
      var localeTitle = eventData.title;
      var localeDescriptions = [eventData.description];
      var localeOrgName = "";
      var localeUserOrgURL = "";
      if (eventData.user.userOrg != null) {
        localeOrgName = eventData.user.userOrg;
      }
      if (i18n.language == 'en'){
        if (eventData.titles != null) {
          if (eventData.titles.en != null) {
            localeTitle = eventData.titles.en;
          }
        }
        if (eventData.descriptions != null) {
          if (eventData.descriptions.en != null) {
            localeDescriptions = eventData.descriptions.en;
          }
        }
        setEventDetailsInfo({
          title: localeTitle,
          descriptions: localeDescriptions,
          orgName: localeOrgName,
          orgURL: localeUserOrgURL,
          language: i18n.language,
        });
      }
      else {
        if (eventData.titles != null) {
          if (eventData.titles.zh != null) {
            localeTitle = eventData.titles.zh;
          }
        }
        if (eventData.descriptions != null) {
          if (eventData.descriptions.zh != null) {
            localeDescriptions = eventData.descriptions.zh;
          }
        }
        setEventDetailsInfo({
          title: localeTitle,
          descriptions: localeDescriptions,
          orgName: localeOrgName,
          orgURL: localeUserOrgURL,
          language: i18n.language,
        });

      }

  }
  

  useEffect(() => {
    fires
    .collection('users')
    .doc(uid)
    .get()
    .then((snapshot) => {
        if (snapshot.exists) {
            const users = [];
            const userD = snapshot.data();
            var userEmail = "";
            if (userD.email != null && userD.email != ""){
              userEmail = userD.email
            }
            setUserDetailData({... userD})
            if (lang == null){
              if (userD.lang != null){
                if (userD.lang == 'en'){
                  i18n.changeLanguage('en')
                }
                else {
                  i18n.changeLanguage('zh')
                }
              }
            }
            else{
              if (lang == 'en'){
                i18n.changeLanguage('en')
              }
              else {
                i18n.changeLanguage('zh')
              }
            }
            
            setUserData({...userD})
            fires
            .collection('events')
            .doc(id)
            .collection("members")
            .doc(userEmail)
            .get()
            .then((snapshotU) => {
              if (!snapshotU.exists){
                routeToRoot()
              }
              else{
                var memberData = snapshotU.data()
                setMemberMeInfo({...memberData})
                if (memberData.checkinStatus == null){
                  routeToRoot()
                }
                else{
                  if (!memberData.checkinStatus){
                    routeToRoot()
                  }
                  else{
                    //get event info real time
                    loadAllMessages()
                    loadAllPictures()
                    loadLuckyDrawInformation()

                    fires
                    .collection('events')
                    .doc(id)
                    .onSnapshot((snapshotB) => {
                      if (snapshotB.exists) {
                          var aData = {};
                          var artiData = snapshotB.data()
                          aData = artiData
                          setEventData(aData);
                          if (artiData.poll !== null) {
                            setModalInfo({...artiData.poll})
                          }
                          if (artiData.agenda !== null) {
                            const agendas = [];
                            artiData.agenda.forEach((agendaItm) => {
                              agendas.push(agendaItm);
                            });
                            setAgendas(agendas);
                          }
                          if (artiData.sponsor !== null){
                            const sponsorObjs = []
                            artiData.sponsor.forEach((sponsorItm)=>{
                              sponsorObjs.push(sponsorItm);
                            })
                            setSponsors(sponsorObjs)
                          }
                          if (artiData.options != null){
                            setEventOptions({...artiData.options})
                          }
                          if (artiData.startDate != null && artiData.endDate != null){
                            if (artiData.startDate.toDate() > (new Date())){
                              //not started
                              setEventStartStatus("NA")
                            }
                            else if (artiData.endDate.toDate() >= (new Date()) && artiData.startDate.toDate() <= (new Date())){
                              //ongoing
                              setEventStartStatus("ONGOING")
                            }
                            else if ((new Date() > artiData.endDate.toDate())){
                              //ended
                              setEventStartStatus("ENDED")
                            }
                            else{
                              setEventStartStatus("ENDED")
                            }
                          }
                          
                          if (artiData.drawItems != null){
                            setDrawItems({...artiData.drawItems})
                            if (artiData.defaultDraw != null){
                              if (Object.keys(artiData.drawItems).includes(artiData.defaultDraw)){
                                const cuDraw = artiData.drawItems[artiData.defaultDraw]
                                setDefaultDraws({...cuDraw, id: artiData.defaultDraw})
                              }
                            }

                            if (memberData.drawOptions != null){
                              if (Object.keys(artiData.drawItems).includes(memberData.drawOptions)){
                                setCurrentDraws(artiData.drawItems[memberData.drawOptions])
                              }
                            }

                            var localeTitle = artiData.title;
                            var localeDescriptions = [artiData.description];
                            var localeOrgName = "";
                            var localeUserOrgURL = "";
                            if (artiData.user.userOrg != null) {
                              localeOrgName = artiData.user.userOrg;
                            }

                            if (i18n.language == 'en'){
                              if (artiData.titles != null) {
                                if (artiData.titles.en != null) {
                                  localeTitle = artiData.titles.en;
                                }
                              }
                              if (artiData.descriptions != null) {
                                if (artiData.descriptions.en != null) {
                                  localeDescriptions = artiData.descriptions.en;
                                }
                              }
                              setEventDetailsInfo({
                                title: localeTitle,
                                descriptions: localeDescriptions,
                                orgName: localeOrgName,
                                orgURL: localeUserOrgURL,
                                language: i18n.language,
                              });
                            }
                            else {
                              if (artiData.titles != null) {
                                if (artiData.titles.zh != null) {
                                  localeTitle = artiData.titles.zh;
                                }
                              }
                              if (artiData.descriptions != null) {
                                if (artiData.descriptions.zh != null) {
                                  localeDescriptions = artiData.descriptions.zh;
                                }
                              }
                              setEventDetailsInfo({
                                title: localeTitle,
                                descriptions: localeDescriptions,
                                orgName: localeOrgName,
                                orgURL: localeUserOrgURL,
                                language: i18n.language,
                              });

                            }

                          }

                      }
                    })
                  
                  
                  
                  }
                }
              }
            })
            
        }
        else{
            //deleted
            posts.push({ id: uid, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
            setPosts(posts)
            document.title = "EVENTGO "
            document.description = "北美活动管家"
            routeToLogout()
        }
    });
  }, []);

  return (
    <div>
    <Container
      className="d-flex justify-content-center"
      style={{ minHeight: "100vh", maxWidth: maxW }}
    >
      <div className="w-100" style={{}}>
      


      <Modal show={showImageModal} onHide={handleCloseImageModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t('event_details_live_modal_picture')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Card.Img variant="top" src={imageModalUrl} style={{width: '100%', maxHeight: '450px'}}/>
        {/* <Row md={4} style={{marginTop: '25px'}}>
        <Col className="d-flex justify-content-center align-items-center" style={{marginTop: '15px'}}>
          <FacebookShareButton url={"https://events.letjoy.app/"+id} quote={eventDetailsInfo.title}>
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
        </Col>

        <Col className="d-flex justify-content-center align-items-center" style={{marginTop: '15px'}}>
          <FacebookShareButton url={"https://events.letjoy.app/"+id}>
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
        </Col>

        <Col className="d-flex justify-content-center align-items-center" style={{marginTop: '15px'}}>
          <FacebookShareButton url={"https://events.letjoy.app/"+id}>
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
        </Col>

        <Col className="d-flex justify-content-center align-items-center" style={{marginTop: '15px'}}>
          <FacebookShareButton url={"https://events.letjoy.app/"+id}>
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
        </Col>
        </Row> */}
        
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseImageModal}>
            {t('event_details_action_close')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showUserModal} onHide={handleCloseUserModal}>
        <Modal.Header closeButton>
          <Modal.Title>欢迎来到{eventData.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form>
        <Form.Group
                                id="work"
                                style={{ marginTop: "15px" }}
                              >
                                <Form.Label style={{fontWeight: 'bold'}}>工作领域</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={memberMeInfo.work}
                                  ref={personalInfoWork}
                                />
                              </Form.Group>
                              <Form.Group
                                id="wechat"
                                style={{ marginTop: "15px" }}
                              >
                                <Form.Label style={{fontWeight: 'bold'}}>微信</Form.Label>
                                <Form.Control
                                  type="text"
                                  defaultValue={memberMeInfo.wechat}
                                  ref={personalInfoWechat}
                                />
                              </Form.Group>
                              <div className="d-flex justify-content-center align-items-center" style={{marginBottom: '15px', marginTop: '15px'}}>
                                <Button variant="success" style={{marginTop: '15px'}} onClick={handlePersonalInfoModify}>更新我的信息</Button>
                              </div>
                              
                              
        </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUserModal}>
            关闭
          </Button>
          
        </Modal.Footer>
      </Modal>

      <Modal show={showNameCardModal} onHide={handleCloseNameCardModal}>
        <Modal.Header closeButton>
          <Modal.Title>{selectedMember.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ListGroup.Item className="d-flex justify-content-between align-items-start" style={{}}>
            <Card.Text  style={{fontSize: '15px', fontWeight: 'normal'}}>姓名: </Card.Text>
            <Card.Text  style={{fontSize: '15px', fontWeight: 'bold'}}> {selectedMember.name}</Card.Text>
          </ListGroup.Item>
          <ListGroup.Item className="d-flex justify-content-between align-items-start" style={{marginBottom: '5px'}}>
          <Card.Text  style={{fontSize: '15px', fontWeight: 'normal'}}>工作领域: </Card.Text>
            <Card.Text  style={{fontSize: '15px', fontWeight: 'bold'}}> {selectedMember.work == '' ? '未知' : selectedMember.work}</Card.Text>
            </ListGroup.Item>
            <ListGroup.Item className="d-flex justify-content-between align-items-start" style={{marginBottom: '5px'}}>
            <Card.Text  style={{fontSize: '15px', fontWeight: 'normal', color: 'black'}}>微信: </Card.Text>
            <Card.Text  style={{fontSize: '15px', fontWeight: 'bold'}}> {selectedMember.wechat == '' ? '未知' : selectedMember.wechat}</Card.Text>
            </ListGroup.Item>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseNameCardModal}>
            关闭
          </Button>
          <Button variant="success" onClick={(e)=>{
              handleCloseNameCardModal()
          }}>
            确认
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPictureModal} onHide={handleClosePictureModal}>
        <Modal.Header closeButton>
          <Modal.Title>{t("event_details_live_action_upload_picture")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <Form.Group id="org-profile" style={{marginTop: '5px', marginBottom: '15px'}}>
        <Form.Label style={{fontWeight: 'bold'}}>{t("event_details_live_action_picture_placeholder")}</Form.Label>
        {selectedImage && (
        <div>
        <img alt="not fount" width={"250px"} src={URL.createObjectURL(selectedImage)} />
        <br />
        <Alert variant="primary">{uploadS}</Alert>
        <div>
          <button onClick={()=>setSelectedImage(null)}>{t("event_details_live_action_remove")}</button>
          <button onClick={handleImageSubmit}>{t("event_details_live_action_upload_picture")}</button>
        </div>
        </div>
      )}
      <br />
      <input
        type="file"
        name="coverImage"
        title="Choose Picture"
        disabled={!eventOptions.picture}
        onChange={handleImageChange}
      />
      <br /><br />
      <div>
      <img alt="cover" width={"100px"} height={"100px"} src={selectedImageURL}/>
      </div>
        </Form.Group>


        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(e)=>{
            handleClosePictureModal()
          }}>
            关闭
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showAllUserModal} onHide={handleCloseAllUserModal}>
        <Modal.Header closeButton>
          <Modal.Title>参与人数({eventCheckinMembers.length})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        <ListGroup style={{marginTop: '15px'}}>
      <ListGroup.Item className="d-flex justify-content-between align-items-start" style={{backgroundColor: '#EEE'}}>  
        <div className="fw-bold">参与者</div>
        <Badge bg="primary" pill>{eventCheckinMembers.length}</Badge>
      </ListGroup.Item>
      {
      eventCheckinMembers.map((memberItm)=>{
        return <ListGroup.Item onClick={()=>{
          setSelectedMember(memberItm)
        }}>
        <div className="d-flex justify-content-between align-items-start">
        <div>
        <Card.Text style={{fontSize: '15px', fontWeight: 'bold', marginBottom: '0px', color: 'black'}}>{memberItm.name}</Card.Text>
        </div>
            <div>
                <Card.Link style={{color: 'black', textDecoration: "none"}}>{memberItm.thumbup ? '👍' : ''}</Card.Link>
                <Card.Link style={{color: 'black', textDecoration: "none"}}>{memberItm.thumbdown ? '👎' : ''}</Card.Link>
                <Card.Link style={{color: 'black', textDecoration: "none"}}>{memberItm.handsclap ? '👏' : ''}</Card.Link>
                <Card.Link style={{color: 'black', textDecoration: "none"}}>{memberItm.handsup ? '🙋' : ''}</Card.Link>
            </div>
        </div>
    </ListGroup.Item>
    })              
      }
            
        </ListGroup>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={(e)=>{
            handleCloseAllUserModal()
          }}>
            关闭
          </Button>
          <Button variant="primary" onClick={(e)=>{
            loadAllOtherUsers()
          }}>
            刷新
          </Button>
        </Modal.Footer>
      </Modal>


      <Card className="border-0">
        <Card.Body className = "align-items-center justify-content-center">
          <ListGroup.Item className="d-flex align-items-center justify-content-center" style={{marginTop: '25px'}}>
            <div>
              <Card.Title className="text-center" style={{fontSize: '22px', fontWeight: 'bold', color: 'black'}}>{eventDetailsInfo.title}</Card.Title>
              <div className="d-flex align-items-center justify-content-center">
              <Spinner 
            as="span"
            animation="border"
            size="sm"
            role="status"
            hidden={eventStartStatus == "ONGOING" || eventStartStatus == "ENDED"}
            aria-hidden="true"/>
              </div>
            <Card.Text className="text-center" hidden={eventStartStatus == "ONGOING" || eventStartStatus == "ENDED"} style={{fontSize: '15px', fontWeight: 'normal'}}>{t('event_details_live_heading_waiting')}</Card.Text>
              
            </div>
          </ListGroup.Item>

          <div className="d-flex align-items-center justify-content-between" style={{marginTop: "15px"}}>
          <Breadcrumb
          style={{  }}>
      <Breadcrumb.Item href={"/"}>{t("event_details_live_breadcrumb_home")}</Breadcrumb.Item>
      <Breadcrumb.Item href={"/"+eventData.id}>
        {t("event_details_live_breadcrumb_event")}
      </Breadcrumb.Item>
      <Breadcrumb.Item active>{t("event_details_live_breadcrumb_current_event")}</Breadcrumb.Item>
    </Breadcrumb>

    <Breadcrumb>
      <Breadcrumb.Item onClick={()=>{
        i18n.changeLanguage('zh')
        changeTitleBaseonLanguage()
      }} active={i18n.language == 'zh'}>中文</Breadcrumb.Item>
      <Breadcrumb.Item onClick={()=>{
        i18n.changeLanguage('en')
        changeTitleBaseonLanguage()
      }} active={i18n.language == 'en'} >English</Breadcrumb.Item>
    </Breadcrumb>
          </div>
          
          <Tabs
              defaultActiveKey="my-event-info"
              id="event-tab"
              className="mb-3"
              style={{ marginTop: "15px" }}
          >
            <Tab eventKey="my-event-info" title={t('event_details_live_tab_my_info')}>
              <ListGroup style={{marginTop: '15px'}}>
                <ListGroup.Item className="d-flex justify-content-between align-items-start" style={{backgroundColor: '#EEE'}}>  
                  <div className="fw-bold">{t('event_details_live_heading_me')}</div>
                  <Badge bg="success" pill>{memberMeInfo.checkinStatus ? t('event_details_live_heading_checkin_status') : 'NA'}</Badge>
                  </ListGroup.Item>

                  <ListGroup.Item onClick={()=>{
          
        }}>
        <div className="d-flex justify-content-between align-items-start">
        <div>
        <Card.Text style={{fontSize: '15px', fontWeight: 'bold', marginBottom: '0px', color: 'black'}}>{t('event_details_live_heading_me_name')}: {memberMeInfo.name}</Card.Text>
        <Card.Text style={{fontSize: '15px', fontWeight: 'bold', marginBottom: '0px', color: 'black'}}>{t('event_details_live_heading_me_ticket')}: {memberMeInfo.ticketId}</Card.Text>
        <Card.Text style={{fontSize: '15px', fontWeight: 'bold', marginBottom: '0px', color: 'black'}}>{t('event_details_live_heading_me_email')}: {memberMeInfo.email}</Card.Text>
        </div>
            <div>
                <Card.Link style={{color: 'black', textDecoration: "none"}}>{memberMeInfo.thumbup ? '👍' : ''}</Card.Link>
                <Card.Link style={{color: 'black', textDecoration: "none"}}>{memberMeInfo.thumbdown ? '👎' : ''}</Card.Link>
                <Card.Link style={{color: 'black', textDecoration: "none"}}>{memberMeInfo.handsclap ? '👏' : ''}</Card.Link>
                <Card.Link style={{color: 'black', textDecoration: "none"}}>{memberMeInfo.handsup ? '🙋' : ''}</Card.Link>
            </div>
        </div>
    </ListGroup.Item>

    <ListGroup.Item hidden={defaultDraws.id == ""}>
        <div className="d-flex justify-content-between align-items-start">
        <div>
        <Card.Title style={{fontSize: '20px', fontWeight: 'bold', color: 'black'}}>{t("event_details_live_heading_luckydraw_title")} - {defaultDraws.name}</Card.Title>
        <Card.Text style={{fontSize: '15px', fontWeight: 'bold', marginBottom: '0px', color: 'black'}}>{t('event_details_live_heading_luckydraw_name')}: {luckyDrawData.name}</Card.Text>
        <Card.Text style={{fontSize: '15px', fontWeight: 'bold', marginBottom: '0px', color: 'black'}}>{t('event_details_live_heading_luckydraw_ticket')}: {luckyDrawData.ticket}</Card.Text>
        <Card.Text style={{fontSize: '15px', fontWeight: 'bold', marginBottom: '0px', color: 'black'}}>{t('event_details_live_heading_luckydraw_gender')}: {luckyDrawData.gender}</Card.Text>
        <Card.Text style={{fontSize: '15px', fontWeight: 'bold', marginBottom: '0px', color: 'black'}}>{t('event_details_live_heading_me_draw_result')}: </Card.Text>
        <Card.Link style={{fontSize: '20px', fontWeight: 'bold', marginBottom: '0px', marginTop: '15px', color: 'black'}} hidden={luckyDrawData.email == auth.currentUser.email} onClick={()=>{
          if (luckyDrawData.email == "" && eventData.defaultDraw != null) {
            //havenot joined
            if (eventData.defaultDraw != ""){
              window.location.href = "/event-lucky-draw/" + id + '/' + eventData.defaultDraw + '/' + i18n.language
            }
            
            
            
            // const luckyDrawDataItem = {
            //   name: memberMeInfo.name,
            //   email: memberMeInfo.email,
            //   ticket: memberMeInfo.ticketId,
            //   firstName: memberMeInfo.firstName,
            //   lastName: memberMeInfo.lastName,
            //   gender: "",
            //   lang: i18n.language,
            //   timestamp: timestampNow,
            // }
            // fires
            // .collection("events")
            // .doc(id)
            // .collection("draw-members")
            // .doc(auth.currentUser.email)
            // .set(luckyDrawDataItem).then(()=>{
            //   loadLuckyDrawInformation()
            // })
          }
          else{

          }
        }}>{t('event_details_live_action_luckydraw_registration')}</Card.Link>
        </div>
        </div>
    </ListGroup.Item>

                </ListGroup>


                <Row md={2}>
                  <Col>
                  <Button 
                variant={memberMeInfo.handsup ? "light" : "dark"} 
                style={{width: '100%', marginTop: '15px'}}
                onClick={()=>{
                const cEmail = userDetailData.email
              fires.collection("events")
              .doc(id)
              .collection("members")
              .doc(cEmail)
              .update({handsup: !memberMeInfo.handsup}).then(()=>{
                createEventMemberLog("operation","handraise",cEmail,auth.currentUser.uid)
              })
              }}>{t('event_details_live_action_handraise')} 🙋</Button>
              </Col>

              <Col>
              <Button 
            variant={memberMeInfo.handsclap ? "light" : "dark"} 
            style={{width: '100%', marginTop: '15px'}}
            onClick={()=>{
              const cEmail = userDetailData.email;
              fires.collection("events")
              .doc(id)
              .collection("members")
              .doc(cEmail)
              .update({handsclap: !memberMeInfo.handsclap}).then(()=>{
                createEventMemberLog("operation","claphands",cEmail,auth.currentUser.uid)
              })
            }}>{t('event_details_live_action_clap')} 👏</Button>
              </Col>

              <Col>
              <Button 
            variant={memberMeInfo.thumbup ? "light" : "dark"} 
            style={{width: '100%', marginTop: '15px'}}
            onClick={()=>{
              const cEmail = userDetailData.email;
              fires.collection("events")
              .doc(id)
              .collection("members")
              .doc(cEmail)
              .update({thumbup: !memberMeInfo.thumbup}).then(()=>{
                createEventMemberLog("operation","thumbup",cEmail,auth.currentUser.uid)
              })
            }}>{t('event_details_live_action_like')} 👍</Button>
              </Col>

              <Col>
              <Button 
            variant={memberMeInfo.thumbdown ? "light" : "dark"} 
            style={{width: '100%', marginTop: '15px'}}
            onClick={()=>{
              const cEmail = userDetailData.email;
              fires.collection("events")
              .doc(id)
              .collection("members")
              .doc(cEmail)
              .update({thumbdown: !memberMeInfo.thumbdown}).then(()=>{
                createEventMemberLog("operation","thumbdown",cEmail,auth.currentUser.uid)
              })
            }}>{t('event_details_live_action_dislike')} 👎</Button>
              </Col>
                </Row>

                <ListGroup style={{marginTop: '35px', marginBottom: '35px'}}>
                  <ListGroup.Item className="border-0 d-flex align-items-center justify-content-center">
                  <QRCodeCanvas
                                    id="qrCodeEl"
                                    value={
                                      "https://rileyevents.com/ticket-id/" +
                                      memberMeInfo.ticketId
                                    }
                                    size={256}
                                    bgColor={"#ffffff"}
                                    fgColor={"#000000"}
                                    level={"L"}
                                    includeMargin={false}
                                    imageSettings={{
                                      src: memberMeInfo.profileURL,
                                      height: 20,
                                      width: 20,
                                      excavate: true,
                                    }}
                                    hidden={memberMeInfo.email == ""}
                                  />
                  </ListGroup.Item>
                </ListGroup>

                            <Form onSubmit={handleUserProfileChange}>
                              <Form.Group
                                id="email"
                                style={{ marginTop: "15px" }}
                              >
                                <FloatingLabel
                                label={t('user_details_user_info_email')}>
                                <Form.Control
                                  type="email"
                                  defaultValue={memberMeInfo.email}
                                  required
                                  disabled
                                />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group
                                id="name"
                                style={{ marginTop: "15px" }}
                              >
                                <FloatingLabel
                                label={t('user_details_user_info_name')}>
                                <Form.Control
                                  type="text"
                                  defaultValue={memberMeInfo.name}
                                  required
                                />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group
                                id="work"
                                style={{ marginTop: "15px" }}
                              >
                                <FloatingLabel
                                label={t('user_details_user_info_work')}>
                                <Form.Control
                                  type="text"
                                  defaultValue={memberMeInfo.work}
                                />
                                </FloatingLabel>
                              </Form.Group>
                              <Form.Group
                                id="wechat"
                                style={{ marginTop: "15px" }}
                              >
                                <FloatingLabel
                                label={t('user_details_user_info_wechat')}>
                                <Form.Control
                                  type="text"
                                  defaultValue={memberMeInfo.wechat}
                                />
                                </FloatingLabel>
                              </Form.Group>
                              <Button
                                className="w-100"
                                type="submit"
                                style={{
                                  marginTop: "15px",
                                  color: "white",
                                  background: "black",
                                  width: "100%",
                                }}
                              >
                                {t('user_details_action_modify_user_info')}
                              </Button>
                              </Form>

                              

                

            
            
            

            </Tab>
            <Tab eventKey="my-invited-members" title={t("event_details_live_tab_my_invited")}>

            <ListGroup style={{ marginTop: "15px" }}>
                                  {invitedMembers.map((memberItm) => {
                                    return (
                                      <ListGroup.Item
                                        className="d-flex align-items-center"
                                        style={{
                                          alignSelf: "center",
                                          display: "flex",
                                          width: "100%",
                                          marginBottom: "5px",
                                        }}
                                      >
                                        <Image
                                          roundedCircle
                                          src={profileString}
                                          width="50px"
                                          height="50px"
                                        />
                                        <div style={{ marginLeft: "5px" }}>
                                          <Card.Text
                                            style={{
                                              fontSize: "18px",
                                              fontWeight: "bold",
                                              marginBottom: "0px",
                                              color: "black",
                                            }}
                                          >
                                            {memberItm.name}
                                          </Card.Text>
                                          <Card.Text
                                            className="text-secondary"
                                            style={{
                                              fontSize: "15px",
                                              fontWeight: "normal",
                                            }}
                                          >
                                            {memberItm.email}
                                          </Card.Text>
                                        </div>
                                      </ListGroup.Item>
                                    );
                                  })}
                                </ListGroup>

            </Tab>
            <Tab eventKey="agenda" 
            title={t('event_details_live_tab_agenda')}
            >

            <div
                              className="d-flex justify-content-between align-items-start"
                              style={{
                                marginTop: "25px",
                                marginBottom: "10px",
                              }}
                              hidden={agendas.length == 0}
                            >
                              <Card.Text
                                className="text-center"
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  color: "#000000",
                                  marginBottom: '25px',
                                }}
                                hidden={agendas.length == 0}
                              >
                                {t('event_details_live_heading_agenda')}({agendas.length})
                              </Card.Text>
                            </div>

            {agendas.map((agendaItm) => {
                                return (
                                  <Card
                                    className="align-items-start border-0"
                                    style={{ marginBottom: "15px" }}
                                  >
                                    <Card.Title className="text-center" style={{fontSize: '20px', fontWeight: 'bold'}}>
                                      {agendaItm.title}
                                    </Card.Title>
                                    <br />
                                    <Card.Text style={{fontSize: '17px', fontWeight: 'normal'}}>
                                      {agendaItm.description}
                                    </Card.Text>
                                    <div>
                                      <FaGlobeAmericas />
                                      {agendaItm.location !== ""
                                        ?  "  " + agendaItm.location
                                        : "  No Location"}
                                    </div>
                                    <div>
                                    <FaClock />
                                    {"  " + agendaItm.date
                                        .toDate()
                                        .toLocaleString("zh")}
                                    </div>
                                    <br />
                                    
                                  </Card>
                                );
                              })}
            


            </Tab>
            <Tab eventKey="sponsor" title={t('event_details_live_tab_sponsor')}>

            <div
                              className="d-flex justify-content-between align-items-start"
                              style={{
                                marginTop: "25px",
                                marginBottom: "10px",
                              }}
                              hidden={sponsors.length == 0}
                            >
                              <Card.Text
                                className="text-center"
                                style={{
                                  fontSize: "20px",
                                  fontWeight: "bold",
                                  color: "#000000",
                                }}
                                hidden={sponsors.length == 0}
                              >
                                {t('event_details_live_heading_sponsor')}({sponsors.length})
                              </Card.Text>
                            </div>

      {sponsors.map((sponsorItm) => {
                              return <Card 
                              className = "border-0 justify-content-center" 
                              style={{ 
                                margin: "25px", 
                                backgroundColor:"#ffffff", 
                                borderRadius: "15px"}}>
                              <img 
                              variant="top" 
                              style={{maxHeight: isMobile ? "250px" : "600px"}} 
                              src={sponsorItm.imageURL} />
                              <Card.Title 
                              className="text-center" 
                              style={{
                                margin: "15px", 
                                fontSize: '20px', 
                                fontWeight: 'bold'}}>
                                  {sponsorItm.name}
                              </Card.Title>
                              <Card.Text 
                              className="text-center" 
                              style={{
                                marginLeft: "15px", 
                                marginRight: "15px", 
                                fontSize: '15px', 
                                fontWeight: 'normal'}}
                                >{sponsorItm.description.substring(0,100)}
                                </Card.Text>
                              <div 
                              className="justify-content-center align-items-center" 
                              style={{alignSelf: 'center'}}>
                              <Button 
                              style={{
                                backgroundColor:'transparent', 
                                borderRadius:'25px', 
                                borderColor:'#000000', 
                                borderWidth:'2px', 
                                fontSize: '20px', 
                                fontWeight:'normal', 
                                height: '50px', 
                                marginTop: "25px", 
                                marginBottom: "15px", 
                                color: "black",
                                 width: '200px'}} 
                                 onClick={()=> {
                                  createLinkVisitLog(sponsorItm.url, sponsorItm.name)
                                  window.location.href = sponsorItm.url
                              }}>{t('event_details_live_action_sponsor_learn_more')}</Button>
                              </div>
                              </Card>

                            })}

            </Tab>
            <Tab eventKey="comments" title={t("event_details_live_tab_comment")}>

            <Form>
                <Form.Group>
                  <FloatingLabel label={t("event_details_live_action_placeholder")}>
                  <Form.Control as="textarea" ref={commentAddRef} required/>
                  </FloatingLabel>
                </Form.Group>
                <Button variant="dark" style={{marginTop: '15px'}} onClick={()=>{
                  const commentValue = commentAddRef.current.value
                  
                  if (commentValue != ""){
                    
                    const ttimestamp = firebase.firestore.Timestamp.fromDate(new Date());
                    const nameN = memberMeInfo.name
                    var emailN = ""
                    if (memberMeInfo.email != ""){
                      emailN = memberMeInfo.email
                    }
                    fires
                      .collection('events')
                      .doc(id)
                      .collection('messages')
                      .add({
                          'timestamp':ttimestamp,
                          'email':emailN,
                          'name':nameN,
                          'profileURL':profileImg,
                          'fromUID': '',
                          'approved':eventOptions.comment,
                          'message':commentValue}).then((sta)=>{
                            commentAddRef.current.value = ""
                            loadAllMessages()
                          })
                  }
                }}>{t("event_details_live_action_send_message")}</Button>
              </Form>

            <ListGroup style={{marginTop: '25px'}}>
            <ListGroup.Item
                className="d-flex align-items-center justify-content-between border-0"
                style={{
                  display: "flex",
                  width: "100%",
                  marginBottom: "5px",
                  backgroundColor: '#EEEEEE'
                }}>
                <Card.Text
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                      marginBottom: "0px",
                                      color: "black",
                                    }}
                                  >
                                    {t("event_details_live_tab_comment")}
                                  </Card.Text>
                <Button variant="dark" onClick={(e)=>{
                    loadAllMessages()
                }}>
                  {t("event_details_live_action_refresh")}
                </Button>
            </ListGroup.Item>
            </ListGroup>  
            <ListGroup style={{marginTop: '5px'}}>
              
        {
                              eventComments.map((messageItm) => {
                                return <ListGroup.Item
                                className="align-items-start"
                                style={{
                                  display: "flex",
                                  width: "100%",
                                }}
                              >
                                <div style={{ marginLeft: "0px" }}>
                                  <Card.Text
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                      marginBottom: "0px",
                                      color: "black",
                                    }}
                                  >
                                    {messageItm.name}
                                  </Card.Text>
                                  <Card.Text
                                    className="text-secondary"
                                    style={{
                                      fontSize: "15px",
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {messageItm.message}
                                  </Card.Text>
                                </div>
                                
                              </ListGroup.Item>
                              })
                            }
                            </ListGroup>


            </Tab>
            <Tab eventKey="pictures" title={t("event_details_live_tab_picture")}>

            <ListGroup style={{marginTop: '25px'}}>
            <ListGroup.Item
                className="d-flex align-items-center justify-content-between border-0"
                style={{
                  display: "flex",
                  width: "100%",
                  marginBottom: "5px",
                  backgroundColor: '#EEEEEE'
                }}>
                <Card.Text
                                    style={{
                                      fontSize: "18px",
                                      fontWeight: "bold",
                                      marginBottom: "0px",
                                      color: "black",
                                    }}
                                  >
                                    {t("event_details_live_tab_picture")} ({eventPics.length})
                                  </Card.Text>
                <div>
                <Button variant="dark" style={{marginRight: '5px'}} onClick={(e)=>{
                  handleShowPictureModal()
                }}>
                  {t("event_details_live_action_upload")}
                </Button>
                <Button variant="dark" onClick={(e)=>{
                    loadAllPictures()
                }}>
                  {t("event_details_live_action_refresh")}
                </Button>
                </div>
                
            </ListGroup.Item>
            </ListGroup>
            <Row md={3}>
            {
                              eventPics.map((picItm) => {
                                return <Col><Card
                                className="align-items-start border-0"
                                style={{
                                  width: "100%",
                                  marginBottom: "5px",
                                  marginTop: "5px"
                                }}
                                onClick={()=>{
                                  setImageModalUrl(picItm.imageURL)
                                  handleShowImageModal()
                                }}
                              >
                                <Card.Img variant="top" width="100%" src={picItm.imageURL} style={{maxHeight: '250px'}}/>
                              </Card></Col>
                              })
                            }
            </Row>
            


            </Tab>
      </Tabs>

                          
        
        
            
        </Card.Body>
      </Card>
      </div>
      </Container>

      <Navbar bg="dark" variant="dark" expand="lg" sticky="bottom" hidden={!memberMeInfo.checkinStatus}>
        <Container fluid>
          <Navbar.Brand href="/">{userData.name == "" ? "NA" : t('event_details_live_heading_me')}</Navbar.Brand>
        </Container>
      </Navbar>

      
    </div>
  )
}

import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert, Container } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link } from "react-router-dom"
import {isMobile} from 'react-device-detect';

export default function ForgotPassword() {
  const emailRef = useRef()
  const { resetPassword } = useAuth()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const maxW = isMobile ? "100%" : "40%"

  async function handleSubmit(e) {
    e.preventDefault()

    try {
      setMessage("")
      setError("")
      setLoading(true)
      await resetPassword(emailRef.current.value)
      setMessage("We have sent the password resetting email to your email, please check your inbox")
    } catch {
      setError("Password reset failed")
    }

    setLoading(false)
  }

  return (
    <>
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh", minWidth: "100vw", backgroundImage: "url(https://images.unsplash.com/photo-1489549132488-d00b7eee80f1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)" }}
    >
      <div className="w-100" style={{ maxWidth: maxW }}>

      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">Reset your password</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          {message && <Alert variant="success">{message}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="email" style={{marginTop: '15px'}}>
              <Form.Label>Email Address</Form.Label>
              <Form.Control type="email" ref={emailRef} required />
            </Form.Group>
            <Button 
            disabled={loading} 
            className="w-100" 
            type="submit" 
            style={{
              marginTop: '15px', 
              backgroundColor: 'black'
              }}>
              Reset your password
            </Button>
          </Form>
          <div className="w-100 text-center mt-3">
            <Link 
            to="/login" 
            style={{
              color: 'black'
              }}>Login</Link>
          </div>
        </Card.Body>
      </Card>
        
        </div>
        </Container>
      
    </>
  )
}

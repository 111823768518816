import React, { useState, useEffect } from "react";
import { fires, auth } from "../../firebase";
import firebase from "firebase/app";
import "firebase/firestore";



const Gallery = (id) => {
  const [currentGrid, setCurrentGrid] = useState(0);

  const [imageUrls, setImageUrls] = useState([
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",
    "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/toronto-event-01.JPG",


 	// 'https://source.unsplash.com/random/200x200?sig=1',
 	// 'https://source.unsplash.com/random/200x200?sig=2',
 	// 'https://source.unsplash.com/random/200x200?sig=3',
 	// 'https://source.unsplash.com/random/200x200?sig=4',
 	// 'https://source.unsplash.com/random/200x200?sig=5',
 	// 'https://source.unsplash.com/random/200x200?sig=6',
 	// 'https://source.unsplash.com/random/200x200?sig=7',
 	// 'https://source.unsplash.com/random/200x200?sig=8',
 	// 'https://source.unsplash.com/random/200x200?sig=9',
 	// 'https://source.unsplash.com/random/200x200?sig=10',
 	// 'https://source.unsplash.com/random/200x200?sig=11',
 	// 'https://source.unsplash.com/random/200x200?sig=12',
 	// 'https://source.unsplash.com/random/200x200?sig=13',
 	// 'https://source.unsplash.com/random/200x200?sig=14',
 	// 'https://source.unsplash.com/random/200x200?sig=15',
 	// 'https://source.unsplash.com/random/200x200?sig=16',
 	// 'https://source.unsplash.com/random/200x200?sig=17',
 	// 'https://source.unsplash.com/random/200x200?sig=18',
 	// 'https://source.unsplash.com/random/200x200?sig=19',
 	// 'https://source.unsplash.com/random/200x200?sig=20',
 	// 'https://source.unsplash.com/random/200x200?sig=21',
 	// 'https://source.unsplash.com/random/200x200?sig=22',
 	// 'https://source.unsplash.com/random/200x200?sig=23',
 	// 'https://source.unsplash.com/random/200x200?sig=24',
 	// 'https://source.unsplash.com/random/200x200?sig=25',
 	// 'https://source.unsplash.com/random/200x200?sig=26',
 	// 'https://source.unsplash.com/random/200x200?sig=27',
 	// 'https://source.unsplash.com/random/200x200?sig=28',
 	// 'https://source.unsplash.com/random/200x200?sig=29',
    //  'https://source.unsplash.com/random/200x200?sig=30',
    //  'https://source.unsplash.com/random/200x200?sig=31',
    //  'https://source.unsplash.com/random/200x200?sig=32',
    //  'https://source.unsplash.com/random/200x200?sig=33',
    //  'https://source.unsplash.com/random/200x200?sig=34',
    //  'https://source.unsplash.com/random/200x200?sig=35',
    //  'https://source.unsplash.com/random/200x200?sig=36',
    //  'https://source.unsplash.com/random/200x200?sig=37',
    //  'https://source.unsplash.com/random/200x200?sig=38',
    //  'https://source.unsplash.com/random/200x200?sig=39',
    //  'https://source.unsplash.com/random/200x200?sig=40',
    //  'https://source.unsplash.com/random/200x200?sig=41',
    //  'https://source.unsplash.com/random/200x200?sig=42',
    //  'https://source.unsplash.com/random/200x200?sig=43',
    //  'https://source.unsplash.com/random/200x200?sig=44',
    //  'https://source.unsplash.com/random/200x200?sig=45',
    //  'https://source.unsplash.com/random/200x200?sig=46',
    //  'https://source.unsplash.com/random/200x200?sig=47',
    //  'https://source.unsplash.com/random/200x200?sig=48',
    //  'https://source.unsplash.com/random/200x200?sig=49',
    //  'https://source.unsplash.com/random/200x200?sig=50',
    //  'https://source.unsplash.com/random/200x200?sig=51',
]);

  const imageGrids = [];
  for (let i = 0; i < imageUrls.length; i += 30) {
    imageGrids.push(imageUrls.slice(i, i + 30));
  }

  useEffect(() => {
      
    //   fires.collection('events').doc(id).collection('members').get().then((memb)=>{
    //       memb.docs.forEach((doc) => {
    //           let memberId = doc.id
    //           let memberData = doc.data()
    //           var memberImages = []
    //           if (memberData.profileURL !== null){
    //               memberImages.push(memberData.profileURL)
    //           }
    //           //setImageUrls(memberImages)
    //       })
    //   })
    const interval = setInterval(() => {
      setCurrentGrid(prevGrid => (prevGrid + 1) % imageGrids.length);
    }, 15000); // Change grid every 15 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="gallery">
      {imageGrids[currentGrid].map((url, index) => (
        <div>
            <img key={index} src={url} alt={`Gallery ${index}`} className="fade" />
        </div>
      ))}
    </div>
  );
};

export default Gallery;

import React, 
{useRef, 
useState, 
useCallback, 
useEffect } from "react";
import {
  Form,
  Button,
  ButtonGroup,
  Card,
  Alert,
  Tab,
  Tabs,
  Carousel,
  Image,
  Container,
  Row,
  Col,
  ListGroup,
  Navbar,
  Modal,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate, useParams, Route } from "react-router-dom";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { fires, auth } from "../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import StarRatings from 'react-star-ratings';

export default function EventFeedback() {
  
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "50%";

  let { id } = useParams();
  var currentUA = ""

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [eventDetails, setEventDetails] = useState([]);
  const [orgDetails, setOrgDetails] = useState({});
  const [agendas, setAgendas] = useState([])
  const [agendaRating, setAgendaRating] = useState([])
  const [userDetails, setUserDetails] = useState({email: ''});
  const [currentRating, setCurrentRating] = useState(-1);

  const submitEmailRef = useRef()
  const detailCommentRef = useRef()

  const routeRedirect404 = () =>{ 
    let path = '/404' ; 
    window.location.href = path
  }

  const [coverImg,setCoverImg] =
    useState("https://images.unsplash.com/photo-1439792675105-701e6a4ab6f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1746&q=80");
  const [profileImg,setProfileImg] =
    useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png");

    function submitFeedback(){
        const feedbackText = detailCommentRef.current.value
        const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
        var femail = ''
        var anyEmail = submitEmailRef.current.value
        if (auth.currentUser != null) {
            femail = auth.currentUser.email
            let cconfirm = window.confirm('您确认提交吗？')
            if (cconfirm){
              fires
            .collection('events')
            .doc(id)
            .collection('feedbacks')
            .doc(femail)
            .set(
              {
                'rating':currentRating, 
                'feedback':feedbackText, 
                'agendas': agendaRating,
                'email': 'femail',
                'timestamp': timestampNow
              }).then(()=>{
                window.location.href = '/'
            })
            }
        }
        else{
          if (anyEmail != ""){
            let cconfirm = window.confirm('您确认提交吗？')
            if (cconfirm){
              fires
            .collection('events')
            .doc(id)
            .collection('feedbacks')
            .add(
              {
                'rating':currentRating, 
                'feedback':feedbackText, 
                'agendas': agendaRating,
                'email': anyEmail,
                'timestamp': timestampNow
              }).then(()=>{
                window.location.href = '/'
            })
            }
          }
          else {
            window.alert('请您先输入电子邮箱')
          }
            
        }
        
    }


    useEffect(() => {
        fires.collection('events').doc(id).get().then((snapshot) => {
            if (snapshot.exists) {
                const postData = snapshot.data()
                if (postData.status != null) {
                  if (postData.status == 'ended' || postData.status == 'live' || postData.status == 'private'){
                    setEventDetails(postData)
                    if (postData.agenda != null){
                      setAgendas(postData.agenda)
                      var aRatings = []
                      postData.agenda.map((agendaItm) => {
                        aRatings.push(
                          {
                            rating: 0, 
                            title: agendaItm.title
                          })
                      })
                      setAgendaRating(aRatings)
                    }
                    var ua = window.navigator.userAgent.toLowerCase()
                    currentUA = ua
                    var fuid = ''
                    var femail = ''
                    const orgId = postData.orgId
                    if (auth.currentUser != null) {
                        fuid = auth.currentUser.uid
                        femail = auth.currentUser.email
                        if (femail != ''){
                            fires.collection('events').doc(id).collection('members').doc(femail).get().then((snapshotA)=>{
                                if (snapshotA.exists){
                                    if (fuid != ''){
                                        fires.collection('users').doc(fuid).get().then((snapshotA)=>{
                                            const snapData = snapshotA.data()
                                            setUserDetails({...snapData, id: fuid})
                                        })
                                        if (orgId != ""){
                                            fires.collection('groups').doc(orgId).get().then((snapshotA)=>{
                                                const snapData = snapshotA.data()
                                                setOrgDetails({...snapData})
                                                if (snapData.profileURL != ""){
                                                    setProfileImg(snapData.profileURL)
                                                }
                                                if (snapData.coverURL != ""){
                                                    setCoverImg(snapData.coverURL)
                                                }
                                            })
                                        }
                                    }
                                    else{
                                        routeRedirect404()
                                    }

                                }
                                else{
                                    routeRedirect404()
                                }
                            })
                        }
                        else{
                            routeRedirect404()
                        }
                    }
                    else{
                        //no auth
                        if (orgId != ""){
                          fires.collection('groups').doc(orgId).get().then((snapshotA)=>{
                              const snapData = snapshotA.data()
                              setOrgDetails({...snapData})
                              if (snapData.profileURL != ""){
                                  setProfileImg(snapData.profileURL)
                              }
                              if (snapData.coverURL != ""){
                                  setCoverImg(snapData.coverURL)
                              }
                          })
                      }
                    }
                  }
                  else{
                    //not live, or ended
                    routeRedirect404()
                  }
                }
                else{
                  //not found
                  //deleted
                  routeRedirect404()
                // posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
                // setPosts(posts)
                }

            }
            else{
                //deleted
                routeRedirect404()
                // posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
                // setPosts(posts)
                
            }
        });
      }, []);

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh",minWidth: '100vw',backgroundImage: 'url(' + coverImg + ')' }}
      >
        <div className="w-100" style={{ maxWidth: maxW }}>
          <Card
            className="border-0 align-items-center justify-content-center" 
            style={{
                alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor:"white",
            marginTop: '25px',
            marginBottom: '25px'}}
          >
            <Card.Img className = "rounded-circle shadow-4-strong" variant="top" src={profileImg} style={{height: '100px', width: '100px', marginTop: '25px', borderColor: '#ffffff'}}/>
              <Card.Body className = "align-items-center justify-content-center">
              <Card.Title className="text-center"
               style={{ fontSize: "30px",
                fontWeight:'bold', 
                color: "#000000"}}>{eventDetails.title}</Card.Title>

            <Form style={{marginTop: '25px'}}>
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
            <Form.Group id="email" style={{marginTop: '15px'}}>
              <Form.Label style={{color: 'black', fontSize: '12px', fontWeight: 'bold'}}>电子邮箱</Form.Label>
              <Form.Control style={{ color: 'black'}} type="email" placeholder="" defaultValue={userDetails.email} ref={submitEmailRef}  />
            </Form.Group>
            <Form.Group id="rating" style={{marginTop: '25px', marginBottom: '60px'}}>
            <Form.Label style={{color: 'black', fontSize: '15px', fontWeight: 'bold'}}>对我们的评价是</Form.Label>
            <br/>
            <StarRatings
                name="对我们的评价是"
                starEmptyColor="lightgray"
                rating={currentRating}
                starRatedColor="orange"
                starDimension="30px"
                starSpacing="15px"
                numberOfStars={5}
                changeRating={(newRating)=>{
                    setCurrentRating(newRating)
                }}
            />
            </Form.Group>
            {
              agendas.map((agendaItm) => {
                return <Form.Group id="rating" style={{marginTop: '25px'}}>
                <Form.Label style={{color: 'black', fontSize: '15px', fontWeight: 'bold'}}>{"第"+(agendaItm.order+1)+"部分: "+agendaItm.title}</Form.Label>
                <br/>
                <StarRatings
                    name={agendaItm.title}
                    starEmptyColor="lightgray"
                    rating={agendaRating[agendaItm.order].rating}
                    starRatedColor="orange"
                    starDimension="30px"
                    starSpacing="15px"
                    numberOfStars={5}
                    changeRating={(newRating)=>{
                      if (agendaItm.order != null){
                        if (agendaItm.order >= 0 && agendaItm.order < agendaRating.length){
                          var aratings = agendaRating
                          var rateItm = aratings[agendaItm.order]
                          rateItm.rating = newRating
                          aratings[agendaItm.order] = rateItm
                          setAgendaRating(aratings)
                          setSuccess("评价成功："+rateItm.title)
                        }
                      }
                    }}
                />
                </Form.Group>
              })
            }
            <Form.Group id="content" style={{marginTop: '25px'}}>
              <Form.Label style={{color: 'black', fontSize: '15px', fontWeight: 'bold'}}>请您提出意见或建议</Form.Label>
              <Form.Control style={{color: 'black'}} as="textarea" ref={detailCommentRef} rows={10} />
            </Form.Group>
            
            <Button className="w-100 p3" style={{
                alignItems:'center', 
                justifyContent:'space-between', 
                        backgroundColor:'#111', 
                        borderRadius:'15px', 
                        borderColor:'#ffffff', 
                        borderWidth:'3px', 
                        fontSize: '15px', 
                        fontWeight:'bold', 
                        height: '50px',
                        marginTop: "15px"}} onClick={()=>{
                          submitFeedback()
                        }}>提交</Button>
          </Form>
            
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}

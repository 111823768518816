import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert, Tab, Tabs, Carousel, Image, Container, Row, Col, Navbar, Badge, ListGroup, FloatingLabel } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { auth, fires} from "../../firebase"
import { Link,  Route, useParams, Navigate } from "react-router-dom"
import {isMobile, isAndroid, isIOS} from 'react-device-detect';
import uuid from 'react-uuid';
import { Helmet } from "react-helmet"
import Select, { MultiValue } from 'react-select';
import SimpleFooter from "./SimpleFooter"
import "firebase/firestore";
import "firebase/auth";
import firebase from "firebase/app";
import { useTranslation } from "react-i18next";
import { createPageView } from "../../contexts/Service"
import PhoneInput from 'react-phone-number-input'


export default function EventSponsorForm() {
    const [error, setError] = useState("")
    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(false);

    const [sponsorInfo, setSponsorInfo] = useState({
      firstName: "",
      lastName: "",
      name: "",
      companyName: "",
      companySector: "",
      address: "",
      email: "",
      password: "",
      phone: ""
    })


    const lngs = [
      { code: "en", native: "English" },
      { code: "zh", native: "简体中文" },
    ];
    const { t, i18n } = useTranslation();
  
    let { lang } = useParams();

    const [businessSector, setBusinessSector] = useState({
      value: "",
      label: "",
    })
    const [budget, setBudget] = useState({
      value: "",
      label: "",
    })
    const [eventType, setEventType] = useState([{
      value: "",
      label: "",
    }])
    const [eventSize, setEventSize] = useState({
      value: "",
      label: "",
    })
    const [ageRange, setAgeRange] = useState([{
      value: "",
      label: "",
    }])
  
    const maxW = isMobile ? "100%" : "60%"


    useEffect(()=>{
      createPageView()
      changeLanguage()
    },[])

    function changeLanguage(){
      if (lang == 'zh'){
        i18n.changeLanguage('zh')
      }
      else{
        i18n.changeLanguage('en')
      }
    }

    function createSponsorRequest(cName,cAddress,firstN,lastN,busSector,cEmail,cPhone){
      var userUID = ""
      if (auth.currentUser != null){
        userUID = auth.currentUser.uid
      }
      fires.collection('sponsors').add({
        sponsor: {
            name: firstN + " " + lastN,
            firstName: firstN,
            lastName: lastN,
            companyName: cName,
            address: cAddress,
            email: cEmail,
            phone: cPhone,
            questions: [],
        },
        uid: userUID,
        email: cEmail,
        status: 'pending',
        lang: i18n.language,
        timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
        notify: ['event','sales','sponsor']
      }).then((snap) => {
          const requestId = snap.id
          var formattedResponse = ``
          formattedResponse += `Company Name: ${cName}\n`
          formattedResponse += `Company Address: ${cAddress}\n`
          formattedResponse += `Customer Email: ${cEmail}\n`
          formattedResponse += `Customer Phone: ${cPhone}\n`
          formattedResponse += `Business Sector: ${busSector}\n`
          let messageS = ""
            //send emails
            fires.collection('mail').add({
              from: 'Event Go Partnership <contact@evtgo.com>',
              to: [cEmail],
              cc: ['contact@evtgo.com'],
              message: {
                subject: "Event Go Partnership Application",
                text: `Hello ${firstN}, \n\nThank you for applying Event Go Partnership program. We will reply to you with our decision in 3 business days! \n\nYour information: \n${formattedResponse}\n\n\nOnce again, thank you very much for your attention!\n\n\nEvent Go Team`
              }
            })
            if (i18n.language == 'en'){
              messageS = "Thank you for applying! We will get back to you in 3 business days!"
            }
            else{
              messageS = "感谢您填写申请表！如果通过审核，我们将在三个工作日后联系您，感谢您的配合！"
            }

            const wC = window.confirm(messageS)
            if (wC) {
              window.location.href = "/#partnership"
            }
            else{
              window.location.href = "/#partnership"
            }
      })

    }


    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        
        const firstN = sponsorInfo.name
        const lastN = sponsorInfo.name
        const cName = sponsorInfo.companyName
        const cAddress = sponsorInfo.address
        const cEmail = sponsorInfo.email.toLowerCase()
        const cPhone = sponsorInfo.phone
        const busSector = sponsorInfo.companySector
        const name = sponsorInfo.name
        let cPassword = ""
        if (auth.currentUser == null){
          cPassword = sponsorInfo.password
          //create account
        }
        else{

        }
        
        //send email to clients
            //send email to us
            //create record

            if (cPhone != "" && cEmail != ""){
                //create account
                if (auth.currentUser != null){
                  const uid = auth.currentUser.uid
                  fires.collection('users').doc(uid).update({
                    sponsor: true,
                    sponsorName: cName,
                    lang: i18n.language,
                    sponsorTimestamp: firebase.firestore.Timestamp.fromDate(new Date()),
                    role: 'sponsor'}).then((authC)=>{
                      createSponsorRequest(cName,cAddress,firstN,lastN,busSector,cEmail,cPhone)
                    })
                }
                else{
                  //try creating account
                  //create account
                  auth.createUserWithEmailAndPassword(cEmail,cPassword).then((authCred) => {
                    const uid = authCred.user.uid
                    //create user auth
                    fires.collection('users').doc(uid).set({
                      email: cEmail,
                      firstName: cName,
                      lastName: cName,
                      name: cName,
                      userName: cName,
                      sponsor: true,
                      sponsorName: cName,
                      role: 'sponsor',
                      roles: [],
                      lang: i18n.language,
                      timestamp: firebase.firestore.Timestamp.fromDate(new Date())
                    }).then((authC) => {
                      createSponsorRequest(cName,cAddress,firstN,lastN,busSector,cEmail,cPhone)
                    })
                  }).catch((e)=>{
                    //
                    auth.signInWithEmailAndPassword(cEmail,cPassword).then((authCred) => {
                      const uid = authCred.user.uid
                      fires.collection('users').doc(uid).update({
                        sponsor: true,
                        sponsorName: cName,
                        role: 'sponsor',
                        roles: [],
                        lang: i18n.language,
                        timestamp: firebase.firestore.Timestamp.fromDate(new Date())
                      }).then((authC) => {
                        createSponsorRequest(cName,cAddress,firstN,lastN,busSector,cEmail,cPhone)
                      })
                    }).catch((e) => {
                      createSponsorRequest(cName,cAddress,firstN,lastN,busSector,cEmail,cPhone)
                    })
                  })
                }
            }
            else{
                setLoading(false)
                window.alert("Please enter your phone and email")
              
            }
        
      }
        

    const registrationForm = () => {
        return <Form onSubmit={handleSubmit} style={{ marginLeft: '35px', marginRight: '35px', marginBottom: '35px'}}>
        <Form.Group style={{marginTop: '25px'}}>
      <Form.Label style={{fontSize: 20, fontWeight: 'bold', marginBottom: '0px'}}>{i18n.language == 'en' ? 'Basic Information' : '基本信息'}</Form.Label><br/>
      <Form.Label>{i18n.language == 'en' ? 'Please let us know more about you' : '您可以输入一些基本信息'}</Form.Label>
  </Form.Group>

  <Form.Group
    id="contact-company"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={i18n.language == 'en' ? 'Company Name *' : '公司名称 *'}>
    <Form.Control
      type="text"
      onChange={(e)=>{
        const addrValue = e.target.value
        let sponsorItm = sponsorInfo
        sponsorItm.companyName = addrValue
        setSponsorInfo(sponsorItm)
      }}
      required
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="contact-company"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={i18n.language == 'en' ? 'Contact Name *' : '联系人姓名 *'}>
    <Form.Control
      type="name"
      onChange={(e)=>{
        const addrValue = e.target.value
        let sponsorItm = sponsorInfo
        sponsorItm.name = addrValue
        setSponsorInfo(sponsorItm)
      }}
      required
    />
    </FloatingLabel>
  </Form.Group>        

  <Form.Group
    id="contact-company"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={i18n.language == 'en' ? 'Company Address *' : '公司地址 *'}>
    <Form.Control
      type="address"
      onChange={(e)=>{
        const addrValue = e.target.value
        let sponsorItm = sponsorInfo
        sponsorItm.address = addrValue
        setSponsorInfo(sponsorItm)
      }}
      required
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="event-name"
    style={{ marginTop: "15px" }}>
    <Form.Label>{i18n.language == 'en' ? 'Business Sector *' : '商业领域 *'} {businessSector.label}</Form.Label>
     <Select 
        defaultValue={""}
        placeholder={i18n.language == 'en' ? "Please select..." : "请您选择"}
        onChange={(itm) => {
          if (itm.value == 'others'){
            const busSector = window.prompt(i18n.language == 'en' ? 'Please select your business sector' : '请选择您的行业领域')
            setBusinessSector({value: 'others', label: busSector})
            let sponsorItm = sponsorInfo
            sponsorItm.companySector = busSector
            setSponsorInfo(sponsorItm)
          }
          else{
            setBusinessSector(itm)
            let sponsorItm = sponsorInfo
            sponsorItm.companySector = itm.value
            setSponsorInfo(sponsorItm)
          }
        }}
        //onChange={setBusinessSector}
        required
        options={[
            { value: 'agriculture', label: i18n.language == 'en' ? 'Agriculture' : '农业'},
            { value: 'business-consulting', label: i18n.language == 'en' ? 'Business Consulting' : '企业咨询'},
            { value: 'communication', label: i18n.language == 'en' ? 'Communication' : '通讯'},
            { value: 'construction', label: i18n.language == 'en' ? 'Construction' : '建筑'},
            { value: 'education', label: i18n.language == 'en' ? 'Education' : '教育'},
            { value: 'energy', label: i18n.language == 'en' ? 'Energy' : '能源'},
            { value: 'fashion', label: i18n.language == 'en' ? 'Fashion/Arts' : '时尚/艺术'},
            { value: 'finance', label: i18n.language == 'en' ? 'Finance' : '金融'},
            { value: 'foodservice', label: i18n.language == 'en' ? 'Foodservice' : '餐饮'},
            { value: 'healthcare', label: i18n.language == 'en' ? 'Healthcare' : '健康'},
            { value: 'insurance', label: i18n.language == 'en' ? 'Insurance' : '保险'},
            { value: 'law', label: i18n.language == 'en' ? 'Law' : '法律'},
            { value: 'manufacturing', label: i18n.language == 'en' ? 'Manufacturing' : '制造业'},
            { value: 'marketing', label: i18n.language == 'en' ? 'Marketing/Advertising' : '营销/广告'},
            { value: 'media', label: i18n.language == 'en' ? 'Media' : '媒体'},
            { value: 'realestate', label: i18n.language == 'en' ? 'Real Estate' : '房地产'},
            { value: 'retail', label: i18n.language == 'en' ? 'Retail' : '零售'},
            { value: 'technology', label: i18n.language == 'en' ? 'Technology/IT' : '科技/IT'},
            { value: 'trade', label: i18n.language == 'en' ? 'Trade' : '贸易'},
            { value: 'transportation', label: i18n.language == 'en' ? 'Transportation/Warehouse' : '运输/仓储'},
            { value: 'others', label: i18n.language == 'en' ? 'Others' : '其他选项'},
          ]}>
     </Select>
  </Form.Group>

  

  <Form.Group
    id="contact-company"
    style={{ marginTop: "15px" }}>
      <Form.Label>{i18n.language == 'en' ? 'Please enter your phone number' : '请您输入电话号码'}*</Form.Label>
    <PhoneInput
        placeholder={i18n.language == 'en' ? 'Please enter your phone number' : '请您输入电话号码'}
        value={sponsorInfo.phone}
        defaultCountry="CA"
        onChange={(e) => {
          const phoneNum = e
          let sponsorItm = sponsorInfo
          if (phoneNum == null) {
            sponsorItm.phone = ""
          }
          else{
            sponsorItm.phone = phoneNum
          }
          setSponsorInfo(sponsorItm)
          console.log('PhoneNum: ' + phoneNum)
        }}/>
  </Form.Group>

  <Form.Group style={{marginTop: '25px'}}>
      <Form.Label style={{fontSize: 20, fontWeight: 'bold', marginBottom: '0px'}}>{i18n.language == 'en' ? 'Create Partner Account' : '创建合作伙伴账户'}</Form.Label><br/>
      <Form.Label>{i18n.language == 'en' ? 'Please enter your email address to create account' : '请用电子邮箱注册，如果您已经拥有EventGo账户输入密码即可'}</Form.Label>
  </Form.Group>

  <Form.Group
    id="contact-company"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={i18n.language == 'en' ? 'Email Address *' : '电子邮箱 *'}>
    <Form.Control
      type="email"
      onChange={(e)=>{
        const addrValue = e.target.value
        let sponsorItm = sponsorInfo
        sponsorItm.email = addrValue
        setSponsorInfo(sponsorItm)
      }}
      required
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="contact-company"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={i18n.language == 'en' ? 'Password *' : '密码 *'}>
    <Form.Control
      type="password"
      onChange={(e)=>{
        const addrValue = e.target.value
        let sponsorItm = sponsorInfo
        sponsorItm.password = addrValue
        setSponsorInfo(sponsorItm)
      }}
      required
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="account-accept-emt"
    style={{ marginTop: "25px" }}>
    <Button 
        disabled={loading}
        variant="dark" 
        className="w-100" 
        type="submit" 
        style={{height: '45px', fontSize: '17px', fontWeight: 'bold'}}>{i18n.language == 'en' ? 'Submit And Signup' : '注册并提交'}</Button>
  </Form.Group>


    </Form>
    }

    return (
        <>
        <Helmet>
            <title>EventGo Partnership</title>     
            <meta property="og:title" content="EventGo Partnership" />        
            <meta property="og:description" content="Unlock the Power of Precision Advertising with EventGo" />        
          <meta property="og:url" content={"https://tickets.evtgo.com/become-eventgo-partner"} />
          <meta property="og:site_name" content="Eventgo Partnership - Unlock the Power of Precision Advertising with EventGo" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
            </Helmet>
            <Container 
            className="d-flex justify-content-center"
              style={{minHeight: '100vh', minWidth: '100vw',backgroundColor: '#EEEEEE', backgroundRepeat: false}}>
                  <Card className="border-0" style={{width: maxW, marginTop: '45px', marginBottom: '45px'}}>
                      <h5 className="text-center" style={{fontSize: 22, fontWeight: 'bold', marginTop: '25px', padding: '10px'}}>{i18n.language == 'en' ? 'EventGo Partner Recruitment in Progress!' : 'EventGo活动伙伴招募中！'}</h5>
                      <div className="d-flex align-items-center justify-content-center">
                        <Image src={'https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/sponsorBG.JPG'} width={isMobile ? "80%" : "60%"} style={{marginTop: '15px', marginBottom: '25px'}}/>
                      </div>
                      <Card.Text style={{marginLeft: '25px', marginRight: '25px', fontWeight: 'bold'}}>{i18n.language == 'en' ? 'Unlock the Power of Precision Advertising with EventGo!' : '与EventGo一起解锁精准营销的力量！'}</Card.Text>
                      <Card.Text style={{marginLeft: '25px', marginRight: '25px'}}>{i18n.language == 'en' ? 'Are you looking for a way to maximize your brand impact and reach a highly targeted audience? Look no further! EventGo brings you closer to the most engaging events, enabling precise interaction with your desired audience.' : '您是否在寻找一种最大化品牌影响力并精准触达目标受众的方法？不要再犹豫了！EventGo让您近距离接触最具吸引力的活动现场，实现精准互动。'}</Card.Text>
                      <Card.Text style={{marginLeft: '25px', marginRight: '25px', fontWeight: 'bold'}}>{i18n.language == 'en' ? 'Why Become an EventGo Partner?' : ''}</Card.Text>
                      <Card.Text style={{marginLeft: '25px', marginRight: '25px'}}><b>{i18n.language == 'en' ? '• Broad Audience: ' : '• 受众广： '}</b>{i18n.language == 'en' ? 'With over 300 ticketed events annually on the EventGo platform, reaching no fewer than 100,000+ targeted users, your brand can achieve over a million impressions.' : 'EventGo平台上每年举办300余场售票活动，触达100,000+精准用户，为品牌带来超过百万次曝光。'}</Card.Text>
                      <Card.Text style={{marginLeft: '25px', marginRight: '25px'}}><b>{i18n.language == 'en' ? '• Precise Coverage: ' : '• 覆盖准： '}</b>{i18n.language == 'en' ? 'Our events cater to specific interests, ensuring your brand message reaches a highly relevant audience. A single budget can cover multiple events, maximizing your exposure and value.' : '我们的活动针对特定兴趣，确保您的品牌信息能触达高度相关的受众。单次预算即可覆盖多个活动，最大化您的曝光率和价值。'}</Card.Text>
                      <Card.Text style={{marginLeft: '25px', marginRight: '25px'}}><b>{i18n.language == 'en' ? '• Easy Sponsorship: ' : '• 赞助易： '}</b>{i18n.language == 'en' ? 'EventGo understands the characteristics and resources of event organizers helps reduce communication costs for partners, ensure your sponsorship benefits and maximize event participation and impact.' : '我们深入了解活动举办方的特点及资源，帮助活动伙伴降低沟通成本，确保赞助权益，实现最大化的活动参与和影响力。'}</Card.Text>
                      <Card.Text style={{marginLeft: '25px', marginRight: '25px'}}><b>{i18n.language == 'en' ? '• Valuable Advertising: ' : '• 广告值： '}</b>{i18n.language == 'en' ? 'With EventGo, your promotional messages reach every participant of the selected events through flexible ticketing system advertising options, ensuring high visibility, open rates, and engagement.' : '通过EventGo，您的推广信息将通过灵活的票务系统广告投放选项，触达每位活动参与者，确保高打开率，提高可见度和参与度。'}</Card.Text>
                      <Card.Text style={{marginLeft: '25px', marginRight: '25px', fontWeight: 'bold'}}>{i18n.language == 'en' ? 'Your Gateway to Event Advertising Excellence' : '您的活动宣传卓越之门'}</Card.Text>

                      {
                          registrationForm()
                      }

                  </Card>
            </Container>
            <SimpleFooter/>
        </>
    )
}

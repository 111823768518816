import Geocode from "react-geocode";
import React from "react";
import app, { auth, fires } from "../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import { isMobile } from "react-device-detect";

export function setupGeocode() {
    // set Google Maps Geocoding API for purposes of quota management. Its optional but recommended.

// set response language. Defaults to english.
    Geocode.setLanguage("en");

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
    Geocode.setRegion("us");

// set location_type filter . Its optional.
// google geocoder returns more that one address for given lat/lng.
// In some case we need one address as response for which google itself provides a location_type filter.
// So we can easily parse the result for fetching address components
// ROOFTOP, RANGE_INTERPOLATED, GEOMETRIC_CENTER, APPROXIMATE are the accepted values.
// And according to the below google docs in description, ROOFTOP param returns the most accurate result.
    Geocode.setLocationType("ROOFTOP");

// Enable or disable logs. Its optional.
    Geocode.enableDebug();

// Get address from latitude & longitude.
    

}

export function geocodeCoordinate(address){
    Geocode.fromAddress(address).then(
        (response) => {
            const { lat, lng } = response.results[0].geometry.location;
            console.log(lat, lng);
            alert(lat + lng)
        }, (error) => {
            console.error(error);
        })
}

export function geocodeAddress(lat,lng) {
    Geocode.fromLatLng(lat, lng).then(
        (response) => {
          const address = response.results[0].formatted_address;
          console.log(address);
          alert(address)
        },
        (error) => {
          console.error(error);
        }
      );
}

export function createPageView(){
    const currentPage = window.location.href
    const currentPath = window.location.pathname
    fetch('https://api.ipify.org?format=json').then((response) => {
        return response.json();
    }).then((res) => {
        let ip_address = ""
        if (res.ip != null){
            ip_address = res.ip
        }
        fires.collection('page_views').add(
            {
                currentPage: currentPage,
                currentPath: currentPath,
                fromUA: window.navigator.userAgent,
                mobile: isMobile,
                currentIP: ip_address,
                timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
            }
        )
    })
    
}

// export function createSiteMap(){

//     require("babel-register")({
//         presets: ["es2015", "react"]
//     });
       
//     const router = require("./Sitemap-Routes").default;
//     const Sitemap = require("react-router-sitemap").default;

//     return (
//         new Sitemap(router)
//             .build("https://evtgo.com")
//             .save("../../public/sitemap.xml")
//       );

// }
import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert, Tab, Tabs, Carousel, Image, Container, Row, Col, Navbar, Badge, FloatingLabel } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { auth, fires} from "../../firebase"
import { Link,  Route, useParams, Navigate } from "react-router-dom"
import {isMobile, isAndroid, isIOS} from 'react-device-detect';
import uuid from 'react-uuid';
import { Helmet } from "react-helmet"
import StarRatings from 'react-star-ratings';
import Footer from "./Footer"
import { validate } from "email-validator"


export default function Home() {
  const [error, setError] = useState("")
  const [index, setIndex] = useState(0);
  const { currentUser, logout } = useAuth()
  

  const [posts, setPosts] = useState([]);
  const [groups, setGroups] = useState([]);
  const [events, setEvents] = useState([]);

  const maxW = isMobile ? "100%" : "90%"

  const [eventImgs, setEventImgs] = useState([
    'https://plus.unsplash.com/premium_photo-1663051210654-0c8a835dad1f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80',
    'https://images.unsplash.com/photo-1540575467063-178a50c2df87?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8ZXZlbnRzfGVufDB8fDB8fHww&auto=format&fit=crop&w=800&q=60',
    'https://images.unsplash.com/photo-1561912774-79769a0a0a7a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1471&q=80',
    'https://images.unsplash.com/photo-1549451371-64aa98a6f660?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80',
    'https://images.unsplash.com/photo-1470225620780-dba8ba36b745?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80',
    'https://images.unsplash.com/photo-1506157786151-b8491531f063?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80'])

  
  const [cityList, setCityList] = useState([
    {name: 'Toronto', intro: 'Toronto, the capital of the province of Ontario, is a major Canadian city along Lake Ontario’s northwestern shore.', imgURL:'https://images.unsplash.com/photo-1507992781348-310259076fe0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80'},
    {name: 'Vancouver', intro: "Vancouver, a bustling west coast seaport in British Columbia, is among Canada’s densest, most ethnically diverse cities. ", imgURL:'https://images.unsplash.com/photo-1559511260-66a654ae982a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1818&q=80'},
    {name: 'Calgary', intro: "Calgary, a cosmopolitan Alberta city with numerous skyscrapers, owes its rapid growth to its status as the centre of Canada’s oil industry.", imgURL:'https://images.unsplash.com/photo-1526863336296-fac32d550655?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80'},
    {name: 'Montreal', intro: "Montréal is the largest city in Canada's Québec province. It’s set on an island in the Saint Lawrence River and named after Mt. Royal, the triple-peaked hill at its heart.", imgURL:'https://images.unsplash.com/photo-1470181942237-78ce33fec141?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80'},
  ])

  const [featureList, setFeatureList] = useState([
    {
      name: 'Attendee Registration', 
      intro: 'Very easy way to collect attendee information, plus our system will create event page for you.', 
      imgURL:'https://images.unsplash.com/photo-1501281668745-f7f57925c3b4?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
      name: 'Accept Payment', 
      intro: '(Coming soon) Payment made easy, we will use Stripe as the payment gateway, but we only charge the amount Stripe charged us. EMT is also popular in Canada', 
      imgURL:'https://images.unsplash.com/photo-1563013544-824ae1b704d3?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
      name: 'Attendee Checkin', 
      intro: 'We offer unique QR code express checkin, you do not have to do anything', 
      imgURL:'https://images.unsplash.com/photo-1528605248644-14dd04022da1?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
      name: 'NameTag Printing', 
      intro: 'We print nametag through our inhouse method. If you live in Toronto, let us know.', 
      imgURL:'https://media.istockphoto.com/id/1035643934/photo/hello-badges.jpg?s=1024x1024&w=is&k=20&c=A1RGmbvqMWih_TxJxekHEZoX4YqbNYNVj1G-lVA7Czk='
    },
    {
      name: 'Lucky Draw', 
      intro: 'We offer lucky draw solution to our premium member only', 
      imgURL:'https://images.unsplash.com/photo-1580519542036-c47de6196ba5?q=80&w=2942&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
      name: 'Data Analytics', 
      intro: 'You will own your event data, simply download it for free', 
      imgURL:'https://images.unsplash.com/photo-1460925895917-afdab827c52f?q=80&w=2426&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
      name: 'Marketing', 
      intro: 'We offer easy to read event page link, shared to social media and click analytics', 
      imgURL:'https://images.unsplash.com/photo-1587614313085-5da51cebd8ac?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
    {
      name: 'Generative AI', 
      intro: 'This advanced feature is only available for premium members', 
      imgURL:'https://plus.unsplash.com/premium_photo-1683121710572-7723bd2e235d?q=80&w=2832&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'
    },
  ])

  
  const routeToLogin = () => { 
    let path = '/login' ; 
    window.location.href = path;
  }
  const routeToSignup = () => { 
    let path = '/signup' ; 
    window.location.href = path;
  }

  const logoImg = "https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/leyuan_logo.png"

  async function joinEmaillist(e) {
    e.preventDefault();
    const emailEntered = e.target[0].value
    if (emailEntered != null && emailEntered != ""){
      if (validate(emailEntered)){
        //resigister
        const dataBody = `email=${emailEntered}`;
    fetch("https://subscribesystememaillist-verqhozorq-uc.a.run.app?"+dataBody,{
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*', }
    }).then((response) => {
      response.json().then((data) => {
        var dataS = {success: false}
        dataS = data
        if (dataS.success != null){
          if (dataS.success){
            //success
            window.alert("You have successfully subscribed to our newsletter")
          }
        }
      })


      })


    }
  }
    
  }
  

  useEffect(() => {
    fires
    .collection('settings')
    .doc('group-settings')
    .get()
    .then((snapshot) => {
      const settingData = snapshot.data()
      if (settingData.eventImgs != null){
        setEventImgs(settingData.eventImgs)
      }
    })

    fires
    .collection('groups')
    .where('groupType', '==', 'event')
    .where('status','==', 'live')
    .get()
    .then((snapshot) => {
      var allGroups = []
      snapshot.forEach((doc) => {
          const groupData = doc.data()
          allGroups.push({ ...groupData, id: doc.id})
      })
      setGroups(allGroups)
  })

    fires
    .collection('events')
    .where('status','==','live')
    .orderBy('endDate','desc')
    .get()
    .then((snapshot) => {
        var allEvents = []
        snapshot.forEach((doc) => {
            const eventData = doc.data()
            if (eventData.status != null){
                if (eventData.status == 'live') {
                    if (eventData.approved != null){
                        if (eventData.approved){
                            allEvents.push({ ...eventData, id: doc.id})
                        }
                    }
                    
                }
            }
        })
        setEvents(allEvents)
        var postss = []
        postss.push({item: 'A'})

        setPosts(postss)
    })
    }, []);


    const coverURL = "url(https://images.unsplash.com/photo-1436891620584-47fd0e565afb?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=687&q=80)"
    
  
  return (
      <div> 
      {
      posts.map((post) => {
        if (isMobile){
            return <>
          <Helmet>
            <title>EventGo - Pioneering the Future of Events</title>     
            <meta property="og:title" content="EventGo - Pioneering the Future of Events" />        
            <meta property="og:description" content="EventGo - Pioneering the Future of Events" />        
          <meta property="og:url" content={"https://evtgo.com/"} />
          <meta property="og:site_name" content="EventGo - Pioneering the Future of Events" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
            </Helmet>
          <Container
            className="d-flex justify-content-center"
            style={{minHeight: '100vh', minWidth: '100vw', backgroundColor: 'white' }}
          >
            <div style={{ maxWidth: maxW, backgroundColor: 'transparent' }}>
            
            <Card className="border-0" style={{backgroundColor:"transparent"}}>
            <Card.Body className = "align-items-center justify-content-center">
                    <div className = "align-items-center justify-content-center">
                    <Card.Title style={{ fontSize: "40px", fontWeight:'bold', color: "#000000"}}>Explore the best events and groups in your area</Card.Title>
                      <Card.Text style={{ fontSize: "20px", fontWeight:'bold', color: "#000000"}}>Celebration • Annual Event • Conference • Party • Rally</Card.Text>
                      <Card.Text style={{ fontSize: "17px", fontWeight:'normal', color: "#000000"}}>Delivery Happiness. Whenever you are, wherever you are, join EventGo for the fun experience you have never had before. Events are on EventGo on a daily basis.</Card.Text>
                      
                      
                    </div>

                    <Carousel style={{marginTop: '25px'}}>
                    {
                      eventImgs.map((img)=>{
                        return <Carousel.Item>
                        <Card.Img src={img} />
                    </Carousel.Item>
                      })
                      
                    }
                 </Carousel>

                 <div className = "d-flex align-items-center justify-content-center">
                      <Button style={{
                      marginTop: '25px', 
                      backgroundColor: "#E84031", 
                      borderColor: "transparent", 
                      height: '50px',
                      width: '200px',
                      fontSize: '18px',
                      fontWeight: 'bold',
                      borderRadius: '25px'}} onClick={()=>{
                        window.location.href = "/signup"
                      }}>Join us</Button>
                      </div>

                
                      <div style={{marginTop: '100px'}}>
                      <Card.Title className="text-center" style={{ fontSize: "35px", fontWeight:'bold', color: "#000000"}}>Join our newsletter</Card.Title>
                      <Card.Text className="text-center" style={{ fontSize: "17px", fontWeight:'normal', color: "#000000"}}>Findout more events in your email inbox. EventGo Events' weekly newsletter is crafting events for your taste</Card.Text>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <Form style={{marginTop: '25px'}} onSubmit={(e)=>{joinEmaillist(e)}}>
                          <Form.Group >
                            <FloatingLabel label="Your email address">
                              <Form.Control type="email" style={{width: '300px'}}/>
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group>
                          <Button
                          type="submit" 
                          variant="dark" 
                          className="border-0" style={{
                            width: '300px', 
                            height: '50px',
                            borderRadius: '25px',
                            backgroundColor: "#E84031", 
                            fontWeight: 'bold',
                            marginTop: '15px'}}>Join Newsletter</Button>
                          </Form.Group>
                        </Form>
                      </div>


                <div style={{marginTop: '120px'}}>
                <Card.Title className="text-center" style={{ fontSize: "35px", fontWeight:'bold', color: "#000000"}}>Explore top events, by top organizer</Card.Title>
                <Card.Text className="text-center" style={{ fontSize: "17px", fontWeight:'normal', color: "#000000"}}>Explore more than 50 organizer from our list</Card.Text>
                </div>
                {
                    groups.map(groupItm => {
                      return <Card className = "border-0 justify-content-center" style={{ marginTop: "25px", backgroundColor:"#EEE", borderRadius: "15px"}}>
                      <Card.Img variant="top" style={{maxHeight: '350px'}} src={groupItm.profileURL} />
                      <Card.Title className="text-center" style={{margin: "15px", fontSize: '20px', fontWeight: 'bold'}}>{groupItm.name}</Card.Title>
                      <Card.Text className="text-center" style={{marginLeft: "15px", marginRight: "15px", fontSize: '15px', fontWeight: 'normal'}}>{groupItm.description.substring(0,100)}</Card.Text>
                      <div className="justify-content-center align-items-center" style={{alignSelf: 'center'}}>
                      <Button style={{backgroundColor:'transparent', borderRadius:'25px', borderColor:'#000000', borderWidth:'2px', fontSize: '15px', fontWeight:'normal', height: '50px', marginTop: "25px", marginBottom: "15px", color: "black", width: '150px'}} onClick={()=> {
                        window.location.href =
                        "/organizer/" + groupItm.id;
                      }}>VIEW EVENTS</Button>
                      </div>
                      </Card>
                    })
                  }

                
                <div style={{marginTop: '120px'}}>
                  <Card.Title className="text-center" style={{ fontSize: "35px", fontWeight:'bold', color: "#000000"}}>We can host your events too</Card.Title>
                  <Card.Text className="text-center" style={{ fontSize: "17px", fontWeight:'normal', color: "#000000"}}>If you are organizer, try our easy-to-use solutions. Here is our feature list.</Card.Text>
                </div>
                <div style={{marginTop: '25px'}} className="d-flex align-items-center justify-content-center">
                <Button 
                    style={{
                    backgroundColor:'#000000', 
                    borderRadius:'15px', 
                    borderColor:'white', 
                    borderWidth:'2px', 
                    fontSize: '16px', 
                    fontWeight:'bold', 
                    height: '50px', 
                    marginTop: "15px", 
                    marginBottom: "15px", 
                    color: "white", 
                    width: '200px'}} 
                    onClick={()=> window.location.href='/how-to-create-group'}>
                        Become Organizer</Button>
                </div>
                {
                  featureList.map(featureItm => {
                    return <Card className = "border-0 justify-content-center" style={{ marginTop: "25px", backgroundColor:"#EEE", borderRadius: "15px"}}>
                    <Card.Img variant="top" style={{maxHeight: '350px'}} src={featureItm.imgURL} />
                    <Card.Title className="text-center" style={{margin: "15px", fontSize: '20px', fontWeight: 'bold'}}>{featureItm.name}</Card.Title>
                    <Card.Text className="text-center" style={{marginLeft: "15px", marginRight: "15px", marginBottom: "20px", fontSize: '15px', fontWeight: 'normal'}}>{featureItm.intro}</Card.Text>
                  </Card>
                  })
                }


                
  
              </Card.Body>
              </Card>
              <br/><br/><br/>
              <Footer/>
              </div>
              </Container>
              </>
          }
          else{
            return <>
            <Helmet>
            <title>EventGo - Pioneering the Future of Events</title>     
            <meta property="og:title" content="EventGo - Pioneering the Future of Events" />        
            <meta property="og:description" content="EventGo - Pioneering the Future of Events" />        
          <meta property="og:url" content={"https://evtgo.com/"} />
          <meta property="og:site_name" content="EventGo - Pioneering the Future of Events" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
            </Helmet>
            <Container
              className="d-flex justify-content-center"
              style={{minHeight: '100vh', minWidth: '100vw',backgroundColor: 'white'}}
            >
              <div style={{ width: maxW, marginTop: '25px', marginBottom: '25px'}}>
              <Card className="border-0" style={{backgroundColor:"transparent"}}>
              <Card.Body className = "align-items-center justify-content-center">
                <Row md={2}>
                  <Col>
                    <div style={{marginTop: '25px'}}>
                      <Card.Title style={{ fontSize: "50px", fontWeight:'bold', color: "#000000"}}>Explore the best events and groups in your area</Card.Title>
                      <Card.Text style={{ fontSize: "20px", fontWeight:'bold', color: "#000000"}}>Celebration • Annual Event • Conference • Party • Rally</Card.Text>
                      <Card.Text style={{ fontSize: "17px", fontWeight:'normal', color: "#000000"}}>Delivery Happiness. Whenever you are, wherever you are, join EventGo for the fun experience you have never had before. Events are on EventGo on a daily basis.</Card.Text>
                    </div>
                    <Button style={{
                      marginTop: '25px', 
                      backgroundColor: "#E84031", 
                      borderColor: "transparent", 
                      height: '45px',
                      width: '120px',
                      fontSize: '15px',
                      fontWeight: 'bold',
                      borderRadius: '15px'}} onClick={()=>{
                        window.location.href = "/signup"
                      }}>Join us</Button>
                  </Col>
                  <Col>
                  <Carousel>
                    {
                      eventImgs.map((img)=>{
                        return <Carousel.Item>
                        <Card.Img src={img} />
                    </Carousel.Item>
                      })
                      
                    }
                    
                 </Carousel>
                  </Col>
                </Row>

                <div style={{marginTop: '100px'}}>
                      <Card.Title className="text-center" style={{ fontSize: "35px", fontWeight:'bold', color: "#000000"}}>Join our newsletter</Card.Title>
                      <Card.Text className="text-center" style={{ fontSize: "17px", fontWeight:'normal', color: "#000000"}}>Findout more events in your email inbox. EventGo' weekly newsletter is crafting events for your taste</Card.Text>
                      </div>
                      <div className="d-flex align-items-center justify-content-center">
                        <Form style={{marginTop: '25px'}} onSubmit={(e)=>{joinEmaillist(e)}}>
                          <Form.Group >
                            <FloatingLabel label="Your email address">
                              <Form.Control type="email" style={{width: '400px'}}/>
                            </FloatingLabel>
                          </Form.Group>
                          <Form.Group>
                          <Button
                          type="submit" 
                          variant="dark" 
                          className="border-0" style={{
                            width: '400px', 
                            height: '50px',
                            borderRadius: '25px',
                            backgroundColor: "#E84031", 
                            fontWeight: 'bold',
                            marginTop: '10px'}}>Join Newsletter</Button>
                          </Form.Group>
                        </Form>
                      </div>


                <div style={{marginTop: '100px'}}>
                <Card.Title className="text-center" style={{ fontSize: "35px", fontWeight:'bold', color: "#000000"}}>Explore top events, by top organizers</Card.Title>
                <Card.Text className="text-center" style={{ fontSize: "17px", fontWeight:'normal', color: "#000000"}}>Explore more than 50 organizer from our list</Card.Text>
                </div>
                <Row md={5}>
                  {
                    groups.map(groupItm => {
                      return <Col><Card className = "border-0 justify-content-center" style={{ marginTop: "25px", backgroundColor:"#EEE", borderRadius: "15px"}}>
                      <Card.Img variant="top" style={{maxHeight: '350px'}} src={groupItm.profileURL} />
                      <Card.Title className="text-center" style={{margin: "15px", fontSize: '20px', fontWeight: 'bold'}}>{groupItm.name}</Card.Title>
                      <Card.Text className="text-center" style={{marginLeft: "15px", marginRight: "15px", fontSize: '15px', fontWeight: 'normal'}}>{groupItm.description.substring(0,100)}</Card.Text>
                      <div className="justify-content-center align-items-center" style={{alignSelf: 'center'}}>
                      <Button style={{backgroundColor:'transparent', borderRadius:'25px', borderColor:'#000000', borderWidth:'2px', fontSize: '15px', fontWeight:'normal', height: '50px', marginTop: "25px", marginBottom: "15px", color: "black", width: '150px'}} onClick={()=> {
                         window.location.href =
                         "/organizer/" + groupItm.id;
                      }}>VIEW EVENTS</Button>
                      </div>
                      </Card></Col>
                    })
                  }
                </Row>

                <div style={{marginTop: '100px'}}>
                  <Card.Title className="text-center" style={{ fontSize: "35px", fontWeight:'bold', color: "#000000"}}>We can host your events too</Card.Title>
                  <Card.Text className="text-center" style={{ fontSize: "17px", fontWeight:'normal', color: "#000000"}}>If you are organizer, try our easy-to-use solutions. Here is our feature list.</Card.Text>
                </div>
                <div style={{marginTop: '5px'}} className="d-flex align-items-center justify-content-center">
                <Button 
                    style={{
                    backgroundColor:'#000000', 
                    borderRadius:'15px', 
                    borderColor:'white', 
                    borderWidth:'2px', 
                    fontSize: '16px', 
                    fontWeight:'bold', 
                    height: '50px', 
                    marginTop: "10px", 
                    marginBottom: "15px", 
                    color: "white", 
                    width: '200px'}} 
                    onClick={()=> window.location.href='/how-to-create-group'}>
                        Become Organizer</Button>
                </div>

                <Row md={4}>
                  {
                    featureList.map((featureItm) => {
                      return <Col>
                      <Card className = "border-0 justify-content-center" style={{ marginTop: "25px", backgroundColor:"#EEE", borderRadius: "15px"}}>
                        <Card.Img variant="top" style={{maxHeight: '350px'}} src={featureItm.imgURL} />
                        <Card.Title className="text-center" style={{margin: "15px", fontSize: '20px', fontWeight: 'bold'}}>{featureItm.name}</Card.Title>
                        <Card.Text className="text-center" style={{marginLeft: "15px", marginRight: "15px", marginBottom: "20px", fontSize: '15px', fontWeight: 'normal'}}>{featureItm.intro}</Card.Text>
                      </Card>
                      </Col>
                    })
                  }

                </Row>
                
                
  
    
    
                </Card.Body>
                </Card>
                <br/><br/><br/>
                <Footer/>
                </div>
                
                </Container>
                </>
          }
            
    })}

      
      
      </div>
   )
}
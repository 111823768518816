import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Card, Alert, Container, FloatingLabel, Row, Col } from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { auth, fires, db } from "../../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import {
  sendWelcomeEmail,
  createOnboardNotification
} from "../../contexts/API";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";


export default function LuckyDrawSignup() {
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "40%";

  const { t, i18n } = useTranslation();

  const [min,setMin] = useState(50);
  const [max, setMax] = useState(500);

  const [credit, setCredit] = useState(0);
  const [luckyDrawStatus, setLuckyDrawStatus] = useState(false);
  const [coverImgURL, setCoverImgURL] =
    useState("url(https://images.unsplash.com/photo-1489549132488-d00b7eee80f1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)");

    useEffect(() => {
        fires
        .collection('settings')
        .doc('lucky-draw')
        .get()
        .then((snapshot) => {
            if (snapshot.exists){
                const drawData = snapshot.data()
                if (drawData.min != null){
                    setMin(drawData.min)
                }
                if (drawData.max != null){
                    setMax(drawData.max)
                }
                if (drawData.background != null){
                    if (drawData.background != ""){
                        setCoverImgURL("url("+drawData.background+")")
                    }
                }
                if (drawData.status != null){
                    if (!drawData.status){
                        window.location.href = "/"
                    }
                }
            }
        })
    }, []);
  
  function createNewUserDB(userName,uid){
    const newUserDB = {
      "userName":userName,
      "userID":"blink_id",
      "phoneNum":"",
      "countryCode": "",
      "country":"Canada",
      "timestamp":firebase.database.ServerValue.TIMESTAMP,
      "initTimestamp": firebase.database.ServerValue.TIMESTAMP,
      "profileURL": "",
      "coverURL": "",
      "audioIntroURL": "",
      "userShareLocation": "OFF",
      "interest": "yes",
      "userLoginLog": {},
      "coordinates": {},
      "vpnConnected": false,
      "version":"web",
    }
    if (uid != ''){
      db.child('users').child(uid).set(newUserDB)
    }
    

  }

  function createTransaction(userName,uid,credit){
    const newTrans = {
      "name":userName,
      "uid": uid,
      "timestamp":firebase.firestore.Timestamp.fromDate(new Date()),
      "creditAmount": credit,
      "description": "signup bonus",
      "fromEvent": "signup lucky draw",
      "version":"web",
    }
    if (uid != ''){
      fires.collection('users').doc(uid).collection('transactions').add(newTrans)
    }
    
  }

  async function handleSubmit(e) {
    e.preventDefault();
    const firstN = e.target[0].value
    const lastN = e.target[1].value
    const emailA = e.target[2].value.toLowerCase()
    const passwordA = e.target[3].value
    const passwordB = e.target[4].value

    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());

    if (passwordA == passwordB && passwordA != ""){
      try {
        setError("");
        setLoading(true);
        await signup(emailA, passwordA)
        const uid = auth.currentUser.uid
        if (!(await fires.collection('users').doc(uid).get()).exists){
          fires
          .collection('users')
          .doc(uid).set({
            userName: firstN + " " + lastN,
            name: firstN + " " + lastN,
            credit: credit,
            firstName: firstN,
            lastName: lastN,
            work: '',
            wechat: '',
            email: emailA,
            lang: 'en',
            currentEvent: '',
            timestamp: timestampNow,
          }).then(()=>{
            const uName = firstN + " " + lastN
            sendWelcomeEmail(emailA, uName)
            createOnboardNotification(uid,uName)
            createTransaction(uName,uid,credit)
            createNewUserDB(uName,uid)
            navigate("/");
          })
        }
        
      } catch {
        setError("Signup failed, user already exist, please try again");
      }
    }
    else{
      setError("Signup failed, password not matched")
    }
    

    setLoading(false);
  }

  function startLuckyDraw(){
    var arr = [];
    for (var i=min, t=max; i<t; i++) {
        arr.push(i)
    }
    const newR = shuffle(shuffle(arr))
    var randomNum = newR[Math.floor(Math.random()*newR.length)]
    setCredit(randomNum)
    setLuckyDrawStatus(true)
  }

  function shuffle(array) {
    var tmp, current, top = array.length;
    if(top) while(--top) {
      current = Math.floor(Math.random() * (top + 1));
      tmp = array[current];
      array[current] = array[top];
      array[top] = tmp;
    }
    return array;
  }
  

  return (
    <>
            <Helmet>
                <title>{'龙年大礼包！注册抽奖拿乐源豆'}</title>
                <meta
                  name="description"
                  content={'2024龙年大礼包！注册并参与抽奖就可以获得乐源Credit ｜ 参与乐源专属活动 ｜ 参加商家活动 ｜ 更有机会在商家活动中获得折扣'}
                />
                <meta name="twitter:card" content={'Signup to get Riley credits'} />
                <meta name="twitter:site" content="@user" />
                <meta name="twitter:creator" content="@user" />
                <meta name="twitter:title" content={'Signup to get Riley credits'} />
                <meta
                  name="twitter:description"
                  content={'Explore events'}
                />
                <meta name="twitter:image" content={''} />
                <meta property="og:title" content={'龙年大礼包！注册抽奖拿乐源豆'} />
                <meta
                  property="og:description"
                  content={'2024龙年大礼包！注册并参与抽奖就可以获得乐源Credit ｜ 参与乐源专属活动 ｜ 参加商家活动 ｜ 更有机会在商家活动中获得折扣'}
                />
                <meta property="og:image" content={''} />
                <meta
                  property="og:url"
                  content={"https://evtgo.com/lucky-draw-signup"}
                />
                <meta
                  property="og:site_name"
                  content={'龙年大礼包！注册抽奖拿乐源豆'}
                />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
              </Helmet>

      <Container
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          backgroundImage: coverImgURL,
        }}
      >
        <div className="w-100" style={{ maxWidth: maxW }}>
          <Card style={{marginTop: '45px', marginBottom: '45px'}}>
            <Card.Body>
              <h2 className="text-center mb-4">{luckyDrawStatus ? t('luckydraw_signup_title') : t('luckydraw_signup_title_prize')}</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              <Card.Text className="mb-4" style={{fontSize: '15px'}}>{t('luckydraw_signup_rules')}</Card.Text>
              <Card.Text className="mb-4 text-center" style={{fontSize: '45px'}}>{credit == 0 ? '-' : credit+t('luckydraw_signup_credit')}</Card.Text>
              <div className="d-flex align-items-center justify-content-center">
                <Button variant="success" hidden={luckyDrawStatus} onClick={()=>{
                    if (!luckyDrawStatus){
                        startLuckyDraw()
                    }
                }}>
                    {t('luckydraw_signup_action')}
                </Button>
              </div>
              <Form onSubmit={handleSubmit} hidden={!luckyDrawStatus}>
              <Row md={2}>
              <Form.Group
                  id="first-name"
                  style={{ marginTop: "15px" }}>
                  <FloatingLabel
                    label={'First Name'}>
                  <Form.Control
                    type="text"
                    required
                  />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group
                  id="last-name"
                  style={{ marginTop: "15px" }}>
                  <FloatingLabel
                    label={'Last Name'}>
                  <Form.Control
                    type="text"
                    required
                  />
                  </FloatingLabel>
                </Form.Group>

              </Row>
              

                <Form.Group
                  id="email"
                  style={{ marginTop: "15px" }}>
                  <FloatingLabel
                    label={'Email Address'}>
                  <Form.Control
                    type="text"
                    required
                  />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group
                  id="password"
                  style={{ marginTop: "15px" }}>
                  <FloatingLabel
                    label={'Enter Password'}>
                  <Form.Control
                    type="password"
                    required
                  />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group
                  id="repeat-password"
                  style={{ marginTop: "15px" }}>
                  <FloatingLabel
                    label={'Repeat Password'}>
                  <Form.Control
                    type="password"
                    required
                  />
                  </FloatingLabel>
                </Form.Group>

                
                <Button
                  disabled={loading}
                  className="w-100"
                  type="submit"
                  style={{ 
                    marginTop: "15px", 
                    borderColor: 'transparent',
                    backgroundColor: 'black' }}
                >
                  Signup
                </Button>
              </Form>
              <div className="w-100 text-center mt-3" hidden={!luckyDrawStatus}>
                <Link to="/login" style={{color: 'black'}}>Already have account? Login</Link>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}

import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert, Tab, Tabs, Carousel, Image, Container, Row, Col, Navbar, Badge, ListGroup, FloatingLabel } from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { auth, fires} from "../../firebase"
import { Link,  Route, useParams, Navigate } from "react-router-dom"
import {isMobile, isAndroid, isIOS} from 'react-device-detect';
import uuid from 'react-uuid';
import { Helmet } from "react-helmet"
import Footer from "./Footer"
import "firebase/firestore";
import firebase from "firebase/app";


export default function ClientForm() {
    const [error, setError] = useState("")
    const [index, setIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const { currentUser, logout } = useAuth()
    
  
    const [posts, setPosts] = useState([]);
    const [groups, setGroups] = useState([]);
    const [events, setEvents] = useState([]);
  
    const maxW = isMobile ? "100%" : "90%"

    const handleSubmit = (e) => {
        e.preventDefault();
        setLoading(true)
        
        const firstN = e.target[0].value
        const lastN = e.target[1].value
        const cName = e.target[2].value
        const cAddress = e.target[3].value
        const cEmail = e.target[4].value
        const cPhone = e.target[5].value
        const cFB = e.target[6].value
        const cWechat = e.target[7].value

        const eName = e.target[8].value
        const eDate = e.target[9].value
        const eTime = e.target[10].value
        const eAddress = e.target[11].value
        
        const eTotalNumber = e.target[12].value
        const eTicketPrice = e.target[13].value
        const eTicketCurrency = e.target[14].value
        const eTicketTypes = e.target[15].value
        const eTicketFrequency = e.target[16].value

        const accountName = e.target[17].value
        const bankName = e.target[18].value
        const transitNum = e.target[19].value
        const institutionNum = e.target[20].value
        const accountNum = e.target[21].value
        const swiftCode = e.target[22].value
        const bankAddress = e.target[23].value
        const hstNumber = e.target[24].value
        const emtEmail = e.target[25].value

        var formattedResponse = `Customer Name: ${firstN + " " + lastN}\n`
        formattedResponse += `Company Name: ${cName}\n`
        formattedResponse += `Company Address: ${cAddress}\n`
        formattedResponse += `Customer Email: ${cEmail}\n`
        formattedResponse += `Customer Phone: ${cPhone}\n`
        formattedResponse += `Customer FB/Instagram: ${cFB}\n`
        formattedResponse += `Customer WeChat/Red: ${cWechat}\n\n\n`

        formattedResponse += `Event Name: ${eName}\n`
        formattedResponse += `Event Date: ${eDate}\n`
        formattedResponse += `Event Time: ${eTime}\n`
        formattedResponse += `Event Address: ${eAddress}\n`
        formattedResponse += `Event Total Ticket Number: ${eTotalNumber}\n`
        formattedResponse += `Event Ticket Types: ${eTicketTypes}\n`
        formattedResponse += `Event Frequency: ${eTicketFrequency}\n\n\n`

        formattedResponse += `Account Name: ${accountName}\n`
        formattedResponse += `Bank Name: ${bankName}\n`
        formattedResponse += `Transit Number: ${transitNum}\n`
        formattedResponse += `Institution Number: ${institutionNum}\n`
        formattedResponse += `Account Number: ${accountNum}\n`
        formattedResponse += `Ticket Price: ${eTicketPrice}\n`
        formattedResponse += `Currency: ${eTicketCurrency}\n`
        formattedResponse += `Swiftcode: ${swiftCode}\n`
        formattedResponse += `HST/GST: ${hstNumber}\n`
        formattedResponse += `Bank Address: ${bankAddress}\n`

        formattedResponse += `Allow EMT: ${emtEmail == '' ? 'NO' : 'YES'}\n`
        formattedResponse += `EMT Email: ${emtEmail}\n\n\n`

        //send email to clients
            //send email to us
            //create record

        fires.collection('client_requests').add({
            customer: {
                name: firstN + " " + lastN,
                firstName: firstN,
                lastName: lastN,
                companyName: cName,
                email: cEmail,
                phone: cPhone,
                fb: cFB,
                wechat: cWechat,
            },
            event: {
                title: eName,
                startDateString: eDate + " " + eTime,
                address: eAddress,
                maxTicket: eTotalNumber,
                eventTypes: eTicketTypes,
                frequency: eTicketFrequency,
                price: eTicketPrice,
                currency: eTicketCurrency,
            },
            account: {
                accountName: accountName,
                bankName: bankName,
                transitNum: transitNum,
                institutionNum: institutionNum,
                accountNum: accountNum,
                swiftCode: swiftCode,
                hstNum: hstNumber,
                bankAddress: bankAddress,
            },
            timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
            notify: ['event','sales']
        }).then((snap) => {
            const requestId = snap.id
            const messageObj = {
                'subject':"[EventGo] New Customer Request", 
                'text': formattedResponse
            }
            fires
            .collection('mail')
            .add({
                'to':[
                    'contact@evtgo.com'
                ],
                'message':messageObj
            }).then((docRef)=>{
                const dataBody = `request_id=${requestId}`
                fetch("https://notifynewclientrequest-verqhozorq-uc.a.run.app?"+dataBody,{
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*', }
    }).then(()=>{
      const confirmStatus = window.confirm("We have received your request, please wait for 2 business days while we process your request! Thank you \nReference ID: " + requestId)
      
      if (confirmStatus){

      }
      window.location.href = "/"
    })


                

                
            })


            

        })
    }

    const registrationForm = () => {
        return <Form onSubmit={handleSubmit} style={{ marginLeft: '35px', marginRight: '35px', marginBottom: '35px'}}>
        <Form.Group style={{marginTop: '25px'}}>
      <Form.Label style={{fontSize: 20, fontWeight: 'bold'}}>Client Information</Form.Label><br/>
      <Form.Label>Please let us know more about you</Form.Label>
  </Form.Group>
        <Row md={2}>
      <Col>
      <Form.Group
    id="contact-first-name"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'First Name *'}>
    <Form.Control
      type="text"
      required
    />
    </FloatingLabel>
  </Form.Group>
      </Col>
        
      <Col>
      <Form.Group
    id="contact-last-name"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Last Name *'}>
    <Form.Control
      type="text"
      required
    />
    </FloatingLabel>
  </Form.Group>
      </Col>

        </Row>

        <Form.Group
    id="contact-company"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Company Name *'}>
    <Form.Control
      type="text"
      required
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="contact-company"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Company Address *'}>
    <Form.Control
      type="address"
      required
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="contact-company"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Email Address *'}>
    <Form.Control
      type="email"
      required
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="contact-company"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Phone Number *'}>
    <Form.Control
      type="phone"
      required
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="contact-company"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Facebook / Instagram URL'}>
    <Form.Control
      type="url"
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="contact-company"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'WeChat / RED ID'}>
    <Form.Control
      type="text"
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group style={{marginTop: '25px'}}>
      <Form.Label style={{fontSize: 20, fontWeight: 'bold'}}>Event Information</Form.Label><br/>
      <Form.Label>Please let us know more details about your events</Form.Label>
  </Form.Group>

  <Form.Group
    id="event-name"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Event Name *'}>
    <Form.Control
      type="text"
      required
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="event-date"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Event Date *'}>
    <Form.Control
      type="date"
      required
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="event-date"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Event Time *'}>
    <Form.Control
      type="time"
      required
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="event-location"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Event Address *'}>
    <Form.Control
      type="address"
      required
    />
    </FloatingLabel>
  </Form.Group>


  <Form.Group
    id="event-location"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Event Attendee Numbers *'}>
    <Form.Control
      type="number"
      required
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="event-location"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Ticket Price (Before Tax) *'}>
    <Form.Control
      type="number"
      required
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="event-location"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Ticket Currency (i.e. USD/CAD)'}>
    <Form.Control
      type="text"
      required
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="event-location"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Ticket Price Types (such as early bird)'}>
    <Form.Control
      type="text"
      required
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="event-location"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Event Frequency Per Month'}>
    <Form.Control
      type="number"
      required
    />
    </FloatingLabel>
  </Form.Group>


  <Form.Group style={{marginTop: '25px'}}>
      <Form.Label style={{fontSize: 20, fontWeight: 'bold'}}>Pricing Information</Form.Label><br/>
      <Form.Label>Don't worry, this part is encrypted</Form.Label>
  </Form.Group>

  <Form.Group
    id="account-name"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Account Name *'}>
    <Form.Control
      type="text"
      required
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="account-bank-name"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Bank Name *'}>
    <Form.Control
      type="text"
      required
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="account-transit-number"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Transit Number *'}>
    <Form.Control
      type="number"
      required
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="account-transit-number"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Institution Number *'}>
    <Form.Control
      type="number"
      required
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="account-transit-number"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Account Number *'}>
    <Form.Control
      type="number"
      required
    />
    </FloatingLabel>
  </Form.Group>


  <Form.Group
    id="account-transit-number"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Swift Code'}>
    <Form.Control
      type="text"
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="account-transit-number"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Bank Address *'}>
    <Form.Control
      type="address"
      required
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="account-transit-number"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'HST/GST Number *'}>
    <Form.Control
      type="text"
      required
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="account-accept-emt"
    style={{ marginTop: "15px" }}>
    <FloatingLabel
      label={'Accept EMT? (If so, enter EMT email)'}>
    <Form.Control
      type="email"
    />
    </FloatingLabel>
  </Form.Group>

  <Form.Group
    id="account-accept-emt"
    style={{ marginTop: "25px" }}>
    <Button 
        disabled={loading}
        variant="dark" 
        className="w-100" 
        type="submit" 
        style={{height: '45px', fontSize: '17px', fontWeight: 'bold'}}>Submit</Button>
  </Form.Group>


    </Form>
    }

    return (
        <>
        <Helmet>
            <title>Event Go Client Form</title>     
            <meta property="og:title" content="EventGo client form" />        
            <meta property="og:description" content="Explore the best events and groups in your area" />        
          <meta property="og:url" content={"https://rileyevents.com/"} />
          <meta property="og:site_name" content="Client Form - Explore the best events and groups in your area" />
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="article" />
            </Helmet>
            <Container 
            className="d-flex justify-content-center"
              style={{minHeight: '100vh', minWidth: '100vw',backgroundColor: '#EEEEEE', backgroundRepeat: false}}>
                  <Card className="border-0" style={{maxWidth: maxW, marginTop: '45px', marginBottom: '45px'}}>
                      <h4 className="text-center" style={{fontSize: 25, fontWeight: 'bold', marginTop: '25px'}}>Client Registration Form</h4>

                      {
                          registrationForm()
                      }

                  </Card>
            </Container>
            <br/><br/><br/>
            <Footer/>
        </>
    )
}
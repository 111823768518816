import React, 
{useRef, 
useState, 
useCallback, 
useEffect } from "react";
import {
  Form,
  Button,
  ButtonGroup,
  Card,
  Alert,
  Tab,
  Tabs,
  Carousel,
  Image,
  Container,
  Row,
  Col,
  ListGroup,
  Navbar,
  Modal,
} from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { 
    Link, 
    useNavigate, 
    useParams, 
    Route
 } from "react-router-dom";
import { 
    isMobile, 
    isAndroid, 
    isIOS
 } from "react-device-detect";
import { fires, auth } from "../../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import StarRatings from 'react-star-ratings';
import { QRCodeCanvas } from "qrcode.react";
import Gallery from "react-photo-gallery";
import { Helmet } from "react-helmet";

export default function EventInfo() {
  
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "50%";


  let { id } = useParams();
  var currentUA = ""
  

  const [showUpload, setShowUpload] = useState(false);
  const handleUploadClose = () => setShowUpload(false);
  const handleUploadShow = () => setShowUpload(true);

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageURL, setSelectedImageURL] = useState("");
  const [uploadS, setUploadS] = useState("请点击上传图片开始上传")
  const [uploadedURL, setUploadedURL] = useState('')
  
  const [eventPhotos, setEventPhotos] = useState([])

  const [eventDetails, setEventDetails] = useState({
    status: "NA",
    title: "",
    description: "",
    announcement: "",
    announcements: [],
    titles: { en: "", zh: "" },
    descriptions: { en: "", zh: "" },
    drawInstruction: "",
    startDate: firebase.firestore.Timestamp.fromDate(new Date()),
    endDate: firebase.firestore.Timestamp.fromDate(new Date()),
    address: {"name": "","fullAddress": ""},
    priceType: "FREE",
    agenda: [],
    sponsor: [],
    drawItems: {},
    wifiLink: "",
  });
  const [orgDetails, setOrgDetails] = useState({});
  
  const [eventOptions, setEventOptions] = useState({
    poll:false,
    draw:false,
    namecard:false,
    review:false,
    comment:true,
    picture:true,
    socialmedia:true,
    hidehost:false,
    'register-feedback':false,
    'accept-registration':true,
    max:500})

  const routeRedirect404 = () =>{ 
    let path = '/404' ; 
    window.location.href = path
  }

  const [coverImg,setCoverImg] =
    useState("https://images.unsplash.com/photo-1561489396-888724a1543d?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  const [profileImg,setProfileImg] =
    useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png");

    function uploadImage(file) {
        fetch('https://r2-worker.sam-12e.workers.dev/', {
          method: 'POST',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': file.type
          },
          body: file
        })
          .then((response) => response.text())
          .then((data) => {
            const downloadURL = "https://pub-e888c0363b7c412caf77f22ba84fcb86.r2.dev/" + data
            fires.collection('events').doc(id).collection('pictures').add(
                {
                    'name': '',
                    'email': '',
                    'status': 'pending',
                    'imageURL': downloadURL,
                    'timestamp': firebase.firestore.Timestamp.fromDate(new Date())
                }
            )
            setSelectedImageURL(downloadURL)
            setSelectedImage(null)
            setUploadedURL(downloadURL)
          }) // 
          .catch((error) => console.error(error));
      }
    
      const handleImageChange = (e) => {
        e.preventDefault()
        const file = e.target.files[0];
        setSelectedImage(file)
      }
    
      const handleImageSubmit = (e) => {
        e.preventDefault()
        const file = selectedImage;
        if (!file) return;
    
        uploadImage(file)
    
        
      }

    useEffect(() => {
        fires.collection('events').doc(id).get().then((snapshotA) => {
            if (snapshotA.exists){
                const eventData = snapshotA.data()
                var wifiL = ""
                var drawInstruction = ""
                if (eventData.wifiLink !== null){
                    wifiL = eventData.wifiLink
                }
                if (eventData.drawInstruction !== null){
                  drawInstruction = eventData.drawInstruction
                }
                setEventDetails({...eventData, wifiLink: wifiL, drawInstruction: drawInstruction})
                if (eventData.user.imageURL !== null && eventData.user.imageURL !== "") {
                    setProfileImg(eventData.user.imageURL)
                }

                fires.collection('events').doc(id).collection('pictures').get().then((snapshotB) => {
                    var photoSD = []
                    snapshotB.docs.forEach(doc => {
                        const photoId = doc.id
                        var photoData = doc.data()
                        photoData.id = photoId
                        photoData.src = photoData.imageURL
                        photoData.width = 1
                        photoData.height = 2
                        if (photoData.status != null){
                            if (photoData.status == 'approved'){
                                photoSD.push(photoData)
                            }
                        }
                        
                    })
                    setEventPhotos(photoSD)
                })

            }
            else{
                //event not found
                routeRedirect404()
            }
        })
      }, []);

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{
            minHeight: "100vh",
            minWidth: '100vw',
            backgroundImage: 'url(' + coverImg + ')',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'  }}
      >
          <Helmet>
                <title>{eventDetails.title}</title>
                <meta
                  name="description"
                  content={eventDetails.description}
                />
                <meta name="twitter:card" content={eventDetails.photoURL} />
                <meta name="twitter:site" content="@user" />
                <meta name="twitter:creator" content="@user" />
                <meta name="twitter:title" content={eventDetails.title} />
                <meta
                  name="twitter:description"
                  content={eventDetails.description}
                />
                <meta name="twitter:image" content={eventDetails.photoURL} />
                <meta property="og:title" content={eventDetails.title} />
                <meta
                  property="og:description"
                  content={eventDetails.description}
                />
                <meta property="og:image" content={eventDetails.photoURL} />
                <meta
                  property="og:url"
                  content={"https://rileyevents.com/event-info/" + id}
                />
                <meta
                  property="og:site_name"
                  content={eventDetails.title}
                />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
              </Helmet>

        <div className="w-100" style={{ maxWidth: maxW }}>
          <Card
            className="border-0 align-items-center justify-content-center" 
            style={{
                alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor:"white",
            marginTop: '15px',
            marginBottom: '15px'}}
          >
              <Card.Body className = "align-items-center justify-content-center">
              <Card.Title className="text-center"
               style={{ fontSize: "30px",
                fontWeight:'bold', 
                color: "#000000"}}>
                    {eventDetails.title}
            </Card.Title>
            <Card.Text
               style={{ fontSize: "17px",
                fontWeight:'normal', 
                marginTop: '25px',
                color: "#000000"}}>
                    {eventDetails.description}
            </Card.Text>
            </Card.Body>
          </Card>
          <Card
            className="border-0" 
            style={{
            backgroundColor:"white",
            marginTop: '15px',
            marginBottom: '15px'}}
          >
              <Card.Body className = "align-items-start">
              <Card.Title
               style={{ 
                   fontSize: "25px",
                fontWeight:'bold', 
                color: "#000000",
                marginTop: '5px',
                }}>
                    场馆信息
            </Card.Title>
            <Card.Text
               style={{ fontSize: "17px",
                fontWeight:'bold', 
                marginTop: '15px',
                color: "#000000"}}>
                    {eventDetails.address.name}
            </Card.Text>
            <Card.Link style={{fontSize: '17px', fontWeight: 'bold'}}>
                {eventDetails.address.fullAddress}
            </Card.Link>
            <br/>
            <Button style={{marginTop: '25px', backgroundColor: "#000000", borderWidth: 0}} onClick={()=>{
                const googleMapStr = "https://google.com/maps/place/"
              const locationQuery =
                eventDetails.address.fullAddress.replace(
                  " ",
                  "+"
                );
              window.location.href =
                googleMapStr + locationQuery;
            }}>
                导航
            </Button>
            <Button style={{marginTop: '25px', backgroundColor: "#000000", borderWidth: 0, marginLeft: '5px'}} onClick={()=>{
                window.alert("Wifi Information: \n"+eventDetails.wifiLink)
            }}>
                加入网络
            </Button>
            </Card.Body>
          </Card>

          <Card
            className="border-0" 
            style={{
            backgroundColor:"white",
            marginTop: '15px',
            marginBottom: '15px'}}
          >
              <Card.Body className = "align-items-start">
              <Card.Title
               style={{ 
                   fontSize: "25px",
                fontWeight:'bold', 
                color: "#000000",
                marginTop: '5px',
                }}>
                    签到
            </Card.Title>
            <Card.Text
               style={{ fontSize: "17px",
                fontWeight:'normal', 
                marginTop: '10px',
                color: "#000000"}}>
                    签到地点 {eventDetails.address.name}
            </Card.Text>
            <Card.Text
               style={{ fontSize: "17px",
                fontWeight:'normal', 
                marginTop: '5px',
                color: "#000000"}}>
                    签到时间 {eventDetails.startDate
                              .toDate()
                              .toLocaleString("zh")}
            </Card.Text>
            
            </Card.Body>
          </Card>

          <Card
            className="border-0" 
            style={{
            backgroundColor:"white",
            marginTop: '15px',
            marginBottom: '15px'}}
          >
              <Card.Body className = "align-items-start">
              <Card.Title
               style={{ 
                   fontSize: "25px",
                fontWeight:'bold', 
                color: "#000000",
                marginTop: '5px',
                }}>
                    活动议程
            </Card.Title>
            <Card.Text
               style={{ fontSize: "15px",
                fontWeight:'bold', 
                marginTop: '15px',
                color: "#000000"}}>
                    {eventDetails.agenda.length == 0 ? '目前还没有活动议程' : ''}
            </Card.Text>
            <ListGroup>
            {
                eventDetails.agenda.map((agend) => {
                    return <ListGroup.Item className="border-0">
                        <div>{agend.date.toDate().toLocaleTimeString('zh')} <b>{agend.title}</b></div>
                    </ListGroup.Item>
                })
            }
            </ListGroup>
            </Card.Body>
          </Card>

          <Card
            className="border-0" 
            style={{
            backgroundColor:"white",
            marginTop: '15px',
            marginBottom: '15px'}}
          >
              <Card.Body className = "align-items-start">
              <Card.Title
               style={{ 
                   fontSize: "25px",
                fontWeight:'bold', 
                color: "#000000",
                marginTop: '5px',
                }}>
                    嘉宾
            </Card.Title>
            <Card.Text
               style={{ fontSize: "15px",
                fontWeight:'normal', 
                marginTop: '15px',
                color: "#000000"}}>
                    {'嘉宾信息无法显示'}
            </Card.Text>
            </Card.Body>
          </Card>

          <Card
            className="border-0" 
            style={{
            backgroundColor:"white",
            marginTop: '15px',
            marginBottom: '15px'}}
          >
              <Card.Body className = "align-items-start">
              <Card.Title
               style={{ 
                   fontSize: "25px",
                fontWeight:'bold', 
                color: "#000000",
                marginTop: '5px',
                }}>
                    赞助商
            </Card.Title>
            <Card.Text
               style={{ fontSize: "15px",
                fontWeight:'normal', 
                marginTop: '15px',
                color: "#000000"}}>
                    {eventDetails.sponsor.length == 0 ? '赞助商信息无法显示' : ''}
            </Card.Text>
            <ListGroup>
            {
                eventDetails.sponsor.map((spons) => {
                    return <ListGroup.Item className="border-0">
                        
                    </ListGroup.Item>
                })
            }
            </ListGroup>
            </Card.Body>
          </Card>

          <Card
            className="border-0" 
            hidden={true}
            style={{
            backgroundColor:"white",
            marginTop: '15px',
            marginBottom: '15px'}}
          >
              <Card.Body className = "align-items-start">
              <div className="d-flex justify-content-between align-items-start">
                <Card.Title
               style={{ 
                   fontSize: "25px",
                fontWeight:'bold', 
                color: "#000000",
                marginTop: '5px',
                }}>
                    抽奖信息
            </Card.Title>
            <Button style={{backgroundColor: "#000000", borderWidth: 0}} onClick={()=>{
                let promptId = window.prompt('请输入抽奖编号')
                if (promptId != ""){
                  fires
                  .collection('events')
                  .doc(id)
                  .collection('draw-members')
                  .doc(promptId)
                  .get()
                  .then((snapshotA) => {
                    if (snapshotA.exists){
                      const drawMData = snapshotA.data()
                      if (drawMData.drawWinnerId != null){
                        if (drawMData.drawWinnerId != "" && drawMData.drawWinnerId != "no-show"){
                          let drawCId = drawMData.drawWinnerId
                          if (Object.keys(eventDetails.drawItems).includes(drawCId)){
                            let drawName = eventDetails.drawItems[drawCId].name
                            window.alert('您已经中奖，恭喜您获得'+drawName)
                          }
                          else{
                            window.alert('没有结果')
                          }
                        }
                        else{
                          window.alert('没有结果')
                        }
                      }
                      else{
                        window.alert('没有结果')
                      }
                    }
                    else{
                      window.alert('没有结果')
                    }
                    
                  })
                }
                else{
                  window.alert('没有结果')
                }
            }}>
                查询结果
            </Button>
                </div>
            <Card.Text
               style={{ fontSize: "15px",
                fontWeight:'normal', 
                marginTop: '15px',
                color: "#000000"}}>
                    {eventDetails.drawInstruction != '' ? eventDetails.drawInstruction : '如果抽奖活动已开始，请您查询抽奖结果'}
            </Card.Text>
            </Card.Body>
          </Card>

          <Card
            className="border-0" 
            style={{
            backgroundColor:"white",
            marginTop: '15px',
            marginBottom: '15px'}}
          >
              <Card.Body className = "align-items-start">
                <div className="d-flex justify-content-between align-items-start">
                <Card.Title
               style={{ 
                   fontSize: "25px",
                fontWeight:'bold', 
                color: "#000000",
                marginTop: '5px',
                }}>
                    照片
            </Card.Title>
            <Button style={{backgroundColor: "#000000", borderWidth: 0}} onClick={()=>{
                handleUploadShow()
            }}>
                上传照片
            </Button>
                </div>

                <Modal
                  show={showUpload}
                  onHide={() => {
                    handleUploadClose()
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      上传照片
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                  {selectedImage && (
                              <div>
                              <img 
                              alt="not found" 
                              width={"250px"} 
                              src={URL.createObjectURL(selectedImage)} 
                              />
                              <br />
                              <Alert 
                              variant="primary"
                              >{uploadS}
                              </Alert>
                              <div>
                                <button 
                                onClick={()=>setSelectedImage(null)}
                                >移除</button>
                                <button 
                                onClick={handleImageSubmit}
                                >上传</button>
                              </div>
                              </div>
      )}
            <input
        type="file"
        name="coverImage"
        title="Choose Image"
        onChange={handleImageChange}
      />
      <br /><br />

                  </Modal.Body>
                  <Modal.Footer></Modal.Footer>
                  </Modal>
            

      
      
            
            </Card.Body>
          </Card>
          
        </div>
      </Container>
    </>
  );
}

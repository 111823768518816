import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Card, Alert, Container, FloatingLabel, Row, Col } from "react-bootstrap";
import Divider from '@material-ui/core/Divider';
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { auth, fires, db } from "../firebase";
import firebase from "firebase/app";
import "firebase/firestore";

import {
  sendWelcomeEmail,
  createOnboardNotification
} from "../contexts/API";

export default function Signup() {
  const { signup } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "30%";

  const coverImgURL =
    "url(https://images.unsplash.com/photo-1489549132488-d00b7eee80f1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)";

  
  let { actionId,actionRef } = useParams();

  function createNewUserDB(userName,uid){
    const newUserDB = {
      "userName":userName,
      "userID":"blink_id",
      "phoneNum":"",
      "countryCode": "",
      "country":"Canada",
      "timestamp":firebase.database.ServerValue.TIMESTAMP,
      "initTimestamp": firebase.database.ServerValue.TIMESTAMP,
      "profileURL": "",
      "coverURL": "",
      "audioIntroURL": "",
      "userShareLocation": "OFF",
      "interest": "yes",
      "userLoginLog": {},
      "coordinates": {},
      "vpnConnected": false,
      "version":"web",
    }
    if (uid != ''){
      db.child('users').child(uid).set(newUserDB)
    }
    

  }

  async function handleSubmit(e) {
    e.preventDefault();
    const firstN = e.target[0].value
    const lastN = e.target[1].value
    const emailA = e.target[2].value.toLowerCase()
    const passwordA = e.target[3].value
    const passwordB = e.target[4].value

    const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());

    if (passwordA == passwordB && passwordA != ""){
      try {
        setError("");
        setLoading(true);
        await signup(emailA, passwordA)
        const uid = auth.currentUser.uid
        if (!(await fires.collection('users').doc(uid).get()).exists){
          fires
          .collection('users')
          .doc(uid).set({
            userName: firstN + " " + lastN,
            name: firstN + " " + lastN,
            firstName: firstN,
            lastName: lastN,
            work: '',
            wechat: '',
            email: emailA,
            lang: 'en',
            currentEvent: '',
            timestamp: timestampNow,
          }).then(()=>{
            const uName = firstN + " " + lastN
            sendWelcomeEmail(emailA, uName)
            createOnboardNotification(uid,uName)
            createNewUserDB(uName,uid)

            if (actionId == 'journey' && actionRef != ''){
              navigate("/journey/"+actionRef);
            }
            else{
              navigate("/");
            }
          })
        }
        
      } catch {
        setError("Signup failed, user already exist, please try again");
      }
    }
    else{
      setError("Signup failed, password not matched")
    }
    

    setLoading(false);
  }

  function signupWithGoogle(){
    var provider = new firebase.auth.GoogleAuthProvider();
                  provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
                  provider.setCustomParameters({
                    'login_hint': 'user@example.com'
                  });
                  firebase.auth()
                  .signInWithPopup(provider)
                  .then((result) => {
    
                  var user = result.user;
                  const uid = user.uid
                  const email = user.email
                  const dName = user.displayName
                  var fName = ""
                  var lName = ""
                  if (dName.includes(' ')){
                    fName = dName.split(' ')[0]
                    lName = dName.split(' ')[1]
                  }
                  else{
                    fName = ""
                    lName = ""
                  }
                  const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
                  fires.collection('users').doc(uid).get().then((docSnap) => {
                    if (!docSnap.exists){
                      fires
                    .collection('users')
                    .doc(uid).set({
                      userName: dName,
                      name: dName,
                      firstName: fName,
                      lastName: lName,
                      work: '',
                      wechat: '',
                      email: email,
                      lang: 'en',
                      currentEvent: '',
                    timestamp: timestampNow,
          }).then(()=>{
            sendWelcomeEmail(email, dName)
            createOnboardNotification(uid,dName)
            createNewUserDB(dName,uid)

            navigate("/");
          })
                    }
                  })
                  

    
  }).catch((error) => {
    // Handle Errors here.
    var errorCode = error.code;
    var errorMessage = error.message;
    var email = error.email;
    setError('Sign up failed with Google | ' + errorMessage)
  });
  }

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          backgroundImage: coverImgURL,
        }}
      >
        <div className="w-100" style={{ maxWidth: maxW }}>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">Signup</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              <Divider style={{marginTop: '25px', marginBottom: '25px'}}/>
              <Form onSubmit={handleSubmit} style={{}}>
              <Row md={2}>
              <Form.Group
                  id="first-name"
                  style={{ marginTop: "15px" }}>
                  <FloatingLabel
                    label={'First Name'}>
                  <Form.Control
                    type="text"
                    required
                  />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group
                  id="last-name"
                  style={{ marginTop: "15px" }}>
                  <FloatingLabel
                    label={'Last Name'}>
                  <Form.Control
                    type="text"
                    required
                  />
                  </FloatingLabel>
                </Form.Group>

              </Row>
              

                <Form.Group
                  id="email"
                  style={{ marginTop: "15px" }}>
                  <FloatingLabel
                    label={'Email Address'}>
                  <Form.Control
                    type="email"
                    required
                  />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group
                  id="password"
                  style={{ marginTop: "15px" }}>
                  <FloatingLabel
                    label={'Password'}>
                  <Form.Control
                    type="password"
                    required
                  />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group
                  id="repeat-password"
                  style={{ marginTop: "15px" }}>
                  <FloatingLabel
                    label={'Repeat Password'}>
                  <Form.Control
                    type="password"
                    required
                  />
                  </FloatingLabel>
                </Form.Group>

                
                <Button
                  disabled={loading}
                  className="w-100"
                  type="submit"
                  style={{ 
                    marginTop: "15px", 
                    borderColor: 'transparent',
                    fontWeight: 'bold',
                    backgroundColor: 'black' }}
                >
                  Signup
                </Button>
              </Form>

              <div className="d-flex align-items-center justify-content-center" style={{marginTop: '25px'}}>
                <Button variant="dark" style={{width: '100%', backgroundColor: 'transparent', borderColor: '#E84031', color: '#E84031', borderWidth: '2px', fontWeight: 'bold'}} onClick={()=>{
                  signupWithGoogle()
                }}>Signup Using Google</Button>
              </div>

              <div className="w-100 text-center mt-3">
                <Link to={actionId == '' ? "/login" : "/login/"+actionId+"/"+actionRef} style={{color: 'black'}}>Already has account? Login Now</Link>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}

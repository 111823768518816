import React, 
{useRef, 
useState, 
useCallback, 
useEffect } from "react";
import {
  Form,
  Button,
  ButtonGroup,
  Card,
  Alert,
  Accordion,
  Tab,
  Tabs,
  Carousel,
  Image,
  Container,
  Row,
  Col,
  ListGroup,
  Badge,
  Navbar,
  Modal,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate, useParams, Route } from "react-router-dom";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { fires, auth } from "../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import uuid from "react-uuid";
import {
  FaClock,
  FaLandmark,
  FaGlassCheers,
  FaGlobeAmericas,
} from "react-icons/fa";
import { Helmet } from "react-helmet";
import { sendEmailTo } from "../contexts/API";


export default function EventSchedule() {
  
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "80%";

  let { id } = useParams();
  var currentUA = ""

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [orgEvents, setOrgEvents] = useState([]);
  const [orgPastEvents, setOrgPastEvents] = useState([]);
  
  const [orgDetails, setOrgDetails] = useState({
    name: '',
    profileURL: '',
    bannerURL: '',
    policy: ['-'],
    picURLs: [''],
    description: '',
    descriptions: [],
    contacts: [],
  });
  const [userDetails, setUserDetails] = useState({
  });
  const [orgInfo, setOrgInfo] = useState({
    email: '',
    firstName: '',
    lastName: '',
  })
  const [isFollower, setIsFollower] = useState(false);
  const [orgPosts, setOrgPosts] = useState([])
  const [orgArticles, setOrgArticles] = useState([]);
  const [followerCount, setFollowerCount] = useState(0)

  const contactEmailRef = useRef()
  const contactNameRef = useRef()
  const contactContentRef = useRef()

  const routeRedirect404 = () =>{ 
    let path = '/' ; 
    window.location.href = path
  }

  function countForFollowers(){
    fires
    .collection('groups')
    .doc(id)
    .collection('followers')
    .get()
    .then((snapshot) => {
      setFollowerCount(snapshot.docs.length)
    })
  }

  function loadLinkedPosts(){
    fires.collection('posts').where('orgId','==',id).limit(5).get().then((snapshot) => {
      var linkedPosts = []
      snapshot.docs.forEach((doc) => {
        const docData = doc.data()
        const docId = doc.id
        linkedPosts.push({...docData, id: docId})
      })
      
      setOrgPosts(linkedPosts)
    })
  }

  function loadLinkedArticles(){
    fires.collection('exps').where('orgId','==',id).limit(10).get().then((snapshot) => {
      var linkedArticle = []
      snapshot.docs.forEach((doc) => {
        const docData = doc.data()
        const docId = doc.id
        linkedArticle.push({...docData, id: docId})
      })
      setOrgArticles(linkedArticle)
    })
  }

  function routeToFollowAccount() {
      if (auth.currentUser != null){
        const uid = auth.currentUser.uid
        const uemail = auth.currentUser.email
        if (uid != "" && uemail != ""){
            var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
            const followerData = {
                'fromUID':uid, 
                'email':uemail, 
                'userName': userDetails.name, 
                'timestamp':timestampNow}
            fires.collection('groups').doc(id).collection('followers').doc(uid).set(followerData).then(()=>{
              fires.collection('users').doc(uid).collection('groups').doc(id).set(
                {
                  timestamp: timestampNow, 
                  name: orgDetails.name, 
                  description: orgDetails.description,
                  imageURL: orgDetails.profileURL })
                window.alert('Followed successful')
                navigate(-1)
            })
        }
        else{
            let path = '/login' ; 
            window.location.href = path
        }
      }
      else{
        let path = '/login' ; 
        window.location.href = path
      }
  }

  function routeToUnFollowAccount() {
    if (auth.currentUser != null){
      const uid = auth.currentUser.uid
      const uemail = auth.currentUser.email
      if (uid != "" && uemail != ""){
        fires.collection('groups').doc(id).collection('followers').doc(uid).get().then((snapT)=>{
          if (snapT.exists){
            fires.collection('groups').doc(id).collection('followers').doc(uid).delete().then(()=>{
              fires.collection('users').doc(uid).collection('groups').doc(id).delete()
              window.location.reload()
            })
          }
          else{
            window.location.reload()
          }
        })
      }
      else{
          let path = 'https://letjoy.app/'+id ; 
          window.location.href = path
      }
    }
    else{
      let path = 'https://letjoy.app/'+id ; 
      window.location.href = path
    }
}

  const [coverImg,setCoverImg] =
    useState("https://images.unsplash.com/photo-1439792675105-701e6a4ab6f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1746&q=80");
  const [profileImg,setProfileImg] =
    useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png");

    useEffect(() => {
        fires.collection('groups').doc(id).get().then((snapshot) => {
            if (snapshot.exists) {
                const posts = [];
                const postData = snapshot.data();
                const uid = postData.fromUID
                if (postData.info != null){
                  setOrgInfo(postData.info)
                }

                if (postData.live != null) {
                  if (postData.live){
                    
                    countForFollowers()
                    loadLinkedPosts()
                    loadLinkedArticles()
                    // live user
                    // add views

                    var ua = window.navigator.userAgent.toLowerCase()
                    currentUA = ua
                    var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
                    const platformName = 'web'
                    var deviceName = 'ios'
                    if (isAndroid){
                        deviceName = 'android'
                      }
                      else if (isIOS) {
                        deviceName = 'ios'
                      }
                      else {
                        deviceName = 'web'
                      }
                      var fuid = ''
                if (auth.currentUser != null) {
                  fuid = auth.currentUser.uid
                  if (fuid != ''){
                    fires.collection('users').doc(fuid).collection('visits').add({orgId: id, fromUA: ua, timestamp: timestampNow})
                    fires.collection('users').doc(fuid).get().then((snapshotA)=>{
                        const snapData = snapshotA.data()
                        setUserDetails({...snapData, id: fuid})
                    })
                    fires.collection('groups').doc(id).collection('followers').doc(fuid).get().then((snapshotA)=>{
                        setIsFollower(snapshotA.exists)
                    })
                  }
                  
                }
                const viewData = {'fromUID':fuid, 'type':'page', 'fromUA':ua, 'platform': platformName, 'device': deviceName, 'timestamp':timestampNow}
                fires.collection('groups').doc(id).collection('views').add(viewData)

                if (postData.length == 0){
                  
                }
                else{
                  var picUrls = []
                  var policy = []
                  var bannerURL = ""
                  var descriptions = []
                  var contacts = []
                  if (postData.picURLs != null){
                    picUrls = postData.picURLs
                  }
                  if (postData.bannerURL != null){
                    bannerURL = postData.bannerURL
                  }
                  if (postData.policy != null){
                    policy = postData.policy
                  }
                  if (postData.descriptions != null){
                    descriptions = postData.descriptions
                  }
                  if (postData.contacts != null){
                    contacts = postData.contacts
                  }
                    setOrgDetails({...postData,
                      id:id,
                      picURLs: picUrls, 
                      bannerURL: bannerURL, 
                      policy: policy, 
                      descriptions: descriptions, 
                      contacts: contacts})

                    if (postData.coverURL != null){
                        setCoverImg(postData.coverURL)
                    }
                    if (postData.profileURL != null){
                        setProfileImg(postData.profileURL)
                    }

                    document.title = postData.name;
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", postData.description);

            if (postData.profileURL !== "") {
              let link = document.querySelector("link[rel~='icon']");
              if (!link) {
                link = document.createElement("link");
                link.rel = "icon";
                document.getElementsByTagName("head")[0].appendChild(link);
              }
              link.href = postData.profileURL;
            }

                    

                    fires
                    .collection('events')
                    .where('orgId','==',id)
                    .orderBy('startDate','asc')
                    .get().then((snapshotA) => {
                      var orgEvents = [];
                      var orgPastEventss = []
                      snapshotA.docs.forEach((doc) => {
                        const contentId = doc.id;
                        const contentData = doc.data();
                        if (contentData.startDate != null && contentData.endDate != null){
                          const today = new Date()
                          const eStart = contentData.startDate.toDate()
                          const eEnd = contentData.endDate.toDate()
                          if (today < eStart){
                              //not started
                              orgEvents.push({ ...contentData, id: contentId, eventStatus: 'upcoming' })
                          }
                          else if (today >= eStart && today < eEnd){
                              //ongoing
                              orgEvents.push({ ...contentData, id: contentId, eventStatus: 'ongoing' })
                          }
                          else if (today >= eEnd){
                              //end
                              orgPastEventss.push({ ...contentData, id: contentId, eventStatus: 'completed' })
                          }
                          else{
                              //false
                          }
                      }
                      })
                      setOrgEvents(orgEvents)
                      setOrgPastEvents(orgPastEventss)
                  })

                }

                  }
                  else{
                    //not live
                    routeRedirect404()
                  }
                }
                else{
                  //not found
                  //deleted
                  routeRedirect404()
                // posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
                // setPosts(posts)
                }

            }
            else{
                //deleted
                routeRedirect404()
                // posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
                // setPosts(posts)
                
            }
        });
      }, []);

  return (
    <>
         <Helmet>
                <title>{orgDetails.name}</title>
                <meta
                  name="description"
                  content={orgDetails.description}
                />
                <meta name="twitter:card" content={orgDetails.profileURL} />
                <meta name="twitter:site" content="@user" />
                <meta name="twitter:creator" content="@user" />
                <meta name="twitter:title" content={orgDetails.name} />
                <meta
                  name="twitter:description"
                  content={orgDetails.description}
                />
                <meta name="twitter:image" content={orgDetails.profileURL} />
                <meta property="og:title" content={orgDetails.name} />
                <meta
                  property="og:description"
                  content={orgDetails.description}
                />
                <meta property="og:image" content={orgDetails.profileURL} />
                <meta
                  property="og:url"
                  content={"https://rileyevents.com/organizer/" + id}
                />
                <meta
                  property="og:site_name"
                  content={orgDetails.name}
                />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
              </Helmet>
      <Container
        className="d-flex justify-content-center"
        style={{ minHeight: "100vh",minWidth: '100vw', backgroundColor: '#EEE' }}
      >
        <div className="w-100" style={{ maxWidth: maxW }}>

        <Card className="border-0" style={{ marginTop: '25px', marginBottom: '25px', padding: '20px', borderRadius: '15px'}}>

        

        

          <div className="d-flex align-items-center justify-content-between" style={{
            marginTop: '25px'
          }}>

          <div
                              className="d-flex align-items-center border-0"
                              style={{
                                alignSelf: "center",
                                display: "flex",
                                width: "100%",
                                marginLeft: '15px',
                                marginRight: '15px',
                                marginBottom: "5px",
                              }}
                              onClick={()=>{
                                
                              }}
                              >
                                <Card.Img
                                  src={profileImg}
                                  style={{height: isMobile ? '50px' : '150px', width: isMobile ? '50px' : '150px'}}
                                />
                                <div style={{ marginLeft: "15px" }}>
                                <Card.Title
          style={{ fontSize: isMobile ? '20px' : "30px", fontWeight: "bold" }}
        >
          {orgDetails.name}
        </Card.Title>
                                <Card.Text
          style={{
            marginTop: "0px",
            fontSize: "20px",
            fontWeight: "bold",
            marginBottom: "0px",
          }}
        >
          {'活动时间表'}
        </Card.Text>
        

                                </div>
                                </div>

          


          </div>

<Card.Body>
    <ListGroup>
        {
            orgEvents.map(eventItm => {
                let dateShort = eventItm.startDate.toDate().toLocaleDateString("en").split('/')[0] + '.' + eventItm.startDate.toDate().toLocaleDateString("en").split('/')[1]
                return <ListGroup.Item className="d-flex align-items-center"
                style={{
                  alignSelf: "center",
                  display: "flex",
                  width: "100%",
                  marginLeft: isMobile ? '0px' : '15px',
                  marginRight: isMobile ? '0px' : '15px',
                  marginBottom: "5px",
                }} onClick={()=>{
                    window.location.href = "/"+eventItm.id
                }

                }>
                  <div className="d-flex align-items-center justify-content-center" style={{height: '50px', width: '50px', backgroundColor: "#E84031"}}>
                      <Card.Text className="text-center" style={{color: 'white', fontSize: '15px', fontWeight: 'bold', margin: '5px'}}>{dateShort}</Card.Text>
                  </div>

                  <div style={{marginLeft: '15px', marginRight: '15px'}}>
                      <div hidden={isMobile}>
                      <div className="d-flex align-items-center" style={{marginTop: '8px'}}><FaClock/><Card.Text
                    style={{
                        marginLeft: '10px',
                  fontSize: '15px', 
                  fontWeight: 'bold'}}>
                    {eventItm.startDate.toDate().toLocaleString("en")}</Card.Text></div>
                      </div>

                      <div hidden={isMobile}>
                      <div className="d-flex align-items-center" style={{marginTop: '8px'}} hidden={isMobile}><FaLandmark/><Card.Text
                style={{
                    marginLeft: '10px',
                  fontSize: '15px', 
                  fontWeight: 'bold'}}>
                    {eventItm.address.fullAddress}</Card.Text></div>
                      </div>
                  
                  <Card.Title style={{fontSize: '20px', fontWeight: 'bold', marginTop: '20px'}}>{eventItm.title}</Card.Title>
                  <Card.Text style={{fontSize: '15px', fontWeight: 'normal'}} hidden={isMobile}>{eventItm.description.substring(0,200)}</Card.Text>
                  <Card.Text style={{fontSize: '12px', fontWeight: 'bold', color:'gray', marginTop: '15px'}}>{'点击报名'}</Card.Text>
                  </div>
                  
    </ListGroup.Item>
            })
        }
    </ListGroup>


</Card.Body>
</Card>



        </div>
      </Container>
    </>
  );
}

import React from "react"
import Divider from '@material-ui/core/Divider';
import { SocialIcon } from 'react-social-icons'
import { Row,Col } from "react-bootstrap";

const SimpleFooter = () => <footer className="page-footer font-small blue pt-4" style={{backgroundColor: '#1F2020'}}>
    <Divider style={{marginTop: '25px'}}/>
    <div className="container-fluid text-center text-md-left">
        <div className="row">
            <div className="col-md-4 mb-md-0 mb-3">
            <img
              alt=""
              src={"https://pub-35666689e8a44b5983940fafc4967c5c.r2.dev/evtgo_banner_white.png"}
              width={"170"}
              height={"25"}
              className="d-inline-block align-center"
            />
            <h5 className="text-center" style={{color: 'white', fontWeight: 'bold', fontSize: '15px', marginTop: '25px'}}>Empower Events, Enhance Experiences</h5>
            <Row md={4} style={{marginTop: '25px', marginLeft: '50px', marginRight: '50px', marginTop: '25px'}}>
                <Col className="d-flex align-items-center justify-content-center"><SocialIcon url="https://www.instagram.com/eventgodigital/" style={{width: '35px', height: '35px'}}/></Col>
                <Col className="d-flex align-items-center justify-content-center"><SocialIcon url="https://www.linkedin.com/company/eventgo-digital" style={{width: '35px', height: '35px'}}/></Col>
                <Col className="d-flex align-items-center justify-content-center"><SocialIcon url="https://www.facebook.com/profile.php?id=61554157201368" style={{width: '35px', height: '35px'}}/></Col>
                <Col className="d-flex align-items-center justify-content-center"><SocialIcon url="https://www.twitter.com/evtgoto" style={{width: '35px', height: '35px'}}/></Col>
            </Row>
            </div>
            <div className="col-md-3 mb-md-0 mb-3">
                <h5 className="text-uppercase" style={{color: 'white', fontWeight: 'bold'}}>About Us</h5>
                <ul className="list-unstyled" style={{color: 'white'}}>
                    <li><a href="https://evtgo.com/blog" style={{color: 'white', fontSize: '15px',textDecoration: 'none'}}>News</a></li>
                    <li><a href="/client-form" style={{color: 'white', fontSize: '15px',textDecoration: 'none'}}>Hosting Events</a></li>
                    <li><a href="/become-eventgo-partner" style={{color: 'white', fontSize: '15px',textDecoration: 'none'}}>Become Partner</a></li>
                    <li><a href="https://evtgo.com/" style={{color: 'white', fontSize: '15px',textDecoration: 'none'}}>Terms and Conditions</a></li>
                    <li><a href="https://evtgo.com/" style={{color: 'white', fontSize: '15px',textDecoration: 'none'}}>About us</a></li>
                </ul>
            </div>
            <div className="col-md-3 mb-md-0 mb-3">
                <h5 className="text-uppercase" style={{color: 'white', fontWeight: 'bold'}}>Contact Us</h5>
                <ul className="list-unstyled" style={{color: 'white'}}>
                    <li><a href="mailto:contact@evtgo.com" style={{color: 'white', fontSize: '15px',textDecoration: 'none'}}>Email us</a></li>
                    <li><a href="https://maps.app.goo.gl/gU7Cd5TJz7LWmDJp9" style={{color: 'white', fontSize: '15px',textDecoration: 'none'}}>Our Address</a></li>
                </ul>
            </div>
        </div>
        

    </div>

    <div className="footer-copyright text-center py-3" style={{color: 'white', fontSize: 15}}>© {(new Date()).getFullYear()} Copyright  
        <a href="/" style={{color: 'white', textDecoration: 'none', fontSize: 15}}> Event Go Digital Inc.</a>
    </div>
</footer>

export default SimpleFooter
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    // language resources
    resources: {
      en: {
        translation: {
         event_details_tab_basic_info: "Event Info",
         event_details_tab_agenda: "Agendas",
         event_details_tab_sponsor: "Sponsors",
         event_details_tab_attendent: "Attendents",
         event_details_tab_speaker: "Speakers",
         event_details_tab_message: "Messages",
         event_details_tab_event_live: "Join Event",
         event_details_heading_basic_info: "Basic Information",
         event_details_heading_agenda_info: "Agenda",
         event_details_heading_sponsor_info: "Sponsors",
         event_details_heading_attendent_info: "Attendents",
         event_details_heading_payment_info: "Payment",
         event_details_heading_refund_info: "Refund Policy",
         event_details_heading_message_info: "Messages",
         event_details_heading_details_info: "Details Information",
         event_details_heading_host_info: "Host",
         event_details_heading_cohost_info: "Co-hosts",
         event_details_heading_live_info: "Live",
         event_details_heading_ticket_info: "Tickets",

         event_details_heading_basic_info_invitation:  "Invite your friend",
         event_details_heading_basic_info_time: "Event Date/Time",
         event_details_heading_basic_info_location: "Event Location",
         event_details_heading_basic_info_navigation: "Navigate to location",
         event_details_heading_basic_info_people:  "My Tickets",
         event_details_heading_basic_info_checkin: "Checkin Now",
         event_details_heading_basic_info_online_event: "Join Online Event",
         event_details_heading_basic_info_label_online_event: "This is online event, make sure you join event to get link",
         event_details_heading_basic_info_label_firstcomefirstserve: "Free Registration",
         event_details_heading_basic_info_enter_event: "Join Event",

         event_details_support_publish_at: "Published at",
         event_details_support_me: "Me",
         event_details_support_me_ticket: "Tickets",
         event_details_support_qr: "My QR Code",
         event_details_support_invited: "Invited by Me",
         event_details_support_require_login: "Please login and checkin to view this section.",
         event_details_support_no_sponsor: "There are currently no sponsors for this event.",
         event_details_support_no_agenda: "There are currently no agenda for this event.",
         event_details_support_host_info: "Click to learn more about the host.",
         event_details_support_refund_na: "Currently the host doesn't accept refund, please contact host directly.",
         event_details_support_refund_stripe: "Payment services is powered by Strip, therefore the transaction fees from stripe is non refundable",
        event_details_support_refund_possible: "Please contact host directly",
        event_details_support_modify_info: "My Info",

         event_details_action_book_ticket: "Join Event",
         event_details_action_book_ticket_soldout: "Soldout",
         event_details_action_invitation: "Invite",
         event_details_action_complete_checkin: "Checkin Now",
        event_details_action_unauth_status: "Login Now",
        event_details_action_enter_event: "Enter Event",
        event_details_action_pay_event: "Pay Event Fee",
        event_details_action_link: "Learn More",
        event_details_action_close: "Close",
        event_details_action_ok: "OK",

            event_details_registration_header_registration: "Event Registration",
            event_details_registration_header_login: "Login",
            event_details_registration_header_login_email: "Your email address",
            event_details_registration_header_login_password: "Your password",
            event_details_registration_header_login_action: "Login And Join Event",
            event_details_registration_header_login_action_forgetpass: "Forget Password",
            event_details_registration_header_optional_invitation: "(Optional) Invited by email",
            event_details_registration_header_signup: "Signup",
            event_details_registration_header_signup_firstname: "Your first name",
            event_details_registration_header_signup_lastname: "Your last name",
            event_details_registration_header_signup_email: "Your email address",
            event_details_registration_header_signup_repeat_email: "Repeat your email address",
            event_details_registration_header_signup_password: "Your password",
            event_details_registration_header_signup_password_repeat: "Repeat the password",
            event_details_registration_header_quantity: "Quantity",
            event_details_registration_header_signup_action: "Signup And Join Event",
            event_details_registration_header_signup_action_privacy: "Terms, Condition and Privacy Policy",
            event_details_registration_header_event_type: "Event type",
            event_details_registration_header_payment_type: "Payment type",
            event_details_registration_header_original_price: "Subtotal",
            event_details_registration_header_service_fee: "Service fee",
            event_details_registration_header_total_price: "Total",
            event_details_registration_header_single_price: "Price",
            event_details_registration_header_tax_price: "Tax",
            event_details_registration_header_free: "Free",
            event_details_registration_header_emt: "E-transfer",
            event_details_registration_header_credit: "Credit card",
            event_details_registration_header_free_event: "Free event",
            event_details_registration_header_paid_event: "Paid event",
            event_details_registration_header_join_event: "Join Event",

            event_details_checkin_header_checkin: "Event Checkin",
            event_details_checkin_header_event_id: "Event checkin ID",
            event_details_checkin_header_email: "Email address",
            event_details_checkin_header_event_id_placeholder: "Please enter event checkin ID",
            event_details_checkin_header_email_placeholder: "Please enter your email address",
            event_details_checkin_header_action_checkin: "Complete Checkin",
            event_details_success_header: "Registration Successful",
            event_details_success_header_notice: "You have successfully registrated our event",
            event_details_success_header_email: "Welcome to our event! The confirmation email has been sent to your inbox",
            
            event_details_invitation_email: "Email Address",
            event_details_invitation_firstname: "First Name",
            event_details_invitation_lastname: "Last Name",
            event_details_invitation_action_send_invitation: "Send Invitation",

            event_details_registration_header_register_action: "Register Event",
            event_details_registration_agreement: "Click ‘Register Event’ you are agree to our",

            user_details_header_app_name: "EventGo",

            user_details_label_greeting: "Welcome back,",
            user_details_tab_section_events: "Events",
            user_details_tab_section_explore_posts: "Explore Posts",
            user_details_tab_section_notifications: "Notifications",
            user_details_tab_section_search: "Search",
            user_details_tab_section_me: "Me",
            user_details_tab_current_event: "Upcoming Event",
            user_details_tab_other_events: "Other Events",
            user_details_tab_explore_events: "Explore Events",
            user_details_label_search_placeholder: "Search for events or posts",
            user_details_me_section_my_edit_info: "My Info",
            user_details_me_section_my_posts: "My Posts",
            user_details_me_section_my_events: "My Events",
            user_details_me_section_my_groups: "My Groups",

            user_details_tab_my_info: "My Info",
            user_details_tab_my_account: "My Account",
            user_details_event_address: "Address: ",
            user_details_event_start_date: "Start Date: ",
            user_details_event_end_date: "End Date: ",
            user_details_event_status_started: "In Progress",
            user_details_event_status_future: "Happening Soon",
            user_details_event_status_ended: "Ended",
            user_details_user_info_firstname: "Your first name",
            user_details_user_info_lastname: "Your last name",
            user_details_user_info_name: "Your name",
            user_details_user_info_email: "Your email address",
            user_details_user_info_work: "Your occupation",
            user_details_user_info_wechat: "Wechat account",
            user_details_user_info_choose_pic: "Please select a picture",
            user_details_user_info_choose_lang: "Language",
            user_details_action_navigate_address: 'Navigate to location',
            user_details_action_modify_user_info: "Modify your info",
            user_details_action_logout: "Logout",

            event_details_live_breadcrumb_home: "Home",
            event_details_live_breadcrumb_event: "Event Page",
            event_details_live_breadcrumb_current_event: "Current Event",

            event_details_live_action_handraise: "Raise",
            event_details_live_action_clap: "Clap",
            event_details_live_action_like: "Like",
            event_details_live_action_dislike: "Dislike",
            event_details_live_action_my_info: "My Info",
            event_details_live_action_messages: "Messages",
            event_details_live_action_pictures: "Pictures",

            event_details_live_action_refresh: "Refresh",
            event_details_live_action_send_message: "Send Message",
            event_details_live_action_upload: "Upload",
            event_details_live_action_remove: "Remove",
            event_details_live_action_upload_picture: "Upload Picture",
            event_details_live_action_placeholder: "Please type your message",
            event_details_live_action_picture_placeholder: "Please select your picture",
            event_details_live_action_picture_waiting_upload: "Please click 'upload' to upload picture",
            event_details_live_action_luckydraw_registration: "Join Lucky Draw",

            event_details_live_action_sponsor_learn_more : "Learn More",
            event_details_live_heading_me: "Me",
            event_details_live_heading_checkin_status: "Checked In",
            event_details_live_heading_agenda: "Agenda",
            event_details_live_heading_sponsor: "Sponsor",
            event_details_live_heading_me_name: "Name",
            event_details_live_heading_me_ticket: "Ticket",
            event_details_live_heading_me_draw_result : "Draw result",
            event_details_live_heading_me_event_link : "Event link",
            event_details_live_heading_me_work : "Work",
            event_details_live_heading_me_wechat : "Wechat",
            event_details_live_heading_me_email : "Email Address",

            event_details_live_heading_luckydraw_title: "Lucky Draw Registration",
            event_details_live_heading_luckydraw_name: "Name",
            event_details_live_heading_luckydraw_ticket: "Ticket",
            event_details_live_heading_luckydraw_gender: "Gender",
            event_details_live_heading_luckydraw_sponsor: "Sponsored by",
            event_details_live_heading_luckydraw_email: "Email",

            event_details_live_tab_my_info: "My Info",
            event_details_live_tab_my_invited: "My Invited Guests",
            event_details_live_tab_sponsor: "Sponsor",
            event_details_live_tab_agenda: "Agenda",
            event_details_live_tab_comment: "Comments",
            event_details_live_tab_picture: "Pictures",

            event_details_live_modal_picture: "Picture",
            
            event_registration_already_signup: "You have completed registration",
            event_registration_more_questions: "More Questions",


            event_details_live_heading_waiting : "Please wait a moment, the event will start shortly",

            luckydraw_signup_rules: "Lucky Draw Rules: If you place a draw and register as a EventGo user from now on, you can get credit rewards provided by the platform. Credit will be used to participate in EventGo's exclusive activities and other participating group activities to obtain discounts! To signup, EventGo credits ranging from 50 to 500 will be drawn, and you must register as a EventGo user to get the drawn credits.",
            luckydraw_signup_title: "Signup",
            luckydraw_signup_title_prize: "Join our lucky draw for signup bonus",
            luckydraw_signup_action: "I'm feeling lucky",
            luckydraw_signup_credit: " Credits",


            

        }
      },
      zh: {
        translation: {
            event_details_tab_basic_info: "活动信息",
            event_details_tab_agenda: "日程安排",
            event_details_tab_sponsor: "赞助商",
            event_details_tab_attendent: "参与者",
            event_details_tab_speaker: "演讲者",
            event_details_tab_message: "留言反馈",
            event_details_tab_event_live: "现场互动",
            event_details_heading_basic_info: "活动信息",
            event_details_heading_agenda_info: "日程安排",
            event_details_heading_sponsor_info: "赞助商",
            event_details_heading_attendent_info: "参与者",
            event_details_heading_message_info: "留言反馈",
            event_details_heading_details_info: "详细介绍",
            event_details_heading_host_info: "活动主办方",
            event_details_heading_cohost_info: "联合主办方",
            event_details_heading_payment_info: "付款方式",
            event_details_heading_refund_info: "退款政策",
            event_details_heading_live_info: "直播链接",
            event_details_heading_ticket_info: "购票链接",

            event_details_heading_basic_info_invitation:  "邀请你的朋友",
            event_details_heading_basic_info_time: "活动时间",
            event_details_heading_basic_info_location: "活动地点",
            event_details_heading_basic_info_navigation: "导航到目的地",
            event_details_heading_basic_info_people:  "我的票",
            event_details_heading_basic_info_checkin: "现在签到",
            event_details_heading_basic_info_online_event: "点击参与",
            event_details_heading_basic_info_label_online_event: "线上活动, 加入活动即可获取链接",
            event_details_heading_basic_info_label_firstcomefirstserve: "注册活动",
            event_details_heading_basic_info_enter_event: "进入活动",

            event_details_support_publish_at: "发布于",
            event_details_support_me: "我",
            event_details_support_me_ticket: "我的门票",
            event_details_support_qr: "我的二维码",
            event_details_support_invited: "我邀请的",
            event_details_support_require_login: "请您登录签到后即可看到",
            event_details_support_no_sponsor: "目前没有赞助商或者无法显示，请您联系活动主办方",
            event_details_support_no_agenda: "目前没有具体的日程安排，请您联系活动主办方",
            event_details_support_host_info: "点击了解主办方更多信息",
            event_details_support_refund_na: "目前主办方不接受网上任何形式的退款，请您联系活动主办方",
            event_details_support_refund_stripe: "支付服务由Stripe提供，因此来自Stripe的手续费无法退款",
            event_details_support_refund_possible: "您可以联系主办方办理退款",
            event_details_support_modify_info: "我的信息",

            event_details_action_book_ticket: "活动报名",
            event_details_action_book_ticket_soldout: "票已卖光",
            event_details_action_invitation: "邀请朋友参加",
            event_details_action_unauth_status: "未报名",
            event_details_action_complete_checkin: "现在签到",
            event_details_action_enter_event: "进入活动",
            event_details_action_pay_event: "支付",
            event_details_action_link: "了解更多",
            event_details_action_close: "关闭",
            event_details_action_ok: "我知道了",
            

            event_details_registration_header_registration: "活动报名",
            event_details_registration_header_login: "登录",
            event_details_registration_header_login_email: "您的电子邮箱",
            event_details_registration_header_login_password: "您的密码",
            event_details_registration_header_login_action: "登录并且参加活动",
            event_details_registration_header_login_action_forgetpass: "忘记密码",
            event_details_registration_header_optional_invitation: "(选填) 邀请您参加的电子邮箱",
            event_details_registration_header_signup: "注册",
            event_details_registration_header_signup_firstname: "您的名字(First Name)",
            event_details_registration_header_signup_lastname: "您的贵姓(Last Name)",
            event_details_registration_header_signup_email: "您的电子邮箱",
            event_details_registration_header_signup_repeat_email: "再次输入电子邮箱",
            event_details_registration_header_signup_password: "您的密码",
            event_details_registration_header_signup_password_repeat: "再次输入密码",
            event_details_registration_header_quantity: "数量",
            event_details_registration_header_signup_action: "注册并且参加活动",
            event_details_registration_header_signup_action_privacy: "用户协议和隐私政策",
            event_details_registration_header_event_type: "活动类型",
            event_details_registration_header_payment_type: "付款方式",
            event_details_registration_header_original_price: "价格",
            event_details_registration_header_service_fee: "服务费",
            event_details_registration_header_total_price: "总价",
            event_details_registration_header_single_price: "单价",
            event_details_registration_header_tax_price: "税",
            event_details_registration_header_free: "免费",
            event_details_registration_header_emt: "E-transfer",
            event_details_registration_header_credit: "信用卡",
            event_details_registration_header_free_event: "免费活动",
            event_details_registration_header_paid_event: "收费活动",
            event_details_registration_header_join_event: "报名",
            event_details_checkin_header_checkin: "签到",
            event_details_checkin_header_event_id: "活动签到ID",
            event_details_checkin_header_email: "电子邮箱",
            event_details_checkin_header_event_id_placeholder: "请输入活动签到ID",
            event_details_checkin_header_email_placeholder: "请输入您的电子邮箱",
            event_details_checkin_header_action_checkin: "完成签到",
            event_details_success_header: "报名成功",
            event_details_success_header_notice: "您的活动已经报名成功！",
            event_details_success_header_email: "欢迎您来参加活动，确认邮件已经发送，请您查收",

            event_details_registration_header_register_action: "报名参加活动",
            event_details_registration_agreement: "点击‘参加活动’即表示您同意我们的",

            event_details_invitation_email: "受邀人的电子邮箱",
            event_details_invitation_firstname: "受邀人的姓名(名字)",
            event_details_invitation_lastname: "受邀人的姓名(姓)",
            event_details_invitation_action_send_invitation: "发送邀请",
            
            user_details_header_app_name: "活动",

            user_details_label_greeting: "欢迎回来,",
            user_details_tab_section_events: "活动",
            user_details_tab_section_explore_posts: "探索更多动态",
            user_details_tab_section_notifications: "消息",
            user_details_tab_section_search: "搜索",
            user_details_tab_section_me: "我",
            user_details_tab_current_event: "当前活动",
            user_details_tab_other_events: "其他活动",
            user_details_tab_explore_events: "探索活动",
            user_details_label_search_placeholder: "搜索更多活动和动态",
            user_details_me_section_my_edit_info: "我的信息",
            user_details_me_section_my_posts: "我的动态",
            user_details_me_section_my_events: "我的活动",
            user_details_me_section_my_groups: "我的群组",

            
            user_details_tab_my_info: "我的资料",
            user_details_tab_my_account: "我的账户",
            user_details_event_address: "地址：",
            user_details_event_start_date: "开始时间：",
            user_details_event_end_date: "结束时间：",
            user_details_event_status_started: "正在进行",
            user_details_event_status_future: "马上开始",
            user_details_event_status_ended: "已结束",
            user_details_user_info_firstname: "您的姓名(名)",
            user_details_user_info_lastname: "您的姓名(姓)",
            user_details_user_info_name: "您的名字",
            user_details_user_info_email: "您的电子邮箱",
            user_details_user_info_work: "您的职业",
            user_details_user_info_wechat: "您的微信ID",
            user_details_user_info_choose_pic: "请您选择一个照片",
            user_details_user_info_choose_lang: "您的语言",
            user_details_action_navigate_address: '导航到这里',
            user_details_action_modify_user_info: "更改我的信息",
            user_details_action_logout: "退出登录",


            event_details_live_breadcrumb_home: "主页",
            event_details_live_breadcrumb_event: "活动页面",
            event_details_live_breadcrumb_current_event: "当前活动",

            event_details_live_action_handraise: "举手",
            event_details_live_action_clap: "鼓掌",
            event_details_live_action_like: "喜欢",
            event_details_live_action_dislike: "不喜欢",
            event_details_live_action_my_info: "我的信息",
            event_details_live_action_messages: "留言反馈",
            event_details_live_action_pictures: "添加照片",
            event_details_live_action_sponsor_learn_more : "了解更多",
            event_details_live_action_luckydraw_registration: "参与抽奖",

            event_details_live_action_refresh: "刷新",
            event_details_live_action_send_message: "发送消息",
            event_details_live_action_upload: "上传",
            event_details_live_action_remove: "移除",
            event_details_live_action_upload_picture: "上传照片",
            event_details_live_action_placeholder: "请在下方输入您的信息",
            event_details_live_action_picture_placeholder: "请选择一张图",
            event_details_live_action_picture_waiting_upload: "请点击上传图片开始上传",

            event_details_live_heading_me : "我",
            event_details_live_heading_checkin_status : "已签到",
            event_details_live_heading_agenda : "日程安排",
            event_details_live_heading_sponsor : "赞助商",
            event_details_live_heading_me_name : "姓名",
            event_details_live_heading_me_ticket : "门票",
            event_details_live_heading_me_draw_result : "抽奖结果",
            event_details_live_heading_me_event_link : "活动链接",
            event_details_live_heading_me_work : "工作",
            event_details_live_heading_me_wechat : "微信",
            event_details_live_heading_me_email : "电子邮箱",

            event_details_live_heading_luckydraw_title: "幸运大抽奖",
            event_details_live_heading_luckydraw_name: "姓名",
            event_details_live_heading_luckydraw_ticket: "抽奖号",
            event_details_live_heading_luckydraw_gender: "性别",
            event_details_live_heading_luckydraw_sponsor: "抽奖赞助方",
            event_details_live_heading_luckydraw_email: "电子邮箱",

            event_details_live_tab_my_info: "我的信息",
            event_details_live_tab_my_invited: "我邀请的",
            event_details_live_tab_sponsor: "赞助商",
            event_details_live_tab_agenda: "日程安排",
            event_details_live_tab_comment: "留言反馈",
            event_details_live_tab_picture: "照片墙",

            event_details_live_modal_picture: "照片",

            event_registration_already_signup: "已完成活动报名",
            event_registration_more_questions: "更多问题",
            


            event_details_live_heading_waiting : "请您捎是等待，活动即将开始",

            luckydraw_signup_rules: "抽奖规则: 即日起抽奖并且注册成为EventGo用户，可获得由EventGo平台提供的EventGo豆奖励，EventGo豆将用于参与EventGo平台的活动以及其他参与加盟商的活动获得优惠！本次将抽取50～500之间不等的EventGo豆，并注册为EventGo用户才能获得所抽取的EventGo豆。",
            luckydraw_signup_title: "注册",
            luckydraw_signup_title_prize: "抽奖获取EventGo豆",
            luckydraw_signup_action: "试试手气吧",
            luckydraw_signup_credit: "个EventGo豆",

        }
      },
    }
  });

export default i18n;
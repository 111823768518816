import React, { useEffect, useRef, useState } from "react";
import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import Divider from '@material-ui/core/Divider';
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import {
  createSystemLoginNotification,
} from "../contexts/API";
import { auth,fires } from "../firebase";
import firebase from "firebase/app";
import "firebase/firestore";

export default function Login() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "30%";

  let { actionId,actionRef } = useParams();

  const coverImgURL =
    "url(https://images.unsplash.com/photo-1489549132488-d00b7eee80f1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)";

  useEffect(()=>{
    
  },[])

  async function handleSubmit(e) {
    e.preventDefault();

    try {
      setError("");
      setLoading(true);
      await login(emailRef.current.value, passwordRef.current.value);
      if (auth.currentUser != null){
        createSystemLoginNotification(auth.currentUser.uid)
      }
      if (actionId == 'journey' && actionRef != ''){
        navigate("/journey/"+actionRef);
      }
      else if (actionId == 'unsubscribe' && actionRef != ''){
        //login to unsubscribe
      }
      else{
        navigate("/");
      }
      
    } catch {
      setError("login failed, please try again");
    }

    setLoading(false);
  }

  function loginWithGoogle(){
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
    provider.setCustomParameters({
      'login_hint': 'user@example.com'
    });
    firebase.auth()
      .signInWithPopup(provider)
      .then((result) => {
        var user = result.user;
        const uid = user.uid
        const email = user.email
        const dName = user.displayName
        fires.collection('users').doc(uid).get().then((docSnap)=>{
          if (docSnap.exists){
            if (auth.currentUser != null){
              createSystemLoginNotification(auth.currentUser.uid)
            }
            navigate("/");
          }
          else{
            auth.signOut()
            navigate("/signup");
          }
        })
      })
  }

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          backgroundImage: coverImgURL,
        }}
      >
        <div className="w-100" style={{ maxWidth: maxW }}>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">Login</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              {/* <div className="d-flex align-items-center justify-content-center">
                <Button variant="dark" style={{width: '100%', backgroundColor: '#E84031', borderColor: '#E84031', color: 'white', borderWidth: '2px', fontWeight: 'bold'}} onClick={()=>{
                  loginWithGoogle()
                }}>Login with Google</Button>
              </div>
              <Divider style={{marginTop: '25px', marginBottom: '25px'}}/> */}
              <Form onSubmit={handleSubmit} style={{}}>
                <Form.Group id="email" style={{ marginTop: "15px" }}>
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control type="email" ref={emailRef} defaultValue={actionId == 'unsubscribe' ? actionRef : ''} required />
                </Form.Group>
                <Form.Group id="password" style={{ marginTop: "15px" }}>
                  <Form.Label>Password</Form.Label>
                  <Form.Control type="password" ref={passwordRef} required />
                </Form.Group>
                <Button
                  disabled={loading}
                  className="w-100"
                  type="submit"
                  style={{ 
                    marginTop: "15px", 
                    borderColor: 'transparent',
                    backgroundColor: 'black' }}
                >
                  Login
                </Button>
              </Form>
              <div className="w-100 text-center mt-3">
                <Link to="/forgot-password" style={{color: 'black'}}>Forget password?</Link>
              </div>
              <div className="w-100 text-center mt-3">
                <Link to={"/signup"} style={{color: 'black'}}>Signup Now</Link>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}

import React, 
{useRef, 
useState, 
useCallback, 
useEffect } from "react";
import {
  Form,
  Button,
  ButtonGroup,
  Card,
  Alert,
  Tab,
  Tabs,
  Carousel,
  Image,
  Container,
  Row,
  Col,
  ListGroup,
  Navbar,
  Modal,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate, useParams, Route } from "react-router-dom";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { fires, auth } from "../firebase";
import { sendEventTicketStripePaymentEmail } from "../contexts/API";
import firebase from "firebase/app";
import "firebase/firestore";


export default function EventPaymentConfirmation() {
  
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingWords, setLoadingWords] = useState("Loading...")
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "40%";

  let { id } = useParams();
  var currentUA = ""

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [eventDetails, setEventDetails] = useState({});
  const [orgDetails, setOrgDetails] = useState({});
  const [userDetails, setUserDetails] = useState({
    email: '',
    firstName: '',
    lastName: '',
    id: '',
  });
  const [eventMemberDetails, setEventMemberDetails] = useState({})
  

  const [eventPriceOptions, setEventPriceOptions] = useState(
    {
    priceType: '', 
    price: 0, 
    priceOriginal: 0, 
    priceId: '', 
    productId: '', 
    priceCurrency: 'CAD', 
    priceEMTEmail: ''
  })

  const routeRedirect404 = () =>{ 
    let path = '/404' ; 
    window.location.href = path
  }

  const routeRedirectHome = () =>{ 
    let path = '/' ; 
    window.location.href = path
  }

  async function addFormQuestions(ticketId, quan, ticketItemId, paymentId){
    if (auth.currentUser != null){
      const uid = auth.currentUser.uid
      fires.collection('users').doc(uid).get().then((snap_user) => {
        if (snap_user.exists){
          const userData = snap_user.data();
          var userCurrentEvent = ""
          var userEmail = ""
          var userForms = []
          if (userData.currentEvent != null){
            userCurrentEvent = userData.currentEvent
          }
          if (userData.forms != null){
            userForms = userData.forms
          }
          if (userData.email != null){
            userEmail = userData.email
          }
          if (userCurrentEvent == id && userEmail != "" && userForms.length > 0){
            fires.collection('events').doc(id).collection('formSubmissions').add({
              forms: userForms,
              email: userEmail,
              uid: uid,
              ticketId: ticketId,
              timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
            })
            fires
            .collection('events')
            .doc(id)
            .collection('members')
            .doc(userEmail)
            .update({forms: userForms})
            .then((updatedD) => {
              sendTicketInvoiceEmail(id,ticketId,quan,ticketItemId,paymentId)
            })
          }
          else{
            sendTicketInvoiceEmail(id,ticketId,quan,ticketItemId,paymentId)
          }
        }
        else{
          window.location.href = "/event-ticket-confirmation/"+ticketId
        }
      })
    }
    else{
      window.location.href = "/event-ticket-confirmation/"+ticketId
    }
  }

  async function sendTicketInvoiceEmail(event_id, ticket_id, quantity, ticket_item_id, payment_id){
    fires.collection('events').doc(event_id).get().then((snapshot) => {
      const snapData = snapshot.data()
      if (snapData.invoice != null){
        setLoadingWords('Generating receipt...')
        let uid = ""
        uid = auth.currentUser.uid
        const dataBody = `event_id=${event_id}&ticket_id=${ticket_id}&quantity=${quantity}&ticket_item_id=${ticket_item_id}&user_uid=${uid}&payment_id=${payment_id}`;
        fetch("https://createorggenerateinvoice-verqhozorq-uc.a.run.app?"+dataBody,
      {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*', }
        }).then((response) => {
          response.json().then((data) => {
            var dataS = {success: false,}
            dataS = data
            if (dataS.success){

            }
            window.location.href = "/event-ticket-confirmation/"+ticket_id
          })
      })

      }
      else{
        window.location.href = "/event-ticket-confirmation/"+ticket_id
      }
    })
  }


  async function createEventTicket(fuid, email, firstName, lastName){
    
    fires
      .collection('service-stripe-customers')
      .doc(fuid)
      .collection("payments")
      .where('status','==','succeeded')
      .where('metadata.eventId','==',id)
      .orderBy('created','desc')
      .get()
      .then((checkout_snap) => {
        if (checkout_snap.docs.length > 0){
            let payment_id = checkout_snap.docs[0].id
            var lastPayment = checkout_snap.docs[0].data()
            if (lastPayment.metadata != null){
                                if (lastPayment.metadata.eventId != null){
                                  if (lastPayment.metadata.eventId == id){
                                    //payment match, create ticket
                                    var paymentQuan = 1
                                    if (lastPayment.metadata.quantity != null){
                                      paymentQuan = Number(lastPayment.metadata.quantity)
                                    }
                                    var ticketName = "general-admission"
                                    var ticketItemId = ""
                                    if (lastPayment.metadata.ticketName != null){
                                      ticketName = lastPayment.metadata.ticketName
                                    }
                                    if (lastPayment.metadata.ticketItemId != null){
                                      ticketItemId = lastPayment.metadata.ticketItemId
                                    }

                                    const dataBody = `email=${email}&first_name=${firstName}&last_name=${lastName}&quantity=${paymentQuan}&event_id=${id}&customer_uid=${fuid}&price_desc=${ticketName}`;
                                    fetch("https://eventsapiaddpaymenteventmembers-verqhozorq-uc.a.run.app?"+dataBody,{
                                      method: 'POST',
                                      headers: { 
                                        'Content-Type': 'application/json',
                                        'Access-Control-Allow-Origin': '*', }
                                      }).then((response) => {
                                        response.json().then((data) => {
                                          var dataS = {success: false, ticketId: ""}
                                          dataS = data
                                          if (dataS.ticketId != null && dataS.ticketId != ""){
                                            addFormQuestions(dataS.ticketId, paymentQuan, ticketItemId, payment_id)
                                          }
                                          else{
                                            window.location.href = "/" + id
                                          }
                                        })
                                      })

                                  }
                                  else{
                                    routeRedirect404()
                                  }
                                }
                                else{
                                  routeRedirect404()
                                }
                              }
                              else{
                                routeRedirect404()
                              }
                            }
                            else{
                              routeRedirectHome()
                            }
                          })

  }

  const [coverImg,setCoverImg] =
    useState("https://images.unsplash.com/photo-1439792675105-701e6a4ab6f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1746&q=80");
  const [profileImg,setProfileImg] =
    useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png");

    
    useEffect(() => {
        fires.collection('events').doc(id).get().then((snapshot) => {
            if (snapshot.exists) {
                const postData = snapshot.data()
                if (postData.status != null) {
                  if (postData.status == 'live' || 
                  postData.status == 'private'){
                  setEventDetails(postData)
                    var orgName = ""
                    if (postData.user.userOrg != null){
                      orgName = postData.user.userOrg
                    }
                    if (postData.priceType != null){
                      if (postData.priceType == "CREDIT" || postData.priceType == "EMT"){
                        //require payment
                          
                      }
                      else{
                          routeRedirect404()
                      }
                    }
                    else{
                      routeRedirect404()
                    }
                      
                    var fuid = ''
                    var femail = ''
                    setLoadingWords('Getting user information...')
                    if (auth.currentUser != null) {
                        fuid = auth.currentUser.uid
                        if (fuid != ''){
                          fires
                          .collection('users')
                          .doc(fuid)
                          .get()
                          .then((snapshotA)=>{
                              const usersData = snapshotA.data()
                              
                              var userFirstName = "";
                              var userLastName = "";
                              
                              

                              if (usersData.firstName != null){
                                userFirstName = usersData.firstName
                              }
                              if (usersData.lastName != null){
                                userLastName = usersData.lastName
                              }
                              if (usersData.email != null){
                                femail = usersData.email
                              }
                              
                              setLoadingWords('Generating ticket (PLEASE DO NOT REFRESH OR CLOSE)')

                              createEventTicket(
                                fuid,
                                femail,
                                userFirstName,
                                userLastName)
                          })
                          
                      }
                      else{
                          routeRedirect404()
                      }

                    }
                    else{
                        //no user
                        routeRedirect404()
                    }
                  }
                  else{
                    //not live, or ended
                    routeRedirect404()
                  }
                }
                else{
                  //not found
                  //deleted
                  routeRedirect404()
                // posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
                // setPosts(posts)
                }

            }
            else{
                //deleted
                routeRedirect404()
                // posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
                // setPosts(posts)
                
            }
        });
      }, []);

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh",minWidth: '100vw',backgroundImage: 'url(' + coverImg + ')' }}
      >
        <div className="w-100" style={{ maxWidth: maxW }}>
          <Card
            className="border-0 align-items-center justify-content-center" 
            style={{
                alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor:"white",
            marginTop: '25px',
            marginBottom: '25px'}}
          >
            <Card.Img
              variant="top"
              src={eventDetails.photoURL}
              style={{ maxHeight: "300px" }}
            />
              <Card.Body className = "align-items-center justify-content-center">
              <Card.Title className="text-center"
               style={{ fontSize: "30px",
                fontWeight:'bold', 
                color: "#000000"}}>{eventDetails.title}</Card.Title>
              <div className = "d-flex align-items-center justify-content-center">
                <Card.Text style={{fontSize: isMobile ? 25 : 35}}>{loadingWords}</Card.Text>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}

import React, 
{useRef, 
useState, 
useCallback, 
useEffect } from "react";
import {
  Form,
  Button,
  ButtonGroup,
  Card,
  Alert,
  Tab,
  Tabs,
  Carousel,
  Image,
  Container,
  Row,
  Col,
  ListGroup,
  Navbar,
  Modal,
} from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { 
    Link, 
    useNavigate, 
    useParams, 
    Route
 } from "react-router-dom";
import { 
    isMobile, 
    isAndroid, 
    isIOS
 } from "react-device-detect";
import { fires, auth } from "../../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import StarRatings from 'react-star-ratings';
import { QRCodeCanvas } from "qrcode.react";

export default function EventTicket() {
  
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const [ticketCheckedIn, setTicketCheckedIn] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "50%";


  let { ticketId } = useParams();
  var currentUA = ""
  

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [ticketDetails, setTicketDetails] = useState({
      eventId: '',
      eventOrgName: 'Loading...',
      eventTitle: 'Loading...',
      validated: false,
      userName: '',
      fromUID: '',
      tickets: [],
      quantity: 1,
      timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
  })

  const [eventDetails, setEventDetails] = useState({});
  const [orgDetails, setOrgDetails] = useState({});
  

  const submitEmailRef = useRef()
  const detailCommentRef = useRef()

  const routeRedirect404 = () =>{ 
    let path = '/404' ; 
    window.location.href = path
  }

  const [coverImg,setCoverImg] =
    useState("https://images.unsplash.com/photo-1439792675105-701e6a4ab6f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1746&q=80");
  const [profileImg,setProfileImg] =
    useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png");


    useEffect(() => {
      //fet ticket details
      fetch("https://us-central1-blink-574af.cloudfunctions.net/v1EventsAPIFetchTicketDetails?ticket_id="+ticketId, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
      })
      .then(response => response.json())
      .then((data) => {
      if (data.data != null){
        let ticketData = {quantity: 1, tickets: []}
        ticketData = data.data
        var quan = 1
        var tickets = []
        if (ticketData.quantity != null){
          quan = ticketData.quantity
        }
        if (ticketData.tickets != null){
          tickets = ticketData.tickets
        }
        setTicketDetails({...ticketData, quantity: quan, tickets: tickets})
        if (data.eventData != null){
          let eventData = {}
          eventData = data.eventData
          setEventDetails({...eventData})
          if (eventData.user.imageURL !== null && eventData.user.imageURL !== "") {
            setProfileImg(eventData.user.imageURL)
          }
        }
      }
      else{
        routeRedirect404()
      }
      

    })

        // fires.collection('event_tickets').doc(ticketId).get().then((snapshot) => {
        //     if (snapshot.exists) {
        //         const ticketData = snapshot.data()
        //         var quan = 1
        //         var tickets = []
        //         if (ticketData.quantity != null){
        //           quan = ticketData.quantity
        //         }
        //         if (ticketData.tickets != null){
        //           tickets = ticketData.tickets
        //         }
        //         setTicketDetails({...ticketData, quantity: quan, tickets: tickets})
        //         var eventId = ""
        //         if (ticketData.eventId !== null && ticketData.eventId !== ""){
        //             eventId = ticketData.eventId
                    
        //             fires.collection('events').doc(eventId).get().then((snapshotA) => {
        //                 if (snapshotA.exists){
        //                     const eventData = snapshotA.data()
        //                     setEventDetails({...eventData})
        //                     if (eventData.user.imageURL !== null && eventData.user.imageURL !== "") {
        //                         setProfileImg(eventData.user.imageURL)
        //                     }
        //                 }
        //                 else{
        //                     //event not found
        //                     routeRedirect404()
        //                 }
        //             })
        //         }
        //         else{
        //             //event not found
        //             routeRedirect404()
        //         }
        //     }
        //     else{
        //         //deleted
        //         routeRedirect404()
        //     }
        // });
      }, []);

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh",minWidth: '100vw',backgroundImage: 'url(' + coverImg + ')' }}
      >
        <div className="w-100" style={{ maxWidth: maxW }}>
          <Card
            className="border-0 align-items-center justify-content-center" 
            style={{
                alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor:"white",
            marginTop: '25px',
            marginBottom: '25px'}}
          >
            <Card.Img className = "rounded-circle shadow-4-strong" variant="top" src={profileImg} style={{height: '100px', width: '100px', marginTop: '25px', borderColor: '#ffffff'}}/>
              <Card.Body className = "align-items-center justify-content-center">
              <Card.Title className="text-center"
               style={{ fontSize: "30px",
                fontWeight:'bold', 
                color: "#000000"}}>
                    {ticketDetails.eventTitle} {ticketCheckedIn ? ' (CheckIn)' : ''}
            </Card.Title>
            <div className="d-flex align-items-center justify-content-center">

            <QRCodeCanvas
                                    id="qrCodeEl"
                                    style={{marginTop: '30px'}}
                                    value={
                                      "https://tickets.evtgo.com/ticket-id/" + ticketId
                                    }
                                    size={256}
                                    bgColor={"#ffffff"}
                                    fgColor={ticketCheckedIn ? "green" : "#000000"}
                                    level={"L"}
                                    includeMargin={false}
                                    imageSettings={{
                                      src: profileImg,
                                      height: 20,
                                      width: 20,
                                      excavate: true,
                                    }}
                                    
                                  />

            </div>

            <div className="d-flex align-items-center justify-content-center">
            <Card.Link 
              className="text-center"
               style={{ 
                   fontSize: "30px",
                   marginTop: "25px",
                fontWeight:'bold', 
                color: "#000000"}}
                onClick={()=>{
                  let ticketInfoD = ""
                  ticketInfoD += "Purchaser Name: " + ticketDetails.userName + "\n"
                  ticketInfoD += "Event Info: " + ticketDetails.eventTitle + "\n"
                  ticketInfoD += "CheckIn: " + (ticketCheckedIn ? 'YES' : 'NO') + "\n"
                  ticketInfoD += "Created At: " + ticketDetails.timestamp.toDate().toLocaleString() + "\n"
                  window.alert(ticketInfoD)
                }}>
                    {ticketId}
            </Card.Link>
            </div>
            
            <Card.Text className="text-center"
               style={{ 
                   fontSize: "18px",
                   marginTop: "25px",
                fontWeight:'bold', 
                color: "#000000"}}>
                    Tickets for {ticketDetails.tickets.length}
            </Card.Text>
            {
              ticketDetails.tickets.map((ticketItmId) => {
                return <div hidden={ticketDetails.tickets.length <= 1} className="d-flex align-items-center justify-content-center">
                <Card.Link 
                className="text-center"
                hidden={ticketDetails.tickets.length <= 1}
                style={{ 
                    fontSize: "16px",
                    marginTop: "5px",
                 fontWeight:'normal', 
                 color: "#000000"}}
                 href={'/ticket-id/' + ticketItmId}>
                     {ticketItmId}
             </Card.Link>
             </div>
              })
            }

           
            <Card.Text className="text-center"
              hidden={ticketCheckedIn}
               style={{ 
                   fontSize: "18px",
                   marginTop: "5px",
                fontWeight:'normal', 
                color: "#000000"}}>
                    {'Please show this badge when check-in'}
            </Card.Text>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}

import React, 
{useRef, 
useState, 
useCallback, 
useEffect } from "react";
import {
  Form,
  Button,
  ButtonGroup,
  Card,
  Alert,
  Tab,
  Tabs,
  Carousel,
  Image,
  Container,
  Row,
  Col,
  ListGroup,
  Navbar,
  Modal,
  Dropdown,
  DropdownButton,
  Spinner,
  FloatingLabel,
  Badge,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate, useParams, Route } from "react-router-dom";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { fires, auth, db } from "../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import { validate } from "email-validator";
import uuid from "react-uuid";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { FileUploader } from "react-drag-drop-files";



export default function EventRequest() {
  
    const { currentUser, logout, signup, login } = useAuth();
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [showCompleted, setShowCompleted] = useState(false)
  const handleCloseCompleted = () => setShowCompleted(false);
  const handleShowCompleted = () => setShowCompleted(true);

  const fileTypes = ["jpg", "png", "gif","heic","svg","mov","mp4"];

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "40%";

  const { t, i18n } = useTranslation();

  const lngs = [
    { code: "en", native: "English" },
    { code: "zh", native: "简体中文" },
  ];

  let { id, lang } = useParams();
  var currentUID = ""

  const [currentViewId, setCurrentViewId] = useState("");

  const privacyLink = "https://www.leyuanreview.com/privacy"

  
  const [submissionId, setSubmissionId] = useState("")
  const [eventDetails, setEventDetails] = useState({
    title: '',
    description: '',
    photoURL: '',
    id: '',
    signup: false,
    ticketStatus: false,
    descriptions: {
      zh: [],
      en: []
    }
  });
  const [eventOptions, setEventOptions] = useState({
    'allow-multiple': false,
    'comment':false,
    'draw':false,
    'picture':true,
    'max': 500,
    'request':true,
  })

  const [eventTicketSelected, setEventTicketSelected] = useState(false)
  const [eventPriceOptions, setEventPriceOptions] = useState(
      {
      priceType: 'FREE', 
      price: 0, 
      priceOriginal: 0, 
      quan: 1,
      priceId: '', 
      productId: '', 
      priceCurrency: 'CAD', 
      priceEMTEmail: '',
      priceDescription: 'General Admission',
    })

  const [orgDetails, setOrgDetails] = useState({});
  const [userDetails, setUserDetails] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const [eventAdditionalQuestions, setEventAdditionalQuestions] = useState([
    {
      title: '',
      placeholder: '',
      id: '',
      order: -1,
      optionType: 'text',
      valueType: 'text',
      required: false,
      options: [],
      optionMore: false,
      value: '',
    }
  ])
  const [eventMemberDetails, setEventMemberDetails] = useState({
    firstName: '',
    lastName: '',
    name: '',
    email: '',
    ticketId: '',
    quantity: 1,
  })

  

  const eventRegisterEmailRef = useRef()
  const eventRegisterEmailConfirmRef = useRef()
  const eventRegisterFirstNameRef = useRef()
  const eventRegisterLastNameRef = useRef()
  

  const eventLoginEmailRef = useRef()
  const eventLoginPasswordRef = useRef()

  const eventPurchaseFirstNameRef = useRef()
  const eventPurchaseLastNameRef = useRef()
    
    
  

  const SubmitSuccessModal = () => {
      return <Modal show={showCompleted} 
      onHide={() => {
        handleCloseCompleted();
      }}>
          <Modal.Header closeButton>
            <Modal.Title>
            {"提交成功"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>

          <Form style={{}}>
          <Form.Group className="align-items-start" controlId="price-item" style={{marginTop: '15px'}}>
            <Card.Text>恭喜您已经成功提交！如果您的活动请求获得主办方通过，您将会收到一封含有付款链接的邮件，如果没通过也会通过邮件告知您</Card.Text>
            </Form.Group>
          <Form.Group className="align-items-start" controlId="price-item" style={{marginTop: '15px'}}>
            <div className="d-flex align-items-center justify-content-between">
                <div className="fw-normal">申请ID</div>
                <div className="fw-bold">{submissionId}</div>
            </div>
        </Form.Group>
                
            </Form>

          </Modal.Body>
          
      </Modal>
  }

  
  


  const routeRedirect404 = () =>{ 
    let path = '/404' ; 
    window.location.href = path
  }

  const routeBackEvents = (eId) => {
    let path = '/'+eId ; 
    window.location.href = path
  }

  const [coverImg,setCoverImg] =
    useState("https://images.unsplash.com/photo-1439792675105-701e6a4ab6f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1746&q=80");
  const [profileImg,setProfileImg] =
    useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png");


    const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageURL, setSelectedImageURL] = useState("");
  const [uploadS, setUploadS] = useState("请点击上传图片开始上传")
  const [uploadedURL, setUploadedURL] = useState('')
  const [fileUploadIndex, setFileUploadIndex] = useState(-1)

  function uploadImage(file) {
    fetch('https://r2-worker.sam-12e.workers.dev/', {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': file.type
      },
      body: file
    })
      .then((response) => response.text())
      .then((data) => {
        const downloadURL = "https://pub-e888c0363b7c412caf77f22ba84fcb86.r2.dev/" + data

        if (fileUploadIndex >= 0 && fileUploadIndex < eventAdditionalQuestions.length){
          var eventQues = eventAdditionalQuestions
          eventQues[fileUploadIndex].value = downloadURL
          setEventAdditionalQuestions(eventQues)
          setSuccess("File uploaded")
        }
        
        
      }) // 
      .catch((error) => console.error(error));
  }


  const handleImageSubmit = (e) => {
    e.preventDefault()
    const file = selectedImage;
    if (!file) return;

    uploadImage(file)
 
  }

    async function handleEventRequestSubmit(userEmail,firstName,lastName){
      var formAnswers = []
      eventAdditionalQuestions.forEach((eventQItm) => {
        formAnswers.push(
          {
          title: eventQItm.title, 
          value: eventQItm.value,
          id: eventQItm.id,
        })
      })

      const ua = window.navigator.userAgent.toLowerCase();
      const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());

      fires
        .collection('events')
        .doc(id)
        .collection('requests')
        .add({
            email: userEmail,
            firstName: firstName,
            lastName: lastName,
            form: formAnswers,
            fromUA: ua,
            status: 'request',
            timestamp: timestampNow
        }).then((snap)=>{
            const requestId = snap.id
            //create function to send emails
            setSubmissionId(requestId)
            createEventRequestNotification(requestId)
            setLoading(false)
            handleShowCompleted()
        })
      
    }

    

    async function createEventRequestNotification(requestId){
        const dataBody = `request_id=${requestId}&event_id=${id}`;
    fetch("https://eventapineweventrequestjoinrequest-verqhozorq-uc.a.run.app?"+dataBody,{
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*', }
    }).then((response) => {
      
    })

    }


    


    async function handleUserRegister(e){
        e.preventDefault()
        const uFirstName = eventRegisterFirstNameRef.current.value;
        const uLastName = eventRegisterLastNameRef.current.value;
        const uEmail = eventRegisterEmailRef.current.value.toLowerCase();

        setError("");
        setLoading(true);

        //check missing value
        var notfilledValue = []
        eventAdditionalQuestions.forEach((quesItm) => {
          if (quesItm.required && quesItm.value == ""){
            notfilledValue.push(quesItm.id);
          }
        })

        if (!eventDetails.ticketStatus) {
          //ticket not available
          window.alert("Ticket is not available to purchase")
          setLoading(false)
        }
        else if (notfilledValue.length > 0){
          //required field not filled
          window.alert("Please fill in required fields.")
          setLoading(false)
        }
        else{
          if (uEmail != ""){
            handleEventRequestSubmit(uEmail,uFirstName,uLastName)
          }
          else{
            setLoading(false)
            window.alert("You have to enter valid email")
          }
        }
        
        
      }


      function ValidateEmail(mail) 
  {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail))
  {
    return (true)
  }
    
    return (false)
}






      


    
    useEffect(() => {
        fires.collection('events').doc(id).get().then((snapshot) => {
            if (snapshot.exists) {
                const postData = snapshot.data()

                document.title = postData.title;
            document
              .querySelector('meta[name="description"]')
              .setAttribute("content", postData.description);

              var ua = window.navigator.userAgent.toLowerCase();

          const timestampNow = firebase.firestore.Timestamp.fromDate(
            new Date()
          );
          const platformName = "web";
          let deviceName = "";
          if (isAndroid) {
            deviceName = "android";
          } else if (isIOS) {
            deviceName = "ios";
          } else {
            deviceName = "web";
          }
          
          var viewLang = i18n.language
          const viewData = {
            fromUA: ua,
            fromUID: '',
            platform: platformName,
            device: deviceName,
            lang: viewLang,
            url: window.location.href,
            timestamp: timestampNow,
          };
          
          const vId = uuid();
          setCurrentViewId(vId);

          fires
          .collection("events")
          .doc(id)
          .collection("views")
          .doc(vId)
          .set(viewData);

          

            if (postData.photoURL !== "") {
              let link = document.querySelector("link[rel~='icon']");
              if (!link) {
                link = document.createElement("link");
                link.rel = "icon";
                document.getElementsByTagName("head")[0].appendChild(link);
              }
              link.href = postData.photoURL;
            }
            
                if (lang != null){
                  i18n.changeLanguage(lang)
                }
                if (postData.status != null) {
                  if (postData.status == 'live'){
                    if (postData.priceType != null){
                        if (postData.priceType == "CREDIT" || postData.priceType == "EMT" || postData.priceType == "FREE"){

                          var ticketS = false
                          if (postData.ticketStatus != null){
                            ticketS = postData.ticketStatus
                          }
                          else{
                            ticketS = true
                          }
                          var zhDescription = []
                          var enDescription = []
                          if (postData.descriptions != null) {
                            if (postData.descriptions.en != null){
                              enDescription = postData.descriptions.en
                            }
                            if (postData.descriptions.zh != null){
                              zhDescription = postData.descriptions.zh
                            }
                          }
                          setEventDetails({...postData, ticketStatus: ticketS, descriptions: {en: enDescription, zh: zhDescription}})
                          

                            
                            
                        }
                        else{
                            routeBackEvents(id)
                        }
                    }
                    else{
                        routeBackEvents(id)
                    }

                    var fuid = ''
                    var femail = ''
                    const orgId = postData.orgId

                    if (auth.currentUser != null){
                        fuid = auth.currentUser.uid
                        fires
                        .collection('users')
                        .doc(fuid)
                        .get()
                        .then((snap_user)=>{
                            if (snap_user.exists){
                                const snap_user_data = snap_user.data()
                                if (snap_user_data.email != null){
                                    femail = snap_user_data.email
                                }
                                setUserDetails({...snap_user_data})
                            }
                        })
                    }

                    //options
                    if (postData.options != null){
                      setEventOptions(postData.options)
                    }
                    
                    fires
                    .collection('events')
                    .doc(id)
                    .collection('forms')
                    .get()
                    .then((snapshotVal)=>{
                      var formDataSet = []
                      snapshotVal.docs.forEach((itm) => {
                        const formId = itm.id
                        const formData = itm.data()
                        formDataSet.push({...formData, id: formId, value: ''})
                      })

                      formDataSet.sort((a,b) => {
                        return a.order - b.order
                      })

                      setEventAdditionalQuestions(formDataSet)
                    })
                    
                  }
                  else{
                    //not live, or ended
                    routeRedirect404()
                  }
                }
                else{
                  //not found
                  //deleted
                  routeRedirect404()
                // posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
                // setPosts(posts)
                }

            }
            else{
                //deleted
                routeRedirect404()
                // posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
                // setPosts(posts)
                
            }
        });
      }, []);

  return (
    <>

<Helmet>
                <title>{eventDetails.title}</title>
                <meta
                  name="description"
                  content={eventDetails.description}
                />
                <meta name="twitter:card" content={eventDetails.photoURL} />
                <meta name="twitter:site" content="@user" />
                <meta name="twitter:creator" content="@user" />
                <meta name="twitter:title" content={eventDetails.title} />
                <meta
                  name="twitter:description"
                  content={eventDetails.description}
                />
                <meta name="twitter:image" content={eventDetails.photoURL} />
                <meta property="og:title" content={eventDetails.title} />
                <meta
                  property="og:description"
                  content={eventDetails.description}
                />
                <meta property="og:image" content={eventDetails.photoURL} />
                <meta
                  property="og:url"
                  content={"https://rileyevents.com/" + id}
                />
                <meta
                  property="og:site_name"
                  content={eventDetails.title}
                />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
              </Helmet>

      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh",minWidth: '100vw', backgroundImage: 'url(' + coverImg + ')' }}
      >
        <div className="w-100" style={{ maxWidth: maxW }}>
            {
                SubmitSuccessModal()
            }
          <Card
            className="border-0 align-items-center justify-content-center" 
            style={{
                alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor:"white",
            borderRadius: '0px',
            maxWidth: '100%',
            marginTop: '25px',
            marginBottom: '25px'}}
          >
            <div className="w-100 align-items-start justify-content-between" style={{marginTop: '15px', marginLeft: '15px', marginRight: '15px'}}>
              <Button className="border-0" href={"/"+id} style={{backgroundColor: 'transparent', color: 'black'}}>{"← 返回活动"}</Button>
            </div>
            
              <Card.Body className = "align-items-center justify-content-center">
                
              <Card.Title className="text-center"
               style={{ fontSize: isMobile ? "22px" : "30px",
                fontWeight:'bold', 
                color: "#000000"}}>{eventDetails.title}</Card.Title>

              <Card.Text className="text-center"
               style={{ fontSize: isMobile ? "22px" : "30px",
                fontWeight:'normal', 
                color: "#000000"}}>{eventTicketSelected ? eventPriceOptions.priceDescription : ''}</Card.Text>

              {error && <Alert variant="danger">{error}</Alert>}
             {success && <Alert variant="success">{success}</Alert>}
                
                
                      <Card className="border-0 d-flex justify-content-center" style={{ marginTop: "25px", marginBottom: "25px"}} hidden={eventAdditionalQuestions.length == 0}>
                        <Card.Title style={{ fontSize: "18px",
                fontWeight:'bold',
                marginTop: '15px',
                marginLeft: '15px', 
                color: "#000000"}}
                hidden={eventAdditionalQuestions.length == 0}>请您回答活动方问题</Card.Title>

                        <Form hidden={eventAdditionalQuestions.length == 0}>
                      {
                        eventAdditionalQuestions.map((formItm) => {
                          const orderI = formItm.order

                          if (formItm.optionType == 'options'){
                            const moreOptons = formItm.optionMore
                            const defaultVal = formItm.value

                            return <Form.Group style={{
                              marginTop: '15px',
                              marginBottom: '10px',
                            marginLeft: '15px',
                            marginRight: '15px'}}>
                                      <Form.Label>{formItm.order + 1}. {formItm.title} {formItm.required ? '*' : ''}</Form.Label>
                                      <Form.Label>{formItm.placeholder}</Form.Label>
                                      <Form.Label><b>{formItm.value == '' ? '' : ' - ' + formItm.value}</b></Form.Label>
                                  {
                                    formItm.options.map((optionItm) => {
                                      return <Form.Check 
                                      type={'checkbox'}
                                      id={optionItm}
                                      label={optionItm}
                                      checked={defaultVal == optionItm}
                                      onChange={(e)=>{
                                        let checked = e.target.checked
                                        
                                        var eventQues = eventAdditionalQuestions
                                        eventQues[orderI].value = optionItm
                                        setEventAdditionalQuestions(eventQues)
                                        setSuccess(formItm.title + ' - ' + optionItm)

                                      }}
                                    />
                                    })
                                  }

                                
                          </Form.Group>
                          }
                          else if (formItm.optionType == 'file'){
                            return <Form.Group
                            className="mb-3" 
                                style={{
                                marginTop: '15px',
                                marginBottom: '10px',
                              marginLeft: '15px',
                              marginRight: '15px'}}>
                                <Form.Label>{formItm.order + 1}. {formItm.title + (formItm.required ? ' *' : ' ')}</Form.Label>
                                <Form.Label>{formItm.placeholder}</Form.Label>

                                

                                <FileUploader
                  handleChange={(e) => {
                    e.preventDefault()
                    const file = e.target.files[0];
                    setSelectedImage(file)
                    setFileUploadIndex(orderI)
                    handleImageSubmit()
                  }}
                  name="传照片"
                  label="可以上传和投送照片到这里"
                  multiple={false}
                  types={fileTypes} />

      

                                {selectedImage && (
                              <div>
                              <img 
                              alt="not found" 
                              width={"250px"} 
                              src={URL.createObjectURL(selectedImage)} 
                              />
                              
                              </div>
      )}
            
      

                            </Form.Group>
                          }
                          else if (formItm.optionType == 'text'){
                            if (formItm.valueType == 'phone') {
                              return <Form.Group 
                                className="mb-3" 
                                style={{
                                marginTop: '15px',
                                marginBottom: '10px',
                              marginLeft: '15px',
                              marginRight: '15px'}}>
                                <Form.Label>{formItm.order + 1}. {formItm.title + (formItm.required ? ' *' : ' ')}</Form.Label>
                                <Form.Label>{formItm.placeholder}</Form.Label>
                                <PhoneInput
                                  value={formItm.value}
                                  defaultCountry="CA"
                                  onChange={(e) => {
                                    var eventQues = eventAdditionalQuestions
                                    eventQues[orderI].value = e
                                    setEventAdditionalQuestions(eventQues)
                                  }}/>

                              </Form.Group>
                            }
                            else{
                              return <Form.Group className="mb-3" controlId="register.email" style={{
                                marginTop: '15px',
                                marginBottom: '10px',
                              marginLeft: '15px',
                              marginRight: '15px'}}>
                                <Form.Label>{formItm.order + 1}. {formItm.title + (formItm.required ? ' *' : ' ')}</Form.Label>
                                <Form.Label>{formItm.placeholder}</Form.Label>
                                <Form.Control
                                type={formItm.valueType}
                                defaultValue=""
                                required={formItm.required}
                                onChange={(e)=>{
                                  const enteredValue = e.target.value
                                  var eventQues = eventAdditionalQuestions
                                  eventQues[orderI].value = enteredValue
                                  setEventAdditionalQuestions(eventQues)
                                }}
                              />
                            </Form.Group>
                            }
                            
                          }
                          else if (formItm.optionType == 'textarea'){
                            return <Form.Group className="mb-3" controlId="register.email" style={{
                              marginTop: '15px',
                            marginBottom: '10px',
                          marginLeft: '15px',
                          marginRight: '15px'}}>
                            <Form.Label>{formItm.order + 1}. {formItm.title + (formItm.required ? ' *' : ' ')}</Form.Label>
                            <Form.Label>{formItm.placeholder}</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={5}
                              defaultValue=""
                              required={formItm.required}
                              onChange={(e)=>{
                                const enteredValue = e.target.value
                                var eventQues = eventAdditionalQuestions
                                eventQues[orderI].value = enteredValue
                                setEventAdditionalQuestions(eventQues)
                              }}
                            />
                          </Form.Group>
                          }
                          else{

                          }
                        })
                      }

                      </Form>
                        
                      </Card>
                      <Form style={{marginTop: '25px'}}>
                      <Card.Title style={{ fontSize: "18px",
                fontWeight:'bold',
                marginTop: '15px',
                marginLeft: '15px', 
                color: "#000000"}}
                >提交问卷还需要如下信息</Card.Title>
                      <Row md={2}>
                          <Col>
                          <Form.Group 
                          className="mb-3" 
                          controlId="register.first" 
                          style={{
                              marginTop: '15px',
                            marginBottom: '10px',
                          marginLeft: '15px',
                          marginRight: '15px'}}>
                              <Form.Label>{i18n.language == 'en' ? 'First Name *' : '您的名字 First Name *'}</Form.Label>
                              <Form.Control type="name" ref={eventRegisterFirstNameRef} required/>
                          </Form.Group>
                          </Col>

                          <Col>
                          <Form.Group 
                          className="mb-3" 
                          controlId="register.last" 
                          style={{
                              marginTop: '15px',
                            marginBottom: '10px',
                          marginLeft: '15px',
                          marginRight: '15px'}}>
                              <Form.Label>{i18n.language == 'en' ? 'Last Name *' : '您的贵姓 Last Name *'}</Form.Label>
                              <Form.Control type="name" ref={eventRegisterLastNameRef} required/>
                          </Form.Group>
                          </Col>
                      </Row>

                      <Form.Group 
                          className="mb-3" 
                          controlId="register.email" 
                          style={{
                              marginTop: '15px',
                            marginBottom: '10px',
                          marginLeft: '15px',
                          marginRight: '15px'}}>
                              <Form.Label>{i18n.language == 'en' ? 'Email Address *' : '您的邮箱 Email Address *'}</Form.Label>
                              <Form.Control type="email" ref={eventRegisterEmailRef} required/>
                          </Form.Group>

                      <div 
                  className="d-flex align-items-center justify-content-center" 
                  id="form-register" style={{marginTop: '8px', marginBottom: '32px'}}>
                    <Button className="border-0" style={{width: '100%' ,height: '40px', fontWeight: 'bold', backgroundColor: "#f6AC19", color: 'black', borderRadius: '0px'}} 
                    disabled={!eventDetails.ticketStatus}
                    onClick={(e)=>{
                      //actions register
                      handleUserRegister(e)
                      }}>
                        <Spinner animation="border" size="sm" hidden={!loading}/>{" "}
                      {i18n.language == 'en' ? 'Submit Form' : '提交问卷'}
                      </Button>
                    </div>

                    </Form>
             
            </Card.Body>
          </Card>
        </div>
      </Container>
      
    </>
  );
}

import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert, Tab, Tabs, Row, Col, Container, TabContainer, ListGroup, Dropdown, DropdownButton, Image } from "react-bootstrap"
import { auth, fires} from "../../firebase"
import { Link, useNavigate, Route, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect"
import firebase from "firebase/app";
import "firebase/firestore";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';


export default function EventOrgMemberCenter() {
    const [error, setError] = useState("")
    
    const [events, setEvents] = useState([]);
    const [orgDetails, setOrgDetails] = useState({
        name: '',
        profileURL: '',
        bannerURL: '',
        policy: ['-'],
        picURLs: [''],
        description: '',
        descriptions: [],
        contacts: [],
      });
    const [orgMemberData, setOrgMemberData] = useState({
        email: '',
        firstName: '',
        lastName: '',
        name: '',
        profileURL: '',
        events: [],
        status: '',
        pts: 0,
        timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
    })

    const maxW = isMobile ? "100%" : "100%"
    
    let { orgId } = useParams();
    const { t, i18n } = useTranslation();

    function loadEventsFromOrg(){
        fires
        .collection('events')
        .where('orgId', '==', orgId)
        .where('startDate','>=',firebase.firestore.Timestamp.fromDate(new Date()))
        .where('status', '==', 'private')
        .orderBy('startDate','asc')
        .get()
        .then((snapshot) => {
            var allEvents = []
            snapshot.forEach((doc) => {
                const eventData = doc.data()
                allEvents.push({ ...eventData, id: doc.id})
            })
            setEvents(allEvents)
        })
    }

    useEffect(() => {
        
        fires.collection('groups').doc(orgId).get().then((docSnap) => {
            if (docSnap.exists){
                const docData = docSnap.data();
                setOrgDetails({...docData});
                if (auth.currentUser != null){
                    const authEmail = auth.currentUser.email
                    if (authEmail != null && authEmail != ""){
                        //email
                        fires.collection('groups').doc(orgId).collection('members').doc(authEmail).get().then((memberSnap) => {
                            if (memberSnap.exists){
                                //correct member
                                const memberData = memberSnap.data()
                                setOrgMemberData({... memberData})

                                //load org details
                                loadEventsFromOrg()
                            }
                            else{
                                //not member
                                window.location.href = "/"
                            }
                        })
                    }
                    else{
                        //not member
                        window.location.href = "/"
                    }
                }
                else{
                    //not login
                    window.location.href = "/"
                }
            }
            else{
                //not found
                window.location.href = "/"
            }
        })

      }, []);

    return (
      
      
        <Container
                className="d-flex justify-content-center"
                style={{ minHeight: "100vh", minWidth: "100vw" }}
              >
                <div
                  className="w-100"
                  style={{
                    marginTop: "25px",
                    marginBottom: "25px",
                    maxWidth: maxW
                  }}
                >


                  <div>
                    <Card className={"border-0"}>
                      <Card.Body>
                      <div className="d-flex justify-content-between align-items-start">
                      <Card.Title
                          style={{
                            fontSize: isMobile ? "20px" : "45px",
                            fontWeight: "bold",
                            color: "#000000",
                          }}
                        >
                          {'Welcome to ' + orgDetails.name + ' ' + ' 👋'}
                        </Card.Title>
                        <div style={{display: 'flex'}}>
                        <DropdownButton
                            style={{marginRight: '10px'}}
                            title={'Membership'}
                            id=""
                            variant="outline-dark"
                            onSelect={(e)=>{
                            }}
                        >
                  <Dropdown.Item eventKey='account-setting'>My Membership</Dropdown.Item>
                  <Dropdown.Divider/>
                  <Dropdown.Item eventKey='cancel' style={{color: 'red'}}>End Membership</Dropdown.Item>
                </DropdownButton>
                        </div>
                      </div>

                        <Tab.Container id="list-group-tabs-example" defaultActiveKey={'#membership'}>
                      <Row  style={{marginTop: '50px'}}>
                          <Col sm={2}>
                          <ListGroup>
                            <ListGroup.Item action href="#about" className="border-0 d-flex align-items-center" 
                            style={{
                              alignSelf: "center",
                              display: "flex",
                              width: "100%",
                              marginBottom: "5px",
                              }}>
                              <div className="fw-bold" style={{marginLeft: '5px'}}>About {orgDetails.name}</div>
                             </ListGroup.Item>
                             <ListGroup.Item action href="#member-rules" className="border-0 d-flex align-items-center" 
                            style={{
                              alignSelf: "center",
                              display: "flex",
                              width: "100%",
                              marginBottom: "5px",
                              }}>
                              <div className="fw-bold" style={{marginLeft: '5px'}}>Member Rules</div>
                             </ListGroup.Item>
                             <ListGroup.Item action href="#member-benefits" className="border-0 d-flex align-items-center" 
                            style={{
                              alignSelf: "center",
                              display: "flex",
                              width: "100%",
                              marginBottom: "5px",
                              }}>
                              <div className="fw-bold" style={{marginLeft: '5px'}}>Member Benefits</div>
                             </ListGroup.Item>
                            <ListGroup.Item action href="#events" className="border-0 d-flex align-items-center" 
                            style={{
                              alignSelf: "center",
                              display: "flex",
                              width: "100%",
                              marginBottom: "5px",
                              }}>
                              <div className="fw-bold" style={{marginLeft: '5px'}}>Exclusive Events</div>
                             </ListGroup.Item>
                             <ListGroup.Item action href="#qna" className="border-0 d-flex align-items-center" 
                            style={{
                              alignSelf: "center",
                              display: "flex",
                              width: "100%",
                              marginBottom: "5px",
                              }}>
                              <div className="fw-bold" style={{marginLeft: '5px'}}>Q and A</div>
                             </ListGroup.Item>
                             <ListGroup.Item action href="#membership" className="border-0 d-flex align-items-center" 
                            style={{
                              alignSelf: "center",
                              display: "flex",
                              width: "100%",
                              marginBottom: "5px",
                              }}>
                              <div className="fw-bold" style={{marginLeft: '5px'}}>My Membership</div>
                             </ListGroup.Item>

                            </ListGroup>

                          </Col>
                          <Col sm={10} style={{marginTop: '25px'}}>
                            <Tab.Content>
                            <Tab.Pane eventKey="#membership">
                            <ListGroup>
                              <ListGroup.Item className="fw-bold border-0">My Membership</ListGroup.Item>
                              <ListGroup.Item className="align-items-start border-0">
                                  <div 
                                  className="d-flex align-items-center border-0"
                                  style={{
                                    alignSelf: "center",
                                    display: "flex",
                                    width: "100%",
                                    marginBottom: "15px",
                                    marginTop: "5px",
                                  }}>

                                  <Image
                                    roundedCircle
                                    src={
                                      orgMemberData.profileURL
                                    }
                                    width="75px"
                                    height="75px"
                                  />
                                  <div style={{ marginLeft: "16px" }}>
                                    <Card.Text
                                      style={{
                                        fontSize: "35px",
                                        fontWeight: "bold",
                                        marginBottom: "0px",
                                        color: "black",
                                      }}
                                    >
                                      {orgMemberData.name}
                                    </Card.Text>
                                  </div>
                                  </div>
                                  </ListGroup.Item>
                                  <ListGroup.Item className="border-0">
                                      <div className="fw-normal">First Name</div>
                                      <div className="fw-bold">{orgMemberData.firstName}</div>
                                  </ListGroup.Item>
                                  <ListGroup.Item className="border-0">
                                      <div className="fw-normal">Last Name</div>
                                      <div className="fw-bold">{orgMemberData.lastName}</div>
                                  </ListGroup.Item>
                                  <ListGroup.Item className="border-0">
                                      <div className="fw-normal">Events Participated</div>
                                      <div className="fw-bold">{orgMemberData.events.length}</div>
                                  </ListGroup.Item>
                                  <ListGroup.Item className="border-0">
                                      <div className="fw-normal">Member Status</div>
                                      <div className="fw-bold">{orgMemberData.status != '' ? 'Activated' : 'NA'}</div>
                                  </ListGroup.Item>
                                  <ListGroup.Item className="border-0">
                                      <div className="fw-normal">Member Since</div>
                                      <div className="fw-bold">{orgMemberData.timestamp.toDate().toDateString('en')}</div>
                                  </ListGroup.Item>
                                  <ListGroup.Item className="border-0" style={{
                                      marginTop: '25px'
                                  }}>
                                      <Button variant="danger" style={{
                                          fontWeight: 'bold'
                                      }}>
                                          End Membership
                                      </Button>
                                  </ListGroup.Item>
                            </ListGroup>

                            </Tab.Pane>

                            <Tab.Pane eventKey="#about">
                            <ListGroup>
                              <ListGroup.Item className="fw-bold border-0">About {orgDetails.name}</ListGroup.Item>
                              <ListGroup.Item className="border-0">
                                <Card.Text style={{fontSize: '17px', color: 'black', marginTop: '15px'}} hidden={orgDetails.descriptions.length != 0}>{orgDetails.description}</Card.Text>
                              </ListGroup.Item>
                              <ListGroup.Item className="border-0">
                              {
                                orgDetails.descriptions.map((desc)=>{
                                    return <Card.Text style={{fontSize: '17px', color: 'black', marginTop: '15px'}}>{desc}</Card.Text>
                                })
                                }
                              </ListGroup.Item>

                            </ListGroup>
                            
                            
                
                
                            </Tab.Pane>

                            <Tab.Pane eventKey="#member-rules">
                            <ListGroup>
                              <ListGroup.Item className="fw-bold border-0">Member Rules</ListGroup.Item>
                            </ListGroup>
                            </Tab.Pane>

                            <Tab.Pane eventKey="#events">
                            <ListGroup>
                              <ListGroup.Item className="fw-bold border-0">Events({events.length})</ListGroup.Item>
                            </ListGroup>

                            {events.map((event) => {
        return <Card className="border-0" style={{width: '100%', marginTop: '25px'}} onClick={()=> {
                window.location.href = "/" + event.id
                }}>
        
        <Card.Body>
                            <div
                                  className="d-flex align-items-center"
                                  style={{
                                    alignSelf: "center",
                                    display: "flex",
                                    width: "100%",
                                    marginBottom: "5px",
                                  }}
                                >
                                  <Card.Img style={{width: '100px', height: '75px', objectFit: 'cover'}} src={event.photoURL} />
                                  <div style={{ marginLeft: '15px' }}>
                                    <Card.Text style={{fontSize: '12px', fontWeight: 'bold', color: '#F57828', margin: 0}}>{event.startDate.toDate().toLocaleDateString('en')}</Card.Text>
                                    <Card.Text style={{fontSize: '17px', fontWeight: 'bold', color: 'black', margin: 0}}>{event.title}</Card.Text>
                                    <Card.Text style={{fontSize: '12px', fontWeight: 'normal', color: 'black', margin: 0}}>{event.address.fullAddress}</Card.Text>
                                    <Card.Text style={{fontSize: '12px', fontWeight: 'normal', color: 'black', margin: 0}}>{event.startDate.toDate().toLocaleString('en')}</Card.Text>
                                  </div>
                                </div>
          
        </Card.Body>
        </Card>
        })}
                            </Tab.Pane>

                            </Tab.Content>

                          </Col>
                    </Row>
                    </Tab.Container>

                    </Card.Body>
                    </Card>
                    </div>

                </div>

            </Container>

        

    )
}
import React, 
{useRef, 
useState, 
useCallback, 
useEffect } from "react";
import {
  Form,
  Button,
  ButtonGroup,
  Card,
  Alert,
  Tab,
  Tabs,
  Carousel,
  Image,
  Container,
  Row,
  Col,
  ListGroup,
  Navbar,
  Modal,
  FloatingLabel,
} from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { 
    Link, 
    useNavigate, 
    useParams, 
    Route
 } from "react-router-dom";
import { 
    isMobile, 
    isAndroid, 
    isIOS
 } from "react-device-detect";
import { fires, auth } from "../../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import StarRatings from 'react-star-ratings';
import { QRCodeCanvas } from "qrcode.react";
import Gallery from "react-photo-gallery";
import { Helmet } from "react-helmet";
import { FileUploader } from "react-drag-drop-files";
import {EmailAuthProvider} from 'firebase/auth';
import { useTranslation } from "react-i18next";


export default function EventTicketConfirmation() {
  
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "50%";

  const fileTypes = ["JPG", "PNG", "GIF","HEIC","SVG","MOV","MP4"];


  let { ticketId } = useParams();
  var currentUA = ""
  
  const { t, i18n } = useTranslation();

  const [showUpload, setShowUpload] = useState(false);
  const handleUploadClose = () => setShowUpload(false);
  const handleUploadShow = () => setShowUpload(true);

  const [showText, setShowText] = useState(false);
  const handleTextClose = () => setShowText(false);
  const handleTextShow = () => setShowText(true);

  const [showCheckin, setShowCheckin] = useState(false);
  const handleCheckinOpen = () => setShowCheckin(true);
  const handleCheckinClose = () => setShowCheckin(false);

  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedImageURL, setSelectedImageURL] = useState("");
  const [uploadS, setUploadS] = useState("请点击上传图片开始上传")
  const [uploadedURL, setUploadedURL] = useState('')
  const textRef = useRef();
  
  const [eventPhotos, setEventPhotos] = useState([])
  const [eventMessages, setEventMessages] = useState([])
  const [jorItems, setJorItems] = useState([])
  const [userData, setUserData] = useState({
      email: '',
      firstName: '',
      lastName: '',
      name: '',
  })

  const [eventDetails, setEventDetails] = useState({
    status: "NA",
    title: "Loading...",
    description: "Loading...",
    announcement: "",
    announcements: [],
    titles: { en: "", zh: "" },
    descriptions: { en: [], zh: [] },
    drawInstruction: "",
    startDate: {
      "_seconds": 1727388000,
      "_nanoseconds": 0},
    endDate: {
      "_seconds": 1727388000,
      "_nanoseconds": 0
    },
    startDateString: "",
    endDateString: "",
    address: {name: "",fullAddress: "Loading...", unit: ''},
    priceType: "FREE",
    agenda: [],
    sponsor: [],
    drawItems: {},
    wifiLink: "",
  });
  const [orgDetails, setOrgDetails] = useState({
    name: 'EventGo'
  });
  const [ticketData, setTicketData] = useState({
      eventId: '',
      quantity: 1,
      userName: '',
      tickets: [],
      ticketType: '',
  });

  const [eventMemberDetails, setEventMemberDetails] = useState({
    name: "",
    firstName: "",
    lastName: "",
    formEmailId: "",
    forms: [],
    tickets: [],
    ticketId: '',
  })

  async function sendTicketInvoiceEmail(event_id, ticket_id){
    const dataBody = `event_id=${event_id}&ticket_id=${ticket_id}`;
      fetch("https://createorggenerateinvoice-verqhozorq-uc.a.run.app?"+dataBody,
      {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*', }
        }).then((response) => {
          response.json().then((data) => {
            var dataS = {success: false,}
            dataS = data
            if (dataS.success){
              
            }
          })
      })
  }

  async function sendTicketEmailWithAgenda(eventId,emailAddress){
    let formContent = ""
    let formSubject = `${eventDetails.title} - Your submission is recorded.`
    if (eventMemberDetails.forms.length != 0){
      formContent = `Hello ${eventMemberDetails.name}, \n\nwe got your submission, we will see you at event.\n\n\n`
      eventMemberDetails.forms.forEach((formItm, index) => {
        let formTitle = ""
        let formDetails = ""
        if (formItm.title != null){
          formTitle = formItm.title
        }
        if (formItm.value != null){
          formDetails = formItm.value
        }
        formContent += `Question ${index+1}.\n${formTitle}\n`
        formContent += `${formDetails}\n\n`
      })
      fires.collection("mail").add({
        from: "EventGo <contact@evtgo.com>",
        to: [emailAddress],
        message: {
          subject: formSubject,
          text: formContent,
        },
      }).then((refI) => {
          const emailId = refI.id
          fires.collection('events').doc(eventId).collection('members').doc(emailAddress).update({
            formEmailId: emailId
          })
      })
    }
    else{

    }
  }
  
  
  const [eventOptions, setEventOptions] = useState({
    poll:false,
    draw:false,
    namecard:false,
    review:false,
    comment:true,
    picture:true,
    socialmedia:true,
    hidehost:false,
    'register-feedback':false,
    'accept-registration':true,
    max:500})

  const routeRedirect404 = () =>{ 
    let path = '/404' ; 
    window.location.href = path
  }

  const [coverImg,setCoverImg] =
    useState("https://images.unsplash.com/photo-1561489396-888724a1543d?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D");
  const [profileImg,setProfileImg] =
    useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png");

    function uploadImage(file) {
        fetch('https://r2-worker.sam-12e.workers.dev/', {
          method: 'POST',
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Content-Type': file.type
          },
          body: file
        })
          .then((response) => response.text())
          .then((data) => {
            const downloadURL = "https://pub-e888c0363b7c412caf77f22ba84fcb86.r2.dev/" + data
            
            
          }) // 
          .catch((error) => console.error(error));
      }
    
      const handleImageChange = (e) => {
        e.preventDefault()
        const file = e.target.files[0];
        setSelectedImage(file)
      }

      const handleImageFileUpload = (file) => {
        setSelectedImage(file)
      }
    
      const handleImageSubmit = (e) => {
        e.preventDefault()
        const file = selectedImage;
        if (!file) return;
    
        uploadImage(file)
      }

      function userBecomeMember(email,fn,ln,uid, orgId){
          if (orgId != ""){

            fires.collection('groups').doc(orgId).collection('requests').add({
                email: email,
                firstName: fn,
                lastName: ln,
                comment: "event from " + eventDetails.title,
                uid: uid,
                status: 'requested',
                timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
              })

          }
        
      }

      function userDataSignup(email,fn,ln,uid){
        fires.collection('users').doc(uid).set({
            email: email,
            firstName: fn,
            lastName: ln,
            currentEvent: "",
            uid: uid,
            name: fn + " " + ln,
            userName: fn + " " + ln,
            lang: 'zh',
            isAnonymous: true,
            timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
          }).then((dat) => {
              handleCheckinClose()
              window.location.reload()
          })
      }

      const userEventSignup = (email, fn, ln) => {

        if (auth.currentUser != null){
          const uid = auth.currentUser.uid
          if (orgDetails.id != null && orgDetails.id != ""){
              userBecomeMember(email,fn,ln,uid,orgDetails.id)
          }
          handleCheckinClose()
          window.location.reload()
      }
      else{
          auth.signInAnonymously().then((userDe) => {
              const uid = userDe.user.uid
              if (orgDetails.id != null && orgDetails.id != ""){
                  userBecomeMember(email,fn,ln,uid,orgDetails.id)
              }
              userDataSignup(email,fn,ln,uid)
          })
      }
      }

      const checkinPrompt = () => {
          return <Modal show={showCheckin} onHide={handleCheckinClose}>
              <Modal.Header closeButton><Card.Title style={{fontWeight: 'bold'}}>Create Account</Card.Title></Modal.Header>
              <Modal.Body>
                  <Form onSubmit={(e) => {
                      e.preventDefault();
                      const pass = e.target[0].value
                      const passC = e.target[1].value
                      
                      if (pass == passC) {
                        if (auth.currentUser != null){
                          if (auth.currentUser.isAnonymous){
                            var credential = firebase.auth.EmailAuthProvider.credential(userData.email, pass);
                          auth.currentUser.linkWithCredential(credential).then((userObj) => {
                            window.alert('Success! You have successfully creating EventGo account!')
                            window.location.href = "/"
                          }).catch((e)=>{
                            window.alert('Error! Not able to create account, this account already existed')
                            window.location.href = "/"
                          })

                          }
                          else{
                            window.location.href = "/"
                          }
                        }
                      }
                  }}>
                    <Form.Group>
                      <Form.Label>电子邮件 Email: {userData.email}</Form.Label>
                    </Form.Group>
                      <Row md={2} style={{marginTop: '15px'}}>
                          <Col>
                            <Form.Group>
                                <FloatingLabel label="密码 Password *">
                                    <Form.Control type="password" required/>
                                </FloatingLabel>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group>
                                <FloatingLabel label="重复密码 Repeat Password *">
                                    <Form.Control type="password" required/>
                                </FloatingLabel>
                            </Form.Group>
                          </Col>
                      </Row>

                      

                            <Form.Group style={{marginTop: '15px'}}>
                                <Button variant="dark" className="w-100 fw-bold" type="submit">提交 Submit</Button>
                                
                            </Form.Group>

                  </Form>
              </Modal.Body>
          </Modal>
      }

    useEffect(() => {

      fetch("https://us-central1-blink-574af.cloudfunctions.net/v1EventsAPIFetchTicketDetails?ticket_id="+ticketId, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin': '*',
        },
      })
      .then(response => response.json())
      .then((data) => {
      if (data.data != null){
        let ticketData = {quantity: 1, tickets: []}
        ticketData = data.data
        var quan = 1
        var tickets = []
        if (ticketData.quantity != null){
          quan = ticketData.quantity
        }
        if (ticketData.tickets != null){
          tickets = ticketData.tickets
        }
        setTicketData({...ticketData, quantity: quan, tickets: tickets})

        let eventId = ""
        if (data.eventData != null){
          let eventData = {orgId: ''}
          eventData = data.eventData
          setEventDetails({...eventData})
          eventId = eventData.id
          if (eventData.user.imageURL !== null && eventData.user.imageURL !== "") {
            setProfileImg(eventData.user.imageURL)
          }
          if (eventData.orgId != null){
            if (eventData.orgId != ""){
                fires.collection("groups").doc(eventData.orgId).get().then((snapshotOrg) => {
                    if (snapshotOrg.exists){
                        const orgData = snapshotOrg.data()
                        setOrgDetails({...orgData})
                    }
                })
            }
        }

        if (auth.currentUser != null){
                  //update user with tickets
                    const uid = auth.currentUser.uid
                    if (uid != ""){
                        fires.collection('users').doc(uid).get().then((snapshotUser) => {
                            if (snapshotUser.exists){
                                const userData = snapshotUser.data()
                                setUserData(userData)
                                if (userData.lang != null){
                                  i18n.changeLanguage(userData.lang)
                                }
                                if (userData.email != null){
                                  if (userData.email != ""){
                                    if (eventId != null && eventId != ""){
                                      fires.collection("events").doc(eventId).collection('members').doc(userData.email).get().then((memberSnap) => {
                                        if (memberSnap.exists){
                                          const memData = memberSnap.data()
                                          setEventMemberDetails({...memData})
                                        }
                                      })
                                    }
                                  }
                                }
                              }
                            })
                          }
                        }

        }

      }
      else{
        //route to 404
        routeRedirect404()
      }
      

    })


        // fires.collection('event_tickets').doc(ticketId).get().then((snapshotA) => {
        //     if (snapshotA.exists){
        //         const ticketD = snapshotA.data()
        //         const eventId = ticketD.eventId
        //         var tQuantity = 1
        //         var usersName = ""
        //         var eventtId = ""
        //         var ticks = []
        //         if (ticketD.tickets != null){
        //             tQuantity = ticketD.tickets.length
        //             ticks = ticketD.tickets
        //         }
        //         if (ticketD.userName != null){
        //             usersName = ticketD.userName
        //         }
        //         if (ticketD.eventId != null){
        //             eventtId = ticketD.eventId
        //         }
                // setTicketData({...ticketD, userName: usersName, eventId: eventtId, quantity: tQuantity, tickets: ticks})
                // if (eventId != null && eventId != ""){
                //     fires.collection("events").doc(eventId).get().then((snapshotB) => {
                //         const eventData = snapshotB.data();
                //         setEventDetails({...eventData})
                        
                //     })
                // }

                // if (auth.currentUser != null){
                //   //update user with tickets
                //     const uid = auth.currentUser.uid
                //     if (uid != ""){
                //         fires.collection('users').doc(uid).get().then((snapshotUser) => {
                //             if (snapshotUser.exists){
                //                 const userData = snapshotUser.data()
                //                 setUserData(userData)
                //                 if (userData.lang != null){
                //                   i18n.changeLanguage(userData.lang)
                //                 }
                //                 if (userData.email != null){
                //                   if (userData.email != ""){
                //                     if (eventId != null && eventId != ""){
                //                       fires.collection("events").doc(eventId).collection('members').doc(userData.email).get().then((memberSnap) => {
                //                         if (memberSnap.exists){
                //                           const memData = memberSnap.data()
                //                           let formss = []
                //                           let formEmailId = ""
                //                           if (memData.forms != null){
                //                             formss = memData.forms
                                        //   }
                                        //   if (memData.formEmailId != null){
                                        //     formEmailId = memData.formEmailId
                                        //   }
                                        //   setEventMemberDetails({...memData, forms: formss, formEmailId: formEmailId})
                                        //   if (memData.formEmailId == null){
                                        //     sendTicketEmailWithAgenda(eventId,userData.email)
                                        //   }
                                          
                                        //   if (userData.events != null){
                                        //     let eventsTotal = []
                                        //     eventsTotal = userData.events
                                        //     if (!eventsTotal.includes(eventId)){
                                        //         eventsTotal.push(eventId)
                                        //     }
                                        //     fires.collection('users').doc(uid).update({
                                        //         events: eventsTotal,
                                        //     })
                                        //   }

                                        // }
                                        // else{
                                          //not exist
                                          
                //                         }
                //                       })
                //                     }
                //                   }
                //                 }
                                
                //             }
                //         })
                //     }
                // }
            //     else{
            //       routeRedirect404()
            //     }
                
                

            // }
            // else{
                //ticket not found
            //     routeRedirect404()
            // }
        // })
      }, []);

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{
            minHeight: "100vh",
            minWidth: '100vw',
            backgroundImage: 'url(' + coverImg + ')',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'  }}
      >
          <Helmet>
                <title>{eventDetails.title}</title>
                <meta
                  name="description"
                  content={eventDetails.description}
                />
                <meta name="twitter:card" content={eventDetails.photoURL} />
                <meta name="twitter:site" content="@user" />
                <meta name="twitter:creator" content="@user" />
                <meta name="twitter:title" content={eventDetails.title} />
                <meta
                  name="twitter:description"
                  content={eventDetails.description}
                />
                <meta name="twitter:image" content={eventDetails.photoURL} />
                <meta property="og:title" content={eventDetails.title} />
                <meta
                  property="og:description"
                  content={eventDetails.description}
                />
                <meta property="og:image" content={eventDetails.photoURL} />
                <meta
                  property="og:url"
                  content={"https://tickets.evtgo.com/ticket-id/"+ticketId }
                />
                <meta
                  property="og:site_name"
                  content={eventDetails.title}
                />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
              </Helmet>

        <div className="w-100" style={{ maxWidth: maxW }}>
            {
                checkinPrompt()
            }
          <Card
            className="border-0" 
            style={{
                
            backgroundColor:"white",
            marginTop: '15px',
            marginBottom: '15px'}}
          >
              <Card.Body className = "">
              <Card.Title className="text-center"
               style={{ fontSize: isMobile ? "20px" : "30px",
                marginBottom: '5px',
                fontWeight:'bold', 
                color: "#000000"}}>
                  {i18n.language == 'en' ? 'Thanks for your order!' : '您已经下单成功!'}
            </Card.Title>
            <Card.Text className="text-center"
               style={{ fontSize: isMobile ? "15px" : "15px",
                marginBottom: '15px',
                fontWeight:'bold', 
                color: "gray"}}>
                  {`#${ticketId}`}
            </Card.Text>
            <div style={{marginTop: '25px', marginBottom: '25px'}}>
              <div>
                <Card.Text
                style={{ 
                  fontSize: 15,
                margin: 0,
                fontWeight:'bold', 
                color: "black"}}>{i18n.language == 'en' ? 'YOU ARE GOING' : '您将要前往'}</Card.Text>
                <Card.Text
                style={{ fontSize: isMobile ? "25px" : "30px",
                marginBottom: '0px',
                fontWeight:'bold', 
                color: "black"}}>{eventDetails.title}</Card.Text>
              </div>

            {/* <div className="d-flex align-items-center justify-content-between">
                            <div className="fw-bold">{i18n.language == 'en' ? 'Ticket' : '订单号'}</div>
                            <div className="fw-bold">{ticketId}</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="fw-bold">{i18n.language == 'en' ? 'Qty' : '票数'}</div>
                            <div className="fw-bold">{ticketData.quantity}</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="fw-bold">{i18n.language == 'en' ? 'Name' : '买票人'}</div>
                            <div className="fw-bold">{ticketData.userName}</div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="fw-bold">{i18n.language == 'en' ? 'Email' : '电子邮箱'}</div>
                            <div className="fw-bold">{userData.email}</div>
                        </div> */}

            </div>

            <Card.Text style={{margin: 0}}><b>{ticketData.quantity}</b>{i18n.language == 'en' ? ` tickets purchased by ` : '张票已购买并发送到'}<b>{ticketData.userName}</b></Card.Text>
            <Card.Text style={{margin: 0}}><b>{userData.email}</b></Card.Text>
            <Card.Text style={{marginTop: '5px'}}>{i18n.language == 'en' ? 'We have confirmed your tickets, the confirmation email has been sent.' : '您的活动订单已确认，确认邮件已发送.'}</Card.Text>

            </Card.Body>
          </Card>

          <Card
            className="border-0"
            style={{
            backgroundColor:"white",
            marginTop: '15px',
            marginBottom: '15px'}}
            hidden={ticketId == ""}
          >
            <Card.Body className = "align-items-center justify-content-center">
            <Card.Title className=""
               style={{ fontSize: "17px",
                marginBottom: '15px',
                fontWeight:'bold',
                color: "#000000"}}>
                    {i18n.language == 'en' ? "QR Code" : '二维码'}
            </Card.Title>
            <div className="d-flex align-items-center justify-content-center">
            <QRCodeCanvas
        id="qrCodeElement"
                                    style={{marginTop: '30px'}}
                                    value={
                                      "https://tickets.evtgo.com/ticket-id/" + ticketId
                                    }
                                    size={200}
                                    bgColor={"#ffffff"}
                                    fgColor={"#000000"}
                                    level={"L"}
                                    includeMargin={false}
                                    
                                  />
            </div>
            
            
            </Card.Body>
          </Card>

          <Card
            className="border-0" 
            style={{
            backgroundColor:"white",
            marginTop: '15px',
            marginBottom: '15px'}}
            
          >
              <Card.Body className = "align-items-start">
              <Card.Title
               style={{ 
                 fontSize: "17px",
                marginBottom: '5px',
                fontWeight:'bold', 
                color: "#000000"}}>
                    {i18n.language == 'en' ? "Event Tickets" : "门票"}
            </Card.Title>
            <Card.Text
               style={{ 
                 fontSize: "15px",
                marginBottom: '15px',
                fontWeight:'normal', 
                color: "#000000"}}>
                    {i18n.language == 'en' ? "Your tickets and QR Codes" : "您的门票和二维码"}
            </Card.Text>
            <ListGroup>
            {
              ticketData.tickets.map((tick)=>{
                return <ListGroup.Item className="d-flex align-items-start justify-content-between">
                  <Card.Link href={`https://tickets.evtgo.com/ticket-id/${tick}`}>{tick}</Card.Link>
                  <QRCodeCanvas
                            id="qrCodeElement"
                                    style={{marginTop: '10px'}}
                                    value={
                                      "https://tickets.evtgo.com/ticket-id/" + tick
                                    }
                                    size={isMobile ? 75 : 150}
                                    bgColor={"#ffffff"}
                                    fgColor={"#000000"}
                                    level={"L"}
                                    includeMargin={false}
                                    
                                  />
                  </ListGroup.Item>
              })
            }
            </ListGroup>
            
            

           
            
            </Card.Body>
          </Card>

          <Card
            className="border-0" 
            style={{
                
            backgroundColor:"white",
            marginTop: '15px',
            marginBottom: '15px'}}
          >
              <Card.Body className = "align-items-start">
              <Card.Title className=""
               style={{ fontSize: "17px",
                marginBottom: '15px',
                fontWeight:'bold',
                color: "#000000"}}>
                    {i18n.language == 'en' ? "Location" : "活动地址"}
            </Card.Title>
            <Card.Text className="" style={{fontSize: 15, fontWeight: 'bold'}}>
            {eventDetails.address.name}
            </Card.Text>
            <Card.Text className="">
            {eventDetails.address.fullAddress}
            </Card.Text>

              <Card.Title className=""
               style={{ fontSize: "17px",
                marginTop: '25px',
                marginBottom: '15px',
                fontWeight:'bold', 
                color: "#000000"}}>
                    {i18n.language == 'en' ? "Event Date/Time" : "本次活动时间"}
            </Card.Title>
            <Card.Text className="">
            {i18n.language == 'en' ? 'Start: ' : '起始日: '} {eventDetails.startDateString}
            </Card.Text>
            <Card.Text className="">
            {i18n.language == 'en' ? 'End: ' : '截止日: '} {eventDetails.endDateString}
            </Card.Text>
            
            </Card.Body>
          </Card>
          
         

         

                  

         

          
        </div>
      </Container>
    </>
  );
}

import React, { useRef, useState } from "react";
import { Form, Button, Card, Alert, Container } from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";

export default function NotFound() {
  const emailRef = useRef();
  const passwordRef = useRef();
  const { login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const maxW = isMobile ? "100%" : "60%";
  let { id } = useParams();

  async function handleSubmit(e) {
    e.preventDefault();

    setLoading(false);
  }

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <div className="w-100" style={{ maxWidth: maxW }}>
          <Card>
            <Card.Body>
              <h2 className="text-center mb-4">Oppos, something wrong. Your page is not found</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              <br />
              <Card.Text className="text-center">
                Please make sure you are searching for correct page
              </Card.Text>
              <br />
              <div className="w-100 text-center mt-3">
                <Link to="/">Back Home</Link>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}

import React, 
{useRef, 
useState, 
useCallback, 
useEffect } from "react";
import {
  Form,
  Button,
  ButtonGroup,
  Card,
  Alert,
  Tab,
  Tabs,
  Carousel,
  Image,
  Container,
  Row,
  Col,
  ListGroup,
  Navbar,
  Modal,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate, useParams, Route } from "react-router-dom";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { fires, auth } from "../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import StarRatings from 'react-star-ratings';

export default function EventPaymentCancel() {
  
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "50%";

  let { id } = useParams();
  var currentUA = ""

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [eventDetails, setEventDetails] = useState([]);
  const [orgDetails, setOrgDetails] = useState({});
  const [userDetails, setUserDetails] = useState({});
  const [eventMemberDetails, setEventMemberDetails] = useState([])


  const routeRedirect404 = () =>{ 
    let path = '/404' ; 
    window.location.href = path
  }

  const routeRedirectEvent = (id) =>{ 
    let path = '/'+id ; 
    window.location.href = path
  }

  const [coverImg,setCoverImg] =
    useState("https://images.unsplash.com/photo-1439792675105-701e6a4ab6f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1746&q=80");
  const [profileImg,setProfileImg] =
    useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png");

    
    useEffect(() => {
        fires.collection('events').doc(id).get().then((snapshot) => {
            if (snapshot.exists) {
                const postData = snapshot.data()
                if (auth.currentUser.isAnonymous){
                  auth.signOut()
                }
                if (postData.status != null) {
                  if (postData.status == 'live' || postData.status == 'private'){
                    setEventDetails(postData)
                    var fuid = ''
                    var femail = ''
                    const orgId = postData.orgId
                    if (auth.currentUser != null) {
                        fuid = auth.currentUser.uid
                        femail = auth.currentUser.email
                        if (femail != ''){
                            fires.collection('events').doc(id).collection('members').doc(femail).get().then((snapshotA)=>{
                                if (snapshotA.exists){
                                    const memberDetails = snapshotA.data()
                                    setEventMemberDetails(memberDetails)
                                    
                                    routeRedirectEvent(postData.id)
                                    

                                    if (fuid != ''){
                                        fires.collection('users').doc(fuid).get().then((snapshotA)=>{
                                            const snapData = snapshotA.data()
                                            setUserDetails({...snapData, id: fuid})

                                            

                                        })
                                        if (orgId != ""){
                                            fires.collection('groups').doc(orgId).get().then((snapshotA)=>{
                                                const snapData = snapshotA.data()
                                                setOrgDetails({...snapData})
                                                if (snapData.profileURL != ""){
                                                    setProfileImg(snapData.profileURL)
                                                }
                                                if (snapData.coverURL != ""){
                                                    setCoverImg(snapData.coverURL)
                                                }
                                            })
                                        }
                                    }
                                    else{
                                        routeRedirect404()
                                    }

                                }
                                else{
                                    routeRedirect404()
                                }
                            })
                        }
                        else{
                            routeRedirect404()
                        }
                    }
                    else{
                        //no auth
                        if (orgId != ""){
                          fires.collection('groups').doc(orgId).get().then((snapshotA)=>{
                              const snapData = snapshotA.data()
                              setOrgDetails({...snapData})
                              if (snapData.profileURL != ""){
                                  setProfileImg(snapData.profileURL)
                              }
                              if (snapData.coverURL != ""){
                                  setCoverImg(snapData.coverURL)
                              }
                          })
                      }
                    }
                  }
                  else{
                    //not live, or ended
                    routeRedirect404()
                  }
                }
                else{
                  //not found
                  //deleted
                  routeRedirect404()
                // posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
                // setPosts(posts)
                }

            }
            else{
                //deleted
                routeRedirect404()
                // posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
                // setPosts(posts)
                
            }
        });
      }, []);

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh",minWidth: '100vw',backgroundImage: 'url(' + coverImg + ')' }}
      >
        <div className="w-100" style={{ maxWidth: maxW }}>
          <Card
            className="border-0 align-items-center justify-content-center" 
            style={{
                alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor:"white",
            marginTop: '25px',
            marginBottom: '25px'}}
          >
            <Card.Img
              variant="top"
              src={eventDetails.photoURL}
              style={{ maxHeight: "300px" }}
            />
              <Card.Body className = "align-items-center justify-content-center">
              <Card.Title className="text-center"
               style={{ fontSize: "30px",
                fontWeight:'bold', 
                color: "#000000"}}>{eventDetails.title}</Card.Title>
            <Card.Text className="text-center"
               style={{ fontSize: "20px",
                fontWeight:'bold', 
                marginTop: '25px',
                color: "#000000"}}>很遗憾，下单失败，系统将自动返回首页</Card.Text>
              
              <div className = "d-flex align-items-center justify-content-center">
                <Card.Link href={"/"}>返回</Card.Link>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}

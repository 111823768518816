import React, 
{useRef, 
useState, 
useCallback, 
useEffect } from "react";
import {
  Form,
  Button,
  ButtonGroup,
  Card,
  Alert,
  Tab,
  Tabs,
  Carousel,
  Image,
  Container,
  Row,
  Col,
  ListGroup,
  Navbar,
  Modal,
  FloatingLabel,
} from "react-bootstrap";
import { useAuth } from "../../contexts/AuthContext";
import { 
    Link, 
    useNavigate, 
    useParams, 
    Route
 } from "react-router-dom";
import { 
    isMobile, 
    isAndroid, 
    isIOS
 } from "react-device-detect";
import { fires, auth } from "../../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import { QRCodeCanvas } from "qrcode.react";

export default function OrganizerMembershipRequest() {
  
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "50%";


  let { orgId } = useParams();
  var currentUA = ""
  

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  const [eventDetails, setEventDetails] = useState({});
  const [orgDetails, setOrgDetails] = useState({
    name: '',
    profileURL: '',
    bannerURL: '',
    policy: ['-'],
    picURLs: [''],
    description: '',
    descriptions: [],
    contacts: [],
  });
  

  const emailRef = useRef()
  const firstNameRef = useRef()
  const lastNameRef = useRef()
  const commentRef = useRef()

  const [rileyMember, setRileyMember] = useState(false);

  const routeRedirect404 = () =>{ 
    let path = '/404' ; 
    window.location.href = path
  }

  const createUserJoinRequest = () => {
    const memberREmail = emailRef.current.value
    const memberRFirstName = firstNameRef.current.value
    const memberRLastName = lastNameRef.current.value
    const memberRComment = commentRef.current.value

    if (memberREmail != ""){
        fires.collection('groups').doc(orgId).collection('requests').add({
            email: memberREmail,
            firstName: memberRFirstName,
            lastName: memberRLastName,
            comment: memberRComment,
            uid: '',
            status: 'requested',
            timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
          }).then((docRef)=>{
              const requestId = docRef.id
              if (rileyMember){
                  fetch("https://subscribesystememaillist-verqhozorq-uc.a.run.app?email=" + memberREmail,{
                      method: 'POST',
                      headers: { 
                          'Content-Type': 'application/json',
                          'Access-Control-Allow-Origin': '*', }
                  }).then((response) => {
                      let itemConfirm = window.confirm("表单提交成功")
                      window.location.href = "/organizer/"+orgId
                  })
              }
              else{
                  let itemConfirm = window.confirm("表单提交成功")
                  window.location.href = "/organizer/"+orgId
              }
          })
    }
    else{
        window.alert("电子邮件不能为空")
    }
  }

  const [coverImg,setCoverImg] =
    useState("https://images.unsplash.com/photo-1439792675105-701e6a4ab6f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1746&q=80");
  const [profileImg,setProfileImg] =
    useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png");


    useEffect(() => {
        fires.collection('groups').doc(orgId).get().then((snapshot) => {
            if (snapshot.exists) {
                const orgData = snapshot.data()
                if (orgData.profileURL != null){
                    setProfileImg(orgData.profileURL)
                }
                if (orgData.coverURL != null){
                    setCoverImg(orgData.coverURL)
                }
                setOrgDetails({...orgData})
            }
            else{
                //deleted
                routeRedirect404()
            }
        });
      }, []);

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh",minWidth: '100vw',backgroundImage: 'url(' + coverImg + ')' }}
      >
        <div className="w-100" style={{ maxWidth: maxW }}>
          <Card
            className="border-0 align-items-center justify-content-center" 
            style={{
                alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor:"white",
            marginTop: '25px',
            marginBottom: '25px'}}
          >
            <Card.Img className = "rounded-circle shadow-4-strong" variant="top" src={profileImg} style={{height: '100px', width: '100px', marginTop: '25px', borderColor: '#ffffff'}}/>
              <Card.Body className = "align-items-center justify-content-center">
              <Card.Title className="text-center"
               style={{ fontSize: "30px",
                fontWeight:'bold', 
                color: "#000000"}}>
                    {orgDetails.name}
            </Card.Title>
            <Card.Title className="text-center"
               style={{ fontSize: "22px",
                fontWeight:'normal', 
                color: "#000000"}}>
                    会员申请
            </Card.Title>

            <Form>
                <Form.Group style={{marginTop: '5px'}}>
                  <Form.Label>想要成为<b>{orgDetails.name}</b>的会员吗？您即将填写会员申请表，成为会员后您将会收到来自{orgDetails.name}的邮件说明，并收到该组织未来的活动通知。</Form.Label>
                </Form.Group>

                <Form.Group style={{marginTop: '15px'}}>
                  <FloatingLabel label="您的邮箱 *">
                    <Form.Control type="email" ref={emailRef} placeholder="Email Address 您的邮箱 *" />
                  </FloatingLabel>
                </Form.Group>

                <Row md={2} style={{marginTop: '15px'}}>
                  <Col>
                  <Form.Group>
                  <FloatingLabel label="您的名字 *">
                    <Form.Control type="name" ref={firstNameRef} placeholder="First Name 您的名字 *" />
                  </FloatingLabel>
                </Form.Group>
                  </Col>
                  <Col>
                  <Form.Group>
                  <FloatingLabel label="您的贵姓 *">
                    <Form.Control type="name" ref={lastNameRef}  placeholder="Last Name 您的贵姓 *" />
                  </FloatingLabel>
                </Form.Group>
                  </Col>
                </Row>

                <Form.Group style={{marginTop: '15px'}}>
                  <Form.Label>有什么话想对{orgDetails.name}说?</Form.Label>
                  <Form.Control as="textarea" ref={commentRef} rows={3}/>
                </Form.Group>

                <Form.Group style={{marginTop: '15px'}}>
                <Form.Check 
                    type={'checkbox'}
                    id={'checked-offer'}
                    label={'您想要收到EVENTGO的活动邮件吗？'}
                    onChange={(e)=>{
                        let checked = e.target.checked
                        setRileyMember(checked)
                    }}
                />
                
                <Button className="w-100" style={{marginTop: '15px'}} variant="dark" onClick={()=>{
                    createUserJoinRequest()
                }}>提交表单</Button>
                </Form.Group>
                


            </Form>


            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}

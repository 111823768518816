import React from "react"
import Divider from '@material-ui/core/Divider';

const Footer = () => <footer className="page-footer font-small blue pt-4">
    <Divider style={{marginTop: '25px', marginBottom: '25px'}}/>
    <div className="container-fluid text-center text-md-left">
        <div className="row">
            <div className="col-md-3 mb-md-0 mb-3">
                <h5 className="text-uppercase" style={{color: 'black', fontWeight: 'bold', fontSize: '20px'}}>About us</h5>
                <ul className="list-unstyled" style={{color: 'black'}}>
                    <li><a href="/how-to-create-group" style={{color: 'black', fontSize: '15px',textDecoration: 'none'}}>Organizers</a></li>
                    <li><a href="/pricing" style={{color: 'black', fontSize: '15px',textDecoration: 'none'}}>Pricing</a></li>
                    <li><a href="/how-to-create-group" style={{color: 'black', fontSize: '15px',textDecoration: 'none'}}>Hosting Event</a></li>
                    <li><a href="/legal" style={{color: 'black', fontSize: '15px',textDecoration: 'none'}}>Legal</a></li>
                    <li><a href="/team" style={{color: 'black', fontSize: '15px',textDecoration: 'none'}}>Our Team</a></li>
                </ul>
            </div>

            <div className="col-md-3 mb-md-0 mb-3">
                <h5 className="text-uppercase" style={{color: 'black', fontWeight: 'bold', fontSize: '20px'}}>Explore</h5>
                <ul className="list-unstyled" style={{color: 'black'}}>
                    <li><a href="/news" style={{color: 'black', fontSize: '15px', textDecoration: 'none'}}>News</a></li>
                    <li><a href="/explore" style={{color: 'black', fontSize: '15px', textDecoration: 'none'}}>Popular Events</a></li>
                    <li><a href="/client-form" style={{color: 'black', fontSize: '15px', textDecoration: 'none'}}>Organizers</a></li>
                </ul>
            </div>

            <div className="col-md-3 mb-md-0 mb-3">
                <h5 className="text-uppercase" style={{color: 'black', fontWeight: 'bold', fontSize: '20px'}}>Downloads</h5>
                <ul className="list-unstyled" style={{color: 'black'}}>
                    <li><a href="#!" style={{color: 'black', fontSize: '15px', textDecoration: 'none'}}>iOS App</a></li>
                    <li><a href="#!" style={{color: 'black', fontSize: '15px', textDecoration: 'none'}}>Android App</a></li>
                </ul>
            </div>

            <div className="col-md-3 mb-md-0 mb-3">
                <h5 className="text-uppercase" style={{color: 'black', fontWeight: 'bold', fontSize: '20px'}}>Social Media</h5>
                <ul className="list-unstyled" style={{color: 'black'}}>
                    <li><a href="https://www.xiaohongshu.com/" style={{color: 'black', fontSize: '15px', textDecoration: 'none'}}>RED(小红书)</a></li>
                    <li><a href="https://www.instagram.com/" style={{color: 'black', fontSize: '15px', textDecoration: 'none'}}>Instagram</a></li>
                    <li><a href="https://twitter.com/" style={{color: 'black', fontSize: '15px', textDecoration: 'none'}}>X(Twitter)</a></li>
                    <li><a href="https://www.facebook.com/" style={{color: 'black', fontSize: '15px', textDecoration: 'none'}}>Facebook</a></li>
                </ul>
            </div>

            
        </div>
    </div>

    <div className="footer-copyright text-center py-3" style={{color: 'black'}}>© 2020-2024 Copyright  
        <a href="/" style={{color: 'black', textDecoration: 'none'}}> Event Go Digital Inc</a>
    </div>

</footer>

export default Footer
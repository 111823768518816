import React, { useRef, useState, useCallback, useEffect } from "react";
import {
  Form,
  Button,
  Card,
  Alert,
  Container,
  DropdownButton,
  Dropdown,
  Modal,
  Tabs,
  Tab,
  ButtonGroup,

} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { fires, auth } from "../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import uuid from "react-uuid";
import { sendEventEmail, sendEmailTo, sendEventCheckinEmail } from "../contexts/API";
import { useTranslation } from "react-i18next";

export default function EventExpressCheckIn() {

  const { currentUser, logout, signup, login } = useAuth();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "50%";

  let { id, checkInCode } = useParams();
  const { t, i18n } = useTranslation();


  const [showRegister, setShowRegister] = useState(false);

  const [userInfo, setUserInfo] = useState({});
  const [eventInfo, setEventInfo] = useState({});
  const [posts, setPosts] = useState([]);
  const [matchID, setMatchID] = useState("");

  const [registerType, setRegisterType] = useState(0);
  const privacyLink = "https://www.leyuanreview.com/privacy";

  const [eventDetailsInfo, setEventDetailsInfo] = useState({
    title: eventInfo.title,
    descriptions: [eventInfo.description],
    orgName: "",
    orgURL: "",
    language: "zh",
  });

  const [eventOptions, setEventOptions] = useState({
    poll:false,
    draw:false,
    namecard:false,
    review:false,
    comment:true,
    picture:true,
    socialmedia:true,
    'register-feedback':false,
    'accept-registration':true,
    max:500})

  const [eventPriceOptions, setEventPriceOptions] = useState({
      priceType: "FREE",
      price: 0,
      priceOriginal: 0,
      priceId: "",
      productId: "",
      priceCurrency: "CAD",
      priceEMTEmail: "",
    });

  //0 is signup, 1 is checkin, 2 is finished, 3 is not found
  const [checkinStatus, setCheckinStatus] = useState(0);


  function createTicketId() {
    const fNumber = Math.floor(1000 + Math.random() * 9000);
    const sNumber = Math.floor(1000 + Math.random() * 9000);
    const tNumber = Math.floor(1000 + Math.random() * 9000);
    const idString =
      fNumber.toString() + "-" + sNumber.toString() + "-" + tNumber.toString();
    return idString;
  }

  function registerTicket(ticket, eTitle, eId, eOrgName) {
    if (ticket != "") {
      const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
      var ticketInfo = {
        validated: true,
        eventTitle: eTitle,
        eventId: eId,
        eventOrgName: eOrgName,
        timestamp: timestampNow,
      };
      fires.collection("event_tickets").doc(ticket).set(ticketInfo);
    }
  }

  function gotoLiveDashboard(){
    const routePath = "/live-event-dashboard/"+id
    window.location.href = routePath
  }

  const coverImg =
    "url(https://images.unsplash.com/photo-1610730297006-ed0e847c34f9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)";
  const profileImg =
    "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png";

    function createMemberJoinLog(eType, uEmail, uidd) {
      const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
      const joinLog = {
        timestamp: timestampNow,
        type: eType,
        email: uEmail,
        uid: uidd,
      };
      fires.collection("events").doc(id).collection("logs").add(joinLog);
    }

    async function handleUserTicket(e) {
      e.preventDefault();
      const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
      const uFirstName = e.target[0].value;
      const uLastName = e.target[1].value;
      const uEmail = e.target[2].value.toLowerCase();
      const inviteEmail = e.target[3].value.toLowerCase()
      setError("");
      setLoading(true);

      try{
        const ticketId = createTicketId();
        fires
                    .collection("events")
                    .doc(id)
                    .collection("members")
                    .doc(uEmail)
                    .set({
                      name: uFirstName + " " + uLastName,
                      email: uEmail,
                      firstName: uFirstName,
                      lastName: uLastName,
                      checkinStatus: true,
                      checkinTimestamp: timestampNow,
                      wechat: '',
                      work: '',
                      invitedBy: inviteEmail,
                      ticketId: ticketId,
                      lang: i18n.language,
                      source: 'express-checkin',
                      timestamp: timestampNow,
                    })
                    .then(() => {
                      const dateString =
                        eventInfo.startDate.toDate().toLocaleDateString("zh") +
                        " " +
                        eventInfo.startDate.toDate().toLocaleTimeString("zh");
                      var orgName = "";
                      if (eventInfo.user.userOrg != null) {
                        orgName = eventInfo.user.userOrg;
                      }
                      registerTicket(
                        ticketId,
                        eventDetailsInfo.title,
                        id,
                        eventDetailsInfo.orgName
                      );
                      sendEventEmail(
                        uEmail,
                        eventDetailsInfo.title,
                        i18n.language,
                        dateString,
                        eventInfo.address.fullAddress == ""
                          ? "线上活动"
                          : eventInfo.address.fullAddress,
                        eventInfo.id,
                        uFirstName + " " + uLastName,
                        ticketId,
                        eventDetailsInfo.orgName
                      );

                      window.alert('报名成功！将返回活动页面')
                      window.location.href = "/"+id
                      
                    })
      }
      catch{}
    }

    async function handleUserRegister(e) {
      e.preventDefault();
      const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
      const uFirstName = e.target[0].value;
      const uLastName = e.target[1].value;
      const uEmail = e.target[2].value.toLowerCase();
      const uPassword = e.target[3].value;
      const uPassword2 = e.target[4].value;
      const inviteEmail = e.target[5].value;
  
      const uWork = "";
      const uWechat = "";
  
      if (uPassword !== uPassword2) {
        return setError(
          i18n.language == "en" ? "Passwords are not matched" : "密码输入不一致"
        );
      }
      setError("");
      setLoading(true);
      try {
        await signup(uEmail, uPassword);
        const uid = auth.currentUser.uid;
        const email = auth.currentUser.email;
        var uevents = [];
        uevents.push(id);
        var eventDat = [];
        const ticketId = createTicketId();
        eventDat.push({
          uid: uid,
          orgId: eventInfo.orgId,
          timestamp: timestampNow,
          eventId: id,
          ticketId: ticketId,
        });
        fires
          .collection("users")
          .doc(uid)
          .set({
            userName: uFirstName + " " + uLastName,
            name: uFirstName + " " + uLastName,
            firstName: uFirstName,
            lastName: uLastName,
            work: uWork,
            wechat: uWechat,
            email: uEmail,
            lang: i18n.language,
            currentEvent: id,
            events: uevents,
            eventData: eventDat,
            source: 'express-checkin',
            timestamp: timestampNow,
          })
          .then(() => {
            fires
              .collection("events")
              .doc(id)
              .collection("members")
              .doc(uEmail)
              .get()
              .then((memberSnap) => {
                if (memberSnap.exists) {
                  //if ticket exist already
                  fires
                .collection("events")
                .doc(id)
                .collection("members")
                .doc(uEmail).update({checkinStatus: true, checkinTimestamp: timestampNow}).then(()=>{
                  createMemberJoinLog("join", uEmail, uid);
                  createMemberJoinLog("checkin", uEmail, uid);
                  gotoLiveDashboard()
                })
                  
                } else {
                  //if ticket not exist
                  fires
                    .collection("events")
                    .doc(id)
                    .collection("members")
                    .doc(uEmail)
                    .set({
                      name: uFirstName + " " + uLastName,
                      email: uEmail,
                      firstName: uFirstName,
                      lastName: uLastName,
                      checkinStatus: true,
                      checkinTimestamp: timestampNow,
                      wechat: uWechat,
                      work: uWork,
                      invitedBy: inviteEmail,
                      ticketId: ticketId,
                      lang: i18n.language,
                      source: 'express-checkin',
                      timestamp: timestampNow,
                    })
                    .then(() => {
                      const dateString =
                        eventInfo.startDate.toDate().toLocaleDateString("zh") +
                        " " +
                        eventInfo.startDate.toDate().toLocaleTimeString("zh");
                      var orgName = "";
                      if (eventInfo.user.userOrg != null) {
                        orgName = eventInfo.user.userOrg;
                      }
                      registerTicket(
                        ticketId,
                        eventDetailsInfo.title,
                        id,
                        eventDetailsInfo.orgName
                      );
                      sendEventEmail(
                        uEmail,
                        eventDetailsInfo.title,
                        i18n.language,
                        dateString,
                        eventInfo.address.fullAddress == ""
                          ? "线上活动"
                          : eventInfo.address.fullAddress,
                        eventInfo.id,
                        uFirstName + " " + uLastName,
                        ticketId,
                        eventDetailsInfo.orgName
                      );
                      createMemberJoinLog("join", uEmail, uid);
                      createMemberJoinLog("checkin", uEmail, uid);
                      gotoLiveDashboard()

                      
                    });
                }
              });
          });
        setLoading(false);
      } catch {
        setError(
          i18n.language == "en"
            ? "Can not create account, account is already existed"
            : "无法创建账户，账户已存在"
        );
      }
    }
    async function handleUserLogin(e) {
      e.preventDefault();
      const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
      const uEmail = e.target[0].value;
      const uPassword = e.target[1].value;
      const inviteEmail = e.target[2].value;
      setError("");
      setLoading(true);
  
      try {
        await login(uEmail, uPassword);
        const uid = auth.currentUser.uid;
        fires
          .collection("users")
          .doc(uid)
          .get()
          .then((snapshotA) => {
            if (snapshotA.exists) {
              const usersData = snapshotA.data();
              var usersName = "";
              var userFirstName = "";
              var userLastName = "";
              var usersWork = "";
              var usersWechat = "";
              if (usersData.name != null) {
                usersName = usersData.name;
              }
              if (usersData.firstName != null) {
                userFirstName = usersData.firstName;
              }
              if (usersData.lastName != null) {
                userLastName = usersData.lastName;
              }
              if (usersData.work != null) {
                usersWork = usersData.work;
              }
              if (usersData.wechat != null) {
                usersWechat = usersData.wechat;
              }
  
              var eventList = [];
              if (usersData.events != null) {
                usersData.events.map((eId) => {
                  eventList.push(eId);
                });
              }
              if (!eventList.includes(id)) {
                eventList.push(id);
              }
              fires
                .collection("users")
                .doc(uid)
                .update({
                  currentEvent: id,
                  events: eventList,
                  timestamp: timestampNow,
                })
                .then(() => {
                  fires
                    .collection("events")
                    .doc(id)
                    .collection("members")
                    .doc(uEmail)
                    .get()
                    .then((eventMSnapshot) => {
                      if (eventMSnapshot.exists) {
                        //member exist
                          fires
                          .collection("events")
                          .doc(id)
                          .collection("members")
                          .doc(uEmail)
                          .update({
                            name: usersName,
                            work: usersWork,
                            email: uEmail,
                            checkinStatus: true,
                            checkinTimestamp: timestampNow,
                            invitedBy: inviteEmail,
                            wechat: usersWechat,
                            source: 'express-checkin',
                            timestamp: timestampNow,
                          })
                          .then(() => {
                            //
                            createMemberJoinLog("checkin", uEmail, uid);
                            gotoLiveDashboard()
                            
                          });
                      } else {
                        //member not exist
                        const ticketId = createTicketId();
                        fires
                          .collection("events")
                          .doc(id)
                          .collection("members")
                          .doc(uEmail)
                          .set({
                            name: usersName,
                            firstName: userFirstName,
                            lastName: userLastName,
                            checkinStatus: true,
                            checkinTimestamp: timestampNow,
                            work: usersWork,
                            email: uEmail,
                            wechat: usersWechat,
                            ticketId: ticketId,
                            lang: i18n.language,
                            timestamp: timestampNow,
                          })
                          .then(() => {
                            const dateString =
                              eventInfo.startDate
                                .toDate()
                                .toLocaleDateString("zh") +
                              " " +
                              eventInfo.startDate
                                .toDate()
                                .toLocaleTimeString("zh");
                            var orgName = "";
                            if (eventInfo.user.userOrg != null) {
                              orgName = eventInfo.user.userOrg;
                            }
                            registerTicket(
                              ticketId,
                              eventDetailsInfo.title,
                              id,
                              eventDetailsInfo.orgName
                            );
                            sendEventEmail(
                              uEmail,
                              eventDetailsInfo.title,
                              i18n.language,
                              dateString,
                              eventInfo.address.fullAddress == ""
                                ? "线上活动"
                                : eventInfo.address.fullAddress,
                              eventInfo.id,
                              usersName,
                              ticketId,
                              eventDetailsInfo.orgName
                            );
                            createMemberJoinLog("checkin", uEmail, uid);
                            gotoLiveDashboard()
                            
                          });
                      }
                    });
                });
            } else {
              fires
                .collection("users")
                .doc(uid)
                .set({
                  userName: "",
                  name: "",
                  firstName: "",
                  lastName: "",
                  work: "",
                  wechat: "",
                  email: uEmail,
                  currentEvent: id,
                  events: [],
                  timestamp: timestampNow,
                })
                .then(() => {
                  if (i18n.language == "en") {
                    setError(
                      "Login successfully, but we can't find your account"
                    );
                  } else {
                    setError("登录成功,但无法找到用户,系统已帮您自动生成新用户");
                  }
                });
            }
          });
        setLoading(false);
      } catch {
        if (i18n.language == "en") {
          setError("Login failed, username and password not matched");
        } else {
          setError("无法登录，用户名或密码不正确");
        }
      }
    }

  useEffect(() => {
    fires
      .collection("events")
      .doc(id)
      .get()
      .then((snapshot) => {
        if (snapshot.exists) {

          const eInfo = snapshot.data();
          setEventInfo({ ...eInfo });

          //check if pin matches
          if (eInfo.pin != checkInCode || checkInCode == ''){
            window.location.href = "/"+id
          }

          var localeTitle = eInfo.title;
          var localeDescriptions = [eInfo.description];
          var localeOrgName = "";
          var localeUserOrgURL = "";
          if (eInfo.user.userOrg != null) {
            localeOrgName = eInfo.user.userOrg;
          }
          i18n.changeLanguage('zh')
          if (eInfo.titles != null) {
            if (eInfo.titles.en != null) {
              localeTitle = eInfo.titles.en;
            }
          }
          if (eInfo.descriptions != null) {
            if (eInfo.descriptions.en != null) {
              localeDescriptions = eInfo.descriptions.en;
            }
          }
          if (eInfo.user.userOrgNames != null) {
            if (eInfo.user.userOrgNames.en != null) {
              localeOrgName = eInfo.user.userOrgNames.en;
            }
          }
          if (eInfo.user.userOrgURLs != null) {
            if (eInfo.user.userOrgURLs.en != null) {
              localeUserOrgURL = eInfo.user.userOrgURLs.en;
            }
          }
          setEventDetailsInfo({
            title: localeTitle,
            descriptions: localeDescriptions,
            orgName: localeOrgName,
            orgURL: localeUserOrgURL,
            language: i18n.language,
          });

          //check if status match
          if (eInfo.status != null){
            if (eInfo.status == 'private' || eInfo.status == 'ended'){
              window.location.href = "/"+id
            }
          }

          //check if user login

          var currentEmail = ""
          if (auth.currentUser != null){
              currentEmail = auth.currentUser.email
          }
          if (currentEmail != "") {
            //if user is login
            fires
              .collection("events")
              .doc(id)
              .collection("members")
              .doc(currentEmail)
              .get()
              .then((snapshotA) => {
                const uid = auth.currentUser.uid;
                if (snapshotA.exists) {
                  //email found
                  const memData = snapshotA.data()
                  const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
                  if (eInfo.pin == checkInCode && checkInCode != ""){
                    if (memData.checkinStatus != null){
                        if (memData.checkinStatus){
                            gotoLiveDashboard()
                        }
                        else{
                            fires
                            .collection("events")
                            .doc(id)
                            .collection("members")
                            .doc(currentEmail).update({
                              checkinStatus: true, 
                              checkinTimestamp: timestampNow}).then(()=>{
                              createMemberJoinLog("checkin",currentEmail,auth.currentUser.uid)
                              gotoLiveDashboard()
                        })

                        }
                    }
                    else{
                        fires
                        .collection("events")
                        .doc(id)
                        .collection("members")
                        .doc(currentEmail).update({
                          checkinStatus: true, 
                          checkinTimestamp: timestampNow}).then(()=>{
                            gotoLiveDashboard()
                        })
                    }
                    
                  }
                  else{
                      //not found
                      window.location.href = "/"+id
                  }
                  
                } else {
                  //user email not found
                  window.location.href = "/"+id
                }
              });
          } else {
            //user not login
            setShowRegister(true)
          }
        } else {
          //event not found
          window.location.href = "/"
        }
      });
  }, []);

  

  return (
    <>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          backgroundImage: coverImg,
        }}
      >
        <div className="w-100" style={{ maxWidth: maxW }}>
          <Card
            className="border-0"
            style={{ marginTop: "35px", marginBottom: "35px" }}
          >
            <Card.Body>
              <h2 className="text-center mb-4">{eventDetailsInfo.title}</h2>
              {error && <Alert variant="danger">{error}</Alert>}
              <Card.Text className="text-center">正在跳转 Redirecting...</Card.Text>
              <div className="d-flex align-items-center justify-content-center">
                <Card.Link className="text-center" onClick={()=>{
                    if (eventInfo.status == 'ended' || eventInfo.status == 'private'){

                    }
                    else{
                      setShowRegister(true)
                    }
                   
                   }}>点击此处报名注册</Card.Link>
              </div>
            </Card.Body>
          </Card>
        </div>

        <Modal
                  show={showRegister}
                  onHide={() => {
                    setShowRegister(false);
                  }}
                >
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {t("event_details_registration_header_registration")}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <div
                      className="d-flex align-items-center justify-content-center"
                      id="select-type"
                      style={{ marginBottom: "15px", marginTop: "25px" }}
                    >
                      {/* <ButtonGroup className="align-items-center justify-content-center">
                        <Button
                          variant={registerType == 0 ? "dark" : "outline-dark"}
                          onClick={() => {
                            setRegisterType(0);
                          }}
                        >
                          {t("event_details_registration_header_signup")}
                        </Button>
                        <Button
                          variant={registerType == 1 ? "dark" : "outline-dark"}
                          onClick={() => {
                            setRegisterType(1);
                          }}
                        >
                          {t("event_details_registration_header_login")}
                        </Button>
                      </ButtonGroup> */}
                      <ButtonGroup className="align-items-center justify-content-center">
                        <Button
                          variant={i18n.language == 'en' ? "dark" : "outline-dark"}
                          onClick={() => {
                            i18n.changeLanguage('en')
                          }}
                        >
                          {'English'}
                        </Button>
                        <Button
                          variant={i18n.language == 'zh' ? "dark" : "outline-dark"}
                          onClick={() => {
                            i18n.changeLanguage('zh')
                          }}
                        >
                          {'中文'}
                        </Button>
                      </ButtonGroup>
                    </div>
                    <Form
                      hidden={registerType == 1}
                      onSubmit={handleUserTicket}
                    >
                      <Form.Group className="mb-3" controlId="register.lastn">
                        <Form.Label>
                          {t(
                            "event_details_registration_header_signup_firstname"
                          )}{" "}
                          *
                        </Form.Label>
                        <Form.Control
                          type="text"
                          defaultValue=""
                          autoFocus
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="register.firstn">
                        <Form.Label>
                          {t(
                            "event_details_registration_header_signup_lastname"
                          )}{" "}
                          *
                        </Form.Label>
                        <Form.Control type="text" defaultValue="" required />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="register.email">
                        <Form.Label>
                          {t("event_details_registration_header_signup_email")}{" "}
                          *
                        </Form.Label>
                        <Form.Control type="email" defaultValue="" required />
                      </Form.Group>
                      {/* <Form.Group className="mb-3" controlId="register.pass">
                        <Form.Label>
                          {t(
                            "event_details_registration_header_signup_password"
                          )}{" "}
                          *
                        </Form.Label>
                        <Form.Control
                          type="password"
                          defaultValue=""
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="register.pass2">
                        <Form.Label>
                          {t(
                            "event_details_registration_header_signup_password_repeat"
                          )}{" "}
                          *
                        </Form.Label>
                        <Form.Control
                          type="password"
                          defaultValue=""
                          required
                        />
                      </Form.Group> */}
                      <Form.Group
                        className="mb-3"
                        controlId="register.email"
                        style={{ marginTop: "10px" }}
                      >
                        <Form.Label>
                          {t(
                            "event_details_registration_header_optional_invitation"
                          )}
                        </Form.Label>
                        <Form.Control type="email" defaultValue="" />
                      </Form.Group>

                      <Form.Group
                        className="align-items-start"
                        controlId="price-item"
                        style={{ marginTop: "15px" }}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="fw-normal">
                            {t("event_details_registration_header_event_type")}
                          </div>
                          <div className="fw-bold">
                            {eventPriceOptions.priceType == ""
                              ? t(
                                  "event_details_registration_header_free_event"
                                )
                              : eventPriceOptions.priceType == "FREE"
                              ? t(
                                  "event_details_registration_header_free_event"
                                )
                              : t(
                                  "event_details_registration_header_paid_event"
                                )}
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="fw-normal">
                            {t(
                              "event_details_registration_header_payment_type"
                            )}
                          </div>
                          <div className="fw-bold">
                            {eventPriceOptions.priceType == "EMT"
                              ? "E-Transfer"
                              : eventPriceOptions.priceType == "FREE"
                              ? t(
                                  "event_details_registration_header_free_event"
                                )
                              : eventPriceOptions.priceType == ""
                              ? t(
                                  "event_details_registration_header_free_event"
                                )
                              : t("event_details_registration_header_credit")}
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="fw-normal">
                            {t(
                              "event_details_registration_header_original_price"
                            )}
                          </div>
                          <div className="fw-bold">
                            {eventPriceOptions.priceOriginal == 0
                              ? t("event_details_registration_header_free")
                              : "$" +
                                Math.round(
                                  eventPriceOptions.priceOriginal * 100
                                ) /
                                  100 +
                                eventPriceOptions.priceCurrency}
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="fw-normal">
                            {t("event_details_registration_header_service_fee")}
                          </div>
                          <div className="fw-bold">
                            {eventPriceOptions.price == 0
                              ? t("event_details_registration_header_free")
                              : "$" +
                                Math.round(
                                  (eventPriceOptions.price -
                                    eventPriceOptions.priceOriginal) *
                                    100
                                ) /
                                  100 +
                                eventPriceOptions.priceCurrency}
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="fw-bold">
                            {t("event_details_registration_header_total_price")}
                          </div>
                          <div className="fw-bold">
                            {eventPriceOptions.price == 0
                              ? t("event_details_registration_header_free")
                              : "$" +
                                eventPriceOptions.price +
                                eventPriceOptions.priceCurrency}
                          </div>
                        </div>
                      </Form.Group>

                      <div
                        className="d-flex align-items-center justify-content-center"
                        id="form-register"
                        style={{ marginTop: "25px" }}
                      >
                        <Button
                          style={{ width: "100%" }}
                          variant="success"
                          type="submit"
                          disabled={eventInfo.status == "private"}
                        >
                          {t("event_details_registration_header_signup_action")}
                        </Button>
                      </div>

                      <Form.Group
                        className="d-flex align-items-center justify-content-center"
                        style={{ marginTop: "15px" }}
                        controlId="register.instruction"
                      >
                        <Card.Link
                          className="text-center"
                          style={{ color: "black" }}
                          href={privacyLink}
                        >
                          {t(
                            "event_details_registration_header_signup_action_privacy"
                          )}
                        </Card.Link>
                      </Form.Group>
                    </Form>

                    <Form hidden={registerType == 0} onSubmit={handleUserLogin}>
                      <Form.Group className="mb-3" controlId="register.email">
                        <Form.Label>
                          {t("event_details_registration_header_login_email")} *
                        </Form.Label>
                        <Form.Control
                          type="email"
                          defaultValue=""
                          autoFocus
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="register.pass">
                        <Form.Label>
                          {t(
                            "event_details_registration_header_login_password"
                          )}{" "}
                          *
                        </Form.Label>
                        <Form.Control
                          type="password"
                          defaultValue=""
                          required
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="register.email"
                        style={{ marginTop: "10px" }}
                      >
                        <Form.Label>
                          {t(
                            "event_details_registration_header_optional_invitation"
                          )}
                        </Form.Label>
                        <Form.Control type="email" defaultValue="" />
                      </Form.Group>
                      <Form.Group
                        className="align-items-start"
                        controlId="price-item"
                        style={{ marginTop: "15px" }}
                      >
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="fw-normal">
                            {t("event_details_registration_header_event_type")}
                          </div>
                          <div className="fw-bold">
                            {eventPriceOptions.priceType == ""
                              ? t(
                                  "event_details_registration_header_free_event"
                                )
                              : eventPriceOptions.priceType == "FREE"
                              ? t(
                                  "event_details_registration_header_free_event"
                                )
                              : t(
                                  "event_details_registration_header_paid_event"
                                )}
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="fw-normal">
                            {t(
                              "event_details_registration_header_payment_type"
                            )}
                          </div>
                          <div className="fw-bold">
                            {eventPriceOptions.priceType == "EMT"
                              ? "E-Transfer"
                              : eventPriceOptions.priceType == "FREE"
                              ? t(
                                  "event_details_registration_header_free_event"
                                )
                              : eventPriceOptions.priceType == ""
                              ? t(
                                  "event_details_registration_header_free_event"
                                )
                              : t("event_details_registration_header_credit")}
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="fw-normal">
                            {t(
                              "event_details_registration_header_original_price"
                            )}
                          </div>
                          <div className="fw-bold">
                            {eventPriceOptions.priceOriginal == 0
                              ? t("event_details_registration_header_free")
                              : "$" +
                                Math.round(
                                  eventPriceOptions.priceOriginal * 100
                                ) /
                                  100 +
                                eventPriceOptions.priceCurrency}
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="fw-normal">
                            {t("event_details_registration_header_service_fee")}
                          </div>
                          <div className="fw-bold">
                            {eventPriceOptions.price == 0
                              ? t("event_details_registration_header_free")
                              : "$" +
                                Math.round(
                                  (eventPriceOptions.price -
                                    eventPriceOptions.priceOriginal) *
                                    100
                                ) /
                                  100 +
                                eventPriceOptions.priceCurrency}
                          </div>
                        </div>
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="fw-bold">
                            {t("event_details_registration_header_total_price")}
                          </div>
                          <div className="fw-bold">
                            {eventPriceOptions.price == 0
                              ? t("event_details_registration_header_free")
                              : "$" +
                                eventPriceOptions.price +
                                eventPriceOptions.priceCurrency}
                          </div>
                        </div>
                      </Form.Group>
                      <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ marginTop: "25px" }}
                        id="form-login"
                      >
                        <Button
                          style={{ width: "100%" }}
                          variant="success"
                          type="submit"
                          disabled={eventInfo.status == "private"}
                        >
                          {t("event_details_registration_header_login_action")}
                        </Button>
                      </div>
                      <Form.Group
                        className="d-flex align-items-center justify-content-center"
                        style={{ marginTop: "15px" }}
                        controlId="register.instruction"
                      >
                        <Card.Link
                          className="text-center"
                          style={{ color: "black" }}
                          href="/forgot-password"
                        >
                          {t(
                            "event_details_registration_header_login_action_forgetpass"
                          )}
                        </Card.Link>
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      onClick={() => {
                        setShowRegister(false);
                      }}
                    >
                      {t("event_details_action_close")}
                    </Button>
                  </Modal.Footer>
                </Modal>

      </Container>
    </>
  );
}

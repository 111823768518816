import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert, Container, Row, Col, FloatingLabel, ListGroup} from "react-bootstrap"
import { useAuth } from "../../contexts/AuthContext"
import { Link } from "react-router-dom"
import {isMobile} from 'react-device-detect';
import { fires } from "../../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import { Stepper } from 'react-form-stepper';
import { useGeolocated } from "react-geolocated";
import { sendEmailTo } from "../../contexts/API";

export default function CreateGroups() {
  const emailRef = useRef()
  const { resetPassword } = useAuth()
  const [error, setError] = useState("")
  const [message, setMessage] = useState("")
  const [loading, setLoading] = useState(false)
  const maxW = isMobile ? "100%" : "75%"
  const [currentPage, setCurrentPage] = useState(0)
  const [planSelected, setPlanSelected] = useState(0)

  const groupNameRef = useRef()
  const groupDescriptionRef = useRef()
  const groupLocationRef = useRef()
  const groupIdRef = useRef()
  const emailAddressRef = useRef()
  const phoneNumRef = useRef()

  const [currentAddress, setCurrentAddress] = useState({
      fullAddress: '',
      lat: 0.0,
      lng: 0.0,
  })
  const { coords, isGeolocationAvailable, isGeolocationEnabled } =
        useGeolocated({
            positionOptions: {
                enableHighAccuracy: false,
            },
            userDecisionTimeout: 5000,
  });

  function createNewServiceRequestEmail(refId){

    var phoneNum = ""
    var emailA = ""
    var name = ""
    var description = ""
    var glocation = ""
    var gId = ""
    phoneNum = phoneNumRef.current.value
    emailA = emailAddressRef.current.value
    name = groupNameRef.current.value
    description = groupDescriptionRef.current.value
    glocation = groupLocationRef.current.value
    gId = groupIdRef.current.value

    var serviceReqTitle = "[Riley Events] New Service Request: "+name
    var serviceReqBody = "Hi Sam, \nNew Service request created from " + emailA + "\n\nName: "+name + "\nDescription: "+description + "\nID: " + gId + "\nLocation: " + glocation + "Reference ID: " + refId

    sendEmailTo(
      'admin@letjoy.app',
      serviceReqTitle,
      serviceReqBody)
  }

  
  

  const logoImg = "https://images.unsplash.com/photo-1506157786151-b8491531f063?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
  return (
    <>
    <Container
      className="d-flex align-items-center justify-content-center"
      style={{ minHeight: "100vh", minWidth: "100vw", backgroundImage: "url(https://images.unsplash.com/photo-1489549132488-d00b7eee80f1?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80)" }}
    >
      <div className="w-100" style={{ maxWidth: maxW }}>

      <Card style={{marginTop: '25px', marginBottom: '25px'}}>
        <Card.Body>
          <h1 className="text-center mb-4" style={{fontWeight: 'bold'}}>Create Your Group</h1>
          {error && <Alert variant="danger">{error}</Alert>}
          {message && <Alert variant="success">{message}</Alert>}
          <Card.Link 
          hidden={currentPage == 0}
          style={{
              fontSize: '15px', 
              fontWeight: 'bold', 
              color: 'black', 
              marginLeft: '5px', 
              marginRight: '5px'
            }} onClick={()=>{
                if (currentPage > 0) {
                    setCurrentPage(currentPage-1)
                }
          }}> Back</Card.Link>
          <Stepper
            steps={
                [
                    { label: 'Group Name' }, 
                    { label: 'Group Details' }, 
                    { label: 'Group Location' }, 
                    { label: 'Pricing' },
                    { label: 'Review' },
                ]}
            activeStep={currentPage}
            />
          <Form>
                <Form.Group
                  id="email"
                  hidden={currentPage != 0}
                  style={{ marginTop: "15px" }}>
                    <Form.Label style={{marginTop: '5px', fontSize: '15px', fontWeight: 'bold'}}>Group Name *</Form.Label>
                  <FloatingLabel
                    label={'Choose a group name'}>
                  <Form.Control
                    type="text"
                    rows={5}
                    placeholder=""
                    ref={groupNameRef}
                    required
                  />
                  </FloatingLabel>
                    <Form.Label style={{marginTop: '5px'}}>Pick a group name that's easy to memorize</Form.Label>
                </Form.Group>

                <Form.Group
                  id="email"
                  hidden={currentPage != 0}
                  style={{ marginTop: "15px" }}>
                    <Form.Label style={{marginTop: '5px', fontSize: '15px', fontWeight: 'bold'}}>Group ID *</Form.Label>
                  <FloatingLabel
                    label={'Enter a group ID, we recommended using xxx-xxx'}>
                  <Form.Control
                    type="text"
                    rows={5}
                    placeholder=""
                    ref={groupIdRef}
                    required
                  />
                  </FloatingLabel>
                    <Form.Label style={{marginTop: '5px'}}>Choose your group ID</Form.Label>
                </Form.Group>

                <Form.Group
                  id="email"
                  hidden={currentPage != 1}
                  style={{ marginTop: "15px" }}>
                    <Form.Label style={{marginTop: '5px', fontSize: '15px', fontWeight: 'bold'}}>Group Description *</Form.Label><br/>
                    <Form.Label>Tell us more about your group</Form.Label>
                    <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Such as, introduction? your group rule?"
                    ref={groupDescriptionRef}
                    required
                  />
                </Form.Group>

                <Form.Group
                  id="email"
                  hidden={currentPage != 2}>
                  <Form.Label style={{marginTop: '5px', fontSize: '15px', fontWeight: 'bold'}}>Group Location *</Form.Label>
                  <FloatingLabel
                    label={'Select your group location'}>
                  <Form.Control
                    type="text"
                    rows={1}
                    placeholder=""
                    defaultValue={""}
                    ref={groupLocationRef}
                    required
                  />
                  </FloatingLabel>
                </Form.Group>

                <Form.Group
                  id="email"
                  style={{marginLeft: '15px', marginRight: "15px"}}
                  hidden={currentPage != 3}>
                  <Row md={3}>
                      <Col>
                        <Card className="border-0">
                            <Card.Img src={'https://images.unsplash.com/photo-1522202176988-66273c2fd55f?auto=format&fit=crop&q=80&w=2942&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} 
                            variant="top" 
                            style={{maxHeight: '150px'}}/>
                            <Card.Title className="text-center" 
                            style={{
                                marginTop: '15px',
                                marginBottom: '5px',
                                fontSize: '25px',
                                fontWeight: 'bold',
                            }}>Basic Plan</Card.Title>
                            <Card.Text 
                            className="text-center"
                            style={{
                                marginTop: '5px',
                                marginBottom: '5px',
                                fontSize: '15px',
                                fontWeight: 'bold',
                            }}>$12.99 / Month</Card.Text>

                            <Button 
                                className="w-100"
                                style={{
                                    marginTop: '15px',
                                    marginBottom: '15px',
                                    height: '45px',
                                    backgroundColor: '#E84031',
                                    borderColor: 'transparent',
                                    fontSize: '15px',
                                    fontWeight: 'bold'
                                    }}
                                  onClick={()=>{
                                    if (currentPage < 4) {
                                      setPlanSelected(0)
                                      setCurrentPage(currentPage+1)
                                    }
                                  }}>
                                    Create Group
                                </Button>

                            <div style={{marginTop: '10px'}}>
                                <Card.Text className="text-center">Upto <b>50</b> Group Chats</Card.Text>
                                <Card.Text className="text-center">Unlimited events and attendees</Card.Text>
                                <Card.Text className="text-center">Group and event promotion to interested members</Card.Text>
                                <Card.Text className="text-center">Organizing your events with our ELMS</Card.Text>
                                <Card.Text className="text-center"><b>1</b> team member</Card.Text>
                                <Card.Text className="text-center">Free and paid events</Card.Text>
                            </div>
                            <br/>
                            <div style={{marginTop: '15px'}}>
                                <Card.Text className="text-center">-</Card.Text>
                                <Card.Text className="text-center">-</Card.Text>
                                <Card.Text className="text-center">-</Card.Text>
                                <Card.Text className="text-center">-</Card.Text>
                                <Card.Text className="text-center">-</Card.Text>
                            </div>
                        </Card>
                      </Col>

                      <Col>
                        <Card className="border-0">
                            <Card.Img src={'https://images.unsplash.com/photo-1591115765373-5207764f72e7?auto=format&fit=crop&q=80&w=2940&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} variant="top" style={{maxHeight: '150px'}}/>
                            <Card.Title className="text-center" 
                            style={{
                                marginTop: '15px',
                                marginBottom: '5px',
                                fontSize: '25px',
                                fontWeight: 'bold',
                            }}>Event Plus Plan</Card.Title>
                            <Card.Text 
                            className="text-center"
                            style={{
                                marginTop: '5px',
                                marginBottom: '5px',
                                fontSize: '15px',
                                fontWeight: 'bold',
                            }}>$24.99 / Month</Card.Text>

                                <Button 
                                className="w-100"
                                style={{
                                    marginTop: '15px',
                                    marginBottom: '15px',
                                    height: '45px',
                                    backgroundColor: '#E84031',
                                    borderColor: 'transparent',
                                    fontSize: '15px',
                                    fontWeight: 'bold'
                                    }}
                                  onClick={()=>{
                                    if (currentPage < 4) {
                                      setPlanSelected(1)
                                      setCurrentPage(currentPage+1)
                                    }
                                  }}>
                                    Create Community
                                </Button>

                            <div style={{marginTop: '10px'}}>
                                <Card.Text className="text-center"><b>Unlimited</b> Group Chats</Card.Text>
                                <Card.Text className="text-center">Unlimited events and attendees</Card.Text>
                                <Card.Text className="text-center">Group and event promotion to interested members</Card.Text>
                                <Card.Text className="text-center">Organizing your events with our ELMS</Card.Text>
                                <Card.Text className="text-center">Invite 3 team members</Card.Text>
                                <Card.Text className="text-center">Free and Paid events</Card.Text>
                            </div>
                            <br/>
                            <div style={{marginTop: '15px'}}>
                                <Card.Text className="text-center">Everything in Basic Plan</Card.Text>
                                <Card.Text className="text-center">-</Card.Text>
                                <Card.Text className="text-center">Poster and QR Code support</Card.Text>
                                <Card.Text className="text-center">Data Analytics</Card.Text>
                                <Card.Text className="text-center">Email support</Card.Text>
                            </div>
                            
                        </Card>
                      </Col>

                      <Col>
                        <Card className="border-0">
                            <Card.Img src={'https://images.unsplash.com/photo-1561489396-888724a1543d?auto=format&fit=crop&q=80&w=2940&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D'} variant="top" style={{maxHeight: '150px'}}/>
                            <Card.Title className="text-center" 
                            style={{
                                marginTop: '15px',
                                marginBottom: '5px',
                                fontSize: '25px',
                                fontWeight: 'bold',
                            }}>Advanced Plan</Card.Title>
                            <Card.Text 
                            className="text-center"
                            style={{
                                marginTop: '5px',
                                marginBottom: '5px',
                                fontSize: '15px',
                                fontWeight: 'bold',
                            }}>$64.99 / Month</Card.Text>
                            <Button 
                                className="w-100"
                                style={{
                                    marginTop: '15px',
                                    marginBottom: '15px',
                                    height: '45px',
                                    backgroundColor: '#E84031',
                                    borderColor: 'transparent',
                                    fontSize: '15px',
                                    fontWeight: 'bold'
                                    }}
                                  onClick={()=>{
                                    setPlanSelected(2)
                                    if (currentPage < 4) {
                                      setCurrentPage(currentPage+1)
                                    }
                                  }}>
                                    Create Business
                                </Button>
                            <div style={{marginTop: '10px'}}>
                                <Card.Text className="text-center"><b>Unlimited</b> Group Chats</Card.Text>
                                <Card.Text className="text-center">Unlimited events and attendees</Card.Text>
                                <Card.Text className="text-center">Group and event promotion to interested members</Card.Text>
                                <Card.Text className="text-center">Organizing your events with our ELMS</Card.Text>
                                <Card.Text className="text-center">Invite unlimited team members</Card.Text>
                                <Card.Text className="text-center">Free and Paid events</Card.Text>
                            </div>
                            <br/>
                            <div style={{marginTop: '15px'}}>
                                <Card.Text className="text-center">Everything in Event Plus Plan</Card.Text>
                                <Card.Text className="text-center">AI-generated event content</Card.Text>
                                <Card.Text className="text-center">Poster creation + QR Code Generator</Card.Text>
                                <Card.Text className="text-center">Data Analytics</Card.Text>
                                <Card.Text className="text-center">Email / Phone Support + Training</Card.Text>
                            </div>
                            
                        </Card>
                      </Col>
                      
                  </Row>
                </Form.Group>

                <Form.Group
                  id="email-address"
                  hidden={currentPage != 4}
                  style={{ marginTop: "15px" }}>
                    <Form.Label style={{marginTop: '15px', marginBottom: '5px', fontSize: '15px', fontWeight: 'bold'}}>Email Address *</Form.Label>
                  <FloatingLabel
                    label={'Email Address'}>
                  <Form.Control
                    type="text"
                    rows={5}
                    placeholder=""
                    ref={emailAddressRef}
                    required
                  />
                  </FloatingLabel>

                  <Form.Label style={{marginTop: '25px', marginBottom: '5px', fontSize: '15px', fontWeight: 'bold'}}>Phone Number</Form.Label>
                  <FloatingLabel
                    label={'Phone Number'}>
                  <Form.Control
                    type="phone"
                    rows={1}
                    placeholder=""
                    ref={phoneNumRef}
                  />
                  </FloatingLabel>

                  <Button 
            style={{
              marginTop: '25px',
              marginBottom: '15px',
              fontWeight: 'bold',
              height: '45px',
              width: '150px',
              borderColor: 'transparent',
              backgroundColor: 'black'
              }}
            onClick={()=>{
                var phoneNum = ""
                var emailA = ""
                var name = ""
                var description = ""
                var glocation = ""
                var gId = ""
                phoneNum = phoneNumRef.current.value
                emailA = emailAddressRef.current.value
                name = groupNameRef.current.value
                description = groupDescriptionRef.current.value
                glocation = groupLocationRef.current.value
                gId = groupIdRef.current.value

                var timestampNow =
                              firebase.firestore.Timestamp.fromDate(new Date());
                fires.collection('service-requests').add({
                  phoneNum: phoneNum,
                  email: emailA,
                  plan: planSelected,
                  orgId: gId,
                  type: 'admin',
                  description: description,
                  name: name,
                  location: glocation,
                  status: 'pending',
                  timestamp: timestampNow
                }).then((docD)=> {
                  const resultRefId = docD.id
                  createNewServiceRequestEmail(resultRefId)
                  window.alert('Your request has been sent for our approval, we will contact you when it finished. Reference: '+resultRefId)
                  window.location.href = "/"
                })
            }}>
              Send Request
            </Button><br/>

                  <Form.Label style={{marginTop: '25px', marginBottom: '5px', fontSize: '15px', fontWeight: 'bold'}}>We will send you the email after we received your account creation request.</Form.Label>
                  
                  

                </Form.Group>

                <Button 
            hidden={currentPage == 3 || currentPage == 4}
            style={{
              marginTop: '15px',
              marginBottom: '15px',
              fontWeight: 'bold',
              height: '45px',
              width: '120px',
              borderColor: 'transparent',
              backgroundColor: 'black'
              }}
            onClick={()=>{
                if (currentPage < 4) {
                    setCurrentPage(currentPage+1)
                }
            }}>
              Next
            </Button>
                

          </Form>
          
          

           
          

        </Card.Body>
      </Card>
        
        </div>
        </Container>
      
    </>
  )
}

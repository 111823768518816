import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert, Tab, Tabs, Carousel, Image, Container, Navbar, Row, Col } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { auth, fires} from "../firebase"
import { Link, Route, useParams } from "react-router-dom"
import {isMobile, isAndroid, isIOS} from 'react-device-detect';
import uuid from 'react-uuid';
import Youtube from 'react-youtube';
import ReactPlayer from 'react-player'
import { height, width } from "dom-helpers"
import firebase from "firebase"
import { Helmet } from "react-helmet"


export default function PostDetails() {
    const [error, setError] = useState("")
    const [index, setIndex] = useState(0);
    const { currentUser, logout } = useAuth()

    const [posts, setPosts] = useState([]);
    const [comments, setComments] = useState([]);
    const [postType, setPostType] = useState([]);

    let { id } = useParams();
    const maxW = isMobile ? "100%" : "50%"

    async function createPostLike(){
        var userUid = ""
        var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());

        if (auth.currentUser != null){
            userUid = auth.currentUser.uid
            const likeData = {
                'fromUID':userUid,
                'timestamp': timestampNow
            }
            if (!(await fires.collection('posts').doc(id).collection('likes').doc(userUid).get()).exists) {
                var postLike = {}
                if (posts[0].likes != null){
                    postLike = posts[0].likes
                    postLike[userUid] = likeData
                }
                fires.collection('posts').doc(id).collection('likes').doc(userUid).set(likeData)
                fires.collection('posts').doc(id).update({'likes': postLike})
                window.alert('You liked successfully')
            }
            else{
                window.alert('You liked this post')
            }
        }
        else{
            window.alert('Please login first')
        }
    }
    function createPostComment(){}
    function createPostShare(){}

    useEffect(() => {
        fires.collection('posts').doc(id).get().then((snapshot) => {
            if (snapshot.exists) {
                const posts = [];
                const postData = snapshot.data();
                var viewByData = postData.viewBy;
                var timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
                const platformName = 'web'
                var deviceName = 'ios'
                if (isAndroid){
                  deviceName = 'android'
                }
                else if (isIOS) {
                  deviceName = 'ios'
                }
                else {
                  deviceName = 'web'
                }
                var fuid = ''
                if (auth.currentUser != null) {
                  fuid = auth.currentUser.uid
                }
                var ua = window.navigator.userAgent.toLowerCase()
                const viewData = {
                  'fromUID':fuid, 
                  'platform': platformName, 
                  'fromUA': ua, 
                  'device': deviceName, 
                  'timestamp':timestampNow}
                viewByData[uuid()] = viewData
                fires.collection('posts').doc(id).collection('views').add(viewData)
                fires.collection('posts').doc(id).update({'viewBy':viewByData})
                
                fires.collection('post_comments').where('postId','==',id).orderBy('timestamp','desc').limit(5).get().then((snapshotA) => {
                  const comments = [];
                  snapshotA.docs.forEach((doc) => {
                    const commentId = doc.id;
                    const commentData = doc.data();
                    comments.push({ ...commentData, id: commentId })
                  })
                  setComments(comments)
                })

                var postType = []
                if (postData.youtubeId != ""){
                  postType.push({"type":"Youtube"})
                }
                else if (postData.videoURL != ""){
                  postType.push({"type":"Video"})
                }
                else if (postData.imageURL == ""){
                  postType.push({"type":"Text"})
                }
                else{
                  postType.push({"type":"Images"})
                }

                setPostType(postType)


                if (postData.length == 0){
                    posts.push({ id: id, error: "NoAccess", error_message: "没有访问权限", error_code: 404 })
                    setPosts(posts)
                    document.title = "没有访问权限"
                    document.description = "请您查看是否具有访问权限"
                }
                else{
                    posts.push({ ...postData, id: id })
                    setPosts(posts)
                    document.title = postData.postContent
                    document.querySelector('meta[name="description"]').setAttribute("content",postData.postContent);
                }

                if (postData.visibility == "all") {
                    
                }
                else {
                    
                }
                
            }
            else{
                //deleted
                posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
                setPosts(posts)
                document.title = "没有访问权限"
                document.description = "请您查看是否具有访问权限"
            }
        });
      }, []);

      
    
    return (
        <div>
        {posts.map((post) => {

        var error = post.error;

        var youtubeId = (post.youtubeId != null) ? post.youtubeId : ""
        var youtubeURL = "https://youtu.be/" + youtubeId
        var videoURL = (post.videoURL != null) ? post.videoURL : ""
        var postImages = []
        postImages = post.imageURLs
        var postTopics = []

        const opts = {
          height: '350',
          width: '100%',
          playerVars: {
            autoplay: 0,
          },
        }

        if (post.topics != null){
            Object.entries(post.topics).map(([key,value])=>{
                postTopics.push(key)
            })
        }
        
        if (error == "NotFound") {
            return <Card>
            <Card.Body>
              <Card.Title>404</Card.Title>
              <Card.Text>该用户已删除或内容不存在</Card.Text>
              <Card.Text>User deleted or the post doesn't exist</Card.Text>
              <Card.Link href="/">返回首页</Card.Link>
            </Card.Body>
            </Card>
        }
        else {
          return <>
          <Helmet>
                  <title>{post.postContent}</title>     
                  <meta property="og:title" content={post.postContent} />        
                  <meta property="og:description" content={post.user.userName+"的动态"} />        
                  <meta property="og:url" content={"https://letjoy.app/post/"+post.id} />
                  <meta property="og:site_name" content={post.postContent} />
                  <meta property="og:locale" content="en_US" />
                  <meta property="og:type" content="article" />
                  <meta property="og:image" content={post.user.profileURL} />
                </Helmet>
                
          <Container
          className="d-flex justify-content-center"
          style={{ minHeight: "100vh", minWidth: '100vw' }}
        >
          <div className="w-100" style={{ maxWidth: maxW, marginTop: '25px', marginBottom: '25px' }}>
          <Card className={isMobile ? 'border-0' : ''}>
          {
            postType.map(typeItm => {
              if (typeItm.type == "Youtube") {
                return <Youtube videoId={youtubeId} opts={opts} />
              }
              else if (typeItm.type == "Video"){
                return <ReactPlayer url={videoURL} style={{maxWidth: '100%'}} playing={true} muted={true} controls/>
              }
              else if (typeItm.type == "Images"){
                return <Carousel variant="top">
                {postImages.map((imageURL) => {
                    return <Carousel.Item interval={5000}>
                    <img
                      className="d-block w-100"
                      src={imageURL}
                      alt="-"
                      style={{}}
                    />
                  </Carousel.Item>
                })}
            </Carousel>
              }
              else{

              }
            })
            
            
          }
          
          <Card.Body>

          <div className="d-flex align-items-center" style={{alignSelf: 'center', display: 'flex'}}>
            <Image roundedCircle src={post.user.profileURL} width='50px' height='50px'/>
            <div style={{marginLeft: '5px'}}>
              <Card.Text style={{fontSize: '18px', fontWeight: 'bold', marginTop: '15px', marginBottom: '0px', color: 'black'}}>{post.user.userName}</Card.Text>
              <Card.Text class="text-secondary" style={{fontSize: '12px', fontWeight: 'normal'}}>Published on {post.timestamp.toDate().toLocaleDateString("en")} {post.postLocation}</Card.Text>
            </div>
            </div>

            <br/>
            <Card.Title>{post.user.userName}'s Post</Card.Title>
            <Card.Text>{post.postContent}</Card.Text>
            
            <br/>
            
            {postTopics.map((topicItem) => {
              return <Card.Link>#{topicItem}</Card.Link>
          })}
          <br/>
            {/* <Row md={3} hidden={isMobile}>
                <Col className="d-flex align-items-center justify-content-center">
                    <Button 
                        variant="dark" 
                        style={{width: '85%', height: '40px', borderRadius: '20px'}}
                        onClick={()=>{
                            createPostLike()
                        }}>Like</Button>
                </Col>
                <Col className="d-flex align-items-center justify-content-center">
                    <Button 
                        variant="dark" 
                        style={{width: '85%', height: '40px', borderRadius: '20px'}}
                        onClick={()=>{
                            createPostComment()
                        }}
                        >Comment</Button>
                </Col>
                <Col className="d-flex align-items-center justify-content-center">
                    <Button 
                        variant="dark" 
                        style={{width: '85%', height: '40px', borderRadius: '20px'}} 
                        onClick={()=>{
                            createPostShare()
                        }}>Share</Button>
                </Col>
            </Row> */}

          <br/><br/>
            <Card.Subtitle>
            {comments.length} Comments
            </Card.Subtitle>
            
            {comments.map((comment) => {
              return <Card className="border-0">
              <Card.Body>
              <div className="d-flex align-items-center" style={{alignSelf: 'center', display: 'flex'}}>
                <Image roundedCircle src={comment.postFromProfileURL} width='50px' height='50px'/>
                <div style={{marginLeft: '10px'}}>
                  <Card.Text class="text-secondary" style={{fontSize: '15px', fontWeight: 'bold', marginTop: '15px', marginBottom: '0px', color: 'black'}}>{comment.postFromName}</Card.Text>
                  <Card.Text class="text-secondary" style={{fontSize: '12px', fontWeight: 'normal'}}>{comment.timestamp.toDate().toLocaleDateString("zh")}</Card.Text>
                </div>
              </div>
              <Card.Subtitle style={{marginLeft: '60px'}}>{comment.content}</Card.Subtitle>
              </Card.Body>
            </Card>
          })}
            <Button 
            className="w-100 mt3" 
            style={{
              alignItems:'center', 
              justifyContent:'space-between', 
              backgroundColor:'#E84031', 
              borderRadius:'20px', 
              borderColor:'#ffffff', 
              fontSize: '12px', 
              fontWeight:'bold', 
              height: '40px', 
              marginTop: "5px"}} onClick={()=>{
                if (post.shareURL != null){
                  if (post.shareURL != ""){
                    window.open(post.shareURL)
                  }
                }
                else{
                  window.alert('Could not open the app')
                }
              }}>Open app to view more</Button>
          </Card.Body>
          </Card>
          </div>
          </Container>
          </>
        }



        
        if (youtubeId != "") {
          //youtube card
          return <Card>
          <Youtube videoId={youtubeId} opts={opts} />
          <Card.Body>
            <Card.Title>[YouTube]来自{post.user.userName}的分享</Card.Title>
            <Card.Text>{post.postContent}</Card.Text>
            <div>
            <Image roundedCircle src={post.user.profileURL} width='50' height='50'/>
            <Card.Link class="text-secondary"> {post.user.userName}</Card.Link>
            </div>
            <Card.Text/>
            <Card.Text>
              发布于 {post.timestamp.toDate().toLocaleDateString("zh")} {post.postLocation}
            </Card.Text>
            <Card.Text/>
            {postTopics.map((topicItem) => {
              return <Card.Link>#{topicItem}</Card.Link>
          })}
          <Card.Text/>
            <Card.Subtitle>
              评论({comments.length})
            </Card.Subtitle>
            <Card.Text/>
            {comments.map((comment) => {
              return <Card>
              <Card.Body>
                <Image roundedCircle src={comment.postFromProfileURL} width='50' height='50'/>
                <Card.Link class="text-secondary">  {comment.postFromName}</Card.Link>
                <Card.Text/>
                <Card.Subtitle>{comment.content}</Card.Subtitle>
                <Card.Text/>
                <Card.Text>评论于 {comment.timestamp.toDate().toLocaleDateString("zh")}</Card.Text>
              </Card.Body>
            </Card>
          })}
            <Card.Text/>
          </Card.Body>
          </Card>
      }
      else{
          if (postImages.length > 0) {
            //image cards
              return <Card class="border-0">
              <Carousel variant="top">
                {postImages.map((imageURL) => {
                    return <Carousel.Item interval={1000}>
                    <img
                      className="d-block w-100"
                      src={imageURL}
                      alt="-"
                    />
                  </Carousel.Item>
                })}
            </Carousel>
              <Card.Body>
              <Image roundedCircle src={post.user.profileURL} width='50' height='50'/>
            <Card.Link class="text-secondary"> {post.user.userName}</Card.Link>
              
              
            <Card.Text/>
              <Card.Title>来自{post.user.userName}的动态</Card.Title>
            <Card.Text>{post.postContent}</Card.Text>
            
            
            <Card.Text/>
            {postTopics.map((topicItem) => {
              return <Card.Link>#{topicItem}</Card.Link>
          })}
          <Card.Text/>

          <Card.Text>
              发布于 {post.timestamp.toDate().toLocaleDateString("zh")}
            </Card.Text>

            <Card.Subtitle>
              评论({comments.length})
            </Card.Subtitle>
            <Card.Text/>
            {comments.map((comment) => {
              return <Card>
              <Card.Body>
                <Image roundedCircle src={comment.postFromProfileURL} width='50' height='50'/>
                <Card.Link class="text-secondary">  {comment.postFromName}</Card.Link>
                <Card.Text/>
                <Card.Subtitle>{comment.content}</Card.Subtitle>
                <Card.Text/>
                <Card.Text>评论于 {comment.timestamp.toDate().toLocaleDateString("zh")}</Card.Text>
              </Card.Body>
            </Card>
          })}
            
            <Card.Text/>

              </Card.Body>
              </Card>;
          }
          else{
              return <Card class="border-0">
              <Card.Img variant="top" src={post.imageURL} />
              <Card.Body>
              <Card.Title>来自{post.user.userName}的动态</Card.Title>
              <Card.Text>{post.postContent}</Card.Text>
              <Image roundedCircle src={post.user.profileURL} width='50' height='50'/>
              <Card.Link>{post.user.userName}</Card.Link>
            
            
            <Card.Text/>
            {postTopics.map((topicItem) => {
              return <Card.Link>#{topicItem}</Card.Link>
          })}
          <Card.Text/>

          <Card.Text>
              发布于{post.timestamp.toDate().toLocaleDateString("zh")}
            </Card.Text>

            <Card.Subtitle>
              评论({comments.length})
            </Card.Subtitle>
            <Card.Text/>
            {comments.map((comment) => {
              return <Card>
              <Card.Body>
                <Image roundedCircle src={comment.postFromProfileURL} width='50' height='50'/>
                <Card.Link class="text-secondary">  {comment.postFromName}</Card.Link>
                <Card.Text/>
                <Card.Subtitle>{comment.content}</Card.Subtitle>
              </Card.Body>
            </Card>
          })}
            <Card.Text/>

              </Card.Body>
              </Card>
          }

          

      }
      
      
      })}

        
        
        </div>
     )
}
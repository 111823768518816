import React, 
{useRef, 
useState, 
useCallback, 
useEffect } from "react";
import {
  Form,
  Button,
  ButtonGroup,
  Badge,
  Card,
  Alert,
  Tab,
  Tabs,
  Carousel,
  Image,
  Container,
  Row,
  Col,
  ListGroup,
  Navbar,
  Modal,
} from "react-bootstrap";
import { useAuth } from "../contexts/AuthContext";
import { Link, useNavigate, useParams, Route } from "react-router-dom";
import { isMobile, isAndroid, isIOS } from "react-device-detect";
import { fires, auth } from "../firebase";
import firebase from "firebase/app";
import "firebase/firestore";
import { Helmet } from "react-helmet";
import uuid from "react-uuid";

export default function EventFollowup() {
  
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const maxW = isMobile ? "100%" : "40%";

  let { id } = useParams();
  var currentUA = ""

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [followupDetails, setFollowupDetails] = useState({
      description: '',
      descriptions: [],
      orgId: '',
      status: 'open',
      max: 10,
      timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
  });
  const [orgDetails, setOrgDetails] = useState({});
  const [userDetails, setUserDetails] = useState({email: ''});
  const [currentFollowups, setCurrentFollowups] = useState([]);

  const submitEmailRef = useRef()
  const submitNameRef = useRef()

  const routeRedirect404 = () =>{ 
    let path = '/404' ; 
    window.location.href = path
  }

  const [coverImg,setCoverImg] =
    useState("https://images.unsplash.com/photo-1439792675105-701e6a4ab6f0?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1746&q=80");
  const [profileImg,setProfileImg] =
    useState("https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png");

    function submitFeedback(){
        const followupName = submitNameRef.current.value
        const timestampNow = firebase.firestore.Timestamp.fromDate(new Date());
        const followupEmail = submitEmailRef.current.value

        if (followupEmail != "" && currentFollowups.length < followupDetails.max){
            let cconfirm = window.confirm('您确认提交吗？您的电子邮箱 '+followupEmail)
            if (cconfirm){
                var cuuid = uuid()
              fires
            .collection('event_followups')
            .doc(id)
            .collection('public-members')
            .doc(cuuid)
            .set(
              {
                'name': followupName,
                'email': "xxx",
                'order': currentFollowups.length+1,
                'timestamp': timestampNow
              }).then(()=>{
                fires
                .collection('event_followups')
                .doc(id)
                .collection('members')
                .doc(followupEmail).set(
                    {
                    'name': followupName,
                    'email': followupEmail,
                    'order': currentFollowups.length+1,
                    'timestamp': timestampNow
                    }
                ).then(()=>{
                    window.reload()
                })
            })
            }
          }
          else {
              if (followupEmail == ""){
                window.alert('请您先输入电子邮箱')
              }
              if (currentFollowups.length >= followupDetails.max) {
                window.alert('已经满员')
              }
            
          }
        
        
    }


    useEffect(() => {
        fires.collection('event_followups').doc(id).get().then((snapshot) => {
            if (snapshot.exists) {
                const postData = snapshot.data()
                if (postData.status != null) {
                  if (postData.status == 'open'){
                    setFollowupDetails(postData)
                    const orgId = postData.orgId
                    if (orgId != ""){
                        fires.collection('groups').doc(orgId).get().then((snapshotA)=>{
                            const snapData = snapshotA.data()
                            setOrgDetails({...snapData})
                            if (snapData.profileURL != ""){
                                setProfileImg(snapData.profileURL)
                            }
                            if (snapData.coverURL != ""){
                                setCoverImg(snapData.coverURL)
                            }
                        })
                    }
                    fires.collection("event_followups").doc(id).collection("public-members").orderBy("timestamp").onSnapshot((snapshotA) => {
                        var currentF = []
                        snapshotA.docs.forEach((doc) => {
                            const snapData = doc.data()
                            currentF.push(snapData)
                        })
                        setCurrentFollowups(currentF)
                    })
                  }
                  else{
                    //not live, or ended
                    routeRedirect404()
                  }
                }
                else{
                  //not found
                  //deleted
                  routeRedirect404()
                // posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
                // setPosts(posts)
                }

            }
            else{
                //deleted
                routeRedirect404()
                // posts.push({ id: id, error: "NotFound", error_message: "没有访问权限", error_code: 404 })
                // setPosts(posts)
                
            }
        });
      }, []);

  return (
    <>
    <Helmet>
                <title>{"邀请您参与接龙"}</title>
                <meta
                  name="description"
                  content={followupDetails.description}
                />
                <meta name="twitter:card" content={profileImg} />
                <meta name="twitter:site" content="@user" />
                <meta name="twitter:creator" content="@user" />
                <meta name="twitter:title" content={"邀请您参与接龙"} />
                <meta
                  name="twitter:description"
                  content={followupDetails.description}
                />
                <meta name="twitter:image" content={profileImg} />
                <meta property="og:title" content={"邀请您参与接龙"} />
                <meta
                  property="og:description"
                  content={followupDetails.description}
                />
                <meta property="og:image" content={profileImg} />
                <meta
                  property="og:url"
                  content={"https://rileyevents.com/follow-up/" + id}
                />
                <meta
                  property="og:site_name"
                  content={"邀请您参与接龙"}
                />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
              </Helmet>
      <Container
        className="d-flex align-items-center justify-content-center"
        style={{ minHeight: "100vh",minWidth: '100vw',backgroundImage: 'url(' + coverImg + ')' }}
      >
        <div className="w-100" style={{ maxWidth: maxW }}>
          <Card
            className="border-0 align-items-center justify-content-center" 
            style={{
                alignItems: 'center', 
            justifyContent: 'center',
            backgroundColor:"white",
            marginTop: '25px',
            marginBottom: '25px'}}
          >
            
              <Card.Body className = "align-items-center justify-content-center">
              <Card.Title className="text-center"
               style={{ fontSize: "30px",
                fontWeight:'bold', 
                color: "#000000", 
                marginBottom: '15px'}}>参与接龙</Card.Title>
                <div className= "d-flex align-items-center justify-content-center" style={{
                    marginBottom: '25px'
                }}>
                    <Badge bg={currentFollowups.length == followupDetails.max ? "danger" : "success"} >{currentFollowups.length == followupDetails.max ? "满员" : "还有" + (followupDetails.max - currentFollowups.length) + "个名额"}</Badge>
                </div>
                
                {
                    followupDetails.descriptions.map((description) => {
                        return <Card.Text 
                        style={{ fontSize: "15px",
                        fontWeight:'normal', 
                        color: "#000000"}}>{description}</Card.Text>
                    })
                }

            <Form style={{marginTop: '25px'}}>
            {error && <Alert variant="danger">{error}</Alert>}
            {success && <Alert variant="success">{success}</Alert>}
            <Form.Group id="email" style={{marginTop: '15px'}}>
              <Form.Label style={{color: 'black', fontSize: '15px', fontWeight: 'bold'}}>电子邮箱 *</Form.Label>
              <Form.Control style={{ color: 'black'}} type="email" placeholder="" ref={submitEmailRef}  />
            </Form.Group>
            <Form.Group id="content" style={{marginTop: '15px'}}>
              <Form.Label style={{color: 'black', fontSize: '15px', fontWeight: 'bold'}}>名字/昵称 *</Form.Label>
              <Form.Control style={{color: 'black'}} type="name" ref={submitNameRef} rows={1} />
            </Form.Group>
            <Button className="w-100 p3" style={{
                alignItems:'center', 
                justifyContent:'space-between', 
                        backgroundColor:'#111', 
                        borderRadius:'15px', 
                        borderColor:'#ffffff', 
                        borderWidth:'3px', 
                        fontSize: '15px', 
                        fontWeight:'bold', 
                        height: '50px',
                        marginTop: "15px"}} 
                        disabled={followupDetails.max <= currentFollowups.length}
                        onClick={()=>{
                          submitFeedback()
                        }}>参与接龙</Button>
            </Form>

            <Card.Title style={{marginTop: '50px'}} >当前接龙 {currentFollowups.length} / {followupDetails.max}</Card.Title>
            <ListGroup style={{marginTop: '15px'}}>
                {
                    currentFollowups.map((followItm) => {
                        return <ListGroup.Item>{followItm.name}</ListGroup.Item>
                    })
                }
        </ListGroup>
            
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}
